import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Backdrop, IconButton, Menu, MenuItem } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { withRouter } from '../../redux/withRouter';
import {
	downloadPostFile,
	enableAccount,
	disableAccount,
} from '../../redux/actions/userActions';
import {
	setPostToEdit,
	setReportData,
	postInfringement,
	commentInfringement,
	updateAppealableContent,
	setOpenedDialog,
} from '../../redux/actions/dataActions';
import EditIcon from '@mui/icons-material/Edit';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import ConfirmationDialog from './ConfirmationDialog';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import LinkIcon from '@mui/icons-material/Link';
import UpdateIcon from '@mui/icons-material/Update';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SocialReport from '../Report/SocialReport';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import { toast } from 'react-toastify';
import CustomTooltip from './CustomTooltip';
import { t } from 'i18next';

const styles = {};

const StyledMenuEditItem = withStyles(() => ({
	root: {
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			position: 'relative',
			transition: '0.2s ease-in-out',
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 153, 255, 0.267)',
		},
	},
}))(MenuItem);
const StyledMenuOrderItem = withStyles(() => ({
	root: {
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			position: 'relative',
			bottom: '1px',
			transition: '0.2s ease-in-out',
		},
		'&:hover': {
			backgroundColor: 'rgb(209, 240, 250) 153, 255, 0.267',
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				bottom: '2px',
			},
		},
	},
}))(MenuItem);
// queryOrder;
const StyledMenuProfileItem = withStyles(() => ({
	root: {
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			color: '#0a90dd',
			position: 'relative',
			bottom: '1px',
			transition: '0.5s ease-in-out',
		},

		'&:hover': {
			backgroundColor: 'rgba(0, 153, 255, 0.267)',

			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: '#07a3fd',
			},
		},
	},
}))(MenuItem);
const StyledMenuPathItem = withStyles(() => ({
	root: {
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			color: '#2fcc71',
			position: 'relative',
			bottom: '1px',
			transition: '0.5s ease-in-out',
		},

		'&:hover': {
			backgroundColor: 'rgba(0, 153, 255, 0.267)',

			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: '#1ce570',
			},
		},
	},
}))(MenuItem);
const StyledMenuDownloadItem = withStyles(() => ({
	root: {
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			color: '#0a90dd',
			transition: '0.5s ease-in-out',
		},

		'&:hover': {
			backgroundColor: 'rgba(0, 153, 255, 0.267)',

			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: '#0a57dd',
			},
		},
	},
}))(MenuItem);

const StyledMenuReportItem = withStyles(() => ({
	root: {
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			color: '#f9ca24',
			position: 'relative',
			bottom: '1px',
			transition: '0.5s ease-in-out',
		},

		'&:hover': {
			backgroundColor: 'rgba(0, 153, 255, 0.267)',

			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: 'red',
			},
		},
	},
}))(MenuItem);
// trash icon - dot menu
const StyledMenuDeleteItem = withStyles(() => ({
	root: {
		'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
			color: 'rgb(236, 36, 36)',
			position: 'relative',
			bottom: '1px',
			transition: '0.5s ease-in-out',
		},

		'&:hover': {
			backgroundColor: 'rgba(0, 153, 255, 0.267)',

			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: 'red',
			},
		},
	},
}))(MenuItem);

export class DottedMenu extends Component {
	state = {
		anchorEl: null,
		confirmDelDialog: false,
		delOwnComment: false,
		confirmationDialog: false,
		canAppeal: true,
		openReportPanel: false,
	};

	componentDidMount() {
		// if (this.props.infringements) {
		let appealable =
			this.props.appealable === true || this.props.appealable === undefined
				? true
				: false;
		this.setState({
			canAppeal: appealable,
		});
		// }
	}
	//open dot menu
	handleClickDots = (event) => {
		// this.setState({ confirmDelDialog: true });
		this.setState({
			anchorEl: event.currentTarget,
		});
	};
	handleCloseDots = () => {
		if (this.state.anchorEl !== null) {
			this.setState({
				anchorEl: null,
			});
		}
	};
	//in dot menu confirmDelDialog post confirmDelDialog
	handleDeleteOpen = () => {
		this.setState({
			anchorEl: null,
			confirmDelDialog: true,
		});
	};

	handleDeleteClose = () => {
		this.setState({
			confirmDelDialog: false,
		});
	};
	handleDelete = () => {
		this.setState({
			confirmDelDialog: false,
		});
		this.props.deleteFunction();
	};

	setInfringement = () => {
		let props = this.props;
		let formData = {
			userId:
				props.data.adminReportedUserId === props.dataUserId
					? props.data.adminReportedUserId
					: props.dataUserId,
			increment: props.infringements ? false : true,
			appealable: this.state.canAppeal,
		};
		if (props.postId) {
			formData.postId = props.postId;
		}
		if (props.commentId) {
			formData.commentId = props.commentId;
			if (props.repliedId) {
				formData.repliedId = props.repliedId;
			}
			formData.postAuthorHandle = this.props.postAuthorHandle;
			formData.postCollection = this.props.data.post.infringements
				? 'removedPosts'
				: this.props.data.post.livePost
				? 'posts'
				: 'unreleasedPosts';
		}
		if (
			props.data.adminReportedUserId === props.dataUserId &&
			props.data.adminReportId &&
			((props.data.adminReportCollection === 'postReports' &&
				props.postComponent) ||
				(props.data.adminReportCollection === 'commentReports' &&
					props.comment))
		) {
			formData.reportId = props.data.adminReportId;
			formData.reportCollection = props.data.adminReportCollection;
		}

		if (props.profile) {
			if (props.infringements) {
				this.props.enableAccount({ userId: formData.userId });
			} else {
				this.props.disableAccount(formData);
			}
		}

		if (props.commentId) {
			props.commentInfringement({
				formData,
				commentInfring:
					props.user.adminPanel.panel === 'queryReports' &&
					props.data.adminReportCollection !== 'user'
						? true
						: false,
				reportedUserId: props.data.adminReportedUserId === props.dataUserId,
			});
		} else if (props.postComponent) {
			if (props.livePost) {
				formData.postCollection = 'posts';
			}
			if (props.livePost === false) {
				formData.postCollection = 'unreleasedPosts';
			}
			if (this.props.removedPost || this.props.infringements) {
				formData.postCollection = 'removedPosts';
			}

			props.postInfringement({
				formData,
				postInfring:
					props.user.adminPanel.panel === 'queryReports' &&
					props.data.adminReportCollection !== 'user'
						? true
						: false,
				reportedUserId: props.data.adminReportedUserId === props.dataUserId,
			});
		}
		this.setState({
			confirmationDialog: false,
		});
	};

	changeQueryOrder = (order, commentId) => {
		this.props.handleChangeQueryOrder(order, commentId);
		this.handleCloseDots();
	};

	report = () => {
		let props = this.props;
		let reportData = {};
		reportData.reportUserId = props.dataUserId;
		reportData.author = props.dataHandle;
		if (props.commentId) {
			reportData.type = 'comment';
			reportData.commentId = props.commentId;
			reportData.postId = props.postId;
			if (props.repliedId) {
				reportData.repliedId = props.repliedId;
			}
		} else if (props.postId) {
			reportData.type = 'post';
			reportData.postId = props.postId;
		} else if (props.dataUserId) {
			reportData.type = 'user';
		}
		this.handleCloseDots();
		this.setState({ openReportPanel: true });
		this.props.setReportData(reportData);
		this.props.setOpenedDialog(true);
	};

	handleConfirmationDialog = (boolean) => {
		this.setState({ confirmationDialog: boolean });
	};

	goToProfile = (path) => {
		// this.props.closeComments && this.props.closeComments();

		this.props.history(path);
	};

	copyUrl = (url) => {
		navigator.clipboard.writeText(url);
		toast.success(t('url_copied'), {
			type: 'success',
			position: 'top-left',
			isLoading: false,
			autoClose: 3000,
			containerId: 'app',
			icon: <CheckCircleIcon id='iconGreen' />,
			toastId: 'copyUrl',
		});
		this.handleCloseDots();
	};

	download = (authorHandle, postId, postCollection, userId, email, file2) => {
		this.props.downloadPostFile({
			dataHandle: authorHandle,
			postId,
			postCollection,
			userId,
			email,
			file2,
		});
		this.handleCloseDots();
	};
	setPostForEdit = () => {
		this.props.setOpenedDialog(true);
		this.props.setPostToEdit(this.props.postToEdit);
		this.handleCloseDots();
	};

	handleRadioChange = (e) => {
		if (e.target.value === 'appealable') {
			this.setState({
				canAppeal: true,
			});
		} else if (e.target.value === 'nonAppealable') {
			this.setState({
				canAppeal: false,
			});
		}
	};

	updateAppealable = () => {
		let props = this.props;
		let formData = {
			appealable: this.state.canAppeal,
			userId: props.dataUserId,
		};

		if (props.profile) {
			formData.profile = true;
		}
		if (props.commentId) {
			formData.collection = 'removedComments';
			formData.postAuthorHandle = props.postAuthorHandle;
			formData.commentId = props.commentId;
			formData.commentCreatedAt = props.commentCreatedAt;
		} else if (props.postId) {
			formData.postId = props.postId;
			if (props.livePost === false) {
				formData.collection = 'unreleasedPosts';
			}
		}

		props.updateAppealableContent(formData);
	};

	render() {
		// dataHandle is the user signed in and handle is the user that made the comment/post
		const {
			data: {
				adminReportedUserId,
				// adminReportCollection,
				updatingAppealableContent,
				successUpdatingAppealableContent,
				errorUpdatingAppealableContent,
				post,
			},
			UI: { mode },
			user: {
				credentials: { handle, userId, email, admin },
				adminPanel: { adminPanelActive },
			},
			dataUserId,
			dataHandle,
			postComponent,
			comment,
			commentId,
			postToEdit,
			deletedPost,
			loadingDeletePost,
			postId,
			canDownload,
			reportProfile,
			infringements,
			orderData,
			queryOrder,
			appealable,
			profile,
			repliedId,
			postAuthorHandle,
			orderFromParams,
			count,
			livePost,
			disabled,
			noTooltip,
			profileOrder,
		} = this.props;
		const {
			confirmationDialog,
			confirmDelDialog,
			anchorEl,
			canAppeal,
			openReportPanel,
		} = this.state;
		const darkMode = mode === 'dark';

		const StyledMenu = withStyles({
			paper: {
				backgroundColor: darkMode ? '#000' : '#f3f3f3',
			},
		})((props) => (
			<Menu
				elevation={0}
				getcontentanchorel={null}
				// anchorOrigin={{
				// 	vertical: 'bottom',
				// 	horizontal: 'center',
				// }}
				// transformOrigin={{
				// 	vertical: 'top',
				// 	horizontal: 'center',
				// }}
				{...props}
			/>
		));
		const deleteDialog = confirmDelDialog && (
			<ConfirmationDialog
				open={confirmDelDialog}
				closeFunc={this.handleDeleteClose}
				actionBtnText={t('delete')}
				action={this.handleDelete}
				startIcon={<DeleteOutlineIcon />}
				customButtonId='customDangerButton'
				phrase={t('delete_option', {
					option: postComponent ? t('publication') : t('comment'),
				})}
				contentTitle={t('delete_option_question', {
					option: postComponent ? t('publication') : t('comment'),
				})}
				phrase3={
					count &&
					`${t('and')} ${count} ${
						postComponent
							? count > 1
								? t('comments')
								: t('comment')
							: count > 1
							? t('replies')
							: t('reply')
					} ${t('deleted_permanently')}`
				}
				backgroundColor='#ff3838'
			/>
		);

		const menuLogic = (
			<StyledMenu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={this.handleCloseDots}
			>
				{orderData && (
					<StyledMenuOrderItem
						onClick={() => this.changeQueryOrder('desc')}
						style={{
							backgroundColor:
								!orderFromParams && queryOrder === 'desc'
									? 'rgba(0, 153, 255, 0.267)'
									: '',
						}}
					>
						<ListItemIcon>
							<UpdateIcon
								id='iconButton'
								className={
									!orderFromParams && queryOrder === 'desc' ? 'new' : ''
								}
							/>
						</ListItemIcon>
						<p id='dotText'>{t('newest_on_top')}</p>
					</StyledMenuOrderItem>
				)}
				{orderData && (
					<StyledMenuOrderItem
						onClick={() => this.changeQueryOrder('asc')}
						style={{
							backgroundColor:
								!orderFromParams && queryOrder === 'asc'
									? 'rgba(0, 153, 255, 0.267)'
									: '',
						}}
					>
						<ListItemIcon>
							<SortByAlphaIcon
								id='iconButton'
								className={
									!orderFromParams && queryOrder === 'asc' ? 'new' : ''
								}
							/>
						</ListItemIcon>
						<p id='dotText'>{t('first_on_top')}</p>
					</StyledMenuOrderItem>
				)}
				{/* // if it's own user's comment.. */}
				{handle === dataHandle &&
					!deletedPost &&
					!infringements &&
					postComponent &&
					!loadingDeletePost.includes(postId) && (
						<StyledMenuEditItem
							onClick={this.setPostForEdit}
							disabled={this.props.data.editingPost.length > 0}
						>
							<ListItemIcon>
								<EditIcon id='iconDottedMenu' />
							</ListItemIcon>
							<p id='dotText'>{t('edit')}</p>
						</StyledMenuEditItem>
					)}

				{canDownload &&
					!deletedPost &&
					postToEdit?.fileType !== 'text' &&
					!comment && (
						<StyledMenuDownloadItem
							onClick={() =>
								this.download(
									dataHandle,
									postId,
									livePost ? 'posts' : 'unreleasedPosts',
									userId,
									email,
								)
							}
						>
							<ListItemIcon>
								<DownloadRoundedIcon id='iconDottedMenu' />
							</ListItemIcon>
							<p id='dotText'>{t('download')}</p>
						</StyledMenuDownloadItem>
					)}
				{canDownload &&
					!deletedPost &&
					postToEdit?.fileType2 === 'audio' &&
					!comment && (
						<StyledMenuDownloadItem
							onClick={() =>
								this.download(
									dataHandle,
									postId,
									livePost ? 'posts' : 'unreleasedPosts',
									userId,
									email,
									'fileType2',
								)
							}
						>
							<ListItemIcon>
								<GraphicEqIcon id='iconDottedMenu' />
							</ListItemIcon>
							<p id='dotText'>
								{t('download')} {t('audio')}
							</p>
						</StyledMenuDownloadItem>
					)}
				{!orderData &&
					!this.props.params.handle &&
					(!reportProfile ||
						dataHandle === post?.authHandle ||
						(postComponent && this.props.params.handle !== handle)) && (
						<StyledMenuProfileItem
							onClick={() => this.goToProfile(`/users/${dataHandle}`)}
						>
							<ListItemIcon>
								<AccountBoxIcon id='iconDottedMenu' />
							</ListItemIcon>
							<p id='dotText'>{t('go_to_profile')}</p>
						</StyledMenuProfileItem>
					)}
				{!orderData && !infringements && (
					<StyledMenuPathItem
						onClick={() =>
							this.copyUrl(
								postComponent
									? `https://goodbyeapp.com/users/${dataHandle}/post/${postId}`
									: comment
									? repliedId
										? `https://goodbyeapp.com/users/${postAuthorHandle}/post/${postId}/${repliedId}/${commentId}`
										: `https://goodbyeapp.com/users/${postAuthorHandle}/post/${postId}/${commentId}`
									: window.location.href,
							)
						}
					>
						<ListItemIcon>
							<LinkIcon id='iconDottedMenu' />
						</ListItemIcon>
						<p id='dotText'>{t('copy_url')}</p>
					</StyledMenuPathItem>
				)}
				{!orderData && dataHandle !== handle && !disabled && !deletedPost && (
					<StyledMenuReportItem onClick={this.report}>
						<ListItemIcon>
							<ReportProblemRoundedIcon id='iconDottedMenu' />
						</ListItemIcon>
						<p id='dotText'>{t('report')}</p>
					</StyledMenuReportItem>
				)}

				{!orderData &&
					(handle === postAuthorHandle ||
						(handle === dataHandle &&
							(postComponent || comment) &&
							!loadingDeletePost.includes(postId) &&
							!deletedPost)) && (
						<StyledMenuDeleteItem onClick={this.handleDeleteOpen}>
							<ListItemIcon>
								<DeleteOutlineIcon id='iconDottedMenu' />
							</ListItemIcon>
							<p id='dotText'>{t('delete')}</p>
						</StyledMenuDeleteItem>
					)}
			</StyledMenu>
		);

		return (
			<Fragment>
				<Backdrop sx={{ zIndex: 10 }} open={Boolean(anchorEl)} />
				<SocialReport
					close={() => this.setState({ openReportPanel: false })}
					open={openReportPanel}
				/>
				{(adminPanelActive || admin) &&
					!infringements &&
					(profile || postId || commentId) && (
						<ConfirmationDialog
							open={confirmationDialog}
							openIconOrString={<LockOpenIcon fontSize='small' />}
							closeFunc={() => this.handleConfirmationDialog(false)}
							openFunc={() => this.handleConfirmationDialog(true)}
							actionBtnText={`Disable ${
								profile ? 'user' : commentId ? 'Comment' : 'Post'
							}`}
							phrase={`Disable ${
								profile ? 'user' : commentId ? 'Comment' : 'Post'
							}`}
							action={this.setInfringement}
							startIcon={<LockIcon fontSize='small' />}
							contentTitle={`Are you sure you want to disable this ${
								profile ? 'user' : commentId ? 'comment' : 'post'
							}?`}
							customButtonId='customDangerButton'
							phrase2={`User ${
								adminReportedUserId === dataUserId
									? adminReportedUserId
									: dataUserId
							} will receive an infringement.`}
							iconButton
							disableRipple={commentId ? true : false}
							actionBtnPadding={commentId ? '0 5px' : 'auto'}
							backgroundColor='#ff3838'
							adminPanelActive={adminPanelActive || admin}
							appealable={appealable}
							canAppeal={canAppeal}
							handleRadioChange={this.handleRadioChange}
							updatingAppealableContent={updatingAppealableContent}
							successUpdatingAppealableContent={
								successUpdatingAppealableContent
							}
							errorUpdatingAppealableContent={errorUpdatingAppealableContent}
							updateAppealable={this.updateAppealable}
							actionBtnMargin='0'
						/>
					)}
				{(adminPanelActive || admin) &&
					infringements &&
					(profile || postId || commentId) && (
						<ConfirmationDialog
							open={confirmationDialog}
							openIconOrString={<LockIcon fontSize='small' />}
							idOpenIconOrString='DButton'
							closeFunc={() => this.handleConfirmationDialog(false)}
							openFunc={() => this.handleConfirmationDialog(true)}
							actionBtnText={`Enable ${
								profile ? 'user' : commentId ? 'Comment' : 'Post'
							}`}
							phrase={`Enable ${
								profile ? 'user' : commentId ? 'Comment' : 'Post'
							}`}
							action={this.setInfringement}
							customButtonId='customSuccessButton'
							startIcon={<LockOpenIcon fontSize='small' />}
							contentTitle={`Are you sure you want to enable this ${
								profile ? 'user' : commentId ? 'comment' : 'post'
							}?`}
							phrase2={`User ${
								adminReportedUserId === dataUserId
									? adminReportedUserId
									: dataUserId
							} infringement will be deleted.`}
							backgroundColor='#ff3838'
							adminPanelActive={adminPanelActive || admin}
							// appealable={appealable}
							iconButton
							disableRipple={commentId ? true : false}
							actionBtnPadding={commentId ? '0 5px' : 'auto'}
							canAppeal={canAppeal}
							handleRadioChange={this.handleRadioChange}
							updatingAppealableContent={updatingAppealableContent}
							successUpdatingAppealableContent={
								successUpdatingAppealableContent
							}
							errorUpdatingAppealableContent={errorUpdatingAppealableContent}
							updateAppealable={this.updateAppealable}
							infringements={infringements}
							actionBtnMargin='0'
						/>
					)}
				{orderData ? (
					<CustomTooltip
						title={!comment && !noTooltip ? t('order') : ''}
						arrow
						placement='right'
					>
						<IconButton
							aria-haspopup='true'
							onClick={this.handleClickDots}
							color={comment ? 'primary' : 'inherit'}
							id={profileOrder ? 'rightTranslate4' : ''}
							sx={{ padding: comment ? '4px 6px' : '' }}
						>
							<FormatLineSpacingIcon aria-haspopup='true' fontSize='small' />
						</IconButton>
					</CustomTooltip>
				) : profile || postComponent ? (
					<IconButton
						aria-haspopup='true'
						onClick={this.handleClickDots}
						id={profile ? 'profileDottedMenu' : 'postDottedMenu'}
					>
						<MoreHorizIcon
							fontSize={postComponent ? 'small' : 'medium'}
							id={postComponent ? 'mediaDotted' : ''}
						/>
					</IconButton>
				) : comment ? (
					<MoreHorizIcon
						aria-haspopup='true'
						onClick={this.handleClickDots}
						className='pointer'
						// id={profile ? 'profileDottedMenu' : ''}
					/>
				) : postComponent ? (
					<MoreHorizIcon className='pointer' onClick={this.handleClickDots} />
				) : null}

				{menuLogic}
				{deleteDialog}
			</Fragment>
		);
	}
}
DottedMenu.propTypes = {
	comment: PropTypes.bool,
	postComponent: PropTypes.bool,
	dataHandle: PropTypes.string,
	enableAccount: PropTypes.func.isRequired,
	disableAccount: PropTypes.func.isRequired,
	deleteFunction: PropTypes.func,
	setReportData: PropTypes.func.isRequired,
	downloadPostFile: PropTypes.func.isRequired,
	setPostToEdit: PropTypes.func.isRequired,
	postInfringement: PropTypes.func.isRequired,
	commentInfringement: PropTypes.func.isRequired,
	updateAppealableContent: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	loadingDeletePost: PropTypes.array.isRequired,
	setOpenedDialog: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	data: state.data,
	UI: state.UI,
	user: state.user,
	loadingDeletePost: state.data.loadingDeletePost,
});
const mapActionsToProps = {
	downloadPostFile,
	setPostToEdit,
	setReportData,
	postInfringement,
	commentInfringement,
	updateAppealableContent,
	enableAccount,
	disableAccount,
	setOpenedDialog,
};
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withStyles(styles)(withRouter(DottedMenu)));
