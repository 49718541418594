import React, { Component } from 'react';
import { withRouter } from '../../redux/withRouter';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from '../../components/Footer/Footer';
import logo from '../../util/Images/logo.svg';
import { Grid, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import {
	verifyEmail,
	sendSignInLinkToEmail,
} from '../../firebase/firebaseInit';
import {
	clearEmailVerificationMessage,
	scrollToTopFunc,
} from '../../redux/actions/dataActions';
import {
	clearVerifyEmailFirst,
	clearAuthErrors,
} from '../../redux/actions/userActions';
import CustomButton from '../../components/SocialComponents/CustomButton';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { t } from 'i18next';

class Verify extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			button: false,
			alreadySentTwice: false,
			hasError: false,
		};
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let newEmailForSignIn = this.props.user.newEmailForSignIn;
		if (newEmailForSignIn && !this.state.alreadySentTwice) {
			this.props.sendSignInLinkToEmail(newEmailForSignIn);
			this.setState({ alreadySentTwice: true });
		} else if (this.props.user.authenticated) {
			this.props.verifyEmail();
		}
		return;
	};
	// adding email to state (getDerivedStateFromProps)
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.email !== nextProps.user.credentials.email) {
			return {
				email: nextProps.user.credentials.email,
				button: true,
			};
		}
		// Return null to indicate no change to state.
		return null;
	}

	componentDidMount() {
		scrollToTopFunc('instant');
	}
	componentWillUnmount() {
		this.props.clearAuthErrors();
		if (this.props.user.verifyEmailFirst) {
			this.props.clearVerifyEmailFirst();
		}
	}

	render(props) {
		const {
			user: {
				authenticated,
				errorVerificationEmail,
				loadingVerificationEmail,
				verificationEmailMessage,
				sendingSignInEmail,
				signedEmailSentSuccess,
				successSignup,
				errorSendingSignInEmail,
				newEmailForSignIn,
				currentEmailForSignIn,
				newEmailUpdateLinkSentSuccess,
				emailVerified,
				credentials: { handle, email },
				verifyEmailFirst,
			},

			UI: { mode },
		} = this.props;

		const { button, alreadySentTwice } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>Verification - Goodbye App</title>
				</Helmet>

				{(newEmailUpdateLinkSentSuccess && newEmailForSignIn) ||
				(verificationEmailMessage !== null && !emailVerified) ||
				(authenticated && !emailVerified && successSignup) ? (
					<div>
						<div className='skyBackground'>
							<div className='pd2Media'>
								<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
									<div className='flexColumn alignItemsCenter'>
										<div className='backgroundLogo'>
											<img className='logo ' src={logo} alt='Logo' />
										</div>
									</div>

									<h1 className='title mg0 center'>
										{newEmailUpdateLinkSentSuccess
											? t('change_email_address')
											: t('account_verification')}
									</h1>

									<h3 className='subtitle center mgbOnly'>
										{newEmailUpdateLinkSentSuccess
											? t('please_check_your_inbox')
											: t('verify_to_login')}
									</h3>
									{newEmailForSignIn ||
									verificationEmailMessage ||
									errorVerificationEmail ? (
										<div>
											<CustomAlerts
												alertId='smallMuiAlert'
												noCenter
												info={
													((sendingSignInEmail ||
														signedEmailSentSuccess ||
														errorSendingSignInEmail ||
														verificationEmailMessage ||
														handle) &&
														!newEmailUpdateLinkSentSuccess) ||
													errorVerificationEmail
												}
												severity={
													sendingSignInEmail || loadingVerificationEmail
														? 'info'
														: signedEmailSentSuccess || verificationEmailMessage
														? 'success'
														: errorSendingSignInEmail || alreadySentTwice
														? 'error'
														: 'warning'
												}
												message={
													sendingSignInEmail || loadingVerificationEmail
														? t('sending_email_link')
														: alreadySentTwice
														? t('verification_email_sent_too_many_times')
														: signedEmailSentSuccess
														? signedEmailSentSuccess
														: errorSendingSignInEmail
														? errorSendingSignInEmail
														: errorVerificationEmail
														? errorVerificationEmail
														: verificationEmailMessage || !alreadySentTwice
														? `${t('email_verify_link_success')} ${t(
																'please_check_your_inbox',
														  )}`
														: ''
												}
												margin='0.5rem 0'
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												noCenter
												info={newEmailUpdateLinkSentSuccess}
												message={`You can begin using your
															new email address as soon as it has been verified.
															Please click the new email verification link we
															sent.
															${
																newEmailForSignIn
																	? ` ${t(
																			'email',
																	  )}: ${newEmailForSignIn.toLowerCase()}`
																	: ''
															}`}
												margin='0.5rem 0'
												icon={<MailIcon />}
												variant='outlined'
											/>

											<CustomAlerts
												alertId='smallMuiAlert'
												noCenter
												success={newEmailUpdateLinkSentSuccess}
												margin='0.5rem 0'
											/>
											<CustomAlerts
												alertId='smallMuiAlert'
												noCenter
												info={
													verificationEmailMessage ||
													signedEmailSentSuccess ||
													newEmailUpdateLinkSentSuccess ||
													errorVerificationEmail
												}
												message={t('new_email_might_be_in_junk_or_spam')}
												margin='0.5rem 0'
												icon={<MailIcon />}
											/>

											<CustomAlerts
												alertId='smallMuiAlert'
												noCenter
												success={
													verificationEmailMessage || signedEmailSentSuccess
												}
												severity='warning'
												message={t('account_removed_after_24')}
												margin='0.5rem 0'
												icon={<AccountCircleRoundedIcon />}
											/>

											<form noValidate onSubmit={this.handleSubmit}>
												{/* wrong credential */}
												<section className='validator_div pdY'>
													{!verifyEmailFirst && (
														<p className='mgbOnly'>
															<b>{t('username')}:</b>{' '}
															{handle ? (
																handle
															) : (
																<CircularProgress
																	variant='indeterminate'
																	size={16}
																	thickness={2}
																	sx={{ position: 'relative', top: '3px' }}
																/>
															)}
														</p>
													)}
													<p className='mgbOnly'>
														<b>
															{newEmailForSignIn
																? t('new_email')
																: t('email_address')}
															:{' '}
														</b>
														{newEmailForSignIn ? (
															newEmailForSignIn.toLowerCase()
														) : email ? (
															email
														) : verifyEmailFirst ? (
															verifyEmailFirst
														) : (
															<CircularProgress
																variant='indeterminate'
																size={16}
																thickness={2}
																sx={{ position: 'relative', top: '3px' }}
															/>
														)}
													</p>

													{!errorVerificationEmail &&
														!verificationEmailMessage && (
															<p className='mgbOnly'>
																<b>{t('current_email_address')}:</b>{' '}
																{currentEmailForSignIn && !email ? (
																	currentEmailForSignIn
																) : email ? (
																	email
																) : (
																	<CircularProgress
																		variant='indeterminate'
																		size={16}
																		thickness={2}
																		sx={{ position: 'relative', top: '3px' }}
																	/>
																)}
															</p>
														)}
												</section>
												{button &&
												!newEmailUpdateLinkSentSuccess &&
												!errorSendingSignInEmail &&
												!errorVerificationEmail ? (
													<CustomButton
														type='submit'
														variant='contained'
														loading={loadingVerificationEmail}
														btnText={t('resend_verifification')}
														id='customSuccessButton'
														fullWidth
													/>
												) : (
													''
												)}
												<hr />
												<Grid container className='center mgt'>
													<Grid item xs={6} sm={6} md={6}>
														{emailVerified || newEmailForSignIn ? (
															<Link to='/feed' id='link'>
																<span className='text bold'>{t('home')}</span>
															</Link>
														) : (
															<Link to='/login' id='link'>
																<span className='text bold capitalize'>
																	{t('login')}
																</span>
															</Link>
														)}
													</Grid>
													<Grid item xs={6} sm={6} md={6}>
														{emailVerified || newEmailForSignIn ? (
															<Link to={`/users/${handle}`} id='link'>
																<span className='text bold capitalize'>
																	{t('profile')}
																</span>
															</Link>
														) : (
															<Link to='/signup' id='link'>
																<span className='text bold capitalize'>
																	{t('signup')}
																</span>
															</Link>
														)}
													</Grid>
												</Grid>
											</form>
										</div>
									) : (
										<div className='flex'>
											<CircularProgress
												variant='indeterminate'
												id='authProgressVerify'
												size={70}
												thickness={3}
											/>
										</div>
									)}
								</div>
							</div>
						</div>

						<Footer />
					</div>
				) : emailVerified ? (
					<Navigate to='/feed' />
				) : (
					<Navigate to='/login' />
				)}
			</React.Fragment>
		);
	}
}
Verify.propTypes = {
	verifyEmail: PropTypes.func.isRequired,
	sendSignInLinkToEmail: PropTypes.func.isRequired,
	clearEmailVerificationMessage: PropTypes.func.isRequired,
	clearAuthErrors: PropTypes.func.isRequired,
	clearVerifyEmailFirst: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
});

const mapActionsToProps = {
	verifyEmail,
	sendSignInLinkToEmail,
	clearAuthErrors,
	clearVerifyEmailFirst,
	clearEmailVerificationMessage,
};
export default connect(mapStateToProps, mapActionsToProps)(withRouter(Verify));
