import { useEffect, useState } from 'react';
import CustomAlerts from './CustomAlerts';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import Media from 'react-media';
import { Dialog, DialogContent, Toolbar } from '@mui/material';
import EnableCamera from '../../pages/USEFUL/EnableCamera';
import { setCameraAccess } from '../../redux/actions/userActions';
import { connect, useDispatch } from 'react-redux';
import CustomButton from './CustomButton';
import { t } from 'i18next';
const CameraPermission = ({ user }) => {
	const dispatch = useDispatch();
	const [camAllowed, setCamAllowed] = useState(true);
	const [prompt, setPrompt] = useState(false);
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		if (!user.cameraAccess) {
			isCamAllowed();
		}
		return () => {};
	}, []);

	const handleDialog = () => {
		setOpened(!opened);
	};

	const recheckPermission = () => {
		navigator.mediaDevices
			.getUserMedia({ video: true })
			.then((stream) => {
				dispatch(setCameraAccess(true));
				setCamAllowed(true);
				// Remember to stop the stream after checking
				stream.getTracks().forEach((track) => track.stop());
			})
			.catch((err) => {
				// console.log('err', err);
				setCamAllowed(false);
				setPrompt(false);
			});
	};
	const isCamAllowed = () => {
		if (!navigator.permissions) {
			console.log('The Permissions API is not supported in this browser.');
			recheckPermission();
			return;
		}
		navigator.permissions
			.query({
				name: 'camera',
			})
			.then((permissionStatus) => {
				const camAllowed = permissionStatus.state === 'granted';
				const prompt =
					!camAllowed &&
					!user.cameraAccess &&
					permissionStatus.state === 'prompt';
				if (camAllowed) {
					dispatch(setCameraAccess(true));
				}

				setCamAllowed(camAllowed);
				setPrompt(prompt);

				permissionStatus.onchange = (event) => {
					// console.log('event!!!', event.target.state);
					if (event.target.state === 'granted') {
						dispatch(setCameraAccess(true));
						setCamAllowed(true);
					} else if (event.target.state === 'denied') {
						dispatch(setCameraAccess(false));
						setCamAllowed(false);
						setPrompt(false);
					} else if (permissionStatus.state === 'prompt') {
						dispatch(setCameraAccess(false));
						setCamAllowed(false);
						setPrompt(true);
					}
				};
			})
			.catch((err) => {
				setCamAllowed(false);
				// console.log(err);
			});
	};
	return (
		<div>
			<CustomAlerts
				error={!camAllowed && !prompt}
				severity='warning'
				noMargin
				icon={<CameraAltIcon fontSize='small' />}
				message={
					<div className='text alignItemsCenter'>
						{t('cam_access_denied')}
						<CustomButton
							onClick={handleDialog}
							className='topTranslate'
							btnText={t('enable_cam_ques')}
							id='underlineHover'
							margin='0'
							padding='0 0 0 6px'
							disableRipple
						/>
					</div>
				}
				alertId='smallMuiAlert'
			/>
			<CustomAlerts
				info={!camAllowed && prompt}
				noMargin
				icon={<CameraAltIcon fontSize='small' />}
				message={
					<div className='text alignItemsCenter'>
						{t('to_access_cam')}
						<CustomButton
							onClick={recheckPermission}
							className='topTranslate'
							btnText={t('request_perm')}
							id='underlineHover'
							margin='0'
							padding='0 0 0 6px'
							disableRipple
						/>
					</div>
				}
				alertId='smallMuiAlert'
			/>
			<Media
				queries={{
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) => (
					<Dialog
						open={opened}
						onClose={handleDialog}
						disableEnforceFocus
						fullWidth
						maxWidth={!matches.isMobile ? 'xl' : false}
						fullScreen={matches.isMobile}
					>
						<Toolbar id='dialogBar'>
							<div className='titleToolbar'>{t('cam_permissions')}</div>
							<CloseIcon
								id='closeDialogButton'
								onClick={handleDialog}
								aria-label='close'
							/>
						</Toolbar>

						<DialogContent sx={{ padding: 0 }}>
							<EnableCamera popup />
						</DialogContent>
					</Dialog>
				)}
			</Media>
		</div>
	);
};

const mapActionsToProps = {
	setCameraAccess,
};

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps, mapActionsToProps)(CameraPermission);
