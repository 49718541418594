import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomButton from './CustomButton';
import { appeal } from '../../redux/actions/dataActions';
import { deleteUser } from '../../redux/actions/userActions';
import CustomAlerts from './CustomAlerts';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CustomInput from './CustomInput';
import ConfirmationDialog from './ConfirmationDialog';
import Grow from '@mui/material/Grow';
import SendIcon from '@mui/icons-material/Send';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ImgSrc from './ImgSrc';
import { logoutAllTabs, refreshAllTabs } from '../../firebase/firebaseInit';
import dayjs from 'dayjs';
import { t } from 'i18next';

class Appeal extends Component {
	state = {
		showAppealForm: false,
		body: '',
		deleteConfirmationDialog: false,
		appealConfirmationDialog: false,
		logoutConfirmationDialog: false,
	};

	handleOpenAppeal = () => {
		this.setState((prevState) => {
			return {
				showAppealForm: !prevState.showAppealForm,
			};
		});

		if (!this.state.showAppealForm) {
			setTimeout(() => {
				document.getElementById('body').focus();
			}, 500);
		}
	};

	handleConfirmationDialog = (boolean, action) => {
		if (action === 'deleteData') {
			this.setState({ deleteConfirmationDialog: boolean });
		}
		if (action === 'appeal') {
			if (boolean && this.state.body.trim() === '') {
				this.setState({
					appealErrors: t('write_appeal'),
				});
			} else {
				this.setState({ appealConfirmationDialog: boolean });
			}
		}
		if (action === 'signout') {
			this.setState({ logoutConfirmationDialog: boolean });
		}
	};

	handleChange = (e) => {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value,
		});
		if (this.state.appealErrors) {
			this.setState({
				appealErrors: '',
			});
		}
	};

	appeal = () => {
		if (this.state.body.trim() === '') {
			this.setState({
				appealErrors: t('write_appeal'),
			});
		} else {
			this.setState({ appealConfirmationDialog: false, showAppealForm: false });
			let formData = {
				body: this.state.body.trim(),
			};
			if (this.props.user.credentials.disabled) {
				formData.collection = 'userAppeals';
			} else {
				formData.postId = this.props.data.post.postId;
				if (this.props.socialComponent === 'comment') {
					formData.collection = 'commentAppeals';
					formData.commentId = this.props.data.post.comments[0].commentId;
				} else if (this.props.socialComponent === 'reply') {
					formData.collection = 'commentAppeals';
					formData.commentId =
						this.props.data.post.comments[0].replies[0].commentId;
					formData.repliedId =
						this.props.data.post.comments[0].replies[0].repliedId;
				} else if (this.props.socialComponent === 'post') {
					formData.collection = 'postAppeals';
				}
			}

			this.props.appeal(
				formData,
				this.props.socialComponent,
				this.props.user.credentials.disabled,
			);
		}
	};
	deleteData = () => {
		this.setState({ deleteConfirmationDialog: false, showAppealForm: false });
		if (this.props.user.credentials.disabled) {
			this.props.logoutAllTabs();
			this.props.deleteUser();
		} else {
			this.props.deleteFunction();
		}
	};

	handleLogout = () => {
		this.setState({
			logoutConfirmationDialog: false,
		});

		this.props.logoutAllTabs();
		this.props.refreshAllTabs(false);
	};

	render() {
		const {
			data: {
				submittingAppeal,
				submittingAppealSuccess,
				errorSubmittingAppeal,
				post,
			},
			user: {
				authenticated,
				emailVerified,
				credentials: {
					disabled,
					appeal,
					appealable,
					handle,
					email,
					imageUrl,
					imageColor,
					thumbnail,
					createdAt,
				},
				loadingLogout,
				errorLogingOut,
			},
			UI: { mode },
			socialComponent,
		} = this.props;
		const {
			body,
			appealErrors,
			showAppealForm,
			deleteConfirmationDialog,
			appealConfirmationDialog,
			logoutConfirmationDialog,
		} = this.state;

		const data =
			socialComponent === 'post'
				? this.props.data.post
				: socialComponent === 'comment'
				? post.comments[0]
				: socialComponent === 'reply'
				? post.comments[0].replies[0]
				: null;

		const alertList = (
			<div>
				{data !== null && (
					<div>
						<CustomAlerts
							error={data.infringements}
							message={t('removed_content', {
								content: t(socialComponent),
							})}
							margin='1rem 1rem 0'
						/>
						<CustomAlerts
							error={t('account_infring')}
							margin='1rem'
							icon={<AccountCircleRoundedIcon />}
							variant='outlined'
						/>
					</div>
				)}
				{(disabled && appealable) || (data !== null && data.appealable) ? (
					<div>
						<CustomAlerts
							info={(disabled && appeal) || (data !== null && data.appeal)}
							margin={socialComponent === 'user' ? '1rem 0' : '0 1rem 0'}
							message={t('challange_infring')}
						/>
						{submittingAppealSuccess ? (
							<div className='pdb'>
								<CustomAlerts
									success={submittingAppealSuccess}
									margin='0 1rem'
								/>
							</div>
						) : (
							<CustomAlerts
								success={
									(disabled && !appeal) || (data !== null && !data.appeal)
								}
								message={t('already_appealed')}
								margin='1rem'
							/>
						)}
					</div>
				) : (disabled && !appealable) || (data !== null && !data.appealable) ? (
					<CustomAlerts
						info={true}
						severity='warning'
						message={t('appeal_made')}
						margin='0 1rem 0'
					/>
				) : null}

				<CustomAlerts error={errorSubmittingAppeal} />
			</div>
		);

		const appealActions = (
			<div>
				{!submittingAppealSuccess && !errorSubmittingAppeal && (
					<div className='flexColumn'>
						{/* <label
							className={`${
								!disabled && data !== null ? 'mgt' : ''
							} inputText underline`}
						>
							{(disabled && !appeal) || (data !== null && !data.appeal)
								? 'Optional:'
								: 'Actions:'}
						</label> */}

						<div className='flex spaceAround pdY'>
							{(appeal || (data !== null && data.appeal)) && (
								<CustomButton
									onClick={this.handleOpenAppeal}
									variant='contained'
									btnText={t('send')}
									id='customInfoButton'
									loading={submittingAppeal}
									startIcon={<EditNoteIcon />}
									margin='0.5rem 0'
								/>
							)}

							{!submittingAppeal && (
								<ConfirmationDialog
									open={deleteConfirmationDialog}
									openIconOrString={t('delete')}
									idOpenIconOrString='customDangerButton'
									customButtonId='customDangerButton'
									closeFunc={() =>
										this.handleConfirmationDialog(false, 'deleteData')
									}
									openFunc={() =>
										this.handleConfirmationDialog(true, 'deleteData')
									}
									actionBtnText={t('delete')}
									action={this.deleteData}
									initialBtnIcon={<DeleteOutlineIcon />}
									startIcon={<DeleteOutlineIcon />}
									phrase={`${t('perm_delete')} ${
										disabled ? `@${handle}` : socialComponent
									}`}
									contentTitle={t('cant_be_undone')}
									backgroundColor='#ff3838'
									actionBtnMargin='0.5rem 0'
								/>
							)}
						</div>
						{showAppealForm && (
							<div>
								<Grow
									in={true}
									style={{ transformOrigin: '0 0 0' }}
									{...(true ? { timeout: 600 } : {})}
								>
									<div>
										<CustomInput
											autoFocus
											noLabelMg
											variant='outlined'
											type='text'
											titleLabel={t('appeal_content', {
												content: disabled ? `@${handle}` : socialComponent,
											})}
											placeholder={t('type_here')}
											name='body'
											inputId='body'
											onChange={this.handleChange}
											inputValue={body}
											errors={appealErrors}
											inputError={appealErrors ? true : false}
											required
											fullWidth
											maxLength={2000}
											helperText={
												appealErrors
													? appealErrors
													: body.length > 1000
													? `${body.length}/2000`
													: ''
											}
											minRows={5}
											multiline
										/>
									</div>
								</Grow>

								<ConfirmationDialog
									open={appealConfirmationDialog}
									openIconOrString={t('send')}
									customButtonId='customInfoButton'
									// idOpenIconOrString='ed_deletePictureButton'
									closeFunc={() =>
										this.handleConfirmationDialog(false, 'appeal')
									}
									openFunc={() => this.handleConfirmationDialog(true, 'appeal')}
									actionBtnText={t('send')}
									cancelBtnText={t('continue_editing')}
									action={this.appeal}
									startIcon={<SendIcon />}
									phrase={t('only_appeal_once')}
									phrase2={t('after_appeal_submited')}
									backgroundColor='#ff3838'
								/>
							</div>
						)}

						{disabled && (
							<ConfirmationDialog
								open={logoutConfirmationDialog}
								customButtonId='customSuccessButton'
								closeFunc={() =>
									this.handleConfirmationDialog(false, 'signout')
								}
								openFunc={() => this.handleConfirmationDialog(true, 'signout')}
								openIconOrString={t('back_to_login')}
								idOpenIconOrString='customCancelButton'
								actionBtnText={t('back_to_login')}
								action={this.handleLogout}
								loading={loadingLogout}
								error={errorLogingOut}
								initialBtnIcon={<ExitToAppIcon />}
								startIcon={<ExitToAppIcon />}
								phrase={t('back_to_login_question')}
								fullWidth
								actionBtnMargin='0'
							/>
						)}
					</div>
				)}
			</div>
		);

		return authenticated && disabled ? (
			<div>
				<Helmet>
					<title>Appeal - Goodbye App</title>
				</Helmet>

				<div className='skyBackground pd2Media'>
					<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
						<ImgSrc
							img={imageUrl}
							thumbnail={thumbnail}
							handle={handle}
							imgColor={imageColor}
							css='imgProfile'
							fontSize='4vw'
							stronger
						/>
						<span className='name-sp flex mgt center'>{handle}</span>
						<h3 className='subtitle center mg0'>
							{t('removed_content', {
								content: t('account').toLowerCase(),
							})}
						</h3>

						<label className='inputText mgY'>
							{t('email_address')}: <b className='mglH breakWord'>{email}</b>
						</label>

						<p className='mg0 mgbH inputText'>
							{t('joined_on')} {dayjs(createdAt).format('LLLL')}
						</p>

						<form noValidate onSubmit={this.handleSubmit}>
							{alertList}
							{appealActions}
							{!showAppealForm && <hr />}
						</form>
					</div>
				</div>
			</div>
		) : authenticated && data !== null ? (
			<div>
				{alertList}
				<div className='pdX'>{appealActions}</div>
			</div>
		) : authenticated && emailVerified ? (
			<Navigate to={`/users/${handle}`} />
		) : (
			<Navigate to='/login' />
		);
	}
}

Appeal.propTypes = {
	appeal: PropTypes.func.isRequired,
	logoutAllTabs: PropTypes.func.isRequired,
	refreshAllTabs: PropTypes.func.isRequired,
	deleteUser: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapActionsToProps = {
	appeal,
	logoutAllTabs,
	refreshAllTabs,
	deleteUser,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(Appeal);
