export default function TableRow({ title, data1, data2, cellColor }) {
	return data1 || data2 ? (
		<table className='reportDialogTableRow'>
			<tbody>
				<tr
					className='tableRow'
					style={{
						border: `2px solid ${cellColor}`,
						backgroundColor: cellColor,
					}}
				>
					<td className='tableRow1'>{data1 > 0 && data1} </td>
					<td className='tableRow2' style={{ backgroundColor: cellColor }}>
						{title}
					</td>
					<td className='tableRow3'>{data2 > 0 && data2}</td>
				</tr>
			</tbody>
		</table>
	) : (
		<div className='center'>{title}</div>
	);
}
