import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EditDetails from './EditDetails';
import ProfileSkeleton from '../Skeletons/ProfileSkeleton';
import ImgSrc from '../SocialComponents/ImgSrc';
import { connect } from 'react-redux';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import { noImgUrl } from '../../redux/actions/dataActions';
import dayjs from 'dayjs';
import {
	CircularProgress,
	Dialog,
	Grow,
	LinearProgress,
	Zoom,
} from '@mui/material';
import LocationOn from '@mui/icons-material/LocationOn';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useTranslation } from 'react-i18next';
import Progress from '../SocialComponents/Progress';

const Profile = ({ user, UI }) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);
	const {
		handle,
		imageUrl,
		thumbnail,
		name,
		biography,
		quote,
		location,
		imageColor,
		createdAt,
	} = user.credentials;

	const expandImg = () => {
		setExpanded(!expanded);
	};
	const { authenticated, uploadingUserDetails } = user;
	const { uploadProgress, mode } = UI;

	return authenticated && handle ? (
		<div className={`card pd ${mode === 'dark' && 'dbc'}`}>
			{uploadProgress !== null ||
				(uploadingUserDetails !== null && (
					<CircularProgress
						color='inherit'
						className='circularProgressOnProfileImg'
					/>
				))}

			<div onClick={expandImg}>
				<ImgSrc
					img={imageUrl}
					thumbnail={thumbnail}
					handle={handle}
					imgColor={imageColor}
					css='imgProfile'
					fontSize='4vw'
					stronger
					onLoad={() => this.setState({ loaded: true })}
				/>
			</div>

			{expanded && (
				<Dialog
					open={true}
					fullWidth
					onClose={expandImg}
					className='center'
					TransitionComponent={Zoom}
				>
					<CustomAlerts
						success={
							<span className='capitalize bold'>{name ? name : handle}</span>
						}
						icon={false}
						noMargin
					/>

					<ImgSrc
						img={imageUrl}
						handle={handle}
						thumbnail={thumbnail}
						imgColor={noImgUrl() === imageUrl ? imageColor : '#'}
						css={
							noImgUrl() === imageUrl
								? 'noImgMainProfileExpanded'
								: 'imgMainProfileExpanded'
						}
						noMargin
						stronger
						fontSize='5vw'
					/>

					<CustomAlerts
						info={`${t('joined')} ${dayjs(createdAt).format('MMMM D, YYYY')}`}
						icon={<AllInclusiveIcon />}
						noMargin
					/>
				</Dialog>
			)}
			{uploadProgress !== null && (
				<div className='pdt pullupH'>
					<Progress
						progress={uploadProgress}
						title={t('uploading_image')}
						icon={<AccountCircleRoundedIcon />}
						variant={'buffer'}
					/>
				</div>
			)}

			<Link
				to={`/users/${handle}`}
				className={`notranslate username-sp underlineHover ${
					mode === 'dark' ? 'white' : 'dark'
				}`}
			>
				<div className='mgt center'>
					{!name && (
						<AccountCircleRoundedIcon
							className='mgrH bottomTranslate2'
							fontSize='medium'
						/>
					)}
					<span className='name-sp prewrap notranslate'>
						{name ? name : handle}
					</span>
				</div>
			</Link>

			<div className='center'>
				{name && handle && (
					<div className='notranslate mgtH bold'>
						<AccountCircleRoundedIcon className='detailsIcon bottomTranslate6' />
						<span className='text mgl4px breakWord'>{handle}</span>
					</div>
				)}
				{location && (
					<div className='mgtH'>
						<LocationOn className='detailsIcon bottomTranslate4' />
						<span className='text mgl4px'>{location}</span>
					</div>
				)}
				{biography && (
					<div className='mgtH'>
						<FingerprintIcon className='detailsIcon bottomTranslate6' />
						<span className='text mgl4px'>{biography}</span>
					</div>
				)}
				{quote && (
					<div className='mgtH'>
						<FormatQuoteIcon className='detailsIcon bottomTranslate6' />
						<span className='text mgl4px'>{quote}</span>
					</div>
				)}
			</div>
			<hr className='mgY' />
			<EditDetails />
		</div>
	) : (
		<ProfileSkeleton />
	);
};

Profile.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
});

export default connect(mapStateToProps)(Profile);
