import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Tos from './Tos';
function TermsOfService() {
	const mode = useSelector((state) => state.UI.mode);
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};
	return (
		<div>
			<Helmet>
				<title>Terms Of Service - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Terms of Service</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p className='bold'>
						If you live in the United States, the{' '}
						<span className='notranslate'> Goodbye App </span> User Agreement
						comprises these{' '}
						<span id='link' onClick={scrollToTop}>
							Terms of Service
						</span>
						, our{' '}
						<Link to='/privacy-policy' id='link'>
							Privacy Policy
						</Link>
						, <span className='notranslate'> Goodbye App </span>{' '}
						<Link to='/community-guidelines' id='link'>
							Community Guidelines
						</Link>
						, and all incorporated policies.
					</p>
					<p className='bold'>
						If you live in the European Union or otherwise outside the United
						States, <span className='notranslate'> Goodbye App </span> User
						Agreement comprises these{' '}
						<span id='link' onClick={scrollToTop}>
							Terms of Service
						</span>
						, our{' '}
						<Link to='/privacy-policy' id='link'>
							Privacy Policy
						</Link>
						, <span className='notranslate'> Goodbye App </span>{' '}
						<Link to='/community-guidelines' id='link'>
							Community Guidelines
						</Link>
						, and all incorporated policies.
					</p>
					<hr />
					<h2>
						<span className='notranslate'> Goodbye App </span> Terms of Service
					</h2>
					<p className='bold'>If you live in the United States</p>
					<p>
						These Terms of Service (“Terms”) govern your access to and use of
						our services, including our various websites, SMS, APIs, email
						notifications, applications, buttons, widgets, ads, commerce
						services, and any information, text, links, graphics, photos, audio,
						videos, or other materials or arrangements of materials uploaded,
						downloaded, or appearing on the Services (collectively referred to
						as “Content”). By using the Services, you agree to be bound by these
						Terms.
					</p>
					<Tos unitedStates />
					<hr />
					<h2>
						<span className='notranslate'> Goodbye App </span> Terms of Service
					</h2>
					<p className='bold'>If you live outside the United States</p>
					<p>
						These Terms of Service (“Terms”) govern your access to and use of
						our services, including our various websites, SMS, APIs, email
						notifications, applications, buttons, widgets, ads, commerce
						services, and any information, text, links, graphics, photos, audio,
						videos, or other materials or arrangements of materials uploaded,
						downloaded, or appearing on the Services (collectively referred to
						as “Content”). By using the Services, you agree to be bound by these
						Terms.
					</p>
					<Tos />
					<hr />

					<p>
						We also don’t control what people and others do or say, and we
						aren’t responsible for their (or your) actions or conduct (whether
						online or offline) or content (including unlawful or objectionable
						content). We also aren’t responsible for services and features
						offered by other people or companies, even if you access them
						through our Service. Our responsibility for anything that happens on
						the Service (also called "liability") is limited as much as the law
						will allow. If there is an issue with our Service, we can't know
						what all the possible impacts might be. You agree that we won't be
						responsible ("liable") for any lost profits, revenues, information,
						or data, or consequential, special, indirect, exemplary, punitive,
						or incidental damages arising out of or related to these Terms, even
						if we know they are possible. This includes when we delete your
						content, information, or account.
					</p>
					<p> We reserve all rights not expressly granted to you.</p>
					<p>
						We always appreciate feedback or other suggestions, but may use them
						without any restrictions or obligation to compensate you for them,
						and are under no obligation to keep them confidential.
					</p>
					<p>
						We may change our Service and policies, and we may need to make
						changes to these Terms so that they accurately reflect our Service
						and policies. Unless otherwise required by law, we will notify you
						(for example, through our Service) before we make changes to these
						Terms and give you an opportunity to review them before they go into
						effect. Then, if you continue to use the Service, you will be bound
						by the updated Terms. If you do not agree to these{' '}
						<span id='link' className='bold' onClick={scrollToTop}>
							Terms of Service
						</span>
						, our{' '}
						<Link to='/privacy-policy' id='link' className='bold'>
							Privacy Policy
						</Link>
						, <span className='notranslate'> Goodbye App </span>{' '}
						<Link to='/community-guidelines' id='link' className='bold'>
							Community Guidelines
						</Link>
						, and all incorporated policies or any future updates, you have the
						option to{' '}
						<Link to='/delete-account' id='link' className='bold'>
							delete your account.
						</Link>
					</p>
					<p></p>
					<h2>How Our Service Is Funded</h2>
					<p>
						By using <span className='notranslate'> Goodbye App </span> service
						under these Terms, you understand that we may display advertisements
						from businesses and organizations that pay us for promotion.
					</p>
					<p>
						<b>Donations:</b> We deeply appreciate your generous donations which
						help us in maintaining and enhancing our platform. However, please
						note that donations are entirely voluntary and are not required to
						access or use our services. Our primary goal is to provide a
						valuable service to all users, regardless of their ability to
						donate. For refunds, view our{' '}
						<Link to='/refund-policy' id='link' className='bold'>
							Refund Policy
						</Link>
						.
					</p>

					<p>
						You can't attempt to create accounts or access or collect
						information in unauthorized ways. This includes creating accounts or
						collecting information in an automated way without our express
						permission.
					</p>
					<p>
						You can’t sell, license, or purchase any account or data obtained
						from us or our Service. This includes attempts to buy, sell, or
						transfer any aspect of your account (including your username);
						solicit, collect, or use login credentials or badges of other users;
						or request or collect{' '}
						<span className='notranslate'> Goodbye App </span> usernames,
						passwords, or misappropriate access tokens. You can't post someone
						else’s private or confidential information without permission or do
						anything that violates someone else's rights, including intellectual
						property rights (e.g., copyright infringement, trademark
						infringement, counterfeit, or pirated goods). You may use someone
						else's works under exceptions or limitations to copyright and
						related rights under applicable law. You represent you own or have
						obtained all necessary rights to the content you post or share.
					</p>
					<p>
						You can't use a domain name or URL in your username without our
						prior written consent.
					</p>
					<p>
						You can delete content individually or all at once by deleting your
						account.
					</p>

					<h2>Additional Rights We Retain</h2>
					<p>
						If you select a username or similar identifier for your account, we
						may change it if we believe it is appropriate or necessary (for
						example, if it infringes someone's intellectual property or
						impersonates another user)
					</p>
					<p>
						You are only allowed to use our intellectual property, trademarks,
						or similar marks as explicitly outlined in our guidelines or with
						our prior written consent. Any modification, creation of derivative
						works, decompiling, or attempts to extract source code from us
						require written permission from us or must be done under an open
						source license.
					</p>
					<h2>Online Dispute Resolution (ODR)</h2>
					<p>
						If you have any concerns or disputes about our service, we encourage
						you to contact us directly to seek a resolution. However, if you are
						a resident of the EU, Norway, Iceland, or Liechtenstein, you may use
						the ODR platform as an alternative method for resolving disputes.
					</p>
					<p>
						You can access the European Online Dispute Resolution (ODR) platform
						at{' '}
						<a
							href='http://ec.europa.eu/consumers/odr/'
							target='_blank'
							rel='noreferrer'
							id='link'
							className='link underlineHover bold breakWord'
						>
							http://ec.europa.eu/consumers/odr/
						</a>
						.
					</p>
					<p className='mgtOnly'>
						We agree to provide you with the{' '}
						<span className='notranslate'> Goodbye App </span> Service. Our
						Service includes all the products, features, applications, services,
						technologies, and software we offer to advance{' '}
						<span className='notranslate'> Goodbye App's </span>
						mission: To create a platform where individuals can connect, share,
						and preserve their cherished memories and stories in a safe and
						inclusive environment. By leveraging innovative technology, we aim
						to foster meaningful relationships, celebrate diverse experiences,
						and support the emotional well-being of our global community
						members.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default TermsOfService;
