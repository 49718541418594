import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { restoreViewed } from '../../redux/actions/dataActions';

const ViewedPosts = ({ loadingLogout, authenticated }) => {
	const dispatch = useDispatch();
	const viewedPostIds = useSelector((state) => state.data.viewedPostIds);

	useEffect(() => {
		if (viewedPostIds && viewedPostIds.length > 0) {
			// console.log('localStorage..viewedPostIds..useEffect', viewedPostIds);
			localStorage.setItem('viewedPostIds', JSON.stringify(viewedPostIds));
		}
		// Restore viewed post IDs from localStorage on component mount
		const storagePostIds = JSON.parse(localStorage.getItem('viewedPostIds'));

		const viewedPostIdsFromStorage = Array.isArray(storagePostIds)
			? storagePostIds
			: [];

		if (
			authenticated &&
			!loadingLogout &&
			window.location.href !== 'https://goodbyeapp.com/logout' &&
			viewedPostIdsFromStorage.length > 0 &&
			(viewedPostIds.length === 0 || viewedPostIds.includes(undefined))
		) {
			dispatch(restoreViewed(viewedPostIdsFromStorage));
		}

		// Return a function to handle cleanup
		const cleanup = () => {
			// Save the current Redux state to localStorage on component unmount
			if (
				authenticated &&
				!loadingLogout &&
				viewedPostIds &&
				viewedPostIds.length > 0
			) {
				localStorage.setItem('viewedPostIds', JSON.stringify(viewedPostIds));
			}
		};

		// Clean up on component unmount
		window.addEventListener('beforeunload', cleanup);

		// Return the cleanup function to be executed on unmount
		return () => {
			window.removeEventListener('beforeunload', cleanup);
			cleanup();
		};
	}, [dispatch, viewedPostIds]);

	return <div></div>;
};

const mapActionsToProps = {
	restoreViewed,
};
const mapStateToProps = (state) => ({
	loadingLogout: state.user.loadingLogout,
	authenticated: state.user.authenticated,
});

export default connect(mapStateToProps, mapActionsToProps)(ViewedPosts);
