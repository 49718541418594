import { useState, useEffect, useRef, useCallback } from 'react';
import Media from 'react-media';
import { useInView } from 'react-intersection-observer';
import { connect, useDispatch } from 'react-redux';
import { addViewed, setActiveVideo } from '../../redux/actions/dataActions';
import { createWaveSurferInstance } from './Wavesurfer';
import CustomButton from '../SocialComponents/CustomButton';
import { Grid, LinearProgress } from '@mui/material';
import VolumeUp from '@mui/icons-material/VolumeUp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import IOSlider from '../SocialComponents/Slider';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import { useTranslation } from 'react-i18next';

const AudioPlayer = ({
	fileUrl,
	postId,
	isMobile,
	makePostFunctionality,
	countViews,
	audioLetter,
	data: {
		viewedPostIds,
		audioUrlsNotFound,
		isMessagingSupported,
		activeVideoPostId,
		removedPostIds,
	},
	UI: { darkMode },
}) => {
	const wavesurferRef = useRef(null);
	const [playing, setPlaying] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [volume, setVolume] = useState(1);
	const [hasVolume, setHasVolume] = useState(true);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [progress, setProgress] = useState('0:00');
	const dispatch = useDispatch();
	const [ref, inView] = useInView({});
	const [mounted, setMounted] = useState(false);
	const prevTimeRef = useRef(0);
	const durationRef = useRef(null);
	const { t } = useTranslation();

	useEffect(() => {
		setMounted(true);
		return () => {
			setMounted(false);
		};
	}, []);

	useEffect(() => {
		if (
			!makePostFunctionality &&
			((mounted &&
				inView &&
				playing &&
				activeVideoPostId &&
				activeVideoPostId !== postId) ||
				(mounted && !inView && playing))
		) {
			setPlaying(false);
			wavesurferRef.current.playPause();
		}
	}, [inView, mounted, playing, postId, activeVideoPostId]);

	const updateProgress = useCallback(() => {
		const wavesurfer = wavesurferRef.current;
		const currentTime = wavesurfer.getCurrentTime();
		const formattedTime = formatTime(currentTime);

		if (durationRef.current && currentTime >= durationRef.current) {
			wavesurfer.stop();
			wavesurfer.seekTo(0);
			setPlaying(false);
			setProgress('0:00');
		} else {
			setProgress(formattedTime);
			prevTimeRef.current = currentTime;
		}
	}, []);

	useEffect(() => {
		const initializeWaveSurfer = async () => {
			if (!mounted) {
				return;
			}
			try {
				const wavesurferInstance = await createWaveSurferInstance(
					fileUrl,
					`audio_${postId}`,
					darkMode,
					isMobile,
					isMessagingSupported,
				);

				if (!mounted) {
					wavesurferInstance.destroy();
					return;
				} else {
					wavesurferRef.current = wavesurferInstance;
					const wavesurfer = wavesurferRef.current;
					wavesurferInstance.on('error', (error) => {
						setLoading(false);
						setError(true);
					});
					wavesurfer.on('ready', () => {
						if (!mounted) return;
						durationRef.current = wavesurfer.getDuration();
						setLoading(false);
					});

					wavesurfer.on('audioprocess', updateProgress);

					wavesurfer.on('finish', () => {
						setProgress('0:00');
						wavesurfer.stop();
						wavesurfer.seekTo(0);
						setPlaying(false);
						const hasViewed = viewedPostIds.includes(postId);
						if (
							countViews &&
							!makePostFunctionality &&
							!audioLetter &&
							!hasViewed
						) {
							dispatch(addViewed(postId));
						}
					});
				}
			} catch (error) {
				setLoading(false);
				setError(true);
			}
		};

		if (mounted) {
			initializeWaveSurfer();
		}
		return () => {
			if (wavesurferRef.current) {
				wavesurferRef.current.destroy();
			}
		};
	}, [wavesurferRef, mounted]);

	const onPlay = useCallback(() => {
		if (inView) {
			if (activeVideoPostId !== postId) {
				dispatch(setActiveVideo(postId));
			}
			// for audio letter to work... I have to set an empty timeout because it updates to fats and pauses audio.
			const timeout = 0;
			setTimeout(() => {
				setPlaying((prevPlaying) => !prevPlaying);
				wavesurferRef.current.playPause();
			}, timeout);
		}
	}, [inView, activeVideoPostId, postId, dispatch]);

	const formatTime = (time) => {
		const hours = Math.floor(time / 3600);
		const minutes = Math.floor((time % 3600) / 60);
		const seconds = Math.floor(time % 60);

		if (hours > 0) {
			return `${hours}:${String(minutes).padStart(2, '0')}:${String(
				seconds,
			).padStart(2, '0')}`;
		} else {
			return `${minutes}:${String(seconds).padStart(2, '0')}`;
		}
	};

	const handlePlaybackRate = (event, newValue) => {
		setPlaybackRate(newValue);
		const wavesurfer = wavesurferRef.current;
		wavesurfer.setPlaybackRate(newValue);
	};

	const changeSpeed = () => {
		let newValue = 0.75;

		if (playbackRate !== 1.5) {
			newValue = playbackRate + 0.25;
		}
		setPlaybackRate(newValue);
		const wavesurfer = wavesurferRef.current;
		wavesurfer.setPlaybackRate(newValue);
	};

	const handleSliderChange = (event, newValue) => {
		setVolume(newValue);
		wavesurferRef.current.setVolume(newValue);
	};

	const muteAudio = () => {
		if (hasVolume) {
			setHasVolume(false);
			wavesurferRef.current.setVolume(0);
		} else {
			setHasVolume(true);
			wavesurferRef.current.setVolume(volume);
		}
	};

	return (
		<div ref={ref}>
			{audioUrlsNotFound.includes(fileUrl) &&
			!removedPostIds.includes(postId) ? (
				<CustomAlerts error={t('audio_404')} />
			) : (
				<div>
					{loading && (
						<div>
							<LinearProgress />
							<div style={{ height: isMobile ? '114.3px' : '119.5px' }}></div>
						</div>
					)}
					{mounted && !error && (
						<div
							id={`audio_${postId}`}
							style={{ zIndex: 0 }}
							className='mgt grabCursor'
						></div>
					)}
					{error && <CustomAlerts info={t('audio_error')} />}
					{!loading && !error && (
						<Grid container>
							<Grid item lg={4} md={4} sm={0} xs={0}>
								<Media
									query='(min-width: 900px)'
									render={() => (
										<div className='flexColumn'>
											<div className='flex alignBaseline'>
												<CustomButton
													btnText={hasVolume ? t('volume') : t('unmute')}
													onClick={muteAudio}
													variant='filled'
													startIcon={
														!hasVolume || volume === 0 ? (
															<VolumeOffIcon />
														) : volume <= 0.3 ? (
															<VolumeMuteIcon />
														) : volume <= 0.7 ? (
															<VolumeDownIcon />
														) : (
															<VolumeUp />
														)
													}
													margin='1rem auto 0.5rem auto'
												/>
											</div>
											<IOSlider
												aria-label='Volume'
												min={0}
												max={1}
												value={volume}
												step={0.01}
												onChange={handleSliderChange}
												sx={{ width: '100px', margin: '0 auto' }}
											/>
										</div>
									)}
								/>
							</Grid>

							<Grid item lg={4} md={4} sm={6} xs={6}>
								<div className='flexColumn pdb2'>
									<CustomButton
										className='notranslate'
										btnText={!playing ? t('play') : t('pause')}
										onClick={onPlay}
										variant='filled'
										margin='1rem auto 0.5rem'
										startIcon={
											!playing ? <PlayCircleOutlineIcon /> : <PauseIcon />
										}
									/>
									<span className='center'>
										{progress}/{formatTime(durationRef.current || 0)}
									</span>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={6} xs={6}>
								<div className='flexColumn'>
									<CustomButton
										btnText={t('speed')}
										variant='filled'
										onClick={changeSpeed}
										startIcon={<SlowMotionVideoIcon />}
										margin={isMobile ? '1rem auto 0.5rem' : '1rem auto 0.15rem'}
									/>
									{isMobile ? (
										<span className='center'>{playbackRate}</span>
									) : (
										<IOSlider
											aria-label='Playback speed'
											valueLabelDisplay='auto'
											min={0.75}
											max={1.5}
											value={playbackRate}
											step={0.25}
											onChange={handlePlaybackRate}
											marks
											sx={{ width: '100px', margin: '0 auto' }}
											top={50}
										/>
									)}
								</div>
							</Grid>
						</Grid>
					)}
				</div>
			)}
		</div>
	);
};

const mapActionsToProps = {
	setActiveVideo,
	addViewed,
};
const mapStateToProps = (state) => ({
	data: state.data,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(AudioPlayer);
