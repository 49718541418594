import { Avatar, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CallIcon from '@mui/icons-material/Call';
import Footer from '../../components/Footer/Footer';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Groups3Icon from '@mui/icons-material/Groups3';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ContactOption from './ContactOption';
import { useSelector } from 'react-redux';
import CopyrightIcon from '@mui/icons-material/Copyright';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PolicyIcon from '@mui/icons-material/Policy';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import StarsIcon from '@mui/icons-material/Stars';
import { useTranslation } from 'react-i18next';
export default function ContactDirectories() {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();
	return (
		<div>
			<Helmet>
				<title>Contact Directories - Goodbye App</title>
			</Helmet>
			<div className='skyBackground pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<Groups3Icon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mg0'>{t('contacts')}</h1>
					<h3 className='subtitle center mg0'>{t('secondary_contact_dir')}</h3>
					<div>
						<CustomAlerts
							margin='1rem 0 0 0'
							info={true}
							message={t('for_quick_res')}
							linkText={t('support_page')}
							linkLocation='/contact'
						/>
						<Grid container>
							<Grid item xs={12} sm={12} md={6}>
								<ContactOption
									icon={<MailOutlineIcon id='cardMainIconSize' />}
									text={t('contact')}
									contact='contact@goodbyeapp.com'
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<ContactOption
									icon={<StarsIcon id='cardMainIconSize' />}
									text={t('marketing')}
									contact='marketing@goodbyeapp.com'
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<ContactOption
									icon={<WorkOutlineIcon id='cardMainIconSize' />}
									text={t('jobs')}
									contact='jobs@goodbyeapp.com'
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<ContactOption
									icon={<LightbulbIcon id='cardMainIconSize' />}
									text={t('suggestions')}
									contact='suggestions@goodbyeapp.com'
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} sx={{ marginBottom: '2rem' }}>
								<ContactOption
									icon={<CopyrightIcon id='cardMainIconSize' />}
									text={t('copyright')}
									contact='copyright@goodbyeapp.com'
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} sx={{ marginBottom: '2rem' }}>
								<ContactOption
									icon={<PolicyIcon id='cardMainIconSize' />}
									text='DPO'
									contact='dpo@goodbyeapp.com'
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={6} sx={{ marginBottom: '2rem' }}>
								<ContactOption
									icon={<RequestQuoteIcon id='cardMainIconSize' />}
									text={t('refunds')}
									contact='refunds@goodbyeapp.com'
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} sx={{ marginBottom: '2rem' }}>
								<ContactOption
									icon={<AccountBalanceIcon id='cardMainIconSize' />}
									text={t('legal')}
									contact='legal@goodbyeapp.com'
								/>
							</Grid>
							<Grid item xs={12}>
								<hr className='lightHr' />

								<ContactOption
									icon={<CallIcon id='cardMainIconSize' />}
									text={t('talk_to_manager')}
								/>
								<span className='flex center mgt bold'>
									{t('available_soon')}
								</span>
								{/* <a
													rel='noreferrer'
													href='tel:+'
													className='contactNumber'
												>
												</a> */}
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
