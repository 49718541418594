import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export default function ScrollToTop() {
	const { pathname } = useLocation();
	const params = useParams();
	useEffect(() => {
		if (
			pathname !== '/feed' &&
			pathname !== '/gbadmins' &&
			!params.postId &&
			// pathname.split('/')[1] !== 'users' &&
			!pathname.split('/').includes('users') &&
			!pathname.split('/').includes('notifications')
		) {
			window.scrollTo(0, 0);
		}
	}, [pathname]);

	return null;
}
