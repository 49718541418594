import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function SuicideHarm() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Suicide and Self-Harm Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Suicide and Self-Harm Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						At <span className='notranslate'> Goodbye App,</span> we understand
						the importance of safeguarding individuals from potential harm due
						to exposure to content that might inadvertently or intentionally
						promote self-harm. That's why our policy strictly prohibits any
						content that encourages or promotes self-harming behaviors. We also
						offer support to those navigating experiences related to self-harm
						or suicidal thoughts.
					</p>

					<h2>What Constitutes a Violation of This Policy?</h2>
					<p>
						Under this policy, you are not permitted to endorse or promote
						suicide or self-harm in any way. We consider promotion and
						encouragement to encompass statements like "the most effective,"
						"the easiest," "the best," "the most successful," "you should," and
						"why don’t you." Violations of this policy can occur through posts,
						images, videos and letters.
					</p>
					<p className='bold'>For the purposes of this policy:</p>
					<ul className='text'>
						<li>Suicide is defined as the act of taking one’s own life.</li>
						<li>
							Self-harm includes self-inflicted physical injuries (e.g.,
							cutting) and eating disorders (e.g., bulimia, anorexia).
						</li>
					</ul>
					<p className='bold'>
						Violations of this policy include, but are not limited to:
					</p>
					<ul className='text'>
						<li>
							Encouraging someone to physically harm or take their own life.
						</li>
						<li>
							Soliciting encouragement from others to engage in self-harm or
							suicide, including seeking partners for group suicides or suicide
							games.
						</li>
						<li>
							Sharing information, strategies, methods, or instructions that
							facilitate self-harm and suicide.
						</li>
					</ul>

					<h2>
						Here are some examples of behavior that do not violate this policy:
					</h2>
					<ul className='text'>
						<li>
							Sharing personal stories and experiences related to self-harm or
							suicide.
						</li>
						<li>
							Providing coping mechanisms and resources for addressing self-harm
							or suicidal thoughts.
						</li>
						<li>
							Engaging in discussions focused on research, advocacy, and
							education related to self-harm or suicide prevention.
						</li>
					</ul>

					<p>
						However, it's important to note that while sharing personal
						experiences is allowed, individuals should refrain from sharing
						detailed information about specific strategies or methods related to
						self-harm, as this could inadvertently encourage such behavior.
					</p>
					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Self-harm, eating disorders, or suicide".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>
					<p className='bold mg0'>
						Our approach to enforcement depends on several factors, including
						the type of content shared, whether the reported account is
						promoting self-harm or suicide, and the account's history of
						violations. If you breach this policy by sharing content that
						encourages self-harm, asks others to encourage self-harm, or
						provides detailed information about self-harm or suicide methods, we
						will require you to remove the content. Additionally, we may
						temporarily lock your account to prevent further posting. Repeat
						violations or accounts dedicated to promoting self-harm or suicide
						will result in permanent suspension.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default SuicideHarm;
