import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ImgSrc from './ImgSrc';
import { CircularProgress, ListItemButton } from '@mui/material';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import DottedMenu from './DottedMenu';
import CustomAlerts from './CustomAlerts';
import { connect } from 'react-redux';
import {
	showReportedComment,
	deleteCommentFromStore,
	getCommentReplies,
	getCommentRepliesAdmin,
	removeDeletedComment,
	numberFormatter,
	shortenDate,
} from '../../redux/actions/dataActions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CopyText from './CopyText';
import ReplayIcon from '@mui/icons-material/Replay';
import LikeButton from './LikeButton';
import PostComments from './PostComments';
import CommentForm from './CommentForm';
import CustomButton from './CustomButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { withRouter } from '../../redux/withRouter';
import LinkFormatter from './LinkFormatter';
import CustomReactReadMoreReadLess from '../ReadMore/CustomReadMoreReadLess';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';

class Comment extends Component {
	state = {
		reply: false,
		showReplies: false,
		queryRepliesOrder: 'asc',
		orderFromParams: false,
		showAll: false,
		userLimiter: false,
	};

	componentDidMount() {
		let repliedId = this.props.params.repliedId;
		let paramsCommentId = this.props.params.paramsCommentId;
		let limit = 10;
		let formData = {
			commentCollection: 'comments',
			limit: limit,
			order: 'desc',
		};
		if (
			(paramsCommentId === this.props.comment.commentId &&
				repliedId &&
				!this.props.data.loadingCommentReplies.includes(paramsCommentId)) ||
			(this.props.user.adminPanel.adminPanelActive &&
				this.props.comment.commentId ===
					this.props.data.adminReportRepliedId) ||
			this.props.location.state?.showReplies
		) {
			if (this.props.user.adminPanel.adminPanelActive) {
				formData.limit = 0;
				formData.repliedId = this.props.data.adminReportCommentId;
				formData.commentId = this.props.comment.commentId;
				formData.commentCollection = this.props.data.adminReportInfringement
					? 'removedComments'
					: 'comments';
				this.props.getCommentRepliesAdmin(formData);
			} else {
				formData.commentId = paramsCommentId;
				formData.repliedId = repliedId;
				if (
					this.props.location.pathname.split('/').includes('removedComment')
				) {
					formData.commentCollection = 'removedComments';
					formData.limit = 0;
				}
				this.props.getCommentReplies(formData);
			}

			this.setState((prevState) => ({
				showReplies: !prevState.showReplies,
				queryRepliesOrder: 'desc',
			}));
		}
	}

	handleChangeQueryOrder = (order, commentId) => {
		if (
			order !== this.state.queryRepliesOrder ||
			(this.state.orderFromParams && order === this.state.queryRepliesOrder)
		) {
			let formData;
			formData = {
				commentId: this.props.comment.commentId,
				commentCollection: 'comments',
				limit: 10,
				order: order,
			};
			this.setState({ orderFromParams: true, queryRepliesOrder: order });
			this.props.getCommentReplies(formData, false, 'changeOrder');
		}
	};

	removeDeletedComment = (commentId, repliedId, replyCount) => {
		let post = this.props.data.post;

		if (replyCount > 0) {
			post.replyCount = post.replyCount - 1;
		}
		post.commentCount = post.commentCount - 1;

		this.props.removeDeletedComment(post, commentId, repliedId);
	};

	handleReadAll = (bool) => {
		this.setState((prevState) => ({
			showAll: !prevState.showAll,
		}));
		if (this.state.showAll) {
			const element = document.getElementById(this.props.comment.commentId);
			const offsetTop = element.offsetTop;
			window.scrollTo({
				top: offsetTop, // Adjust this value to scroll to the desired position
				behavior: 'smooth', // Optional: Add smooth scrolling behavior
			});
		}
	};

	reply = (action) => {
		if (action === 'showReplies') {
			this.setState((prevState) => ({
				reply: !prevState.reply,
				showReplies: true,
			}));
		} else if (this.props.user.credentials.repliesMadeToday >= 200) {
			this.setState({
				userLimiter: true,
			});

			toast.info(
				`${t('you_have_exceeded_daily_limit_action')} ${t(
					'security_anti_spam_measures',
				)}`,
				{
					position: 'top-left',
					autoClose: 15000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					toastId: 'repliesMadeToday',
				},
			);
		} else {
			this.setState((prevState) => ({
				reply: !prevState.reply,
			}));
		}
	};

	getReplies = (commentId, repliedId, commentCollection, limit) => {
		if (
			!this.state.showReplies &&
			this.props.comment.replyCount > 0 &&
			this.props.comment.replies === undefined
		) {
			let formData = {
				commentId,
				repliedId,
				commentCollection,
				limit,
				order: 'asc',
			};
			this.props.getCommentReplies(formData);
		}
		this.setState((prevState) => ({ showReplies: !prevState.showReplies }));
	};

	render() {
		const {
			comment: {
				admin,
				commentId,
				body,
				createdAt,
				userImage,
				userHandle,
				userImageColor,
				userThumbnail,
				postId,
				userId,
				infringements,
				appeal,
				appealable,
				likeCount,
				replyCount,
				liked,
				replies,
				repliedId,
				repliedPostId,
				mentions,
			},
			user: {
				credentials: { handle },
				adminPanel: { adminPanelActive },
			},
			UI: { mode },
			data: {
				submittingComments,
				errorSubmitComments,
				errorDeletingComments,
				deletingComments,
				reportingCommentId,
				reportedComments,
				loadingCommentInfringement,
				errorCommentInfringement,
				removedCommentIds,
				adminReportCommentId,
				adminReportRepliedId,
				removedPostIds,
			},
			params: { paramsCommentId },
			queryCommentId,
			onlyComments,
			onlyLiked,
			livePost,
			notFound,
		} = this.props;
		const { reply, showReplies, queryRepliesOrder, userLimiter, showAll } =
			this.state;
		const creds = this.props.user.credentials;
		const bool = userId === creds.userId;
		const imgUrl = bool ? creds.imageUrl : userImage;
		const imgColor = bool ? creds.imageColor : userImageColor;
		const thumbnail = bool ? creds.thumbnail : userThumbnail;
		const dataHandle = bool ? handle : userHandle;
		const isAdmin = bool ? creds.admin : admin;
		const reportedComment = reportedComments.includes(commentId);
		const createdAtFromNow = shortenDate(dayjs(createdAt).fromNow());

		return !reportedComment ? (
			<div>
				{adminPanelActive && (
					<div className='adminCommentData'>
						{queryCommentId && (
							<CopyText frase='Post ID:' text={postId} small />
						)}
					</div>
				)}

				<CustomAlerts
					alertId='smallMuiAlert'
					error={errorSubmitComments.includes(commentId)}
					message={t('error_commenting')}
					noMargin
				/>
				<CustomAlerts
					alertId='smallMuiAlert'
					error={errorDeletingComments.includes(commentId)}
					message={t('error_delete_comment')}
					noMargin
				/>

				<CustomAlerts
					alertId='smallMuiAlert'
					error={errorCommentInfringement && commentId === reportingCommentId}
					message={errorCommentInfringement}
					noMargin
				/>
				<CustomAlerts
					alertId='smallMuiAlert'
					info={removedCommentIds.includes(commentId)}
					message={t('content_deleted', {
						content: repliedId ? t('reply') : t('comment'),
					})}
					noMargin
				/>

				<li
					id='C_userDetails'
					className={`pdComment ${
						(adminPanelActive && commentId === adminReportRepliedId) ||
						submittingComments.includes(commentId)
							? 'commentReplyReport'
							: deletingComments.includes(commentId) ||
							  errorSubmitComments.includes(commentId)
							? 'lightWarningComment pdbH'
							: errorSubmitComments.includes(commentId)
							? 'lightBlueComment'
							: infringements ||
							  deletingComments.includes(commentId) ||
							  commentId === adminReportCommentId
							? 'deletingComment'
							: removedCommentIds.includes(commentId) ||
							  removedCommentIds.includes(repliedId)
							? 'commentReport pdbH'
							: (commentId === paramsCommentId &&
									!this.props.params.repliedId) ||
							  commentId === this.props.params.repliedId
							? 'additional'
							: ''
					}`}
				>
					<div className='flex'>
						<Link
							to={adminPanelActive ? '#' : `/users/${dataHandle}`}
							state={{ data: userId }}
							className={`bottomTranslate7 ${
								mode === 'light' ? 'dark' : 'white'
							}`}
						>
							<ImgSrc
								img={imgUrl}
								imgColor={imgColor}
								thumbnail={thumbnail}
								handle={dataHandle}
								fontSize={repliedId ? '14px' : '18px'}
								css={repliedId ? 'listReplyImg' : 'listCommentImg'}
							/>
						</Link>

						<ListItemButton
							// just using this component for the focus property
							disableRipple
							disableGutters
							autoFocus={
								commentId === this.props.params.repliedId ||
								(commentId === paramsCommentId && !repliedId) ||
								submittingComments.includes(commentId)
									? true
									: false
							}
							style={{
								backgroundColor: 'transparent',
								cursor: 'default',
								padding: 0,
							}}
						/>
						<div>
							<div className='flex alignItemsCenter'>
								<Link
									to={adminPanelActive ? '#' : `/users/${dataHandle}`}
									state={{ data: userId }}
									className={` ${mode === 'light' ? 'dark' : 'white'}`}
								>
									<span className='listItemSpan'>
										{dataHandle}
										{isAdmin && (
											<VerifiedUserIcon
												id='verifiedBadgeComment'
												fontSize='small'
											/>
										)}
									</span>
								</Link>
								<div className='flex'>
									<span className='commentDateFromNow notranslate'>
										{createdAtFromNow}
									</span>

									{onlyLiked && (
										<div className='flex alignItems' id='mgr'>
											<Link
												to={
													postId
														? `/feed/post/${postId}/${commentId}`
														: repliedPostId
														? `/feed/post/${repliedPostId}/${repliedId}/${commentId}`
														: '#'
												}
												state={{
													onlyLiked: true,
													showLikes: true,
													commentId: commentId,
													repliedId: repliedId,
													author:
														dataHandle.slice(0, 1).toUpperCase() +
														dataHandle.slice(1),
													limit: likeCount > 10 ? 10 : likeCount,
												}}
												className='bold link'
											>
												{t('view')}
											</Link>
										</div>
									)}
									{!submittingComments.includes(commentId) &&
										!errorSubmitComments.includes(commentId) &&
										!errorDeletingComments.includes(commentId) &&
										!onlyComments &&
										!onlyLiked &&
										!removedCommentIds.includes(commentId) &&
										!removedCommentIds.includes(repliedId) &&
										!removedPostIds.includes(postId) &&
										!removedPostIds.includes(repliedPostId) && (
											<DottedMenu
												comment={true}
												count={replyCount}
												repliedId={repliedId}
												dataUserId={userId}
												dataHandle={dataHandle}
												deleteFunction={this.props.function}
												postAuthorHandle={
													creds.userId === this.props.data.post?.userId
														? handle
														: this.props.data.post?.userHandle
												}
												commentId={commentId}
												postId={postId ? postId : repliedPostId}
												infringements={infringements}
												appeal={appeal}
												appealable={appealable}
											/>
										)}
									<div className='mglH'>
										{adminPanelActive && (
											<span>
												<CopyText frase='ID:' text={commentId} small />
											</span>
										)}
									</div>
								</div>
							</div>

							{body && (
								<div id={commentId}>
									{mentions && mentions.length > 0 ? (
										// ||(hashtags && hashtags.length > 0)
										<div>
											<p
												className={`text prewrap mg0 mgr4px ${
													body.length > 300 ? 'commentBodyContent' : ''
												}  ${showAll ? 'showAllContent' : ''}`}
											>
												<LinkFormatter
													str={body}
													adminPanelActive={adminPanelActive}
													mentions={mentions}
													commentFormatter
													// hashtags={hashtags}
												/>
											</p>
											{body.length > 200 && (
												<div className='flex'>
													<CustomButton
														onClick={this.handleReadAll}
														variant='text'
														btnText={showAll ? t('show_less') : t('read_more')}
														margin='0 auto -12px auto'
														fontSize='16px'
														height='36px'
														textTransform='none'
													/>
												</div>
											)}
										</div>
									) : (
										<div className='text prewrap mgr4px'>
											<CustomReactReadMoreReadLess
												charLimit={180}
												readMoreText={t('read_more')}
												readLessText={t('show_less')}
												onClickReadLess={this.handleReadAll}
											>
												{body.replace(/\n{2,}/g, '\n')}
											</CustomReactReadMoreReadLess>
										</div>
									)}
								</div>
							)}
							{onlyComments || onlyLiked ? (
								<div className='flex mgtH  leftTranslate4 alignItemsCenter'>
									{onlyComments && likeCount > 0 && (
										<div className='flex mgrH'>
											<Link
												to={
													!infringements && !adminPanelActive && postId
														? `/feed/post/${postId}/${commentId}/likes`
														: repliedPostId
														? `/feed/post/${repliedPostId}/${repliedId}/${commentId}/likes`
														: '#'
												}
												state={{
													onlyLiked: true,
													onlyComments: true,
													showLikes: true,
													commentId: commentId,
													repliedId: repliedId,
													author:
														dataHandle.slice(0, 1).toUpperCase() +
														dataHandle.slice(1),
													limit: likeCount > 10 ? 10 : likeCount,
												}}
												className='bold center link'
											>
												{numberFormatter(likeCount, 1)}{' '}
												{likeCount > 1 ? t('likes') : t('like')}
											</Link>
										</div>
									)}
									{replyCount > 0 && !infringements && (
										<Link
											to={
												postId
													? `/feed/post/${postId}/${commentId}`
													: repliedPostId
													? `/feed/post/${repliedPostId}/${repliedId}/${commentId}`
													: '#'
											}
											state={{
												onlyComments: onlyComments,
												onlyLiked: onlyLiked,
												showReplies: true,
											}}
											className='bold link'
										>
											{numberFormatter(replyCount, 1)}{' '}
											{replyCount > 1
												? t('replies')
												: replyCount === 1
												? t('reply')
												: ''}
										</Link>
									)}
								</div>
							) : (
								<div className='leftTranslate78 commentActions'>
									{!errorSubmitComments.includes(commentId) &&
										!errorDeletingComments.includes(commentId) &&
										!onlyComments &&
										!onlyLiked &&
										!removedPostIds.includes(postId) &&
										!removedPostIds.includes(repliedPostId) &&
										!removedCommentIds.includes(commentId) &&
										!removedCommentIds.includes(repliedId) && (
											<div>
												{replyCount && replyCount > 0 ? (
													<CustomButton
														onClick={() =>
															!deletingComments.includes(commentId) &&
															this.getReplies(
																commentId,
																repliedId,
																'comments',
																replyCount >= 10 ? 10 : replyCount,
															)
														}
														btnText={`${numberFormatter(replyCount, 1)} ${
															replyCount > 1 ? t('replies') : t('reply')
														}`}
														fontSize='16px'
														margin='0 5px 0 1px'
														padding='0 6px'
														startIcon={
															showReplies ? (
																<ArrowDropUpIcon />
															) : (
																<ArrowDropDownIcon />
															)
														}
													/>
												) : null}

												{!adminPanelActive && !notFound && !infringements && (
													<CustomButton
														btnText={t('reply')}
														margin='0'
														onClick={() => {
															!submittingComments.includes(commentId) &&
																!deletingComments.includes(commentId) &&
																this.reply();
														}}
														disabled={userLimiter}
														fontSize='16px'
														padding='0'
													/>
												)}

												{showReplies && replyCount > 1 && (
													<DottedMenu
														queryFromCommentId={
															paramsCommentId &&
															replies &&
															replies.length > 0 &&
															paramsCommentId === replies[0].commentId
														}
														orderData={true}
														comment={true}
														replies={replies}
														queryOrder={queryRepliesOrder}
														handleChangeQueryOrder={this.handleChangeQueryOrder}
													/>
												)}
											</div>
										)}
								</div>
							)}
						</div>
					</div>
					{removedCommentIds.includes(repliedId) ? (
						''
					) : removedCommentIds.includes(commentId) ? (
						<CustomButton
							iconButton
							startIcon={<CloseIcon />}
							color='inherit'
							margin='0'
							id='rightTranslate4'
							onClick={() =>
								this.removeDeletedComment(commentId, repliedId, replyCount)
							}
						/>
					) : (
						<div>
							{submittingComments.includes(commentId) ||
							deletingComments.includes(commentId) ||
							(loadingCommentInfringement &&
								commentId === reportingCommentId) ? (
								<div id='circularProgressQuery'>
									<CircularProgress
										id='userRowCircularLoading'
										size={20}
										thickness={3}
									/>
								</div>
							) : errorSubmitComments.includes(commentId) ? (
								<CustomButton
									iconButton
									startIcon={<DeleteOutlineIcon />}
									color='inherit'
									margin='0'
									id='rightTranslate4'
									onClick={() =>
										this.props.deleteCommentFromStore(commentId, repliedId)
									}
								/>
							) : errorDeletingComments.includes(commentId) ? (
								<CustomButton
									iconButton
									startIcon={<ReplayIcon />}
									color='inherit'
									margin='0'
									id='rightTranslate4'
									onClick={this.props.function}
								/>
							) : onlyComments ? (
								<Link
									to={
										postId
											? `/feed/post/${postId}/${commentId}`
											: repliedPostId
											? `/feed/post/${repliedPostId}/${repliedId}/${commentId}`
											: '#'
									}
									state={{
										onlyComments: onlyComments,
										onlyLiked: onlyLiked,
									}}
									id='link'
									className={`bold mgrH ${mode === 'light' ? 'link' : 'white'}`}
								>
									{t('view')}
								</Link>
							) : (
								<div className='flexColumn alignItemsCenter'>
									{!adminPanelActive &&
										!removedPostIds.includes(postId) &&
										!removedPostIds.includes(repliedPostId) &&
										!removedCommentIds.includes(commentId) &&
										!removedCommentIds.includes(repliedId) &&
										!notFound &&
										!infringements && (
											<LikeButton
												comment={true}
												postId={postId ? postId : repliedPostId}
												commentId={commentId}
												repliedId={repliedId}
												liked={liked}
												likeCount={likeCount}
											/>
										)}
									{likeCount > 0 && (
										<small className='flex mgtH'>
											<Link
												to={
													onlyComments || onlyLiked
														? !infringements && !adminPanelActive && postId
															? `/feed/post/${postId}/${commentId}/likes`
															: repliedPostId
															? `/feed/post/${repliedPostId}/${repliedId}/${commentId}/likes`
															: '#'
														: !infringements &&
														  !adminPanelActive &&
														  this.props.pathname + '/likes'
												}
												state={
													onlyComments || onlyLiked
														? {
																onlyLiked: true,
																showLikes: true,
																commentId: commentId,
																repliedId: repliedId,
																author:
																	dataHandle.slice(0, 1).toUpperCase() +
																	dataHandle.slice(1),
																limit: likeCount > 10 ? 10 : likeCount,
														  }
														: {
																commentId: commentId,
																author:
																	dataHandle.slice(0, 1).toUpperCase() +
																	dataHandle.slice(1),
																limit: likeCount > 10 ? 10 : likeCount,
														  }
												}
												className={`bold center ${
													mode === 'light' ? 'dark' : 'white'
												}`}
											>
												{likeCount > 10000
													? numberFormatter(likeCount, 1)
													: likeCount}{' '}
											</Link>
										</small>
									)}
								</div>
							)}
						</div>
					)}
				</li>

				{reply && !adminPanelActive && !infringements && (
					<CommentForm
						livePost={livePost}
						postId={postId ? postId : repliedPostId}
						commentId={commentId}
						closeReply={this.reply}
						reply={reply}
						replyTo={userHandle}
						replyToId={userId}
						repliedId={repliedId}
					/>
				)}
				{showReplies && (
					<div className='pdl'>
						<PostComments
							queryFromCommentId={
								paramsCommentId &&
								replies &&
								replies.length > 0 &&
								paramsCommentId === replies[0].commentId
							}
							commentCount={replyCount}
							comments={replies ? replies : []}
							postId={repliedPostId}
							commentId={commentId}
							replies={true}
							livePost={livePost}
							paramsCommentId={paramsCommentId}
						/>
					</div>
				)}
			</div>
		) : (
			<CustomAlerts
				success={t('report_success', { content: t('comment') })}
				showBtn={true}
				btnClick={() => this.props.showReportedComment(commentId)}
				btnText={t('show_content', {
					content: t('comment'),
				})}
				margin='0.5rem 1rem'
			/>
		);
	}
}
Comment.propTypes = {
	comment: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	handle: PropTypes.string,
	paramsCommentId: PropTypes.string,
	function: PropTypes.func,
	function2: PropTypes.func,
	submitting: PropTypes.object,
	errorSubmitComments: PropTypes.array,
	errorDeletingComments: PropTypes.array,
	deletingComments: PropTypes.string,
	deleteCommentFromStore: PropTypes.func.isRequired,
	getCommentReplies: PropTypes.func.isRequired,
	getCommentRepliesAdmin: PropTypes.func.isRequired,
	removeDeletedComment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	UI: state.UI,
	data: state.data,
	user: state.user,
});

const mapActionsToProps = {
	showReportedComment,
	deleteCommentFromStore,
	getCommentReplies,
	getCommentRepliesAdmin,
	removeDeletedComment,
};
export default connect(mapStateToProps, mapActionsToProps)(withRouter(Comment));
