import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function SensitiveContent() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Sensitive Content Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Sensitive Content Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						Excessive gore, violent content, adult material, and media depicting
						sexual violence or assault are not permitted on the platform due to
						their potentially harmful and disturbing nature. Such content can be
						psychologically distressing, triggering, and inappropriate for
						certain audiences, including minors. The platform aims to maintain a
						safe and respectful environment for all users, free from graphic or
						explicit content that may cause discomfort or harm. Therefore,
						strict guidelines are in place to ensure that sensitive media is not
						shared or promoted on the platform.
					</p>

					<h2>What Constitutes a Violation of This Policy?</h2>

					<p className='bold'>Graphic content:</p>
					<p>
						Graphic content refers to any form of media that portrays death,
						violence, medical procedures, or severe physical injury in a
						detailed and explicit manner. This includes depictions of violent
						crimes or accidents, physical altercations, child abuse, bodily
						fluids such as blood or feces, significant physical harm with
						visible wounds, and images of severely injured or mutilated animals.
						These types of content are often disturbing and can have a negative
						impact on viewers, especially those who are sensitive or vulnerable.
						Therefore, platforms and communities typically have strict
						guidelines to regulate and prohibit the sharing of graphic content
						to ensure the safety and well-being of their users.
					</p>
					<p>
						Note: exceptions may be made for documentary or educational content.{' '}
					</p>
					<p className='bold'>Violent sexual conduct</p>
					<p>
						Violent sexual conduct refers to media that depicts violence,
						whether real or simulated, in conjunction with sexual acts. This
						category includes depictions of rape and other forms of violent
						sexual assault, or sexual acts that occur without the consent of
						participants, including simulated lack of consent. It also
						encompasses sexualized violence, which involves inflicting physical
						harm on an individual within an intimate setting, where it is not
						immediately obvious if those involved have consented to take part.
					</p>

					<p className='bold'>Adult nudity and sexual behavior</p>
					<p>
						Adult nudity and sexual behavior refers to media that is
						pornographic or created with the intent to stimulate sexual arousal,
						and it must be consensually produced and distributed. This category
						includes depictions of full or partial nudity, including close-ups
						of genitals, buttocks, or breasts (excluding content related to
						breastfeeding), simulated sexual acts, and sexual intercourse or
						other sexual acts. This policy also applies to cartoons, hentai, or
						anime involving humans or depictions of animals with human-like
						features.
					</p>
					<p>
						However, exceptions may be made for content that is artistic,
						medical, health-related, or educational in nature.{' '}
					</p>

					<p className='bold'>Bestiality and Necrophilia</p>
					<p>
						Bestiality refers to any media depicting sexual acts between a human
						and an animal, while necrophilia involves sexual acts between a
						living human and a human corpse. These are prohibited categories
						that are not allowed on our platform.
					</p>
					<p className='bold'>Gratuitous Gore</p>
					<p>
						Gratuitous Gore refers to media that excessively portrays graphic or
						gruesome content related to death, violence, or severe physical
						harm, often shared for sadistic purposes. This includes depictions
						of dismembered or mutilated humans, charred or burned human remains,
						exposed internal organs or bones, and animal torture or killing.
						Such content is strictly prohibited on our platform for ever.
					</p>

					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Nudity or sexual behavior"</b>, or{' '}
							<b>"Self-harm, eating disorders, or suicide"</b>, or{' '}
							<b>"Violence or dangerous organizations".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>
					<p className='bold mg0'>
						Our approach to enforcement depends on several factors, including
						the type of content shared, and the account's history of violations.
						If you breach this policy we will require you to remove the content.
						Additionally, we may temporarily lock your account to prevent
						further posting. Your account may be permanently suspended if the
						majority of your activity on{' '}
						<span className='notranslate'> Goodbye App </span> is sharing this
						type of media. Repeat violations or accounts dedicated to promoting
						self-harm or suicide will result in permanent suspension.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default SensitiveContent;
