import {
	applyMove,
	diff,
	moveToPositionRestrictions,
	resizeToSizeRestrictions,
	getCenter,
	rotatePoint,
	getTransformedImageSize,
	getPositionRestrictions,
	getSizeRestrictions,
	getAreaSizeRestrictions,
	getAreaPositionRestrictions,
	approximateSize,
	isInitializedState,
	mergeSizeRestrictions,
	CoreSettings,
	CropperState,
	deepCompare,
	ImageTransform,
	ModifierSettings,
	PostprocessAction,
	transformImage,
	Rotate,
	isNumber,
	copyState,
	Coordinates,
	InitializedCropperState,
} from 'advanced-cropper';

interface CropperStateWithBasis extends CropperState {
	basis?: Coordinates;
}

interface InitializedCropperStateWithBasis extends InitializedCropperState {
	basis?: Coordinates;
}

function rotateImageAlgorithm(
	state: CropperStateWithBasis | InitializedCropperStateWithBasis,
	settings: CoreSettings,
	rotate: number | Rotate,
) {
	if (isInitializedState(state)) {
		const result = copyState(state);

		const angle = isNumber(rotate) ? rotate : rotate.angle;

		const imageCenter = rotatePoint(
			getCenter({
				left: 0,
				top: 0,
				...getTransformedImageSize(state),
			}),
			angle,
		);

		result.transforms.rotate += angle;

		result.coordinates = {
			...approximateSize({
				sizeRestrictions: getSizeRestrictions(result, settings),
				aspectRatio: result.coordinates.width / result.coordinates.height,
				width: (result.basis || result.coordinates).width,
				height: (result.basis || result.coordinates).height,
			}),
			...rotatePoint(getCenter(result.coordinates), angle),
		};

		const center =
			!isNumber(rotate) && rotate.center
				? rotate.center
				: getCenter(state.coordinates);

		const shift = diff(
			getCenter(state.coordinates),
			rotatePoint(getCenter(state.coordinates), angle, center),
		);

		const imageSize = getTransformedImageSize(result);

		result.coordinates.left -=
			imageCenter.left -
			imageSize.width / 2 +
			result.coordinates.width / 2 -
			shift.left;
		result.coordinates.top -=
			imageCenter.top -
			imageSize.height / 2 +
			result.coordinates.height / 2 -
			shift.top;

		// Check that visible area doesn't break the area restrictions:
		result.visibleArea = resizeToSizeRestrictions(
			result.visibleArea,
			mergeSizeRestrictions(getAreaSizeRestrictions(result, settings), {
				minWidth: result.coordinates.width,
				minHeight: result.coordinates.height,
			}),
		);

		result.coordinates = moveToPositionRestrictions(
			result.coordinates,
			getPositionRestrictions(result, settings),
		);

		result.visibleArea = applyMove(
			result.visibleArea,
			diff(getCenter(result.coordinates), getCenter(state.coordinates)),
		);

		result.visibleArea = moveToPositionRestrictions(
			result.visibleArea,
			getAreaPositionRestrictions(result, settings),
		);
		return result;
	}
	return state;
}

export function customizedTransformImage(
	state: CropperStateWithBasis,
	settings: CoreSettings & ModifierSettings,
	transform: ImageTransform,
): CropperState {
	const { rotate, flip, scale, move } = transform;

	if (state.coordinates) {
		if (!flip && !scale && !move) {
			state.basis = state.basis || { ...state.coordinates };
		} else {
			state.basis = undefined;
		}
	}

	if (rotate) {
		state = rotateImageAlgorithm(state, settings, rotate);
	}

	return transformImage(state, settings, { flip, scale, move });
}

export function resetBasis(
	state: CropperStateWithBasis,
	settings: CoreSettings,
	action: PostprocessAction,
) {
	const result = copyState(state);

	if (action.immediately) {
		if (
			action.name !== 'transformImage' &&
			action.name !== 'transformImageEnd' &&
			!deepCompare(result.coordinates, result.basis)
		) {
			result.basis = undefined;
		}
	}
	return result;
}
