import LocationOn from '@mui/icons-material/LocationOn';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

const ProfileSkeleton = (props) => {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div className={`card pd ${mode === 'dark' && 'dbc'}`}>
			<div className='flex center'>
				<Skeleton variant='circular' width={'210px'} height={'260px'} />
			</div>

			<div className='flex center mgt'>
				<Skeleton animation='wave' width={'80%'} height={45} />
			</div>
			<div className='flex alignItemscenter mgY'>
				<Skeleton
					variant='rectangular'
					animation='pulse'
					width={'60%'}
					height={20}
				/>
			</div>
			<div className='flex alignItemscenter mgY'>
				<FingerprintIcon className='detailsIcon topTranslate2 mgrH' />
				<Skeleton
					variant='rectangular'
					animation='pulse'
					width={'70%'}
					height={20}
				/>
			</div>

			<div className='flex alignItemscenter mgY'>
				<LocationOn className='detailsIcon topTranslate2 mgrH' />
				<Skeleton
					variant='rectangular'
					animation='pulse'
					width={'80%'}
					height={20}
				/>
			</div>
			<div className='flex alignItemscenter mgY'>
				<CalendarToday className='detailsIcon topTranslate2 mgrH' />
				<Skeleton
					variant='rectangular'
					animation='pulse'
					width={'100%'}
					height={20}
				/>
			</div>
			<hr />
		</div>
	);
};

export default ProfileSkeleton;
