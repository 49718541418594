import jwtDecode from 'jwt-decode';
import axios from 'axios';
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import { Navigate } from 'react-router-dom';
const token = localStorage.FBIdToken;

const VerifyToken = () => {
	if (token) {
		const decodedToken = jwtDecode(token);
		let decodedTokenExp = decodedToken.exp * 1000;
		if (decodedTokenExp < Date.now()) {
			<Navigate to='/login' />;
		} else {
			if (
				document.location.pathname !== '/logout' &&
				document.location.pathname !== '/accountActions'
			) {
				store.dispatch({ type: SET_AUTHENTICATED });
				axios.defaults.headers.common['Authorization'] = token;
				const FBIdToken = `Bearer ${token}`;
				localStorage.setItem('FBIdToken', FBIdToken);
			}
		}
	} else {
		<Navigate to='/login' />;
	}
	return <div></div>;
};
export default VerifyToken;
