import Footer from '../../components/Footer/Footer';
import { withRouter } from '../../redux/withRouter';
import { Helmet } from 'react-helmet-async';
import { Avatar } from '@mui/material';
import { Link, Navigate } from 'react-router-dom';
import CustomButton from '../../components/SocialComponents/CustomButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setNotificationRedirect } from '../../redux/actions/userActions';
import TimerIcon from '@mui/icons-material/Timer';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import { useTranslation } from 'react-i18next';

const AuthRequired = () => {
	const { t } = useTranslation();
	const mode = useSelector((state) => state.UI.mode);
	const [redirectIn, setRedirectIn] = useState(15);
	const dispatch = useDispatch();
	useEffect(() => {
		let interval;
		dispatch(
			setNotificationRedirect({
				path: window.location.pathname,
			}),
		);
		interval = setInterval(() => {
			setRedirectIn((prevRedirectIn) => prevRedirectIn - 1);
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [dispatch]);

	let notFoundJsx = (
		<div>
			<Helmet>
				<title>Authentication Required - Goodbye App</title>
			</Helmet>
			{redirectIn <= 0 && <Navigate to='/login' />}
			<div className='skyBackground'>
				<div className='pd2Media'>
					<div className={`card pd2B1 ${mode === 'dark' && 'dbc'}`}>
						<Avatar id='cardMainIcon' className='flexAuto'>
							<AccountCircleIcon id='cardMainIconSize' />
						</Avatar>
						<h1 className='title center mg0'>{t('session_exp')}</h1>
						<h3 className='subtitle center mgbOnly'>{t('please_log_in')}</h3>
						<hr />
						<CustomAlerts
							info={true}
							icon={<TimerIcon />}
							message={
								redirectIn > 0
									? t('redirecting_to_login', {
											number: redirectIn,
									  })
									: t('redirecting')
							}
							margin='0'
						/>

						<Link to='/login'>
							<CustomButton
								fullWidth
								id='customSuccessButton'
								variant='contained'
								btnText={t('login')}
								startIcon={<LoginIcon />}
								margin='1rem 0 0.5rem 0'
							/>
						</Link>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
	return notFoundJsx;
};

const mapActionsToProps = {
	setNotificationRedirect,
};

const mapStateToProps = (state) => ({});
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(AuthRequired));
