import { Alert, AlertTitle, Grow, LinearProgress } from '@mui/material';

export default function Progress({
	progress,
	title,
	icon,
	variant,
	severity,
	progressText,
}) {
	return (
		<div>
			{progress !== null ? (
				<Grow
					in={true}
					style={{ transformOrigin: 'top' }}
					{...(true ? { timeout: 800 } : {})}
				>
					<Alert
						severity={
							severity
								? severity
								: !progress || progress <= 99
								? 'info'
								: 'success'
						}
						id='alertUploadProgressPost'
						icon={icon ? icon : null}
					>
						<AlertTitle>
							{title}{' '}
							<strong>
								{progressText
									? progressText
									: typeof progress === 'number'
									? `${progress}%`
									: ''}
							</strong>
						</AlertTitle>

						<div className='uploadProgressPost'>
							<LinearProgress
								variant={variant}
								value={progress}
								valueBuffer={progress + 15}
							/>
						</div>
					</Alert>
				</Grow>
			) : null}
		</div>
	);
}
