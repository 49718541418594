import { useState } from 'react';
import { Alert, Collapse, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import CustomTooltip from './CustomTooltip';
import CustomButton from './CustomButton';

const CustomAlerts = (props) => {
	const [open, setOpen] = useState(true);
	const mode = useSelector((state) => state.UI.mode);

	const closeAlert = () => {
		if (props.onClose) {
			props.onClose();
		} else {
			setOpen(false);
		}
	};

	const {
		success,
		success1,
		success2,
		error,
		error1,
		error2,
		moreErrors,
		severity,
		info,
		info1,
		message,
		margin,
		noMargin,
		icon,
		linkText,
		linkLocation,
		linkState,
		close,
		secondClose,
		closeIcon,
		title,
		alertTitle,
		smallAlert,
		variant,
		noCenter,
		tooltip,
		placement,
		showBtn,
		btnClick,
		btnTooltip,
		btnText,
		iconButton,
		btnIcon,
		secondAction,
		secondTooltip,
		secondIcon,
		alertColor,
		alertId,
		noBorder,
		onlyCancelable,
		component,
	} = props;
	const closeAction = (
		<CustomTooltip
			title={tooltip ? tooltip : ''}
			arrow
			placement={placement ? placement : 'bottom'}
		>
			<IconButton
				aria-label='close'
				color='inherit'
				size='small'
				onClick={closeAlert}
			>
				{closeIcon ? closeIcon : <CloseIcon />}
			</IconButton>
		</CustomTooltip>
	);
	const alertActions = secondClose ? (
		<div>
			<CustomTooltip
				title={secondTooltip ? secondTooltip : ''}
				arrow
				placement={placement ? placement : 'bottom'}
			>
				<IconButton
					aria-label='close'
					color='inherit'
					size='small'
					id='mgrH'
					onClick={secondAction}
				>
					{secondIcon ? secondIcon : <CloseIcon />}
				</IconButton>
			</CustomTooltip>
			{closeAction}
		</div>
	) : close ? (
		closeAction
	) : undefined;
	return (
		<div>
			{success ||
			success1 ||
			success2 ||
			error ||
			error1 ||
			error2 ||
			moreErrors ||
			info ||
			info1 ||
			onlyCancelable ? (
				<Collapse in={open} sx={{ width: '100%' }}>
					<Alert
						id={alertId ? alertId : 'muiAlert'}
						icon={icon ? icon : icon === false ? false : null}
						color={alertColor ? alertColor : ''}
						variant={variant ? variant : 'standard'}
						action={alertActions}
						severity={
							severity
								? severity
								: info || info1
								? 'info'
								: success || success1 || success2
								? 'success'
								: error || error1 || error2
								? 'error'
								: 'warning'
						}
						sx={{
							margin: noMargin ? '' : margin ? margin : '1rem 0',
							borderRadius: noMargin || noBorder ? '0' : '4px',
							justifyContent: noCenter ? '' : 'center',
							textAlign: noCenter ? '' : 'center',
							color: mode === 'dark' ? '#fff' : '#000',
						}}
					>
						{alertTitle && (
							<div className='flex'>
								<p className='mg0 mgbH bold'>{alertTitle}</p>
								{smallAlert && (
									<small className='mglH bottomTranslate'>{smallAlert}</small>
								)}
							</div>
						)}
						{title && <div className='bold textTitle'>{title}</div>}
						{component && component}
						{message
							? message
							: info
							? info
							: info1
							? info1
							: error
							? error
							: error1
							? error1
							: error2
							? error2
							: success
							? success
							: success1
							? success1
							: success2
							? success2
							: moreErrors
							? moreErrors
							: null}
						{showBtn && (
							<CustomButton
								onClick={btnClick}
								tooltip={btnTooltip}
								margin='0 0 -0.1rem 0.3rem'
								padding='3px 6px'
								btnText={btnText}
								iconButton={iconButton}
								startIcon={btnIcon}
								textTransform='capitalize'
							/>
						)}

						{linkText && (
							<Link
								to={linkLocation}
								state={{ data: linkState }}
								id='link'
								className='bold'
							>
								<span>{linkText}</span>.
							</Link>
						)}
					</Alert>
				</Collapse>
			) : null}
		</div>
	);
};

export default CustomAlerts;
