import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import isEqual from 'lodash.isequal';
import CustomButton from '../SocialComponents/CustomButton';
import ConfirmationDialog from '../SocialComponents/ConfirmationDialog';
import { useState } from 'react';
import Media from 'react-media';
import { useTranslation } from 'react-i18next';

export default function HandleTopic({
	state,
	edit,
	customEditParams,
	title,
	keyname,
	handleRemoveTopic,
	getInitialValuesForType,
	setTopicTo,
	indx,
}) {
	const { t } = useTranslation();
	const [openedConfirmDialog, setOpenedConfirmDialog] = useState('');
	const setConfirmationDialog = (topic, setTo) => {
		let compare1;
		let compare2;
		if (Array.isArray(state[topic])) {
			compare1 = getInitialValuesForType(topic);
			compare2 = state[topic].map(({ id, chosen, selected, ...rest }) => rest);
		} else {
			compare1 = getInitialValuesForType(topic);
			compare2 = state[topic];
		}

		if (isEqual(compare1, compare2)) {
			handleRemoveTopic({ topic, initialVal: setTo });
		} else {
			setOpenedConfirmDialog(openedConfirmDialog === topic ? '' : topic);
		}
	};

	const editTopic = () => {
		if (customEditParams !== undefined) {
			edit(keyname, customEditParams);
		} else {
			edit(keyname);
		}
	};
	return (
		<Media
			queries={{
				isSmall: '(max-width: 800px)',
				isReallySmall: '(max-width: 350px)',
			}}
		>
			{(matches) => (
				<div className='flex alignItemsCenter'>
					<p
						className={`${
							!matches.isReallySmall ? 'nowrap' : ''
						} textTitle mg0 underline bold`}
					>
						{title}
					</p>
					<div className='flex spaceBetween fullWidth alignBaseline'>
						<div>
							<CustomButton
								onClick={editTopic}
								btnText={matches.isSmall ? '' : t('edit')}
								startIcon={<EditIcon fontSize='small' />}
								// margin='0 0.5rem'
								padding='0 1rem'
								color='inherit'
								// variant='outlined'
							/>
						</div>
						{!matches.isReallySmall && <div className='dotted' />}
						<div>
							<ConfirmationDialog
								open={openedConfirmDialog === keyname}
								openIconOrString={<DeleteOutlineIcon fontSize='small' />}
								openFunc={() => setConfirmationDialog(keyname, setTopicTo)}
								closeFunc={() => setConfirmationDialog(keyname, setTopicTo)}
								actionBtnText={t('delete')}
								actionBtnMargin={'0'}
								tooltip={t('delete')}
								variant='text'
								action={() =>
									handleRemoveTopic({
										topic: keyname,
										initialVal: setTopicTo,
										index: indx,
									})
								}
								startIcon={<DeleteOutlineIcon id='iconButton' />}
								phrase={`${t('delete')} ${title}`}
								contentTitle={t('changes_will_be_lost')}
								customButtonId='customDangerButton'
							/>
						</div>
					</div>
				</div>
			)}
		</Media>
	);
}
