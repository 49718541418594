import { Component } from 'react';
import { connect } from 'react-redux';
import {
	pauseVideoUpload,
	resumeVideoUpload,
	cancelVideoUpload,
} from '../../redux/actions/dataActions';
import { clearPostUploadError } from '../../redux/actions/dataActions';
import PropTypes from 'prop-types';
import Progress from '../SocialComponents/Progress';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import MakePost from '../SocialComponents/MakePost';
import CustomButton from '../SocialComponents/CustomButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseIcon from '@mui/icons-material/Pause';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import ImageIcon from '@mui/icons-material/Image';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { t } from 'i18next';

class UploadFileControls extends Component {
	pauseVideoUpload = () => {
		this.props.pauseVideoUpload();
	};
	resumeVideoUpload = () => {
		this.props.resumeVideoUpload();
	};
	cancelVideoUpload = () => {
		this.props.cancelVideoUpload();
	};
	render() {
		const {
			data: {
				uploadingPost,
				uploadingVideoProgress,
				uploadingAudioProgress,
				uploadingImageProgress,
				errorUploadingImage,
				errorUploadingAudio,
				errorUploadingVideo,
				uploadingVideoPaused,
				uploadingVideoRunning,
				errorUploadingPost,
			},
			feed,
		} = this.props;

		const uploadError = errorUploadingImage
			? errorUploadingImage
			: errorUploadingAudio
			? errorUploadingAudio
			: errorUploadingVideo
			? errorUploadingVideo
			: errorUploadingPost
			? errorUploadingPost
			: false;

		return uploadingPost !== null ? (
			<div className='pdt'>
				<Progress title={t('uploading_post')} icon={<BlurOnRoundedIcon />} />
			</div>
		) : uploadingImageProgress !== null ? (
			<div className='pdt'>
				<Progress
					progress={uploadingImageProgress}
					title={t('uploading_image')}
					icon={<ImageIcon />}
					variant='buffer'
				/>
			</div>
		) : uploadingAudioProgress !== null ? (
			<div className='pdt'>
				<Progress
					progress={uploadingAudioProgress}
					title={t('uploading_audio')}
					icon={<GraphicEqIcon />}
					variant='buffer'
				/>
			</div>
		) : uploadingVideoProgress !== null ? (
			<div className='pdt'>
				<Progress
					progress={uploadingVideoProgress}
					title={
						uploadingVideoRunning
							? t('uploading_video')
							: uploadingVideoPaused
							? t('uploading_paused_at')
							: ''
					}
					icon={<VideoCameraFrontIcon />}
					variant={
						uploadingVideoRunning
							? 'buffer'
							: uploadingVideoPaused
							? 'determinate'
							: 'determinate'
					}
				/>
				<div className='flex spaceAround'>
					{uploadingVideoRunning && (
						<CustomButton
							onClick={this.pauseVideoUpload}
							btnText={t('pause')}
							variant='text'
							margin='0'
							startIcon={<PauseIcon />}
						/>
					)}
					{uploadingVideoPaused && (
						<CustomButton
							onClick={this.resumeVideoUpload}
							btnText={t('resume')}
							variant='text'
							margin='0'
							startIcon={<PlayCircleOutlineIcon />}
						/>
					)}
					<CustomButton
						onClick={this.cancelVideoUpload}
						btnText={t('cancel')}
						variant='text'
						margin='0'
					/>
				</div>
			</div>
		) : uploadError ? (
			<div className='pdt'>
				<CustomAlerts
					error={uploadError}
					onClose={() => this.props.clearPostUploadError()}
					close
				/>
			</div>
		) : (
			<MakePost feed={feed} />
		);
	}
}

UploadFileControls.propTypes = {
	pauseVideoUpload: PropTypes.func.isRequired,
	resumeVideoUpload: PropTypes.func.isRequired,
	cancelVideoUpload: PropTypes.func.isRequired,
	clearPostUploadError: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	pauseVideoUpload,
	resumeVideoUpload,
	cancelVideoUpload,
	clearPostUploadError,
};

const mapStateToProps = (state) => ({
	data: state.data,
});

export default connect(mapStateToProps, mapActionsToProps)(UploadFileControls);
