import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../../ColorModeProvider';
import { toggleMode } from '../../redux/actions/dataActions';
import { connect, useDispatch } from 'react-redux';
import Switch from '../SocialComponents/Switch';
import { changeColorModeAllTabs } from '../../firebase/firebaseInit';
import { useTranslation } from 'react-i18next';
// import WbSunnyIcon from '@mui/icons-material/WbSunny';
const LightMode = () => {
	const theme = useTheme();
	const { t } = useTranslation();
	const colorMode = useContext(ColorModeContext);

	const dispatch = useDispatch();
	const lightMode = theme.palette.mode === 'light';

	return (
		<div
			className={`center flex alignItemsCenter pd ${
				lightMode ? '' : 'postHeaderDark'
			}`}
		>
			<Switch
				value='toggleMode'
				handleSwitchChange={() =>
					dispatch(
						changeColorModeAllTabs(
							colorMode.prefersMode === 'light' ? 'dark' : 'light',
						),
					)
				}
				checked={lightMode}
				name='toggleMode'
			/>
			{theme.palette.mode === 'light' ? t('light_mode') : t('dark_mode')}
		</div>
	);
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, { toggleMode })(LightMode);
