import {
	onMessage,
	getToken,
	deleteToken,
	isSupported,
} from 'firebase/messaging';
import { auth, db, messaging } from './config';
import {
	doc,
	getDoc,
	collection,
	query,
	where,
	onSnapshot,
	documentId,
	orderBy,
} from 'firebase/firestore';
import {
	setNotificationToken,
	getUserData,
	setAuthorizationHeader,
	markNotificationsRead,
} from '../redux/actions/userActions';
import history from '../browserHistory';
import {
	fetchRecipientsData,
	launchConfetti,
	noImgUrl,
	toggleMode,
} from '../redux/actions/dataActions';
import {
	signInWithEmailAndPassword,
	sendEmailVerification,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	verifyBeforeUpdateEmail,
} from 'firebase/auth';
import axios from 'axios';
import { BroadcastChannel } from 'broadcast-channel';
import store from '../redux/store';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import {
	DELETE_NOTIFICATION,
	ADD_NOTIFICATIONS,
	RELEASE_POSTS,
	UPDATE_POST_LIKES,
	SET_USER,
	SET_USER_ID_LISTENER,
	UPDATE_NOTIFICATION,
	SET_AUTHENTICATED,
	REFRESH_TAB,
	LOADING_LOGIN,
	NEW_EMAIL_UPDATE_LINK_SENT,
	DELETE_ACCOUNT_ACTION,
	VERIFICATION_EMAIL_SUCCESS_MESSAGE,
	ERRORS_LOGIN,
	LOADING_SIGNUP,
	SUCCESS_SIGNUP,
	ERRORS_SIGNUP,
	SENDING_LOG_IN_EMAIL_SENT,
	SIGN_IN_EMAIL_SENT,
	ERROR_SENDING_LOG_IN_EMAIL_SENT,
	SET_UNAUTHENTICATED,
	SENDING_FORGOT_PASSWORD_EMAIL,
	FORGOT_PASSWORD_EMAIL_SUCCESS,
	ERROR_SENDING_FORGOT_PASSWORD_EMAIL,
	LOADING_VERIFICATION_EMAIL,
	SENT_TOO_MANY_EMAILS,
	SET_NEW_EMAIL_ACTIONS,
	SET_DISABLED_USER,
	ON_MESSAGE_LISTENER,
	DELETE_INFRINGEMENT_NOTIFICATION,
	LOADING_LOGOUT,
	ERROR_LOADING_LOGOUT,
	UPDATE_POST_COMMENTS,
	USER_APPEAL_SUCCESFULLY_COMMITTED,
	UPDATE_POST_REPLIES,
	REMOVE_DELETED_COMMENT_ACTIONS,
	UPDATE_UNREAD_NOTIFICATIONS_COUNT,
	SET_SIGN_IN_EMAIL,
	SET_LOGOUT_SUCCESS_MESSAGE,
	SET_LOGOUT_INFO_MESSAGE,
	SET_LOGOUT_WARNING_MESSAGE,
	SET_LOGOUT_ERROR_MESSAGE,
	SET_VERIFIED_USER,
	FCM_DATA,
	IS_MESSAGING_SUPPORTED,
	SET_USER_ADMIN,
	ALLOW_USERNAME_CHANGE,
	VERIFY_EMAIL_FIRST,
} from '../redux/types';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import PolicyIcon from '@mui/icons-material/Policy';
import TimerIcon from '@mui/icons-material/Timer';
import ImgSrc from '../components/SocialComponents/ImgSrc';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ThreePSharpIcon from '@mui/icons-material/ThreePSharp';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import FeedbackIcon from '@mui/icons-material/Feedback';
import TaskIcon from '@mui/icons-material/Task';
import RecommendIcon from '@mui/icons-material/Recommend';
import GroupIcon from '@mui/icons-material/Group';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';

let currentUser = null;
let userListener = [];
let userAdminListener = [];
let notificationListeners = [];
let userNotificationListener = [];
let credentials = {};
var loginChannel = new BroadcastChannel('login');
var refreshChannel = new BroadcastChannel('refresh');
var logoutChannel = new BroadcastChannel('logout');
var colorModeChannel = new BroadcastChannel('colorMode');
let updateUiNots = false;

auth.onIdTokenChanged((user) => {
	if (user !== null) {
		user.getIdToken().then((token) => {
			// console.log('TOKEN', token);
			if (localStorage.FBIdToken !== token) {
				const FBIdToken = `Bearer ${token}`;
				localStorage.setItem('FBIdToken', FBIdToken);
				const lang = localStorage.getItem('i18nextLng');
				axios.defaults.headers.common['Authorization'] = FBIdToken;
				axios.defaults.headers.common['accept-language'] = lang;
			}
		});
	}
});

auth.onAuthStateChanged((firebaseUser) => {
	if (firebaseUser && !currentUser && window.location.pathname !== '/logout') {
		currentUser = firebaseUser;
		store.dispatch({ type: SET_AUTHENTICATED });
		const FBIdToken = `Bearer ${firebaseUser.accessToken}`;
		axios.defaults.headers.common['Authorization'] = FBIdToken;
		localStorage.setItem('userId', firebaseUser.uid);
		localStorage.setItem('FBIdToken', FBIdToken);
	}

	if (firebaseUser && firebaseUser.emailVerified) {
		if (Object.keys(credentials).length === 0) {
			store.dispatch(getUserData('loadUser'));
		}
		store.dispatch({ type: SET_VERIFIED_USER });

		if (window.location.pathname === '/') {
			history.replace('/feed');
		}
	}

	if (!firebaseUser && Object.keys(credentials).length > 0) {
		store.dispatch({ type: SET_UNAUTHENTICATED });
		credentials = {};
		currentUser = null;
		unsubscribeListeners();
		localStorage.removeItem('userId');
		localStorage.removeItem('handle');
		localStorage.removeItem('boundaryId');
		localStorage.removeItem('FBIdToken');
		localStorage.removeItem('viewedPostIds');
		localStorage.removeItem('scrollPosition');
		delete axios.defaults.headers.common['Authorization'];
		store.dispatch(refreshAllTabs(false));
		store.dispatch(logoutAllTabs());
	}
});

export const refreshToken = () => () => {
	if (currentUser !== null) {
		auth.currentUser
			.getIdToken(true)
			.then((idToken) => {
				const FBIdToken = `Bearer ${idToken}`;
				localStorage.setItem('FBIdToken', FBIdToken);
				axios.defaults.headers.common['Authorization'] = FBIdToken;

				toast.success(t('reauthenticated'), {
					position: 'bottom-left',
					containerId: 'app',
				});
			})
			.catch((error) => {
				// console.log('error new idToken', error);
				toast.error(
					<span onClick={() => window.location.reload(false)}>
						{t('err_refresh')}
					</span>,
					{
						position: 'bottom-left',
						containerId: 'app',
					},
				);
			});
	}
};

export const setAdminUserListener = (userId) => (dispatch) => {
	let setAdminListener = () => {
		let unsubscribeUserListenerFunc = onSnapshot(
			doc(db, 'users', userId),
			(doc) => {
				if (
					doc &&
					doc.exists &&
					doc.data() &&
					Object.keys(doc.data()).length > 0
				) {
					dispatch({
						type: SET_USER_ADMIN,
						payload: doc.data(),
					});
				} else {
					toast.error(t('user_deleted'), {
						position: 'bottom-left',
						containerId: 'app',
					});
					dispatch({
						type: SET_USER_ADMIN,
						payload: {},
					});
				}
			},
		);
		let listenerObj = {
			userId: userId,
			listener: unsubscribeUserListenerFunc,
		};
		userAdminListener.push(listenerObj);
	};
	if (userAdminListener.length > 0) {
		userAdminListener.filter((doc) =>
			doc.userId === userId ? doc : (doc.listener(), setAdminListener()),
		);
	} else {
		setAdminListener();
	}
};
export const setUserListener = (userId, login) => (dispatch) => {
	let setListener = () => {
		dispatch({ type: SET_USER_ID_LISTENER, payload: userId });
		let unsubscribeUserListenerFunc = onSnapshot(
			doc(db, 'users', userId),
			(doc) => {
				let updatedCredentials = doc.data();

				if (updatedCredentials) {
					localStorage.setItem('handle', updatedCredentials.handle);
				}

				if (updatedCredentials && updatedCredentials.disabled) {
					if (updatedCredentials.appealable && updatedCredentials.appeal) {
						updatedCredentials.canRecoverLetter =
							updatedCredentials.canRecoverLetter.sort((a, b) =>
								b.releaseDate.localeCompare(a.releaseDate),
							);
						updatedCredentials.letters = updatedCredentials.letters.sort(
							(a, b) => b.releaseDate.localeCompare(a.releaseDate),
						);
						updatedCredentials.unreleasedLetters =
							updatedCredentials.unreleasedLetters.slice().reverse();
						dispatch({
							type: SET_USER,
							payload: { credentials: updatedCredentials },
						});
						history.replace('/appeal');
					} else {
						const sHandle = updatedCredentials.handle;
						const upercaseHandle =
							sHandle.charAt(0).toUpperCase() + sHandle.slice(1);
						let formData = {
							disabledMessage: t('account_disabled', {
								handle: upercaseHandle,
							}),
							appeal: updatedCredentials.appeal,
							appealable: updatedCredentials.appealable,
						};

						dispatch(logoutAllTabs(formData));
					}
				} else if (
					(credentials &&
						Object.keys(credentials).length > 0 &&
						updatedCredentials === undefined) ||
					(updatedCredentials && updatedCredentials.logout)
				) {
					dispatch(logoutAllTabs());
				} else if (
					updatedCredentials &&
					new Date(updatedCredentials.disabledFor) > Date.now()
				) {
					let formData = {};
					const sHandle = updatedCredentials.handle;
					const upercaseHandle =
						sHandle.charAt(0).toUpperCase() + sHandle.slice(1);
					formData.disabledMessage = t('account_disabled', {
						handle: upercaseHandle,
					});
					formData.disabledFor = t('disabled_for', {
						date: dayjs(updatedCredentials.disabledFor).fromNow(),
					});

					dispatch(logoutAllTabs(formData));
				} else {
					if (Object.keys(credentials).length > 0) {
						if (
							updatedCredentials.unreleasedPostCount <
								credentials.unreleasedPostCount &&
							updatedCredentials.postCount > credentials.postCount
						) {
							const addCount =
								updatedCredentials.postCount - credentials.postCount;

							const removedUnreleasedIds = updatedCredentials.recentPosts
								.slice(0, addCount)
								.map((obj) => obj.postId);
							dispatch({ type: RELEASE_POSTS, payload: removedUnreleasedIds });
							dispatch(launchConfetti(1));
						}

						if (
							Object.keys(updatedCredentials).length > 0 &&
							credentials.canRecoverLetter.length + 1 ===
								updatedCredentials.canRecoverLetter.length
						) {
							dispatch(
								fetchRecipientsData(
									[
										updatedCredentials.canRecoverLetter[
											updatedCredentials.canRecoverLetter.length - 1
										].userId,
									],
									'letter',
									true,
								),
							);
						}
						if (
							Object.keys(updatedCredentials).length > 0 &&
							credentials.nextUsernameChange !== '' &&
							credentials.nextUsernameChange !==
								updatedCredentials.nextUsernameChange
						) {
							const bool = updatedCredentials.nextUsernameChange <= new Date();
							dispatch({ type: ALLOW_USERNAME_CHANGE, payload: bool });
						}
					}

					if (
						updatedCredentials &&
						Object.keys(updatedCredentials).length > 0
					) {
						updatedCredentials.canRecoverLetter =
							updatedCredentials.canRecoverLetter.sort((a, b) =>
								b.releaseDate.localeCompare(a.releaseDate),
							);
						updatedCredentials.letters = updatedCredentials.letters.sort(
							(a, b) => b.releaseDate.localeCompare(a.releaseDate),
						);
						updatedCredentials.unreleasedLetters =
							updatedCredentials.unreleasedLetters.slice().reverse();
						dispatch({
							type: SET_USER,
							payload: { credentials: updatedCredentials },
						});
						credentials = updatedCredentials;
					}
				}
			},
		);
		let listenerObj = {
			userId: userId,
			listener: unsubscribeUserListenerFunc,
		};
		userListener.push(listenerObj);
	};
	if (userListener.length > 0) {
		userListener.filter((doc) =>
			doc.userId === userId ? doc : (doc.listener(), setListener()),
		);
	} else {
		setListener();
	}
};

export const setNotificationListener = (notId) => (dispatch) => {
	if (notificationListeners.findIndex((obj) => obj.notId === notId) === -1) {
		const q = query(
			collection(db, 'notifications'),
			where(documentId(), '==', notId),
		);
		let unsubscribeNotsListener = onSnapshot(q, (querySnapshot) => {
			querySnapshot.docChanges().forEach((change) => {
				let notificationId = change.doc.id;
				let not = change.doc.data();
				not.notificationId = notificationId;
				if (change.type === 'added' && !not.read && updateUiNots) {
					if (not.type === 'like') {
						dispatch({
							type: UPDATE_POST_LIKES,
							payload: { postId: not.postId, count: 1 },
						});
					} else if (
						not.type === 'comment' ||
						(not.type === 'mention' && !not.repliedId)
					) {
						dispatch({
							type: UPDATE_POST_COMMENTS,
							payload: { postId: not.postId },
						});
					} else if (
						not.type === 'commentReply' ||
						not.type === 'commentReplyMention' ||
						not.type === 'commentReplyPost'
					) {
						dispatch({
							type: UPDATE_POST_REPLIES,
							payload: {
								postId: not.postId,
								repliedId: not.repliedId,
							},
						});
					}
				} else if (change.type === 'removed') {
					dispatch({
						type: DELETE_NOTIFICATION,
						payload: {
							notificationId,
							userId: not.sender ? not.sender : not.recipient,
						},
					});
					toast.dismiss(notificationId);

					if (
						not.type === 'comment' ||
						not.type === 'mention' ||
						not.type === 'commentReply' ||
						not.type === 'commentReplyMention' ||
						not.type === 'commentReplyPost'
					) {
						let commentId = not.commentId ? not.commentId : notificationId;
						dispatch({
							type: REMOVE_DELETED_COMMENT_ACTIONS,
							payload: commentId,
						});
					}

					if (notificationListeners.length > 0) {
						notificationListeners.filter((doc) =>
							doc.notId !== notificationId ? doc : doc.listener(),
						);
					}
					let reduceCount = not.allNotificationIds
						? not.allNotificationIds.length
						: 1;
					dispatch({
						type: UPDATE_UNREAD_NOTIFICATIONS_COUNT,
						payload: { reduce: reduceCount },
					});
				} else if (change.type === 'modified') {
					if (not.read) {
						let reduceCount = not.allNotificationIds
							? not.allNotificationIds.length
							: 1;
						dispatch({
							type: UPDATE_UNREAD_NOTIFICATIONS_COUNT,
							payload: { reduce: reduceCount },
						});
					}
					if (
						!not.read &&
						(not.type === 'commentInfringement' ||
							not.type === 'removedCommentInfringement')
					) {
						if (not.recipient === credentials.userId) {
							not.type =
								not.type === 'removedCommentInfringement'
									? 'commentInfringement'
									: 'removedCommentInfringement';

							dispatch({
								type: DELETE_INFRINGEMENT_NOTIFICATION,
								payload: not,
							});
						} else {
							dispatch({
								type: DELETE_NOTIFICATION,
								payload: {
									notificationId,
									userId: not.sender ? not.sender : not.recipient,
								},
							});
						}
					} else {
						dispatch({
							type: UPDATE_NOTIFICATION,
							payload: not,
						});
					}
				}
			});
		});
		let listenerObj = { notId: notId, listener: unsubscribeNotsListener };
		notificationListeners.push(listenerObj);
	}
};

//format mass notifications
// function to fetch imageUrl and handle based on userId
export const fetchUserInfo = (userId) => async () => {
	let user = { sender: userId };

	if (userId === credentials.userId) {
		user.senderImg = credentials.imageUrl;
		user.senderThumbnail = credentials.thumbnail;
		user.senderImgColor = credentials.imageColor;
		user.senderHandle = credentials.handle;
	} else {
		var docRef = doc(db, 'users', userId);
		var docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			user.senderImg = docSnap.data().imageUrl;
			user.senderImgColor = docSnap.data().imageColor;
			user.senderThumbnail = docSnap.data().thumbnail;
			user.senderHandle = docSnap.data().handle;
		} else {
			user.imageUrl = noImgUrl();
		}
	}
	return user;
};

export const setUserNotificationListener = (userId) => async (dispatch) => {
	if (
		userNotificationListener.findIndex((obj) => obj.userId === userId) === -1
	) {
		const q = query(
			collection(db, 'notifications'),
			where('recipient', '==', userId),
			where('read', '==', false),
			orderBy('createdAt', 'desc'),
		);
		let notifications = [];
		let unsubscribeNewNots = onSnapshot(q, (querySnapshot) => {
			querySnapshot.docChanges().forEach((change) => {
				let doc = change.doc;
				let notificationData = doc.data();
				notificationData.notificationId = doc.id;
				if (change.type === 'added') {
					notifications.push(notificationData);
				}
			});

			if (notifications.length > 0) {
				let newestNotifications = notifications.slice(0, 30);

				// Set listeners for the newest 30 notifications
				newestNotifications.forEach((notification) => {
					//update ui of post likes comment and user follows...
					dispatch(setNotificationListener(notification.notificationId));
				});

				dispatch({
					type: UPDATE_UNREAD_NOTIFICATIONS_COUNT,
					payload: { unreadCount: notifications.length },
				});
				let newNots = notifications.filter(
					(not) => !not.hasOwnProperty('senders'),
				);

				dispatch(groupNotifications(newNots));
				notifications = [];
			}
		});
		const hasFirebaseMessagingSupport = await isSupported();
		if (hasFirebaseMessagingSupport) {
			store.dispatch({ type: IS_MESSAGING_SUPPORTED, payload: true });
		}
		setTimeout(() => {
			updateUiNots = true;
		}, 10000);
		let listenerObj = { userId: userId, listener: unsubscribeNewNots };
		userNotificationListener.push(listenerObj);
	}
};

export const groupNotifications =
	(
		nots,
		typesToSort = [
			'like',
			'comment',
			'mention',
			'commentReply',
			'commentReplyMention',
			'commentReplyPost',
			'commentLike',
		], // Types to be sorted
		typesToReturnImmediately = [
			'app',
			'legal',
			'follow',
			'postRelease',
			'postUpload',
			'letterUpload',
			'postReleasesIn24',
			'postReleasesIn72',
			'letterReleasesIn24',
			'letterReleasesIn72',
			'userInfringement',
			'profileImgInfringement',
			'postInfringement',
			'removedPostInfringement',
			'commentInfringement',
			'removedCommentInfringement',
			'profileDetailsInfringement',
			'inactivityNotice',
			'letterRelease',
			'letterReleaseNotice',
			'welcome',
		],
		// Types to be returned immediately
	) =>
	async (dispatch) => {
		let notifications = [...nots];
		function getKey(notification) {
			return `${notification.postId}_${notification.sender}_${notification.type}`;
		}

		// Group notifications by key (sender, postId, type)
		const groupedNotifications = notifications.reduce(function (
			groups,
			notification,
		) {
			const key = getKey(notification);
			groups[key] = groups[key] || [];
			groups[key].push(notification);
			return groups;
		},
		{});

		const duplicatesThreshold = 1;
		const duplicates = [];
		const immediatelyHandled = [];

		Object.values(groupedNotifications).forEach((group) => {
			const notificationType = group[0].type;
			const key = getKey(group[0]);

			if (!typesToReturnImmediately.includes(notificationType)) {
				if (group.length > duplicatesThreshold) {
					// If there are more than the threshold, consider them as duplicates
					duplicates.push(group);
				} else {
					// If there are fewer than duplicatesThreshold then...
					// Only handle if the key is present, else, let them go through to be sorted
					if (group.length > 1 && groupedNotifications[key]) {
						immediatelyHandled.push(...group);
						group.forEach((notification) => {
							const index = notifications.indexOf(notification);
							if (index !== -1) {
								notifications.splice(index, 1);
							}
						});
					}
				}
			}
		});

		// Transform duplicates into objects with senders and comments
		const transformedDuplicates = duplicates.map(function (group) {
			let commentId,
				createdAt = '';
			//goodbyeapp.com/users/calvin/post/wlF4DossR7SJVvQBDDni/1Xvmab7iefIHwOQtKq8R
			const keyValues = group.reduce((result, notification, i) => {
				if (i === 0) {
					commentId = notification.commentId;
					createdAt = notification.createdAt;
				}

				let obj = { ...result, ...notification };

				if (commentId) {
					obj = { ...obj, commentId, createdAt };
				}

				return obj;
				// return Object.assign(result, notification);
			}, {});
			keyValues.senders = Array.from(
				new Set(
					[].concat.apply(
						[],
						group.map(function (notification) {
							return notification.sender;
						}),
					),
				),
			);

			keyValues.comments = group
				.map(function (notification) {
					return notification.body ? [notification.body] : [];
				})
				.reduce(function (a, b) {
					return a.concat(b);
				}, [])
				.filter(Boolean);
			keyValues.allNotificationIds = group.map(
				(notification) => notification.notificationId,
			);
			return keyValues;
		});

		// Remove notifications in duplicates from the remaining array
		duplicates.forEach(function (group) {
			group.forEach(function (notification) {
				const index = notifications.indexOf(notification);
				if (index !== -1) {
					notifications.splice(index, 1);
				}
			});
		});

		// Use a Map to efficiently group remaining notifications by key
		const remainingNotifications = new Map();

		// Group remaining notifications by postId and type
		notifications.forEach(function (notification) {
			const key = `${notification.postId}_${notification.type}`;
			if (!remainingNotifications.has(key)) {
				remainingNotifications.set(key, [notification]);
			} else {
				remainingNotifications.get(key).push(notification);
			}
		});

		// Filter to include only groups with more than 3 notifications and exclude immediately returned types
		const duplicatesGroupsThreshold = 3;
		const groups = Array.from(remainingNotifications.values()).filter(
			(group) => {
				let avoidGroupingFewComments = group.every(
					(not) => not.type !== 'comment',
				);

				return (
					(group.length > duplicatesGroupsThreshold ||
						(group.length <= duplicatesGroupsThreshold &&
							avoidGroupingFewComments)) &&
					!typesToReturnImmediately.includes(group[0].type)
				);
			},
		);

		// Remove notifications in groups from the remaining array
		groups.forEach(function (group) {
			group.forEach(function (notification) {
				const index = notifications.indexOf(notification);
				if (index !== -1) {
					notifications.splice(index, 1);
				}
			});
		});
		const mergedGroups = groups.map((group) => {
			let commentId,
				createdAt = '';
			//goodbyeapp.com/users/calvin/post/wlF4DossR7SJVvQBDDni/1Xvmab7iefIHwOQtKq8R

			const keyValues = group.reduce((result, notification, i) => {
				if (i === 0) {
					commentId = notification.commentId;
					createdAt = notification.createdAt;
				}

				let obj = { ...result, ...notification };

				if (commentId) {
					obj = { ...obj, commentId, createdAt };
				}

				return obj;
			}, {});

			const sendersMap = {};
			const comments = [];

			for (let i = 0; i < group.length; i++) {
				sendersMap[group[i].sender] = true;
				if (group[i].body && group[i].type === 'comment') {
					comments.push(group[i].body.slice(1, -1));
				}
			}

			keyValues.senders = Object.keys(sendersMap);
			if (comments.length > 0) {
				keyValues.comments = comments.filter(Boolean).slice(0, 3); // Include only the first 3 comments
			}

			keyValues.allNotificationIds = group.map(
				(notification) => notification.notificationId,
			);

			return keyValues;
		});
		// Return immediately the specified types
		const immediatelyReturned = typesToReturnImmediately
			.map(function (type) {
				if (groupedNotifications[type]) {
					return groupedNotifications[type];
				}
				return [];
			})
			.reduce(function (a, b) {
				return a.concat(b);
			}, []);

		// Concatenate all the results
		const allResults = transformedDuplicates.concat(
			immediatelyReturned,
			notifications,
			mergedGroups,
			immediatelyHandled,
		);
		// Extract unique senders
		const uniqueSenders = Array.from(
			new Set([
				...notifications.map(function (not) {
					return not.sender ? not.sender : not.recipient;
				}),
				...immediatelyHandled.map(function (not) {
					return not.sender ? not.sender : not.recipient;
				}),
				...transformedDuplicates.map(function (group) {
					return group.senders[0];
				}),
				...mergedGroups
					.map(function (group) {
						return group.senders.slice(0, 3);
					})
					.reduce(function (a, b) {
						return a.concat(b);
					}, []),
			]),
		);

		const sendersInfo = await Promise.all(
			uniqueSenders.map(async (senderId) => {
				try {
					const userInfo = await dispatch(fetchUserInfo(senderId));
					return { senderId, ...userInfo };
				} catch (error) {
					console.error('Error fetching user info:', error);
					// throw error; // Re-throw the error if needed
				}
			}),
		);
		// Set sender information for each notification
		allResults.forEach((result) => {
			if (result.allNotificationIds) {
				// delete result.notificationId;
				result.notificationId = result.allNotificationIds[0];
			}
			if (result.comments) {
				delete result.body;
			}
			if (!result.sender && result.recipient) {
				result.senders = [result.recipient];
				delete result.sender;
			}
			if (result.sender && !result.senders) {
				result.senders = [result.sender];
				delete result.sender;
			}

			if (result.senders) {
				const onlyFirst3Senders = result.senders.slice(0, 3);
				result.sendersInfo = sendersInfo.filter((info) => {
					return onlyFirst3Senders.includes(info.senderId);
				});
			}
		});

		// Sort all results by createdAt in descending order
		const sortedResults = allResults.sort(function (a, b) {
			return new Date(b.createdAt) - new Date(a.createdAt);
		});

		// Sort specified types by createdAt in descending order
		const sortedTypes = typesToSort
			.map(function (type) {
				if (groupedNotifications[type]) {
					return groupedNotifications[type];
				}
				return [];
			})
			.reduce(function (a, b) {
				return a.concat(b);
			}, [])
			.sort(function (a, b) {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});

		// Concatenate sorted types to the final result
		let finalResult = sortedResults.concat(sortedTypes);
		let windowLocation = window.location.pathname.split('/');
		let paramshandle =
			windowLocation[1] === 'users' ? windowLocation[2] : undefined;

		if (credentials.popupNotifications && throttle()) {
			finalResult.length <= 5 &&
				finalResult.forEach((not) => {
					const path = notPath(
						not,
						paramshandle,
						window.location.pathname.split('/')[1],
					);
					const { verb, sender_s } = notVerb(not);
					// const time = dayjs(not.createdAt).fromNow();
					const notIds = not.allNotificationIds
						? not.allNotificationIds
						: [not.notificationId];
					const Container = (props) => <div>{props.children}</div>;

					toast.info(
						<div
							onClick={() => {
								history.replace(path, path.state);
								dispatch(markNotificationsRead(notIds));
							}}
						>
							<div
								className={
									not.sendersInfo.length > 1 && !not.comments
										? 'flexColumn'
										: 'flex'
								}
							>
								{not.topic === 'globalTopic' ? (
									<CircleNotificationsIcon id='iconBlue' />
								) : not.sendersInfo.length === 1 &&
								  not.sendersInfo[0].sender !== credentials.userId ? (
									<ImgSrc
										img={not.sendersInfo[0].senderImg}
										thumbnail={not.sendersInfo[0].senderThumbnail}
										imgColor={not.sendersInfo[0].senderImgColor}
										handle={not.sendersInfo[0].senderHandle}
										userId={not.sendersInfo[0].sender}
										css='toastImg'
										fontSize='16px'
										noMargin
										margin='0 10px 0 0'
									/>
								) : not.sendersInfo.length > 1 && !not.comments ? (
									<div className='flex'>
										{not.sendersInfo.map((info, index) => (
											<div key={index}>
												<ImgSrc
													img={info.senderImg}
													thumbnail={info.senderThumbnail}
													imgColor={info.senderImgColor}
													handle={info.senderHandle}
													userId={info.sender}
													css='notCommentImg'
													fontSize='10px'
												/>
											</div>
										))}
									</div>
								) : null}

								<div className='flexColumn spaceAround'>
									<div className='flex alignItemsCenter'>
										{sender_s !== `${credentials.handle} ` && (
											<span
												className={`mg0 mgr4px bold ${
													not.senders && not.senders.length >= 3
														? 'smallText'
														: ''
												}`}
											>
												{sender_s}{' '}
												{not.senders && not.senders.length >= 3 && (
													<span className='normalWeight'>
														{verb} {not.body}
													</span>
												)}
											</span>
										)}

										{not.senders &&
											not.senders.length === 1 &&
											notIcon(not, 'toast')}
										{sender_s === `${credentials.handle} ` && (
											<small className='mgl6px'>{verb}</small>
										)}
										{(not.type === 'app' || (!verb && not.body)) && (
											<small className='mgl6px'>{not.body}</small>
										)}
										{/* {not.sendersInfo.length === 1 &&
											not.sendersInfo[0].sender === credentials.userId && (
												<small className='toastTime'> {time}</small>
											)} */}
									</div>

									<small className='flex alignItemsCenter'>
										{sender_s === `${credentials.handle} `
											? ''
											: not.senders && not.senders.length < 3
											? `${verb}${
													not.body && not.body.length > 150
														? ` ${not.body.slice(0, 150)}...`
														: not.body
														? ` ${not.body}`
														: ''
											  }`
											: ''}
									</small>
								</div>
							</div>
							{not.comments && (
								<ul
									className={`${
										not.sendersInfo.length === 1 ? 'pdl' : 'pd0'
									} mg0 flexColumn`}
									style={{
										listStyleType: not.sendersInfo.length > 1 ? 'none' : 'disc',
									}}
								>
									{not.comments.map((comment, index) => {
										let userData = not.sendersInfo[index];

										return (
											<li key={index} className='mg0 mgtH'>
												<div className='flex alignItemsCenter'>
													{not.sendersInfo.length > 1 && (
														<ImgSrc
															img={userData.senderImg}
															thumbnail={userData.senderThumbnail}
															imgColor={userData.senderImgColor}
															noMargin
															margin='0 8px 0 0'
															handle={userData.senderHandle}
															userId={userData.sender}
															css='notCommentImg'
															fontSize='10px'
														/>
													)}
													<span>
														{comment.length > 100
															? comment.slice(0, 100) + '...'
															: comment}
													</span>
												</div>
											</li>
										);
									})}
								</ul>
							)}
							{/* {not.sendersInfo.length !== 1 && (
								<span className='toastTime'>{time}.</span>
							)} */}
						</div>,
						{
							icon: false,
							containerId: 'A',
							toastId: not.notificationId,
							position: 'top-left',
							closeButton: (
								<Container>
									<div className='flexColumn'>
										<CloseIcon
											onClick={() => dispatch(markNotificationsRead(notIds))}
										/>
										{not.thumbnail && (
											<div className='mgtH'>
												<img
													src={not.thumbnail}
													className='toastThumbnail'
													alt='thumbnail'
												/>
											</div>
										)}
									</div>
								</Container>
							),
						},
					);
				});
		}
		dispatch({ type: ADD_NOTIFICATIONS, payload: finalResult });
	};

var lastTime = 0;
let firstTime = false;
function throttle() {
	let timeFrame = firstTime ? 0 : 500;
	firstTime = true;
	var now = new Date().getTime();
	if (now - lastTime >= timeFrame) {
		lastTime = now;
		return true;
	} else {
		return false;
	}
}

export const unauthorizedToken = () => async (dispatch) => {
	try {
		if (currentUser !== null) {
			const token = await currentUser.getIdToken(true);
			const FBIdToken = `Bearer ${token}`;
			localStorage.setItem('FBIdToken', FBIdToken);
			axios.defaults.headers.common['Authorization'] = FBIdToken;
			return true;
		} else {
			dispatch(logoutAllTabs());
			return false;
		}
	} catch (err) {
		// console.log(err);
		return false;
	}
};

// invoked when the browser is in the foreground. (has focus)
export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			if (
				payload.data.recipient === credentials.userId ||
				payload.data.topic === 'globalTopic'
			) {
				store.dispatch({ type: ON_MESSAGE_LISTENER, payload: payload });
			}
			resolve(payload);
		});
	});

export const getFCMToken = (token, tokenCreatedAt) => (dispatch) => {
	// Get registration token. Initially this makes a network call, once retrieved
	// subsequent calls to getToken will return from cache.
	getToken(messaging, {
		vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
	})
		.then((currentToken) => {
			if (currentToken !== token) {
				// Send the token to your server and update the UI if necessary
				dispatch(setNotificationToken(currentToken));
			} else if (currentToken === token && tokenCreatedAt) {
				// create new token;
				let tokenTimePlus7days = new Date(`${tokenCreatedAt}`);
				tokenTimePlus7days.setDate(tokenTimePlus7days.getDate() + parseInt(7));
				let today = new Date().toISOString();
				if (tokenTimePlus7days && tokenTimePlus7days.toISOString() <= today) {
					// token equal but outdated! Create new token;
					deleteToken(messaging).then(() => {
						getToken(messaging, {
							vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
						})
							.then((newToken) => {
								dispatch(setNotificationToken(newToken));
							})
							.catch((err) => {
								// console.log(err)
							});
					});
				}
			} else return;
		})
		.catch((err) => {
			// console.log('An error occurred while retrieving token. ', err);
			if (err.code === 'messaging/permission-blocked') {
				toast.info(t('unblock_nots_prompt'), {
					position: 'bottom-right',
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					onClick: () => history.replace('/enableNotifications'),
				});
			}
			// console.log(err);
		});
};

export const unsubscribeListeners = () => {
	if (userListener.length > 0) {
		userListener.map((doc) => {
			userListener = [];
			return doc.listener();
		});
	}
	if (userAdminListener.length > 0) {
		userAdminListener.map((doc) => {
			userAdminListener = [];
			return doc.listener();
		});
	}

	if (userNotificationListener.length > 0) {
		userNotificationListener.map((doc) => {
			return doc.listener();
		});
		userNotificationListener = [];
	}
	if (notificationListeners.length > 0) {
		notificationListeners.map((doc) => {
			return doc.listener();
		});
		notificationListeners = [];
	}
};

export const refreshAllTabs = (bool) => () => {
	refreshChannel = new BroadcastChannel('refresh');
	refreshChannel.postMessage({ Refresh: bool });
	refreshChannel.close();
};
export const refreshAllTabsListener = () => (dispatch) => {
	refreshChannel.onmessage = (event) => {
		if (event.Refresh !== undefined) {
			dispatch({
				type: REFRESH_TAB,
				payload: event.Refresh,
			});
		}
	};
};

export const fetchNotificationToken = () => (dispatch) => {
	return axios
		.get('/user/fetchNotificationToken')
		.then((res) => {
			let fcmDoc = res.data;
			if (res.data.notificationToken) {
				const notToken = fcmDoc.notificationToken;
				const notTokenCreatedAt = fcmDoc.createdAt;
				const disabledPush = fcmDoc.disabledPush;
				dispatch({ type: FCM_DATA, payload: fcmDoc });
				if (
					!disabledPush &&
					typeof Notification !== 'undefined' &&
					Notification.permission === 'granted'
				) {
					dispatch(getFCMToken(notToken, notTokenCreatedAt));
				}
			} else {
				if (dispatch(isMessagingSupported())) {
					if (
						typeof Notification !== 'undefined' &&
						Notification.permission === 'granted'
					) {
						dispatch(getFCMToken(undefined));
					}
				}
			}
			return;
		})
		.catch((err) => {
			console.log(err);
		});
};

export const changeColorModeAllTabs = (mode) => () => {
	let channelMessage = {
		colorMode: mode,
	};
	colorModeChannel = new BroadcastChannel('colorMode');
	colorModeChannel.postMessage(channelMessage);
	colorModeChannel.close();
};
export const changeColorModeAllTabsListener = () => (dispatch) => {
	colorModeChannel.onmessage = (event) => {
		dispatch(toggleMode(event.colorMode));
	};
};

export const logoutAllTabs = (data) => () => {
	let channelMessage = {
		Logout: true,
	};
	if (data && Object.keys(data).length > 0) {
		channelMessage = {
			...channelMessage,
			...data,
		};
	}
	logoutChannel = new BroadcastChannel('logout');
	logoutChannel.postMessage(channelMessage);
	logoutChannel.close();
};
export const logoutAllTabsListener = () => (dispatch) => {
	logoutChannel.onmessage = (event) => {
		if (event.Logout && currentUser !== null) {
			dispatch(logoutUser(event));
		}
	};
};
export const loginAllTabs = (userData) => () => {
	loginChannel = new BroadcastChannel('login');
	loginChannel.postMessage({ Login: userData });
	loginChannel.close();
};
export const loginAllTabsListener = () => (dispatch) => {
	loginChannel.onmessage = (event) => {
		dispatch(loginUser(event.Login));
	};
};

export const loginUser = (data) => async (dispatch) => {
	let userData = data;
	localStorage.removeItem('userId');
	localStorage.removeItem('handle');
	localStorage.removeItem('boundaryId');
	localStorage.removeItem('FBIdToken');
	localStorage.removeItem('viewedPostIds');
	localStorage.removeItem('scrollPosition');
	delete axios.defaults.headers.common['Authorization'];

	if (currentUser) {
		await dispatch(logoutUser());
	}
	dispatch({ type: LOADING_LOGIN });
	const newEmailForSignIn = userData.newEmailForSignIn;
	const deleteAccount = userData.deleteAccount;
	let formData = {
		event: {
			token: userData.responseToken,
			siteKey: '6LdB0nslAAAAALKouSUhm6ChLS7Xy7mVe9npNo-z',
			expectedAction: 'SIGNUP',
		},
	};

	const loginDB = () => {
		return signInWithEmailAndPassword(auth, userData.email, userData.password)
			.then((data) => {
				const emailVerified = data.user.emailVerified;
				let userId = data.user.uid;
				userData.emailVerified = emailVerified;
				userData.uid = userId;
				if (emailVerified) {
					store.dispatch({ type: SET_VERIFIED_USER });
				} else {
					dispatch({ type: VERIFY_EMAIL_FIRST, payload: userData.email });

					sendEmailVerification(auth.currentUser).then(() => {
						// Email verification sent!
						dispatch({
							type: VERIFICATION_EMAIL_SUCCESS_MESSAGE,
							payload: t('email_verify_link_success'),
						});
						history.replace('/verification');
					});
					throw Object.assign(new Error(), {
						code: t('email_not_verified'),
					});
				}
				return data.user.getIdToken();
			})
			.then(async (token) => {
				dispatch(setAuthorizationHeader(token));
				dispatch({
					type: SET_AUTHENTICATED,
				});

				if (newEmailForSignIn) {
					verifyBeforeUpdateEmail(auth.currentUser, newEmailForSignIn)
						.then(() => {
							// Email verification sent!
							dispatch({
								type: NEW_EMAIL_UPDATE_LINK_SENT,
								payload: t('awaiting_verify_email'),
							});
							dispatch(getUserData('', userData));
							history.replace('/verification');
						})
						.catch((error) => {
							// console.log(error);
						});
				} else if (deleteAccount) {
					dispatch(getUserData('', userData));
					history.replace('/accountActions');
				} else {
					if (window.location.pathname === '/accountActions') {
						history.replace('/feed');
					}
					dispatch(getUserData('', userData));
				}
			})
			.catch((error) => {
				history.replace('/login');
				if (
					error.code === 'auth/invalid-credential' ||
					error.code === 'auth/user-not-found' ||
					error.code === 'auth/wrong-password'
				) {
					dispatch({
						type: ERRORS_LOGIN,
						payload: t('incorrect_creds'),
					});
				} else if (error.code === 'auth/too-many-requests') {
					dispatch({
						type: ERRORS_LOGIN,
						payload: t('try_later_auth'),
					});
				} else if (error.code === 'Your email must be verified to login.') {
					dispatch({
						type: ERRORS_LOGIN,
						payload: t('email_not_verified'),
					});
					dispatch({ type: VERIFY_EMAIL_FIRST, payload: userData.email });
				} else if (error.code === 'auth/user-disabled') {
					dispatch({
						type: ERRORS_LOGIN,
						payload: t('account_is_disabled'),
					});
				} else {
					dispatch({
						type: ERRORS_LOGIN,
						payload: error.code,
					});
				}
			});
	};

	if (userData.action === 'accountActions') {
		loginDB();
	} else {
		axios
			.post(
				`https://recaptchaenterprise.googleapis.com/v1/projects/${process.env.REACT_APP_PROJECT_ID}/assessments?key=${process.env.REACT_APP_API_KEY}`,
				formData,
			)
			.then((resp) => {
				let enterpriseTokenProps = resp.data.tokenProperties;
				// let enterpriseRiskAnalysis = resp.data.riskAnalysis;
				// console.log('enterpriseRiskAnalysis', enterpriseRiskAnalysis.score);
				if (enterpriseTokenProps.valid) {
					// if (!userData.isEmail) {
					// 	axios
					// 		.post('/fetchEmailFromLoginHandle', {
					// 			possibleUsername: userData.email,
					// 		})
					// 		.then((res) => {
					// 			userData = {
					// 				email: res.data.email,
					// 				password: userData.password,
					// 				responseToken: userData.responseToken,
					// 				isEmail: true,
					// 			};
					// 			// console.log('LOGIN USER AGAIN - RECURSIVE', data);
					// 			// dispatch(loginUser(data));
					// 			loginDB();
					// 		})
					// 		.catch((error) => {
					// 			console.log(error);
					// 			dispatch({
					// 				type: ERRORS_LOGIN,
					// 				payload:
					// 					'Your account or password is incorrect. Please try again.',
					// 			});
					// 		});
					// } else if (userData.isEmail) {
					loginDB();
					// }
				} else {
					dispatch({
						type: ERRORS_LOGIN,
						payload: t('invalid_recapctha'),
					});
				}
			})
			.catch((error) => {
				// console.log(error);
				dispatch({
					type: ERRORS_LOGIN,
					payload: t('err_recapctha'),
				});
			});
	}
};

export const isMessagingSupported = () => async () => {
	const hasFirebaseMessagingSupport = await isSupported();
	if (
		hasFirebaseMessagingSupport &&
		'Notification' in window &&
		'serviceWorker' in navigator &&
		'PushManager' in window
	) {
		store.dispatch({ type: IS_MESSAGING_SUPPORTED, payload: true });

		return true;
	} else return false;
};

export const signupUser = (newUserData) => async (dispatch) => {
	const lang = localStorage.getItem('i18nextLng') || 'en';
	dispatch({ type: LOADING_SIGNUP });
	delete axios.defaults.headers.common['Authorization'];
	localStorage.removeItem('FBIdToken');
	localStorage.removeItem('userId');
	localStorage.removeItem('handle');
	localStorage.removeItem('boundaryId');
	localStorage.removeItem('viewedPostIds');
	localStorage.removeItem('scrollPosition');

	let userData = {
		handle: newUserData.handle,
		birthDate: newUserData.birthDate,
		lang,
	};
	// let userId;
	const newUser = {
		email: newUserData.email,
		password: newUserData.password,
		confirmPassword: newUserData.confirmPassword,
		handle: newUserData.handle,
	};

	let formData = {
		event: {
			token: newUserData.responseToken,
			siteKey: '6LdB0nslAAAAALKouSUhm6ChLS7Xy7mVe9npNo-z',
			expectedAction: 'SIGNUP',
		},
	};
	axios
		.post(
			`https://recaptchaenterprise.googleapis.com/v1/projects/${process.env.REACT_APP_PROJECT_ID}/assessments?key=${process.env.REACT_APP_API_KEY}`,
			formData,
		)
		.then(async (resp) => {
			let enterpriseTokenProps = resp.data.tokenProperties;
			// let enterpriseRiskAnalysis = resp.data.riskAnalysis;

			if (enterpriseTokenProps.valid) {
				var docRef = doc(db, 'usernames', newUser.handle);
				var docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					throw Object.assign(new Error(), {
						code: t('username_in_use'),
					});
				} else {
					return createUserWithEmailAndPassword(
						auth,
						newUser.email,
						newUser.password,
					);
				}
			} else {
				throw Object.assign(new Error(), {
					code: t('invalid_validation_token'),
				});
			}
		})
		.then((data) => {
			return data.user.getIdToken();
		})
		.then((idToken) => {
			const FBIdToken = `Bearer ${idToken}`;
			axios.defaults.headers.common['Authorization'] = FBIdToken;
			localStorage.setItem('FBIdToken', FBIdToken);
			return axios.post('/signup', userData);
		})
		.then(() => {
			window.gtag('event', 'signup', { handle: newUser.handle });
			dispatch({ type: SUCCESS_SIGNUP });
			dispatch({
				type: SET_USER,
				payload: {
					credentials: { email: newUser.email, handle: newUser.handle },
				},
			});
			history.replace('/verification');
			dispatch({
				type: VERIFICATION_EMAIL_SUCCESS_MESSAGE,
				payload: t('email_verify_link_success'),
			});
		})
		.catch((error) => {
			// console.log(error);
			if (error.code === 'auth/too-many-requests') {
				dispatch({
					type: ERRORS_SIGNUP,
					payload: t('requests_try_later'),
				});
			} else if (error.code === 'auth/email-already-in-use') {
				dispatch({
					type: ERRORS_SIGNUP,
					payload: t('email_address_in_use'),
				});
			} else if (error.code === 'auth/weak-password') {
				dispatch({
					type: ERRORS_SIGNUP,
					payload: t('add_stronger_pass'),
				});
			} else {
				dispatch({
					type: ERRORS_SIGNUP,
					payload: error.code,
				});
			}
		});
};

export const forgotPassword = (userRecoveryData) => (dispatch) => {
	dispatch({ type: SENDING_FORGOT_PASSWORD_EMAIL });

	return sendPasswordResetEmail(auth, userRecoveryData.email)
		.then(() => {
			let response = {
				res: t('email_verify_link_success'),
				email: userRecoveryData.email,
			};
			dispatch({ type: FORGOT_PASSWORD_EMAIL_SUCCESS, payload: response });
		})
		.catch((error) => {
			// console.error(error);
			if (error.code === 'auth/user-not-found') {
				dispatch({
					type: ERROR_SENDING_FORGOT_PASSWORD_EMAIL,
					payload: t('account_not_found'),
				});
			} else if (error.code === 'auth/too-many-requests') {
				dispatch({
					type: ERROR_SENDING_FORGOT_PASSWORD_EMAIL,
					payload: t('verification_email_sent_too_many_times'),
				});
			} else {
				dispatch({
					type: ERROR_SENDING_FORGOT_PASSWORD_EMAIL,
					payload: error.code,
				});
			}
		});
};

export const verifyEmail = () => (dispatch) => {
	dispatch({ type: LOADING_VERIFICATION_EMAIL });

	sendEmailVerification(auth.currentUser)
		.then(() => {
			dispatch({
				type: VERIFICATION_EMAIL_SUCCESS_MESSAGE,
				payload: t('email_verify_link_success'),
			});
		})
		.catch((error) => {
			// console.log(error);

			if (error.code === 'auth/user-token-expired') {
				dispatch({
					type: SENT_TOO_MANY_EMAILS,
					payload: t('session_expired'),
				});
			} else if (
				error.code === 'auth/too-many-requests' ||
				error.response.status === 500
			) {
				dispatch({
					type: SENT_TOO_MANY_EMAILS,
					payload: t('verification_email_sent_too_many_times'),
				});
			} else {
				dispatch({
					type: SENT_TOO_MANY_EMAILS,
					payload: {
						error: error.code,
					},
				});
			}
		});
};

export const sendSignInLinkToEmail = (email, sentAgain) => (dispatch) => {
	if (email) {
		dispatch({ type: SENDING_LOG_IN_EMAIL_SENT });
		let formData = { email: email };
		axios
			.post('/sendSignInLinkToEmail', formData)
			.then((res) => {
				if (sentAgain) {
					dispatch({
						type: SIGN_IN_EMAIL_SENT,
						payload: t('new_confirmation_link_success'),
					});
				} else {
					dispatch({
						type: SIGN_IN_EMAIL_SENT,
						payload: t('confirmation_link_success'),
					});
				}
			})
			.catch((err) => {
				// console.log(err.code);
				if (err.code === 405 || err.code === 'ERR_BAD_REQUEST') {
					dispatch({
						type: ERROR_SENDING_LOG_IN_EMAIL_SENT,
						payload: t('relogin_to_change_email'),
					});
				} else {
					dispatch({
						type: ERROR_SENDING_LOG_IN_EMAIL_SENT,
						payload: err.message,
					});
				}
			});
	}
};

export const logoutUser = (data) => (dispatch) => {
	currentUser = null;
	dispatch({ type: LOADING_LOGOUT });
	return auth
		.signOut()
		.then(() => {
			credentials = {};
			if (window.location.pathname !== 'accountActions') {
				history.replace('/login');
			}

			dispatch({ type: SET_UNAUTHENTICATED });
			unsubscribeListeners();
			localStorage.removeItem('FBIdToken');
			localStorage.removeItem('userId');
			localStorage.removeItem('handle');
			localStorage.removeItem('viewedPostIds');
			localStorage.removeItem('boundaryId');
			localStorage.removeItem('scrollPosition');
			delete axios.defaults.headers.common['Authorization'];
			if (data?.signInEmail) {
				dispatch({
					type: SET_SIGN_IN_EMAIL,
					payload: data.signInEmail,
				});
			}

			if (
				data?.newEmailForSignIn
				// && data?.currentEmailForSignIn
			) {
				dispatch({
					type: SET_NEW_EMAIL_ACTIONS,
					payload: {
						newEmailForSignIn: data.newEmailForSignIn,
						currentEmailForSignIn: data.currentEmailForSignIn,
					},
				});
			} else if (data?.deleteAccount) {
				dispatch({
					type: DELETE_ACCOUNT_ACTION,
					payload: data.deleteAccount,
				});
			} else if (data?.disabledMessage) {
				dispatch({
					type: SET_DISABLED_USER,
					payload: data,
				});
			} else if (data?.submittingAppealSuccess) {
				dispatch({
					type: USER_APPEAL_SUCCESFULLY_COMMITTED,
					payload: data.submittingAppealSuccess,
				});
			} else if (data?.successMessage) {
				dispatch({
					type: SET_LOGOUT_SUCCESS_MESSAGE,
					payload: data.successMessage,
				});
			} else if (data?.infoMessage) {
				dispatch({
					type: SET_LOGOUT_INFO_MESSAGE,
					payload: data.infoMessage,
				});
			} else if (data?.warningMessage) {
				dispatch({
					type: SET_LOGOUT_WARNING_MESSAGE,
					payload: data.warningMessage,
				});
			} else if (data?.errorMessage) {
				dispatch({
					type: SET_LOGOUT_ERROR_MESSAGE,
					payload: data.errorMessage,
				});
			}
		})
		.catch((error) => {
			dispatch({
				type: ERROR_LOADING_LOGOUT,
				payload: error.message,
			});
		});
};

export const notVerb = ({
	type,
	repliedId,
	senders,
	senderHandle,
	sendersInfo,
	body,
}) => {
	let sender_s;
	let verb =
		type === 'like'
			? `${t('liked_your_post')}${body ? ':' : '.'}`
			: type === 'comment'
			? t('commented_on_your_post')
			: type === 'mention'
			? t('mentioned_post')
			: type === 'commentReply'
			? t('replied_to_your_comment')
			: type === 'commentReplyMention'
			? t('mention_in_comment')
			: type === 'commentReplyPost'
			? t('replied_to_your_post')
			: type === 'commentLike' && repliedId
			? t('liked_comment_reply')
			: type === 'commentLike'
			? t('liked_your_comment')
			: type === 'follow'
			? `${t('followed_you').toLowerCase()}.`
			: type === 'postRelease'
			? t('wanted_post_not')
			: type === 'letterRelease'
			? t('wanted_letter_not')
			: type === 'postUpload'
			? 'Your post has been released.'
			: type === 'postReleasesIn24'
			? t('postReleasesIn24')
			: type === 'postReleasesIn72'
			? t('postReleasesIn72')
			: type === 'letterUpload'
			? t('letter_sent')
			: type === 'legal'
			? t('we_updated_terms')
			: type === 'inactivityNotice'
			? t('inactivity_not', {
					number: credentials.inactivityThreshold,
			  })
			: type === 'welcome'
			? t('welcome_aboard')
			: type === 'letterReleasesIn24'
			? t('letterReleasesIn24')
			: type === 'letterReleasesIn72'
			? t('letterReleasesIn72')
			: type === 'profileImgInfringement'
			? t('profile_img_removed')
			: type === 'postInfringement'
			? t('content_removed', {
					content: t('post'),
			  })
			: type === 'removedPostInfringement'
			? t('content_infringements_removed', {
					content: t('post'),
			  })
			: type === 'commentInfringement'
			? t('content_removed', {
					content: t('comment'),
			  })
			: type === 'removedCommentInfringement'
			? t('content_infringements_removed', {
					content: t('comment'),
			  })
			: '';
	if (!sendersInfo && senderHandle) {
		sender_s = senderHandle + ' ';
	} else if (sendersInfo && senders.length === 1) {
		sender_s = sendersInfo[0].senderHandle + ' ';
	} else if (senders && sendersInfo) {
		if (senders.length === 2) {
			sender_s = `${sendersInfo[0].senderHandle}, ${t('and')} ${
				sendersInfo[1].senderHandle
			} `;
		} else if (senders.length === 3) {
			sender_s = `${sendersInfo[0].senderHandle}, ${
				sendersInfo[1].senderHandle
			}, ${t('and')} ${sendersInfo[2].senderHandle} `;
		} else if (senders.length > 3) {
			sender_s = `${sendersInfo[0].senderHandle}, ${
				sendersInfo[1].senderHandle
			}, ${sendersInfo[2].senderHandle}, ${t('and')} ${
				senders.length - 3 > 1
					? `${senders.length - 3} ${t('more_users')} `
					: `1 ${t('more_user')} `
			}`;
		}
	}
	return { verb, sender_s };
};

export const notIcon = ({ type }, location) => {
	let size = location ? 'small' : 'medium';
	return type === 'like' ? (
		<FavoriteIcon style={{ color: '#ED4956' }} fontSize={size} />
	) : type === 'comment' ? (
		<ChatIcon id='iconBlue' fontSize={size} />
	) : type === 'mention' ||
	  type === 'commentReply' ||
	  type === 'commentReplyMention' ||
	  type === 'commentReplyPost' ? (
		<ThreePSharpIcon id='iconBlue' fontSize={size} />
	) : type === 'commentLike' ? (
		<RecommendIcon id='iconBlue' fontSize={size} />
	) : type === 'follow' ? (
		<GroupIcon id='iconBlue' fontSize={size} />
	) : type === 'postRelease' ? (
		<BlurOnRoundedIcon id='iconBlue' fontSize={size} />
	) : type === 'postUpload' ? (
		<CheckCircleOutlineRoundedIcon id='iconBlue' fontSize={size} />
	) : type === 'postInfringement' ? (
		<ReportGmailerrorredIcon style={{ color: '#eb2f06' }} fontSize={size} />
	) : type === 'commentInfringement' ? (
		<FeedbackIcon style={{ color: '#eb2f06' }} fontSize={size} />
	) : type === 'removedPostInfringement' ? (
		<TaskIcon id='iconBlue' fontSize={size} />
	) : type === 'removedCommentInfringement' ? (
		<MarkChatReadIcon id='iconBlue' fontSize={size} />
	) : type === 'app' || type === 'welcome' ? (
		<CircleNotificationsIcon
			id='iconBlue'
			className='mgl4px-'
			fontSize={size}
		/>
	) : type === 'legal' ? (
		<PolicyIcon id='iconBlue' className='mgl4px-' fontSize={size} />
	) : type === 'inactivityNotice' ? (
		<TimerIcon id='iconBlue' fontSize={size} />
	) : type === 'letterRelease' || type === 'letterUpload' ? (
		<HistoryEduIcon id='iconBlue' fontSize={size} />
	) : type === 'postReleasesIn24' ||
	  type === 'postReleasesIn72' ||
	  type === 'letterReleasesIn24' ||
	  type === 'letterReleasesIn72' ? (
		<AccessTimeIcon id='iconBlue' fontSize={size} />
	) : type === 'profileImgInfringement' ||
	  type === 'profileDetailsInfringement' ? (
		<AccountCircleIcon id='iconBlue' fontSize={size} />
	) : null;
};
export const notPath = (
	{
		postId,
		type,
		url,
		commentId,
		repliedId,
		sender,
		senderHandle,
		sendersInfo,
		letterId,
	},
	paramsHandle,
	pathLocation,
) => {
	let path;
	const urlLocation = pathLocation;

	if (type === 'follow') {
		let handle = sendersInfo ? sendersInfo[0].senderHandle : senderHandle;
		let senderId = sendersInfo ? sendersInfo[0].sender : sender;
		const location = {
			pathname: `/users/${handle}`,
			state: { data: senderId },
		};

		path = location;
	}
	if (type === 'inactivityNotice') {
		path = '/settings';
		const location = {
			pathname: '/settings',
			state: { expanded: 'inactivityPanel' },
		};

		path = location;
	} else if (type === 'letterRelease') {
		const location = {
			pathname: '/letter',
			state: { loadLetterId: letterId },
		};

		path = location;
	} else {
		let startUrl = '';
		if (paramsHandle !== undefined) {
			startUrl = `${urlLocation}/${paramsHandle}`;
		} else {
			startUrl = `${urlLocation}`;
		}
		if (type === 'app' || type === 'legal' || type === 'welcome') {
			path = new URL(url).pathname;
		} else if (
			type === 'comment' ||
			type === 'mention' ||
			type === 'removedCommentInfringement'
		) {
			if (commentId) {
				path = `/${startUrl}/notifications/post/${postId}/${commentId}`;
			} else {
				path = `/${startUrl}/notifications/post/${postId}`;
			}
		} else if (
			type === 'commentReply' ||
			type === 'commentReplyMention' ||
			type === 'commentReplyPost' ||
			type === 'removedCommentInfringement'
		) {
			path = `/${startUrl}/notifications/post/${postId}/${repliedId}/${commentId}`;
		} else if (type === 'commentLike') {
			if (repliedId) {
				path = `/${startUrl}/notifications/post/${postId}/${repliedId}/${commentId}`;
			} else {
				path = `/${startUrl}/notifications/post/${postId}/${commentId}`;
			}
		} else if (
			type === 'like' ||
			type === 'postRelease' ||
			type === 'postUpload' ||
			type === 'removedPostInfringement'
		) {
			path = `/${startUrl}/notifications/post/${postId}`;
		} else if (
			type === 'profileImgInfringement' ||
			type === 'profileDetailsInfringement'
		) {
			path = `/users/${
				sendersInfo
					? sendersInfo[0].senderHandle
					: senderHandle
					? senderHandle
					: credentials.handle
			}`;
		} else if (type === 'postInfringement') {
			path = `/${startUrl}/notifications/removedPost/${postId}`;
		} else if (type === 'commentInfringement') {
			if (repliedId) {
				path = `/${startUrl}/notifications/post/${postId}/removedComment/${repliedId}/${commentId}`;
			} else {
				path = `/${startUrl}/notifications/post/${postId}/removedComment/${commentId}`;
			}
		} else if (type === 'postReleasesIn24' || type === 'postReleasesIn72') {
			path = `/${startUrl}/notifications/unreleasedPost/${postId}`;
		} else if (
			type === 'letterReleasesIn24' ||
			type === 'letterReleasesIn72' ||
			type === 'letterUpload'
		) {
			const location = {
				pathname: `/letter/${letterId}`,
				state: { data: type },
			};
			path = location;
		}
	}
	return path;
};
