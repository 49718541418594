import { initializeApp } from 'firebase/app';
import {
	browserLocalPersistence,
	browserPopupRedirectResolver,
	// getAuth,
	indexedDBLocalPersistence,
	initializeAuth,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, isSupported } from 'firebase/messaging';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	projectId: process.env.REACT_APP_PROJECT_ID,
	appId: process.env.REACT_APP_APP_ID,
	messagingSenderId: process.env.REACT_APP_MESSAGIN_SENDER_ID,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(config);
let messaging = null;
if (
	isSupported() &&
	'Notification' in window &&
	'serviceWorker' in navigator &&
	'PushManager' in window
) {
	messaging = getMessaging(app);
}

// const messaging = getMessaging(app);
const db = getFirestore(app);
// const auth = getAuth(app);
const auth = initializeAuth(app, {
	persistence: [browserLocalPersistence, indexedDBLocalPersistence],
	popupRedirectResolver: browserPopupRedirectResolver,
});

export { auth, db, messaging };
