import { FC, ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import './Button.scss';
import { Tooltip } from '@mui/material';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	active?: boolean;
	tooltip?: string;
}

export const Button: FC<Props> = ({
	className,
	active,
	children,
	tooltip,
	...props
}) => {
	return (
		<Tooltip
			title={<h2 className='mg0'>{tooltip}</h2>}
			arrow
			placement={tooltip === 'Reset' ? 'bottom' : 'top'}
		>
			<button
				className={cn(
					'image-editor-button',
					active && 'image-editor-button--active',
					className,
				)}
				{...props}
			>
				{children}
			</button>
		</Tooltip>
	);
};
