import { useEffect } from 'react';

export default function SmoothScrollToTop() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, []);
	return <div></div>;
}
