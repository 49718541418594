import { Avatar } from '@mui/material';
import CopyText from '../../components/SocialComponents/CopyText';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';

export default function ContactOption({ icon, text, contact }) {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div className='flexColumn mgt2'>
			<Avatar id='cardMainIcon'>{icon}</Avatar>
			<p className='mg0 center mgtH bold'>{text}</p>
			{contact && (
				<div className='flex center mgtH'>
					<CopyText
						info={true}
						text={contact}
						icon={<ContentCopyIcon className='pointer' />}
						placement='bottom'
					/>
					<a
						href={`mailto:${contact}?subject=Contact`}
						className={`mglMedia notranslate ${mode === 'dark' && 'white'}`}
					>
						{contact}
					</a>
				</div>
			)}
		</div>
	);
}
