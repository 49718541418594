// import { useLocation, useParams, useNavigate } from 'react-router-dom';

// export const withRouter = (WrappedComponent) => {
// 	function ComponentWithRouterProp(props) {
// 		let location = useLocation();
// 		const history = useNavigate();
// 		const params = useParams();
// 		return (
// 			<WrappedComponent
// 				{...props}
// 				location={location}
// 				params={params}
// 				history={history}
// 			/>
// 		);
// 	}
// 	return ComponentWithRouterProp;
// };

import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
	const Wrapper = (props) => {
		let location = useLocation();
		const history = useNavigate();
		const params = useParams();

		return (
			<Component
				location={location}
				params={params}
				history={history}
				{...props}
			/>
		);
	};

	return Wrapper;
};
