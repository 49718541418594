import { Component } from 'react';
import CopyText from '../../components/SocialComponents/CopyText';
import countdown from '../../components/Countdownjs/countdown';
import TableRow from '../../components/SocialComponents/TableRow';
import dayjs from 'dayjs';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import { CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import ImgSrc from '../../components/SocialComponents/ImgSrc';
import { isEmail } from '../../redux/actions/dataActions';

class AccountReports extends Component {
	priority = (priorityLevel) => {
		if (priorityLevel === 'major') {
			return 'majorPriority';
		} else if (priorityLevel === 'medium') {
			return 'medPriority';
		} else {
			return 'minorPriority';
		}
	};

	formatDate = (date) => {
		const time = countdown(
			new Date(date),
			null,
			countdown.YEARS | countdown.MONTHS | countdown.DAYS | countdown.HOURS,
		).toString();
		if (time === '') {
			return countdown(new Date(date), null, countdown.MINUTES).toString();
		} else return time;
	};
	render() {
		const { collection, report, reportDialogId } = this.props;
		const {
			data: {
				setAccountReports: { reportedId, reporterId },
				loadingAccountReports,
				errorSetAccountReports,
			},
			queryUser,
		} = this.props;

		return (
			<div className='flexColumn pdb'>
				{!queryUser && (
					<div>
						{((reporterId && reporterId.handle) || reportedId) && (
							<table className='reportDialogTable mgb'>
								<tbody>
									<tr className='trTitles'>
										{reportedId ? <td>REPORTED</td> : null}

										<td>{reporterId ? 'REPORTER' : null}</td>
									</tr>

									<tr className='trContent'>
										{reportedId && reportedId.handle ? (
											<td style={{ width: '50%' }}>
												<ImgSrc
													img={reportedId.imageUrl}
													thumbnail={reportedId.thumbnail}
													handle={reportedId.handle}
													imgColor={reportedId.imageColor}
													css='listImg'
													margin='1rem auto'
												/>
												<p className='notranslate bold mg0'>
													@{reportedId.handle}
												</p>
												{reportedId.name && reportedId.name}
											</td>
										) : null}
										{reporterId && reporterId.handle && (
											<td style={{ width: '50%' }}>
												<ImgSrc
													img={reporterId.imageUrl}
													thumbnail={reporterId.thumbnail}
													handle={reporterId.handle}
													imgColor={reporterId.imageColor}
													css='listImg'
													margin='1rem auto'
												/>
												<p className='notranslate bold mg0'>
													@{reporterId.handle}
												</p>
												{reporterId.name && reporterId.name}
											</td>
										)}
									</tr>
								</tbody>
							</table>
						)}
						<table className='reportDialogTable mgb'>
							<tbody>
								<tr className='trTitles'>
									{collection === 'issueReports' ? (
										<td style={{ width: '50%' }}>Priority</td>
									) : collection === 'supportReports' ? (
										<td style={{ width: '50%' }}>Topic</td>
									) : reportedId ? (
										<td style={{ width: '50%' }}>REPORTED ID</td>
									) : null}

									<td style={{ width: '50%' }}>
										{reportedId
											? 'REPORTER ID'
											: isEmail(report.userId)
											? 'EMAIL'
											: 'USER ID'}
									</td>
								</tr>

								<tr className='trContent'>
									{collection === 'issueReports' ? (
										<td className={this.priority(report.priority)}>
											{report.priority.toUpperCase()}
										</td>
									) : collection === 'supportReports' ? (
										<td>{report.topic.toUpperCase()}</td>
									) : reportedId ? (
										<td>
											<CopyText
												placement='bottom'
												info={true}
												text={report.reported}
											/>
										</td>
									) : null}
									<td>
										<CopyText
											placement='bottom'
											info={true}
											text={report.userId}
										/>
									</td>
								</tr>
							</tbody>
						</table>
						<table className='reportDialogTable mgb'>
							<tbody>
								<tr className='trTitles'>
									<td>Report Id</td>
									{report.postId && <td>Post Id</td>}
									{report.commentId && <td>Comment Id</td>}
									<td>
										Created {this.formatDate(report.createdAt)}
										{' ago.'}
									</td>
								</tr>
								<tr className='trContent'>
									<td>
										<CopyText
											placement='bottom'
											info={true}
											text={reportDialogId}
										/>
									</td>
									{report.postId && (
										<td>
											<CopyText
												placement='bottom'
												info={true}
												text={report.postId}
											/>
										</td>
									)}
									{report.commentId && (
										<td>
											<CopyText
												placement='bottom'
												info={true}
												text={report.commentId}
											/>
										</td>
									)}
									<td>{dayjs(report.createdAt).format('LLLL')}</td>
								</tr>
							</tbody>
						</table>
						{report.repliedId && (
							<table className='reportDialogTable'>
								<tbody>
									<tr className='trTitles'>
										<td>REPLIED ID</td>
									</tr>
									<tr className='trContent'>
										<td>
											<CopyText
												placement='bottom'
												info={true}
												text={report.repliedId}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						)}
					</div>
				)}

				{
					// collection !== 'issueReports' &&
					// 	collection !== 'supportReportsReports' &&
					!loadingAccountReports &&
						Object.keys(this.props.data.setAccountReports).length > 0 && (
							<div>
								<h3 className='center mgtOnly underline'>Account Reports</h3>

								<TableRow
									title='User'
									data1={reportedId && reportedId.userReports}
									data2={reporterId.userReports}
									cellColor='#0a3d62'
								/>
								<TableRow
									title='Post'
									data1={reportedId && reportedId.postReports}
									data2={reporterId.postReports}
									cellColor='#3c6382'
								/>
								<TableRow
									title='Comment'
									data1={reportedId && reportedId.commentReports}
									data2={reporterId.commentReports}
									cellColor='#60a3bc'
								/>
								<h3 className='center mgtOnly underline'>Important Data</h3>

								<TableRow
									title='Infringements'
									data1={reportedId && reportedId.infringements}
									cellColor='red'
									data2={reporterId.infringements}
								/>
								<TableRow
									title='Removed Posts'
									data1={reportedId && reportedId.removedPosts}
									cellColor='#e67e22'
									data2={reporterId.removedPosts}
								/>
								<TableRow
									title='Removed Comments'
									data1={reportedId && reportedId.removedComments}
									cellColor='#6F1E51'
									data2={reporterId.removedComments}
								/>
								<TableRow
									title='Removed Profile Images'
									data1={reportedId && reportedId.removedProfileImg}
									cellColor='#1e1e1e'
									data2={reporterId.removedProfileImg}
								/>
								<h3 className='center mgtOnly underline'>Reports Made</h3>

								<TableRow
									title='Total Reports'
									data1={reportedId && reportedId.reportsMade}
									data2={reporterId.reportsMade}
									cellColor='#006266'
								/>
								<TableRow
									title='User Reports'
									data1={reportedId && reportedId.userReportsMade}
									data2={reporterId.userReportsMade}
									cellColor='#079992'
								/>
								<TableRow
									title='Post Reports'
									data1={reportedId && reportedId.postReportsMade}
									data2={reporterId.postReportsMade}
									cellColor='#38ada9'
								/>
								<TableRow
									title='Comment Reports'
									data1={reportedId && reportedId.commentReportsMade}
									data2={reporterId.commentReportsMade}
									cellColor='#27ae60'
								/>
								<TableRow
									title='Feature Reports'
									data1={reportedId && reportedId.featureReportsMade}
									data2={reporterId.featureReportsMade}
									cellColor='#454545'
								/>
								<TableRow
									title='Issue Reports'
									data1={reportedId && reportedId.issueReportsMade}
									data2={reporterId.issueReportsMade}
									cellColor='#3b0c4f'
								/>
								<TableRow
									title='Support Reports'
									data1={reportedId && reportedId.supportReportsMade}
									data2={reporterId.supportReportsMade}
									cellColor='#dd9904'
								/>
							</div>
						)
				}

				{loadingAccountReports && (
					<CustomAlerts
						info={'Loading Account Details'}
						icon={
							<CircularProgress
								variant='indeterminate'
								size={23}
								thickness={4}
								color='inherit'
							/>
						}
					/>
				)}
				<CustomAlerts error={errorSetAccountReports} />
			</div>
		);
	}
}

const mapActionsToProps = {};

const mapStateToProps = (state) => ({
	data: state.data,
});

export default connect(mapStateToProps, mapActionsToProps)(AccountReports);
