import { useEffect } from 'react';

export default function RefreshSite() {
	useEffect(() => {
		const unloadCallback = (event) => {
			const e = event || window.event;
			e.preventDefault();
			if (e) {
				e.returnValue = '';
			}
			return '';
		};

		window.addEventListener('beforeunload', unloadCallback);
		return () => {
			//cleanup function
			window.removeEventListener('beforeunload', unloadCallback);
		};
	}, []);
	return <div></div>;
}
