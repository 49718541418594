import gbaLogo from '../../util/Images/gbaLogo.png';
import logo from '../../util/Images/logo.svg';
export default function FooterLetterLogo({ mode }) {
	return (
		<div className='flex alignItemsCenter spaceBetween'>
			<div className='smallBackgroundLogo mgr'>
				<img className='tinyLogo' src={logo} alt='Logo' />
			</div>
			<img className='footerGBLogo' src={gbaLogo} alt='Goodbye App' />
		</div>
	);
}
