import { useDispatch, useSelector } from 'react-redux';
import {
	instantLikeAction,
	like,
	unlike,
} from '../../redux/actions/dataActions';
import PropTypes from 'prop-types';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const LikeButton = ({
	postId,
	commentId,
	repliedId,
	liked,
	likeCount,
	unreleasedPost,
	disabled,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const mode = useSelector((state) => state.UI.mode);
	const credentials = useSelector((state) => state.user.credentials);
	const errorLikeData = useSelector((state) => state.data.errorLikeData);
	const loadingDeletePost = useSelector(
		(state) => state.data.loadingDeletePost,
	);
	const deletingComments = useSelector((state) => state.data.deletingComments);
	const [likeTimeout, setLikeTimeout] = useState(null);
	const [instantLiked, setInstantLiked] = useState(null);
	const [userLimiter, setUserLimiter] = useState(false);
	const instantLikedRef = useRef(instantLiked);
	// Add instantLiked as a dependency to useEffect
	const dataId = repliedId ? repliedId : commentId ? commentId : postId;

	const handleLikeClick = (liked) => {
		if (
			!liked &&
			((repliedId && credentials.replyLikesMadeToday >= 200) ||
				(!repliedId && commentId && credentials.commentLikesMadeToday >= 200) ||
				(!repliedId &&
					!commentId &&
					postId &&
					credentials.postLikesMadeToday >= 300))
		) {
			setUserLimiter(true);
			toast.info(
				`${t('you_have_exceeded_daily_limit_action')} ${t(
					'security_anti_spam_measures',
				)}`,
				{
					position: 'bottom-left',
					autoClose: 15000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					toastId: 'likesMadeToday',
					icon: <ThumbUpAltOutlinedIcon className='iconBlue' />,
				},
			);
		} else {
			clearTimeout(likeTimeout);
			// Update instantLiked with the new value
			if (instantLiked !== null) {
				const instantLikedLogic = instantLiked ? false : true;
				setInstantLiked(instantLikedLogic);
				instantLikedRef.current = instantLikedLogic;
			} else {
				const likedLogic = liked ? false : true;
				setInstantLiked(likedLogic);
				instantLikedRef.current = likedLogic;
			}

			// Immediately update UI to show like action
			const alreadyToggledLikeAction =
				typeof instantLiked === 'boolean' ? instantLiked : liked;
			dispatch(
				instantLikeAction({
					liked: alreadyToggledLikeAction,
					likeCount,
					postId,
					commentId,
					repliedId,
				}),
			);

			// Clear any existing timeout

			// Set a 3-second timeout before sending the request
			const timeoutId = setTimeout(() => {
				if (
					typeof instantLikedRef.current === 'boolean' &&
					instantLikedRef.current !== liked
				) {
					if (liked) {
						dispatch(
							unlike({
								likeCount,
								postId,
								commentId,
								repliedId,
								unreleasedPost,
							}),
						);
					} else {
						dispatch(
							like({ likeCount, postId, commentId, repliedId, unreleasedPost }),
						);
					}
				}
			}, 3000);

			// Store the timeout ID in state
			setLikeTimeout(timeoutId);
		}
	};

	const disableButton =
		errorLikeData.includes(dataId) ||
		userLimiter ||
		disabled ||
		loadingDeletePost.includes(postId) ||
		deletingComments.includes(commentId) ||
		deletingComments.includes(repliedId);

	const likeButtonLogic =
		typeof instantLiked === 'boolean' &&
		!userLimiter &&
		!errorLikeData.includes(dataId) ? (
			<button
				onClick={() => handleLikeClick(liked)}
				id='LB_postButtons'
				className='pointer'
				disabled={disabled}
			>
				{instantLiked ? (
					<ThumbUpIcon
						sx={{
							color: disabled
								? '#ccc'
								: commentId && mode === 'light'
								? '#000'
								: '#fff',
						}}
						fontSize={postId && !commentId ? 'medium' : 'small'}
					/>
				) : (
					<ThumbUpAltOutlinedIcon
						sx={{
							color: disabled
								? '#ccc'
								: commentId && mode === 'light'
								? '#000'
								: '#fff',
						}}
						fontSize={postId && !commentId ? 'medium' : 'small'}
					/>
				)}
			</button>
		) : (
			<button
				onClick={() => handleLikeClick(liked)}
				id='LB_postButtons'
				className={disableButton ? '' : 'pointer'}
				disabled={disableButton}
			>
				{liked ? (
					<ThumbUpIcon
						sx={{
							color: disableButton
								? '#ccc'
								: commentId && mode === 'light'
								? '#000'
								: '#fff',
						}}
						fontSize={postId && !commentId ? 'medium' : 'small'}
					/>
				) : (
					<ThumbUpAltOutlinedIcon
						sx={{
							color: disableButton
								? '#ccc'
								: commentId && mode === 'light'
								? '#000'
								: '#fff',
						}}
						fontSize={postId && !commentId ? 'medium' : 'small'}
					/>
				)}
			</button>
		);
	return likeButtonLogic;
};

LikeButton.propTypes = {
	postId: PropTypes.string,
	liked: PropTypes.bool,
	commentId: PropTypes.string,
	comment: PropTypes.bool,
};

export default LikeButton;
