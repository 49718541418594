import { useEffect, useRef } from 'react';
import { setOnlineStatus } from '../redux/actions/dataActions';
import { connect, useDispatch } from 'react-redux';
import isOnline from 'is-online';

const OnlineStatus = () => {
	const dispatch = useDispatch();
	const intervalRef = useRef(null); // Ref to store interval ID

	const checkOnlineStatus = async () => {
		try {
			const online = await fetch(
				'https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/no-img.jpg?alt=media&token=fa80e5de-ef3d-4f50-af60-52d4190a6ce0',
			);
			const isOnlineStatus = online.status >= 200 && online.status < 300;
			dispatch(setOnlineStatus(isOnlineStatus));
			return isOnlineStatus;
		} catch (err) {
			dispatch(setOnlineStatus(false));
			return false;
		}
	};

	useEffect(() => {
		const updateOnlineStatus = async () => {
			const response = await isOnline();

			if (response || navigator.onLine) {
				dispatch(setOnlineStatus(true));
				console.log('Connected to internet.');

				// Clear the interval if we are online
				if (intervalRef.current) {
					clearInterval(intervalRef.current);
					intervalRef.current = null;
				}
			} else {
				dispatch(setOnlineStatus(false));
				console.log('Connection is down. Please check your internet.');

				// Set up an interval to check the online status periodically
				intervalRef.current = setInterval(async () => {
					const result = await checkOnlineStatus();
					if (result) {
						// Clear the interval once we're back online
						if (intervalRef.current) {
							clearInterval(intervalRef.current);
							intervalRef.current = null;
						}
					}
				}, 20000); // every 20 seconds
			}
		};

		const handleOnline = () => updateOnlineStatus();
		const handleOffline = () => updateOnlineStatus();

		window.addEventListener('online', handleOnline, false);
		window.addEventListener('offline', handleOffline, false);

		// Initial status check
		updateOnlineStatus();

		return () => {
			// Clean up event listeners and interval
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [dispatch]);

	return null;
};

const mapActionsToProps = {
	setOnlineStatus,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapActionsToProps)(OnlineStatus);
