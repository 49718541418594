import { IconButton, Skeleton } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ForumIcon from '@mui/icons-material/Forum';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import blankImg from '../../util/Images/emailBlankProfile.png';
import { Grid } from '@mui/material';

const PostSkeleton = (props) => {
	const content = Array.from({ length: props.number }).map((item, index) => (
		<div id='postCard' key={index}>
			<div
				id='PS_Card'
				style={{
					boxShadow: props.darkMode
						? 'rgb(204, 204, 204) 0px 2px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
						: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
				}}
			>
				<section className='flex spaceBetween pdPostHeader'>
					<div className='flex pdb'>
						<div
							style={{
								overflow: 'hidden',
								background: '#fff',
							}}
							id='mediaImgSkeleton'
							className='mgr blueBoxShadow'
						>
							<img
								src={blankImg}
								alt={`default img`}
								style={{
									objectFit: 'cover',
									width: '100%',
									height: '100%',
									transform: 'translate(0, -8px)',
								}}
							/>
						</div>
						<div>
							<div className='mgb6px'>
								<Skeleton
									animation='wave'
									height={30}
									width={
										props.handle ? `${props.handle.length * 12}px` : '100px'
									}
								/>
							</div>

							<Skeleton
								animation='pulse'
								variant='rectangular'
								id='skeletonDate'
								// width='120px'
								// height={15}
								className='mgbH'
							/>
						</div>
					</div>
					<div className='flexColumn'>
						<IconButton disableRipple id='postDottedMenu'>
							<MoreHorizIcon fontSize='small' id='mediaDotted' />
						</IconButton>
					</div>
				</section>

				<Skeleton
					variant='rectangular'
					animation='wave'
					width={'100%'}
					height={'50vh'}
				/>
				<Grid container className='postActions'>
					<Grid
						item
						xs={5}
						sm={4}
						md={4}
						id='postBtn_actions'
						className='bottomTranslate2'
					>
						<button id='LB_postButtons'>
							<ThumbUpIcon id='likeIcon_SK' />
						</button>
						<button className='commentBtn'>
							<ForumIcon id='cIcon' />
						</button>
					</Grid>
					<Grid item xs={null} sm={4} md={4}></Grid>
					<Grid item xs={7} sm={4} md={4}></Grid>
				</Grid>
			</div>
		</div>
	));

	return content;
};

export default PostSkeleton;
