import React, { Component, Fragment } from 'react';
import { Dialog, Grow, Toolbar } from '@mui/material';
import CustomButton from './CustomButton';
import { Radio, FormControlLabel } from '@mui/material';
import CustomAlerts from './CustomAlerts';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';

class ConfirmationDialog extends Component {
	constructor(props) {
		super(props);
		this.logOutBtn = React.createRef();
	}
	handleOpen = () => {
		this.props.openFunc();
	};
	handleClose = () => {
		this.props.closeFunc();
	};
	callAction = () => {
		this.props.action();
	};

	render() {
		const {
			open,
			openIconOrString,
			idOpenIconOrString,
			actionBtnText,
			cancelBtnText,
			cancelBtnIcon,
			startIcon,
			initialBtnIcon,
			contentTitle,
			phrase,
			phrase2,
			phrase3,
			phrase4,
			backgroundColor,
			customButtonId,
			adminPanelActive,
			canAppeal,
			handleRadioChange,
			updatingAppealableContent,
			successUpdatingAppealableContent,
			errorUpdatingAppealableContent,
			updateAppealable,
			infringements,
			actionBtnMargin,
			actionBtnPadding,
			tooltip,
			loading,
			loadingInitialBtn,
			error,
			disableRipple,
			list,
			componentTitle,
			component,
			variant,
			iconButton,
			alertIcon,
			// actionBtnDisabled,
		} = this.props;

		return (
			<Fragment>
				{openIconOrString !== undefined && (
					<div>
						{iconButton ? (
							<CustomButton
								iconButton
								onClick={this.handleOpen}
								margin={actionBtnMargin}
								id={idOpenIconOrString}
								variant={variant ? variant : 'contained'}
								tooltip={tooltip}
								padding={actionBtnPadding ? actionBtnPadding : ''}
								startIcon={openIconOrString}
								disableRipple={disableRipple}
								loading={loadingInitialBtn}
								color='inherit'
							/>
						) : (
							<CustomButton
								onClick={this.handleOpen}
								btnText={openIconOrString}
								margin={actionBtnMargin}
								id={idOpenIconOrString}
								variant={variant ? variant : 'contained'}
								tooltip={tooltip}
								startIcon={initialBtnIcon}
								disableRipple={disableRipple}
								loading={loadingInitialBtn}
								color='inherit'
								// disabled={actionBtnDisabled}
							/>
						)}
					</div>
				)}
				{open && (
					<Dialog
						open={open}
						onClose={this.handleClose}
						fullWidth
						maxWidth='md'
						disableEnforceFocus
					>
						<Toolbar
							id='confirmDialogBar'
							style={{
								backgroundColor: backgroundColor ? backgroundColor : '#1e1e1e',
							}}
						>
							<div className='titleToolbar flex alignBaseline'>{phrase}</div>
							<CloseIcon
								id='closeDialogButton'
								onClick={this.handleClose}
								aria-label='close'
							/>
						</Toolbar>

						{/* <div
							className='confirmationTitle pdH'
							style={{
								backgroundColor: backgroundColor ? backgroundColor : '#1e1e1e',
							}}
						>
							<div className='titleToolbar center'>{phrase}</div>
						</div> */}
						<div>
							{componentTitle && componentTitle}
							<div>
								<CustomAlerts
									info={contentTitle}
									margin='0 0 1rem'
									noBorder
									icon={alertIcon}
									alertId='smallMuiAlert'
								/>

								{phrase2 && (
									<p className='pdX textTopic bold center mgtOnly'>
										{phrase3 ? `${phrase2} ${phrase3}` : phrase2}
									</p>
								)}
								{phrase4 && (
									<p className='pdX textTopic bold center mgtOnly'>{phrase4}</p>
								)}
								{component && component}
								{list && list}
							</div>
							{adminPanelActive && (
								<div>
									<CustomAlerts
										success={successUpdatingAppealableContent}
										close
									/>
									<CustomAlerts error={errorUpdatingAppealableContent} close />
									<div className='appealableAdmin'>
										<FormControlLabel
											value='appealable'
											control={
												<Radio
													onChange={handleRadioChange}
													name='appealable'
													sx={{
														color: '#2980b9',
													}}
													checked={canAppeal}
												/>
											}
											label='Appealable'
											labelPlacement='bottom'
										/>
										<FormControlLabel
											value='nonAppealable'
											control={
												<Radio
													onChange={handleRadioChange}
													name='nonAppealable'
													sx={{
														color: '#2980b9',
													}}
													checked={!canAppeal}
												/>
											}
											label='Non Appealable'
											labelPlacement='bottom'
										/>
										{infringements && (
											<CustomButton
												type='update'
												variant='contained'
												loading={updatingAppealableContent}
												btnText={t('update')}
												id='customInfoButton'
												fontSize='10px'
												margin='0 0 0 3rem'
												onClick={updateAppealable}
											/>
										)}
									</div>
								</div>
							)}
							<CustomAlerts error={error} />
							<Grow in={true} {...(true ? { timeout: 800 } : {})}>
								<div
									className={`${
										cancelBtnText ? 'flexColumnMedia' : 'flex'
									} spaceAround pdb`}
								>
									{cancelBtnText && (
										<CustomButton
											onClick={this.handleClose}
											variant='contained'
											id='customCancelButton'
											btnText={cancelBtnText}
											startIcon={cancelBtnIcon}
										/>
									)}
									<CustomButton
										onClick={this.callAction}
										variant='contained'
										id={customButtonId}
										btnText={actionBtnText}
										startIcon={startIcon}
										loading={loading}
									/>
								</div>
							</Grow>
						</div>
					</Dialog>
				)}
			</Fragment>
		);
	}
}

export default ConfirmationDialog;
