import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
	logoutUser,
	logoutAllTabs,
	refreshAllTabs,
} from '../../firebase/firebaseInit';

const Logout = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		localStorage.removeItem('FBIdToken');
		localStorage.removeItem('userId');
		localStorage.removeItem('viewedPostIds');
		dispatch(logoutUser());
		dispatch(logoutAllTabs());
		dispatch(refreshAllTabs(false));
	}, [dispatch]);

	return <div></div>;
};

const mapActionsToProps = {
	logoutUser,
	logoutAllTabs,
	refreshAllTabs,
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionsToProps)(Logout);
