import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function Perpetrators() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Perpetrators of Violent Attacks Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>
						Perpetrators of Violent Attacks Policy
					</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						To maintain a safe and responsible community, we enforce strict
						measures against individuals associated with violent attacks or
						extremism
					</p>
					<h2>Our policy includes:</h2>

					<p>
						<b>Account Removal:</b> Any accounts linked to individuals involved
						in terrorist acts, violent extremism, or mass violent attacks will
						be promptly removed from our platform.
					</p>
					<p>
						<b>Removal of Related Content:</b> Posts or content that disseminate
						manifestos or materials produced by perpetrators of violent attacks
						will also be removed to prevent further harm or glorification.
					</p>
					<p>
						<b>Preventing Re-Engagement:</b> We take measures to prevent
						individuals associated with violent attacks from rejoining our
						platform under new accounts or aliases.
					</p>
					<p>
						<b>Cooperating with Authorities:</b> We cooperate with law
						enforcement agencies and authorities to ensure accountability and
						prevent the spread of harmful ideologies or content.
					</p>

					<p className='bold mg0'>
						Our commitment to removing such accounts and content is part of our
						broader efforts to promote safety, security, and responsible online
						behavior within our community.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Perpetrators;
