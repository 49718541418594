import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function ViolentSpeech() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Violent Speech Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Violent Speech Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<h2>What is in violation of this policy?</h2>
					<p>
						Violent Threats: You are prohibited from issuing threats of physical
						harm to others. This includes threatening actions such as killing,
						torturing, sexually assaulting, or causing harm to individuals. It
						also encompasses threats to damage civilian residences, shelters, or
						essential infrastructure vital for daily, civic, or business
						activities.
					</p>
					<p>
						Glorification of Violence: Celebrating or praising acts of violence
						where harm has occurred is not permitted. This includes expressing
						gratitude for someone experiencing physical harm or praising violent
						entities and perpetrators of violent attacks. Furthermore,
						glorifying animal abuse or cruelty is strictly prohibited.
					</p>
					<p>
						Incitement of Violence: You may not incite, promote, or encourage
						others to engage in violent or harmful acts. This includes
						encouraging self-harm or inciting others to commit crimes such as
						crimes against humanity, war crimes, or genocide. Additionally, the
						use of coded language ("dog whistles") to indirectly provoke
						violence is not allowed.
					</p>
					<p>
						Wishing Harm: Expressing desires or hopes for harm is strictly
						prohibited. This encompasses wishing for individuals to suffer
						illness, tragedy, or other physically harmful occurrences.
					</p>
					<h2>What Doesn't Violate This Policy?</h2>
					<p>
						We ensure thorough evaluation and understanding of the context
						before taking action, allowing expressions of violent speech when
						there's no clear abusive or violent context. Examples include
						hyperbolic and consensual speech among friends or discussions
						related to video games and sporting events. Additionally, we permit
						certain instances of figures of speech, satire, or artistic
						expression, provided the context aims to express a viewpoint rather
						than instigate actionable violence or harm.
					</p>
					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Offensive words or symbols"</b>, or{' '}
							<b>"Bullying or harassment"</b>, or{' '}
							<b>"Violence or dangerous organizations".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<p className='textTitle bold mg0'>
						Consequences of Policy Violations:
					</p>
					<p>
						For severe violations of this policy, we mandate the removal of such
						content.
					</p>
					<p>
						In certain instances, immediate and permanent account suspension may
						be implemented for policy violations. For most breaches, temporary
						account lockouts may precede further actions. Rarely, violative
						content may have its reach restricted on{' '}
						<span className='notranslate'> Goodbye App </span> to reduce
						visibility.
					</p>
					<p>
						Persistent violations, even after a warning, may result in permanent
						account suspension. We acknowledge that discussions regarding
						individuals credibly accused of severe violence may elicit outrage
						and associated violent speech. In such limited cases, less punitive
						measures may be taken.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default ViolentSpeech;
