import { Avatar, LinearProgress, Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { setActiveVideo } from '../../redux/actions/dataActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import AudioPlayer from '../../components/MediaPlayers/AudioPlayer';
import { useEffect } from 'react';
import { getMinUserDetails } from '../../redux/actions/userActions';
import CustomButton from '../../components/SocialComponents/CustomButton';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import ImgSrc from '../../components/SocialComponents/ImgSrc';
import store from '../../redux/store';
import dayjs from 'dayjs';
import ContactsIcon from '@mui/icons-material/Contacts';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import GetAppIcon from '@mui/icons-material/GetApp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinkIcon from '@mui/icons-material/Link';
import { toast } from 'react-toastify';
import CopyText from '../../components/SocialComponents/CopyText';
import Media from 'react-media';
import { useTranslation } from 'react-i18next';
const AudioLetter = ({
	user: {
		loadingAudioUserDetails,
		errorLoadingAudioUserDetails,
		audioUserDetails,
	},
	data: { activeVideoPostId },
	UI: { mode },
}) => {
	const aud = document.getElementById('alternativeAudioPlayer');
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const audioUrlsNotFound = useSelector(
		(state) => state.data.audioUrlsNotFound,
	);

	const url = `https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/letterAudios%2F${window.location.pathname
		.split('/')
		.slice(2)
		.join('')}?alt=media`;

	useEffect(() => {
		if (aud) {
			aud.onplay = function () {
				dispatch(setActiveVideo('alternativeAudioPlayer'));
			};
			aud.addEventListener('error', function (e) {
				console.error('Audio playback error:', e);
			});
		}

		const parts = window.location.pathname.split('_');
		const userId = parts[parts.length - 1].split('.')[0];

		if (
			!loadingAudioUserDetails &&
			Object.keys(audioUserDetails).length === 0 &&
			!errorLoadingAudioUserDetails
		) {
			store.dispatch(getMinUserDetails(userId));
		}
	}, [aud]);

	useEffect(() => {
		if (aud && activeVideoPostId !== 'alternativeAudioPlayer') {
			aud.pause();
		}
	}, [aud, activeVideoPostId]);

	const downloadAudio = () => {
		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = (event) => {
			const blob = xhr.response;
			const name = audioUserDetails.name
				? audioUserDetails.name
				: audioUserDetails.handle
				? audioUserDetails.handle
				: 'audio';

			var file = new File([blob], `${name}_GoodbyeApp`, {
				type: blob.type,
			});
			//grab the a tag
			var link = document.createElement('a');
			//set the download attribute of the a tag to the name stored in the file
			link.download = file.name;
			//generate a temp url to host the image for download
			link.href = URL.createObjectURL(file);
			link.click();
		};
		xhr.open('GET', url);
		xhr.send();
		toast.success(t('file_downloaded'), {
			position: 'bottom-left',
			type: 'success',
			isLoading: false,
			autoClose: 6000,
			containerId: 'app',
			icon: <CheckCircleIcon id='iconGreen' />,
		});
	};

	return (
		<div>
			<Helmet>
				<title>Letter Recording - Goodbye App</title>
			</Helmet>
			{loadingAudioUserDetails && <LinearProgress />}
			<div className='skyBackground pd2'>
				<div className={`card pd ${mode === 'dark' && 'dbc'}`}>
					{loadingAudioUserDetails ? (
						<div className='flex center mgb2'>
							<Skeleton
								variant='circular'
								width={'35vw'}
								height={'40.1vw'}
								sx={{
									maxWidth: '260px',
									maxHeight: '310px',
								}}
							/>
						</div>
					) : Object.keys(audioUserDetails).length > 0 ? (
						<div className='pdt'>
							<Media
								queries={{
									larger900: '(min-width: 901px)',
									isMobile: '(max-width: 900px)',
								}}
							>
								{(matches) => (
									<ImgSrc
										img={audioUserDetails.imageUrl}
										thumbnail={audioUserDetails.thumbnail}
										handle={audioUserDetails.handle}
										imgColor={audioUserDetails.imageColor}
										css='imgMainProfile'
										fontSize={matches.isMobile ? '8vw' : '5vw'}
										onLoad={() => this.setState({ loaded: true })}
									/>
								)}
							</Media>
							<div className='mgt center flexColumn'>
								<span className='name-sp notranslate capitalize'>
									{audioUserDetails.name
										? audioUserDetails.name
										: audioUserDetails.handle}
								</span>

								<small className='mgbOnly bold'>
									{t('joined')} {dayjs(audioUserDetails.createdAt).format('LL')}
								</small>

								{audioUserDetails.biography && (
									<div className='mgbOnly'>
										<FingerprintIcon className='detailsIcon bottomTranslate6' />
										<span className='text mgl4px'>
											{audioUserDetails.biography}
										</span>
									</div>
								)}
								{audioUserDetails.quote && (
									<div className='mgbOnly'>
										<FormatQuoteIcon className='detailsIcon bottomTranslate6' />
										<span className='text mgl4px'>
											{audioUserDetails.quote}
										</span>
									</div>
								)}
							</div>
						</div>
					) : (
						<div>
							<Avatar id='cardMainIcon' className='flexAuto'>
								<ContactsIcon id='cardMainIconSize' />
							</Avatar>
							<h3 className='subtitle center mg0'>{t('audio_recording')}</h3>
						</div>
					)}

					<CustomAlerts
						error={errorLoadingAudioUserDetails}
						icon={<AccountCircleRoundedIcon />}
						margin='0.5rem 0'
					/>

					{audioUrlsNotFound.includes(url) ? (
						<CustomAlerts info={t('audio_404')} margin='0' />
					) : (
						<div>
							<AudioPlayer fileUrl={url} postId='audioLetter' audioLetter />

							<hr />
							<hr />
							<div className='center mgt'>
								<p>{t('alternative_audio_player')}</p>
								<audio controls id='alternativeAudioPlayer'>
									<source src={url} type='audio/webm' />
									<source src={url} type='audio/mpeg' />
									<source src={url} type='audio/ogg' />
								</audio>
							</div>
							{url && (
								<div className='flex spaceBetween'>
									<CopyText
										info
										icon={
											<CustomButton
												startIcon={<LinkIcon />}
												btnText={t('copy_url')}
												margin='1rem 0 0 0'
											/>
										}
										text={window.location.href}
									/>
									<CustomButton
										onClick={downloadAudio}
										margin='1rem 0 0 0'
										btnText={t('download')}
										startIcon={<GetAppIcon />}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

const mapActionsToProps = {
	setActiveVideo,
};
const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(AudioLetter);
