import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CookieIcon from '@mui/icons-material/Cookie';
function CookieUse() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Cookie Use - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<CookieIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mg0'>Cookie Use</h1>
					<h2>Policy Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						Cookies and similar technologies like pixels and local storage
						provide you with a better, faster, and safer experience on Goodbye
						App. Cookies are also used to operate our services, which include
						our websites, applications, APIs, pixels, embeds, and email
						communications. Specifically, Goodbye App uses these technologies
						to:
					</p>

					<ul className='text'>
						<li className='mgb'>Save and honor your preferences.</li>
						<li className='mgb'>Protect you against spam and abuse.</li>
						<li className='mgb'>Keep you logged in to Goodbye App.</li>
						<li className='mgb'>
							Deliver features and functionality of Goodbye App services.
						</li>
						<li className='mgb'>Personalize the content you see.</li>
						<li className='mgb'>Show you more relevant ads.</li>
						<li className='mgb'>
							Provide subscription features and distribute certain content.
						</li>
						<li className='mgb'>
							Assess how our services perform and identify bugs and other
							quality issues.
						</li>
						<li className='mgb'>
							Measure the effectiveness of our advertising and marketing.
						</li>
						<li className='mgb'>
							Understand how you interact with our services and identify areas
							for improvement.
						</li>
						<li className='mgb'>
							Gather data used to operate our business — from measuring our
							audience size to enforcing{' '}
							<span className='notranslate'> Goodbye App </span>{' '}
							<Link to='/community-guidelines' id='link' className='bold'>
								Community Guidelines
							</Link>
							.
						</li>
					</ul>
					<h2>What are cookies, pixels, and local storage?</h2>

					<p>
						<b>Cookies</b> are small pieces of data stored on your device by
						your web browser. They are used to remember information about your
						visit to a website, such as your login status, preferences, and
						other settings. Cookies can be either temporary (session cookies,
						which are deleted when you close your browser) or persistent (stored
						on your device until they expire or are deleted).
					</p>
					<p>
						<b>Pixels</b> (also known as tracking pixels or web beacons) are
						tiny, invisible images embedded in web pages or emails. They are
						used to track user behavior and gather data about how users interact
						with a website or email. When a user loads a page with a tracking
						pixel, the pixel sends information back to the server, such as the
						user's IP address, the time the pixel was viewed, and the type of
						browser used.
					</p>
					<p>
						<b>Local storage</b> is a web storage technology that allows
						websites to store data on a user's device. Unlike cookies, local
						storage is more secure and can store larger amounts of data. It is
						used to save user preferences, session data, and other information
						that needs to persist across multiple visits to a website. Data in
						local storage remains until it is explicitly deleted by the user or
						the website.
					</p>

					<h2>Why Do Our Services Use These Technologies?</h2>

					<p>
						Cookies, pixels, and local storage are essential for the operation
						and support of our business, and they enhance your experience on our
						platform. These technologies are used for various purposes,
						including:
					</p>
					<ol className='text'>
						<li className='mgb'>
							<b>Authentication and Security:</b> Ensuring you stay logged in
							securely and protecting your account from unauthorized access.
						</li>
						<li className='mgb'>
							<b>Functionality:</b> Enabling essential features and functions of
							our services.
						</li>
						<li className='mgb'>
							<b>User Preferences:</b> Saving your preferences to improve your
							user experience.
						</li>
						<li className='mgb'>
							<b>Analytics:</b> Collecting data to understand how you interact
							with our services.
						</li>
						<li className='mgb'>
							<b>Research and Development:</b> Using insights to improve and
							develop our services.
						</li>
						<li className='mgb'>
							<b>Personalized Content:</b> Tailoring content to your interests
							and interactions.
						</li>
						<li className='mgb'>
							<b>Advertising:</b> Delivering relevant ads based on your activity
							and interests.
						</li>
						<li className='mgb'>
							<b>Marketing:</b> Supporting our marketing efforts and measuring
							their effectiveness.
						</li>
						<li className='mgb'>
							<b>Personalization Across Devices:</b> Ensuring a consistent
							experience across all your devices.
						</li>
					</ol>

					<p>
						In each of these categories, cookies, pixels, and local storage play
						key roles in enhancing your experience on{' '}
						<span className='notranslate'> Goodbye App </span>:
					</p>

					<p>
						<b>Authentication and Security:</b> These technologies authenticate
						your access to <span className='notranslate'> Goodbye App </span>,
						ensuring you see relevant content while safeguarding your account
						from unauthorized access. They help us by:
					</p>
					<ul className='text'>
						<li className='mgb'>
							Logging you into{' '}
							<span className='notranslate'> Goodbye App </span> and associated
							services securely.
						</li>
						<li className='mgb'>
							Enhancing security measures for your protection and the overall
							integrity of our services.
						</li>
						<li className='mgb'>
							Enabling access to content with limited distribution.
						</li>
						<li className='mgb'>
							Detecting and combating spam, abuse, and violations of Goodbye
							App's policies.
						</li>
					</ul>
					<p>
						<b>Functionality:</b> Cookies, pixels, and local storage facilitate
						various functional aspects of{' '}
						<span className='notranslate'> Goodbye App </span>, such as
						displaying error messages, enabling account switching, coordinating
						service activities across browser tabs, and offering specific
						features on partner websites.
					</p>
					<p>
						<b>User Preferences:</b> These technologies remember your browser
						information and preferences, ensuring a personalized and streamlined
						experience.
					</p>
					<p>
						<b>Analytics:</b> They play a crucial role in analyzing and
						improving our services by:
					</p>

					<ul className='text'>
						<li className='mgb'>
							Optimizing your experience based on how you engage with our
							services, including your visit frequency, preferred links, and
							source of access.
						</li>
						<li className='mgb'>
							Tracking the reach of posts, embedded content, buttons, or
							timelines to gauge audience engagement and performance metrics. We
							may utilize tools like Google Analytics to aid in these analytics
							efforts.
						</li>
						<li className='mgb'>
							Enhancing our understanding of user behavior, interactions, and
							preferences, including the usage of buttons and widgets.
						</li>
					</ul>

					<p>
						<b>Research and Development:</b>
					</p>
					<ul className='text'>
						<li className='mgb'>
							Conduct tests for updates to{' '}
							<span className='notranslate'> Goodbye App </span> services and
							create new features, functionalities, and services.
						</li>
						<li className='mgb'>
							Gather data about your usage patterns and the performance of our
							services, aiding in issue detection, identifying areas for
							improvement, and fostering the development of new products and
							services.
						</li>
					</ul>
					<p>
						<b>Personalized Content:</b>
					</p>
					<ul className='text'>
						<li className='mgb'>
							Utilize local storage to track which parts of your{' '}
							<span className='notranslate'> Goodbye App </span>
							timeline you've already viewed, ensuring you're presented with
							fresh content.
						</li>
						<li className='mgb'>
							Tailor our services to your preferences, delivering personalized
							content in areas such as trends, stories, ads, and suggestions for
							relevant people to follow.
						</li>
						<li className='mgb'>
							Employ cookies to recommend accounts you might find interesting
							based on your interactions with websites integrated with Goodbye
							App services.
						</li>
					</ul>
					<p>
						<b>Advertising:</b>
					</p>
					<ul className='text'>
						<li className='mgb'>
							Collaborate with third-party advertising partners like Google to
							promote our services effectively.
						</li>
						<li className='mgb'>
							Customize ads and evaluate their performance by displaying ads and
							assessing their effectiveness according to your activity on
							<span className='notranslate'> Goodbye App </span> and visits to
							our ad partners' websites.
						</li>
					</ul>
					<p>
						<b>Marketing:</b>
					</p>
					<ul className='text'>
						<li className='mgb'>
							Manage email and web marketing campaigns and evaluate their
							effectiveness in reaching our audience.
						</li>
					</ul>
					<p>
						<b>Personalization across Devices::</b>
					</p>
					<ul className='text'>
						<li className='mgb'>
							Understand your device usage to provide a personalized experience
							across all your devices.
						</li>
					</ul>
					<hr />

					<p>
						In summary, cookies, pixels, and local storage are integral to
						providing a secure, functional, and personalized experience on
						<span className='notranslate'> Goodbye App </span> while enabling us
						to gather insights for continual service enhancement. Please note
						that when you log in to{' '}
						<span className='notranslate'> Goodbye App </span>
						with your device, we associate that device with your account. This
						association allows us to receive information about your device(s)
						whether you're logged in or not, primarily using IP addresses and
						timestamps to link specific devices to you.
					</p>

					<hr />

					<h2>Where are these technologies used?</h2>
					<p>
						These technologies are utilized across various platforms within the
						<span className='notranslate'> Goodbye App </span> ecosystem,
						including our websites, applications, and services. Additionally,
						they are integrated into other websites, applications, and services
						that have incorporated{' '}
						<span className='notranslate'> Goodbye App </span>
						services. This encompasses third-party properties that utilize our
						advertising technology, such as our ad partners’ websites, as well
						as sites featuring our embeds like embedded timelines. Third parties
						may also employ these technologies, especially in scenarios such as
						clicking on links from{' '}
						<span className='notranslate'> Goodbye App </span> services,
						interacting with third-party content within our services, or
						visiting external websites that incorporate our advertising
						technology.
					</p>

					<h2>Cookie settings through web browsers</h2>
					<p>
						Disabling cookies completely can lead to issues with the
						functionality of <span className='notranslate'> Goodbye App </span>{' '}
						services or may cause difficulties with logging into{' '}
						<b>goodbyeapp.com.</b>
					</p>

					<h2>Third-Party Services and Cookie Policies</h2>
					<p>
						We work with third-party services that may install, manage, or
						access cookies on our site or app. These services help us improve
						functionality, analyze site usage, and provide personalized content.
						Below is a list of third-party services along with links to their
						respective cookie policies and opt-out forms (where available):
					</p>

					<ol className='text'>
						<li className='mgb'>
							<b>Firebase (Google LLC)</b>
							<ul>
								<li>
									Privacy Policy:{' '}
									<a
										href='https://policies.google.com/technologies/cookies?hl=en-US'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold breakWord'
									>
										https://firebase.google.com/support/privacy
									</a>
								</li>
								{/* <li>Opt-Out:</li> */}
							</ul>
						</li>
						<li className='mgb'>
							<b>Google Cloud Platform (Google LLC)</b>
							<ul>
								<li>
									Cookie Policy:{' '}
									<a
										href='https://policies.google.com/technologies/cookies?hl=en-US'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold breakWord'
									>
										https://policies.google.com/technologies/cookies?hl=en-US
									</a>
								</li>
								{/* <li>Opt-Out:</li> */}
							</ul>
						</li>
					</ol>
					<p>
						Please note that these links will direct you to the cookie policies
						and opt-out forms of the respective third-party services. You can
						review their policies to understand how they use cookies and manage
						your preferences regarding data collection.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CookieUse;
