import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Avatar } from '@mui/material';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { useTranslation } from 'react-i18next';
export default function Website() {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();

	return (
		<div>
			<Helmet>
				<title>Website - Goodbye App</title>
			</Helmet>
			<div className='skyBackground'>
				<div className='pd2Media'>
					<div className={`card pd2Media ${mode === 'dark' && 'dbc'} `}>
						<Avatar id='cardMainIcon' className='flexAuto bold'>
							<AdsClickIcon id='cardMainIconSize' />
						</Avatar>
						<h1 className='title mg0 center'>{t('website')}</h1>
						<h3 className='subtitle center mg0'>{t('website_subtitle')}</h3>
						<h2>{t('accessibility')}</h2>
						<p>{t('accessibility_desc')}</p>
						<p>{t('accessibility_desc2')}</p>
						<h2>{t('rapid_deployment')}</h2>
						<p>{t('rapid_deplotment_desc')}</p>
						<h2>{t('scalability_and_seo_benefits')}</h2>
						<p>{t('scalability_and_seo_benefits_desc')}</p>
						<p className='mgtOnly'>{t('scalability_and_seo_benefits_desc2')}</p>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
