import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function FairUse() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Fair Use Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Fair Use Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						In the United States, and in some other countries under the concept
						of fair dealing, certain uses of copyrighted material may not
						necessitate permission from the copyright owner. Fair use is a legal
						doctrine that permits the use of copyrighted material in certain
						circumstances without authorization.
					</p>
					<p>
						Determining whether a specific use qualifies as fair use is
						typically decided by a court of law. Courts evaluate fair use
						arguments based on four key factors:
					</p>

					<ol className='text'>
						<li>
							<b>The purpose and nature of the use:</b> Is the use
							transformative, adding new meaning or context to the original
							work, such as through commentary, criticism, or educational
							content? Non-commercial, transformative uses are more likely to be
							considered fair use.
						</li>
						<li>
							<b>The nature of the copyrighted work:</b> Is the work factual or
							fictional? Use of factual works leans towards fair use.
						</li>
						<li>
							<b>The amount and substantiality of the portion used:</b> Copying
							small excerpts is more likely to be deemed fair use compared to
							copying substantial portions or the entire work.
						</li>
						<li>
							<b>The effect on the market value of the original work:</b> Will
							the use diminish the market value of the copyrighted work by
							acting as a substitute? If so, fair use is less likely to apply.
						</li>
					</ol>

					<p>
						Fair use determinations are context-specific, and there is no fixed
						formula to determine fairness. If uncertain about whether a
						particular use qualifies as fair use, seeking legal advice is
						recommended. Please note that{' '}
						<span className='notranslate'> Goodbye App </span> cannot provide
						guidance on whether your use constitutes fair use or not.
					</p>

					<p className='mg0 bold'>
						Learn more at:{' '}
						<a
							href='http://en.wikipedia.org/wiki/Fair_use'
							target='_blank'
							rel='noopener noreferrer'
							className='link underlineHover bold breakWord'
						>
							http://en.wikipedia.org/wiki/Fair_use
						</a>
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default FairUse;
