import { Fragment, useState } from 'react';
import { Dialog, DialogContent, Popover, Toolbar } from '@mui/material';
import CustomButton from '../SocialComponents/CustomButton';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import InfoIcon from '@mui/icons-material/Info';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useTranslation } from 'react-i18next';
export default function CustomPopover({
	content,
	dialog,
	title,
	tooltip,
	className,
	tips,
}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const { t } = useTranslation();
	const handleClick = (event) => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Fragment>
			<CustomButton
				iconButton
				ariaDescribedby={id}
				onClick={handleClick}
				startIcon={
					tips ? <LightbulbIcon className='iconYellow' /> : <InfoIcon />
				}
				tooltip={tooltip ? tooltip : t('info')}
				className={className}
				placement='right'
				margin='0'
			/>

			{dialog ? (
				<Dialog
					open={open}
					onClose={handleClose}
					// disableScrollLock
					disableEnforceFocus
				>
					<Toolbar id='popoverDialogBar'>
						<div className='fullWidth'>
							<CustomAlerts
								info={true}
								icon={tips ? <LightbulbIcon /> : <InfoIcon />}
								message={title ? title : t('info')}
								close
								onClose={handleClose}
								noMargin
							/>
						</div>
					</Toolbar>
					<DialogContent className='pd0Dialog'>{content}</DialogContent>
				</Dialog>
			) : (
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					{content}
				</Popover>
			)}
		</Fragment>
	);
}
