import { createContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMode } from './redux/actions/dataActions';
import { blue } from '@mui/material/colors';

export const ColorModeContext = createContext({
	toggleColorMode: () => {},
});

const ColorModeProvider = ({ children }) => {
	let storageColorMode = localStorage.getItem('colorMode');
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const prefers = storageColorMode
		? storageColorMode
		: prefersDarkMode
		? 'dark'
		: 'light';
	const [prefersMode, setPrefersMode] = useState(prefers);
	const mode = useSelector((state) => state.UI.mode);
	const dispatch = useDispatch();
	const colorMode = useMemo(
		() => ({
			prefersMode, // Include prefersMode in the context value
			toggleColorMode: () => {
				setPrefersMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
			},
		}),
		[prefersMode],
	);

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: prefersMode,
					primary: {
						light: blue[300],
						main: blue[500],
						dark: blue[700],
						darker: blue[900],
					},
					yellow: {
						main: '#FFFF00',
						light: '#ffb74d',
						dark: '#FFD600',
						contrastText: '#FFAB00',
					},
					brown: {
						main: '#8D6E63',
						light: '#A1887F',
						dark: '#795548',
						contrastText: '#6D4C41',
					},
					gray: {
						main: '#b2bec3',
						light: '#bdc3c7',
						dark: '#34495e',
						contrastText: '#636e72',
					},
					violet: {
						main: '#8e44ad',
						light: '#8e44ad',
						dark: '#8e44ad',
						contrastText: '#8e44ad',
					},
				},
			}),
		[prefersMode],
	);

	useEffect(() => {
		// Update prefersMode based on prefersDarkMode when component mounts
		// if (authenticated) {
		setPrefersMode(prefers);
		dispatch(toggleMode(prefers));
		// } else {
		// 	setPrefersMode('light');
		// 	dispatch(toggleMode('light'));
		// }
	}, [prefersDarkMode]);

	useEffect(() => {
		if (mode !== '' && mode !== colorMode.prefersMode) {
			localStorage.setItem('colorMode', mode);
			colorMode.toggleColorMode();
		}
	}, [mode, colorMode]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	);
};

export default ColorModeProvider;
