import { Flip, Slide, ToastContainer, Zoom } from 'react-toastify';
import { logoutAllTabs } from '../../firebase/firebaseInit';
import store from '../../redux/store';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { ColorModeContext } from '../../ColorModeProvider';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Media from 'react-media';
import { CircularProgress } from '@mui/material';
export default function ToastContainers() {
	const { prefersMode } = useContext(ColorModeContext);
	const location = useLocation();

	const openedDialog = useSelector((state) => state.data.openedDialog);

	const restrictedPaths = [
		'/',
		'/donate',
		'/report',
		'/accountActions',
		'/settings',
		'/terms-of-service',
		'/community-guidelines',
		'/data-policy',
		'/contact',
		'/login',
		'/signup',
		'/verification',
		'/gbadmins',
		'/letterAudios',
		'/appeal',
	];
	const firstPath = `/${window.location.pathname.split('/')[1]}`;

	return (
		<div>
			<ToastContainer
				position='top-left'
				autoClose={3000}
				transition={Zoom}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme={prefersMode}
				containerId={'app'}
				enableMultiContainer
				icon={({ type }) => {
					if (type === 'success')
						return <CheckCircleIcon className='iconLightGreen' />;
					if (type === 'error') return <InfoIcon className='iconRed' />;
					if (type === 'info') return <InfoIcon className='iconBlue' />;
					if (type === 'default')
						return (
							<CircularProgress
								sx={{ display: 'flex', marginLeft: '2px' }}
								size={20}
							/>
						);
				}}
			/>
			{!openedDialog &&
				!restrictedPaths.includes(location.pathname) &&
				!restrictedPaths.includes(firstPath) && (
					<div>
						<Media
							queries={{
								isSmall: '(max-width: 604px)',
							}}
						>
							{(matches) => (
								<ToastContainer
									autoClose={false}
									// autoClose={matches.isSmall ? 6000 : 8000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme={prefersMode}
									transition={matches.isSmall ? Flip : Slide}
									enableMultiContainer
									containerId={'A'}
									zIndex={1}
									limit={matches.isSmall ? 1 : 5}
								/>
							)}
						</Media>
						<ToastContainer
							position='bottom-left'
							autoClose={6000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							transition={Slide}
							draggable
							pauseOnHover
							theme={prefersMode}
							enableMultiContainer
							containerId={'B'}
							limit={5}
						/>

						<ToastContainer
							position='bottom-left'
							hideProgressBar={false}
							newestOnTop
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme={prefersMode}
							enableMultiContainer
							containerId={'C'}
						/>

						<ToastContainer
							position='bottom-left'
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme={prefersMode}
							enableMultiContainer
							transition={Flip}
							containerId={'E'}
						/>
						<ToastContainer
							position='bottom-left'
							autoClose={100000000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme={prefersMode}
							enableMultiContainer
							containerId={'F'}
							transition={Flip}
						/>
						<ToastContainer
							position='bottom-left'
							autoClose={6000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme={prefersMode}
							enableMultiContainer
							containerId={'G'}
							transition={Flip}
						/>

						<ToastContainer
							position='bottom-left'
							autoClose={7000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							transition={Slide}
							draggable
							pauseOnHover
							theme={prefersMode}
							enableMultiContainer
							containerId={'S'}
							style={{ zIndex: 1 }}
						/>

						<ToastContainer
							position='bottom-left'
							autoClose={6000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme={prefersMode}
							transition={Zoom}
							enableMultiContainer
							containerId={'W'}
						/>
						<ToastContainer
							position='bottom-left'
							autoClose={6000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme={prefersMode}
							transition={Zoom}
							enableMultiContainer
							containerId={'R'}
							onClick={() => store.dispatch(logoutAllTabs())}
						/>
					</div>
				)}
		</div>
	);
}
