import { IconButton, Skeleton } from '@mui/material';
import CustomButton from '../SocialComponents/CustomButton';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import Media from 'react-media';
import { useTranslation } from 'react-i18next';

function QueryCommentsSkeleton({
	number,
	replies,
	moreComments,
	onlyComments,
}) {
	const { t } = useTranslation();
	const content = (
		<Media
			queries={{
				isMobile: '(max-width: 1064px)',
				smallText: '(max-width: 900px)',
			}}
		>
			{(matches) =>
				Array.from({ length: number }).map((item, index) => (
					<li key={index} id='C_userDetails' className='pdComment'>
						<div className='flex'>
							<Skeleton
								variant='circular'
								id={replies ? 'listReplyImg' : 'listCommentImg'}
								className='mgr blueBoxShadow bottomTranslate7'
								sx={{ alignSelf: 'baseline' }}
							/>

							<div className='flexColumn'>
								<span className='listItemSpan'>
									<Skeleton
										animation='pulse'
										width={matches.isMobile ? 160 : 160}
									/>
								</span>

								<p className='text prewrap mg0 mgr4px'>
									<Skeleton
										animation='wave'
										width={matches.smallText ? 260 : 260}
									/>
								</p>
								{onlyComments ? (
									<div className='mgb'></div>
								) : (
									<div className='leftTranslate78'>
										<CustomButton
											btnText={<span className='capitalize'>{t('reply')}</span>}
											margin='0'
											fontSize='16px'
											padding='0'
											// disabled
										/>
									</div>
								)}
							</div>
						</div>
					</li>
				))
			}
		</Media>
	);

	return (
		<div>
			{!moreComments && !replies && number > 1 ? (
				<div className='orderCommentsIcon mgYH-'>
					<IconButton aria-haspopup='true' className='pointer' disabled>
						<FormatLineSpacingIcon aria-haspopup='true' fontSize='small' />
					</IconButton>
				</div>
			) : (
				<div className={onlyComments ? '' : 'mgb-'}></div>
			)}
			<ul className='ul_list' id='pdt'>
				{content}
			</ul>
		</div>
	);
}

export default QueryCommentsSkeleton;
