import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
function DeleteAccount() {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();
	return (
		<div>
			<Helmet>
				<title>Delete Account - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<NoAccountsIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mg0 capitalize'>
						{t('delete')} {t('account')}
					</h1>
					<h2>Steps:</h2>
					<ol className='text'>
						<li className='mgb'>
							<b>Authenticate:</b> Start by logging into your{' '}
							<span className='notranslate'> Goodbye App </span>
							account.
						</li>
						<li className='mgb'>
							<b>Access Settings:</b> Navigate to the settings panel by clicking
							in the navigation bar the following icon:
							<ul className='mgY'>
								<li className='mgb'>
									Desktop: <SettingsIcon className='bottomTranslate4' />
								</li>
								<li className='mgb'>
									Mobile: <MenuIcon className='bottomTranslate4' />
								</li>
							</ul>
						</li>
						<li className='mgb'>
							Open the <b>"Delete Account"</b> dropdown menu.
						</li>
						<li className='mgb'>
							Click on the <b>"Proceed"</b> button.
						</li>
						<li className='mgb'>
							<b>Security Re-authentication:</b> You will be signed out and
							prompted to log in again using your email and password for
							security reasons.
						</li>
						<li className='mgb'>
							<b>Final Confirmation:</b> After logging in, a{' '}
							<b>"Delete Account"</b> screen will appear with a button to
							proceed with the deletion.
						</li>
						<li className='mgb'>
							<b>Confirm Deletion:</b> Click the button to finalize the account
							deletion process.
						</li>
					</ol>

					<h2>Important Notes</h2>
					<p>
						<b>Data Handling:</b> Upon initiating the deletion, your data will
						be scheduled for removal according to our data retention policies.
						Please refer to our Privacy Policy for more details.
					</p>
					<p>
						<b>Irreversibility:</b> Deleting your account is irreversible.
						Ensure that you have backed up any important information before
						proceeding.
					</p>
					<p>
						<b>Communication:</b> Certain essential communications, such as
						announcements and administrative messages, are considered part of
						the Services and your account, and you may not be able to opt-out of
						them.
					</p>
					<p className='mgtOnly'>
						By following these steps, you can ensure your account is securely
						deleted. If you encounter any issues during this process, our{' '}
						<Link to='/contact' id='link' className='bold'>
							support team
						</Link>{' '}
						is available to assist you.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default DeleteAccount;
