import { Tooltip, Zoom } from '@mui/material';
import { forwardRef } from 'react';

const CustomTooltip = forwardRef(
	({ children, title, placement, arrow }, ref) => {
		return (
			<Tooltip
				ref={ref}
				TransitionComponent={Zoom}
				title={title ? <h2 className='mg0 capitalize'>{title}</h2> : ''}
				placement={placement ? placement : 'bottom'}
				arrow={arrow}
				// followCursor
			>
				{children}
			</Tooltip>
		);
	},
);

export default CustomTooltip;
