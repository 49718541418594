import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Avatar } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withRouter } from '../../redux/withRouter';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import CustomInput from '../../components/SocialComponents/CustomInput';
import CustomButton from '../../components/SocialComponents/CustomButton';
import Footer from '../../components/Footer/Footer';
import { isEmail } from '../../redux/actions/dataActions';
import { clearAuthErrors } from '../../redux/actions/userActions';
import { forgotPassword } from '../../firebase/firebaseInit';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import { t } from 'i18next';

const styles = () => ({});

class ForgotPassword extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			persistEmail: '',
			emailErrors: {},
			alreadySent: false,
			alreadySentTwice: false,
		};
	}

	// eslint disable comment on line 85 to remove no-useless-escape console warning...
	testEmail = () => {
		let isEmailBool = isEmail(this.state.email);

		if (this.state.email.trim() === '') {
			this.setState({
				emailErrors: {
					email: t('email_error_enter_address'),
				},
			});
		} else if (!isEmailBool) {
			this.setState({
				emailErrors: {
					email: t('email_error_invalid_address'),
				},
			});
		} else {
			this.setState({
				emailErrors: { email: '' },
			});
			return true;
		}
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({
			alreadySent: false,
			alreadySentTwice: false,
		});

		if (this.testEmail()) {
			//valid email?
			const userRecoveryData = {
				email: this.state.email,
			};
			// checking if the user had already submitted the password recovery email to his account.
			// making a copy of email on state to later on review possible differences.
			if (
				userRecoveryData.email !== this.state.persistEmail ||
				!this.state.alreadySentTwice
			) {
				this.props.forgotPassword(userRecoveryData);
				this.setState({
					persistEmail: this.state.email,
				});

				if (this.state.alreadySent) {
					this.setState({
						alreadySent: true,
						alreadySentTwice: true,
					});
				}
			}

			this.setState({
				alreadySent: true,
			});
		} else return;
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value.trim(),
		});
	};

	componentWillUnmount() {
		this.props.clearAuthErrors();
	}

	render() {
		const {
			user: {
				sendingForgotPasswordEmail,
				errorSendingForgotPasswordEmail,
				forgotPasswordEmailSuccess,
			},
			UI: { onlineStatus, mode },
		} = this.props;

		const { email, emailErrors, alreadySent, alreadySentTwice } = this.state;
		return (
			<React.Fragment>
				<Helmet>
					<title>Forgot Password - Goodbye App</title>
				</Helmet>
				<div className='skyBackground pd2Media'>
					<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
						<div>
							<Avatar id='cardMainIcon' className='flexAuto'>
								<PasswordOutlinedIcon id='cardMainIconSize' />
							</Avatar>
							<h1 className='title center mg0'>{t('forgot_password_title')}</h1>
							<h3 className='subtitle center mgbOnly'>
								{t('forgot_password_subtitle')}
							</h3>

							<form noValidate onSubmit={this.handleSubmit}>
								{/* wrong credential */}
								<CustomAlerts
									alertId='smallMuiAlert'
									margin='0.5rem 0'
									verifying={sendingForgotPasswordEmail}
									message={t('sending_email_link')}
								/>

								<CustomAlerts
									alertId='smallMuiAlert'
									margin='0.5rem 0'
									error={errorSendingForgotPasswordEmail}
								/>
								<CustomAlerts
									alertId='smallMuiAlert'
									margin='0.5rem 0'
									success={forgotPasswordEmailSuccess}
								/>
								<CustomAlerts
									alertId='smallMuiAlert'
									info={forgotPasswordEmailSuccess}
									message={t('new_email_might_be_in_junk_or_spam')}
									margin='0.5rem 0'
									icon={<MailIcon />}
								/>
								<CustomAlerts
									alertId='smallMuiAlert'
									margin='0.5rem 0'
									verifying={
										forgotPasswordEmailSuccess !== null &&
										(alreadySent || alreadySentTwice)
									}
									severity='warning'
									message={t('sent_too_many')}
								/>

								<CustomInput
									variant='outlined'
									autoFocus={true}
									type='email'
									titleLabel={t('email')}
									placeholder={t('type_here')}
									name='email'
									inputId='email'
									onChange={this.handleChange}
									helperText={emailErrors.email && emailErrors.email}
									inputError={
										emailErrors.email || errorSendingForgotPasswordEmail
											? true
											: false
									}
									inputValue={email}
									inputAdornmentPosition='end'
									autoComplete='email'
									required={true}
									fullWidth={true}
									deletable={() => this.setState({ email: '' })}
									isEmail={isEmail(email)}
									disabled={sendingForgotPasswordEmail}
								/>
								<CustomButton
									type='submit'
									variant='contained'
									disabled={!onlineStatus || alreadySentTwice}
									loading={sendingForgotPasswordEmail}
									btnText={alreadySent ? t('resend') : t('send')}
									fullWidth
									id={
										!onlineStatus || alreadySentTwice
											? ''
											: 'customSuccessButton'
									}
									margin='1.3rem 0 1rem 0'
									startIcon={<SendIcon />}
								/>
								<hr />
								<Grid container className='center '>
									<Grid item xs={6} sm={6} md={6}>
										<Link
											to='/login'
											id='link'
											className='text bold capitalize'
										>
											{t('login')}
										</Link>
									</Grid>
									<Grid item xs={6} sm={6} md={6}>
										<Link
											to='/signup'
											id='link'
											className='text bold capitalize'
										>
											{t('signup')}
										</Link>
									</Grid>
								</Grid>
							</form>
						</div>
					</div>
				</div>

				<Footer />
			</React.Fragment>
		);
	}
}
ForgotPassword.propTypes = {
	forgotPassword: PropTypes.func.isRequired,
	clearAuthErrors: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	// UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
});

const mapActionsToProps = {
	forgotPassword,
	clearAuthErrors,
};
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(withStyles(styles)(ForgotPassword)));
// export default useLocation(withStyles(styles)(Login));
