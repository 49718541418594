import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { Component } from 'react';
const iOSBoxShadow =
	'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(({ theme, top }) => ({
	color: theme.palette.mode === 'dark' ? '#0a84ff' : '#007bff',
	height: 5,
	padding: '15px 0',
	'& .MuiSlider-thumb': {
		height: 20,
		width: 20,
		backgroundColor: '#fff',
		boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
		'&:focus, &:hover, &.Mui-active': {
			boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				boxShadow: iOSBoxShadow,
			},
		},
		'&:before': {
			boxShadow:
				'0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
		},
	},
	'& .MuiSlider-valueLabel': {
		fontSize: 12,
		fontWeight: 'bold',
		top: top,
		backgroundColor: 'unset',
		color: theme.palette.text.primary,
		'&::before': {
			display: 'none',
		},
		'& *': {
			background: 'transparent',
			color: theme.palette.mode === 'dark' ? '#fff' : '#fff',
		},
	},
	'& .MuiSlider-track': {
		border: 'none',
		height: 2,
	},
	'& .MuiSlider-rail': {
		opacity: 0.5,
		boxShadow: 'inset 0px 0px 4px -2px #000',
		height: 2,
		backgroundColor: '#bfbfbf',
	},
	'& .MuiSlider-mark': {
		backgroundColor: '#bfbfbf',
		height: 8,
		width: 1,
	},
	'& .MuiSlider-markActive': {
		opacity: 1,
		backgroundColor: 'currentColor',
	},
}));

class IOSlider extends Component {
	render(props) {
		return <IOSSlider aria-label='ios slider' {...this.props} />;
	}
}
export default IOSlider;
