import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Download() {
	const { t } = useTranslation();
	const mode = useSelector((state) => state.UI.mode);

	return (
		<div>
			<Helmet>
				<title>Download - Goodbye App</title>
			</Helmet>
			<div className='skyBackground'>
				<div className='pd2Media'>
					<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'} `}>
						<Avatar id='cardMainIcon' className='flexAuto'>
							<AppShortcutIcon id='cardMainIconSize' />
						</Avatar>
						<h1 className='title mg0 center'>{t('download')}</h1>
						<h3 className='subtitle center mg0'>{t('download_subtitle')}</h3>

						<p>{t('download_desc_1')}</p>
						<p>{t('download_desc_2')}</p>
						<p>{t('download_desc_3')}</p>
						<p>{t('download_desc_4')}</p>
						<hr />
						<hr className='mgb' />
						<CustomAlerts
							info={t('download_final_message')}
							icon={<AllInclusiveIcon />}
							margin='0'
						/>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
