import { passwordStrength } from 'check-password-strength';
import Progress from './Progress';
import { useTranslation } from 'react-i18next';
import PasswordIcon from '@mui/icons-material/Password';

export default function PasswordStrength({ password }) {
	const { t } = useTranslation();

	const passStrength = (type) => {
		const strength = passwordStrength(password, [
			{
				id: 0,
				value: t('password_strength_weak'),
				minDiversity: 0,
				minLength: 0,
			},
			{
				id: 1,
				value: t('password_strength_medium'),
				minDiversity: 2,
				minLength: 4,
			},
			{
				id: 2,
				value: t('password_strength_strong'),
				minDiversity: 3,
				minLength: 6,
			},
			{
				id: 3,
				value: t('password_strength_very_strong'),
				minDiversity: 4,
				minLength: 10,
			},
		]);
		const strengthProgress =
			password.length === 0
				? 0
				: password.length <= 3
				? 10
				: strength.id === 0
				? 25
				: strength.id === 1
				? 50
				: strength.id === 2
				? 75
				: 100;

		const severity =
			strength.id === 0
				? 'error'
				: strength.id === 1
				? 'warning'
				: strength.id === 2
				? 'info'
				: 'success';

		if (type === 'progress') {
			return strengthProgress;
		} else if (type === 'severity') {
			return severity;
		} else {
			return strength.value;
		}
	};
	return (
		<Progress
			title={t('password_strength')}
			variant='determinate'
			progress={passStrength('progress')}
			progressText={passStrength()}
			severity={passStrength('severity')}
			icon={<PasswordIcon />}
		/>
	);
}
