import { Skeleton } from '@mui/material';

const ReportSkeleton = (props) => {
	const content = Array.from({ length: props.number }).map((item, index) => (
		<div className='cardReport dbc mgY' key={index}>
			<Skeleton animation='pulse' variant='rounded' width='100%' height={340} />
		</div>
	));

	return <div className='mg'>{content}</div>;
};

export default ReportSkeleton;
