import logo from '../../util/Images/logo.svg';
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useTranslation } from 'react-i18next';
export default function Slogan() {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();
	return (
		<div>
			<Helmet>
				<title>Slogan - Goodbye App</title>
			</Helmet>
			<div className='skyBackground'>
				<div className='pd2Media'>
					<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'} `}>
						<div className='flexColumn alignItemsCenter'>
							<div className='backgroundHomeLogo mgY'>
								<img className='homeLogo' src={logo} alt='Logo' />
							</div>
						</div>
						<h3 className='slogan GBA center notranslate'>
							Every person has a legacy™
						</h3>
						<h2>{t('our_slogan')}</h2>
						<p>{t('our_slogan_desc')}</p>
						<h2>{t('celebrating_individuality')}</h2>
						<p>{t('celebrating_individuality_desc')}</p>
						<h2>{t('motivating_instrospection')}</h2>
						<p>{t('motivating_instrospection_desc')}</p>

						<h2>{t('empowering_change')}</h2>
						<p>{t('empowering_change_desc')}</p>
						<h2>{t('recognizing')}</h2>
						<p>{t('recognizing_desc')}</p>
						<hr />
						<hr className='mgb' />
						<CustomAlerts
							info={t('our_slogan_final_message')}
							icon={<AllInclusiveIcon />}
							margin='0'
						/>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
