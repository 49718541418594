import {
	Avatar,
	Radio,
	Dialog,
	FormControlLabel,
	Grid,
	Toolbar,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import ForumIcon from '@mui/icons-material/Forum';
import BlurOnRoundedIcon from '@mui/icons-material/BlurOnRounded';
import {
	clearReportData,
	dim,
	reportSocialIssue,
	setOpenedDialog,
} from '../../redux/actions/dataActions';
import { connect } from 'react-redux';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import CustomButton from '../SocialComponents/CustomButton';
import SendIcon from '@mui/icons-material/Send';
import CustomInput from '../SocialComponents/CustomInput';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SecurityIcon from '@mui/icons-material/Security';
import { t } from 'i18next';

class SocialReport extends Component {
	state = {
		minorError: false,
		mediumError: true,
		majorError: false,
		body: '',
		reportIssueError: '',
		title: '',
		titleErrors: '',
		email: '',
		emailErrors: '',
		userReport1: false,
		userReport2: false,
		userReport3: false,
		userReport4: false,
		userReport5: false,
		userReport6: false,
		userReport7: false,
		userReport8: false,
		userReport9: false,
		userReport10: false,
		userReport11: false,
	};

	focus = (id) => {
		document.getElementById(id).focus();
	};

	blur = (id) => {
		document.getElementById(id).blur();
	};

	handleChecked = (event) => {
		this.setState({
			userReport1: false,
			userReport2: false,
			userReport3: false,
			userReport4: false,
			userReport5: false,
			userReport6: false,
			userReport7: false,
			userReport8: false,
			userReport9: false,
			userReport10: false,
			userReport11: false,
			[event.target.name]: true,
			title: event.target.name,
			reportIssueError: '',
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();
		let state = this.state;
		if (
			state.userReport1 === false &&
			state.userReport2 === false &&
			state.userReport3 === false &&
			state.userReport4 === false &&
			state.userReport5 === false &&
			state.userReport6 === false &&
			state.userReport7 === false &&
			state.userReport8 === false &&
			state.userReport9 === false &&
			state.userReport10 === false &&
			state.userReport11 === false
		) {
			this.setState({
				reportIssueError: t('select_one_checkbox'),
			});
			this.dialogContent.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		} else {
			let formData = {
				title: state.title,
				reported: this.props.data.reportData.reportUserId,
			};
			if (state.body !== '') {
				formData.body = state.body.trim();
			}
			if (this.props.data.reportData.postId !== undefined) {
				formData.postId = this.props.data.reportData.postId;
			}
			if (this.props.data.reportData.commentId !== undefined) {
				formData.commentId = this.props.data.reportData.commentId;
			}
			if (this.props.data.reportData.repliedId !== undefined) {
				formData.repliedId = this.props.data.reportData.repliedId;
			}
			this.props.reportSocialIssue(formData, this.props.data.reportData.type);

			if (this.props.data.reportData.type !== 'user') {
				this.setState({
					userReport1: false,
					userReport2: false,
					userReport3: false,
					userReport4: false,
					userReport5: false,
					userReport6: false,
					userReport7: false,
					userReport8: false,
					userReport9: false,
					userReport10: false,
					userReport11: false,
					reportIssueError: '',
					body: '',
				});
			}
		}
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	handleClose = () => {
		this.props.close();
		setTimeout(() => {
			this.setState({
				userReport1: false,
				userReport2: false,
				userReport3: false,
				userReport4: false,
				userReport5: false,
				userReport6: false,
				userReport7: false,
				userReport8: false,
				userReport9: false,
				userReport10: false,
				userReport11: false,
				reportIssueError: '',
				body: '',
			});
			this.props.clearReportData();
			if (this.props.data.openedDialog) {
				this.props.setOpenedDialog(false);
			}
		}, 300);
	};

	render() {
		const {
			body,
			userReport1,
			userReport2,
			userReport3,
			userReport4,
			userReport5,
			userReport6,
			userReport7,
			userReport8,
			userReport9,
			userReport10,
			userReport11,
			reportIssueError,
		} = this.state;
		const {
			user: { credentials },
			data: {
				reportData: { type, reportUserId, postId, commentId, author },
				reportedContent,
				// visitedUserCredentials: { handle },
				reportingSocialIssue,
				reportingSocialIssueSuccess,
				errorReportingSocialIssue,
			},
			UI: { mode },
			open,
		} = this.props;

		const disabled =
			(type === 'user' && credentials.userReportsMadeToday >= 30) ||
			(type === 'post' && credentials.postReportsMadeToday >= 30) ||
			(type === 'comment' && credentials.commentReportsMadeToday >= 30);
		return (
			<Fragment>
				{open && (
					<Helmet>
						<title>Social Report - Goodbye App</title>
					</Helmet>
				)}
				<Dialog
					disableEnforceFocus
					fullScreen
					open={open}
					onClose={this.handleClose}
					id='notDialog'
					style={{ zIndex: '1' }}
				>
					<Toolbar
						id='dialogBar'
						ref={(node) => {
							this.dialogContent = node;
						}}
					>
						<div className='titleToolbar'>{t('report')}</div>
						<CloseIcon
							id='closeDialogButton'
							onClick={this.handleClose}
							aria-label='close'
						/>
					</Toolbar>

					<div id='backgroundAuth' className='pd2Media'>
						<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
							<Avatar id='cardMainIcon' className='flexAuto'>
								{type === 'post' ? (
									<BlurOnRoundedIcon id='cardMainIconSize' />
								) : type === 'comment' ? (
									<ForumIcon id='cardMainIconSize' />
								) : null}
							</Avatar>
							<h1 className='title center mg0 capitalize'>
								{t('report')}
								{/* {' '} */}
								{/* {type === 'user'
									? t('user')
									: type === 'post'
									? t('post')
									: t('comment')} */}
							</h1>
							<h3 className='subtitle center mg0'>
								{t('report_post_subtitle')}
							</h3>

							{disabled && (
								<div>
									<CustomAlerts
										severity='warning'
										info={t('you_have_exceeded_daily_limit_action')}
									/>
									<CustomAlerts
										info={t('security_anti_spam_measures')}
										icon={<SecurityIcon />}
										margin='0'
									/>
								</div>
							)}

							<div style={dim(disabled)}>
								{reportedContent.includes(
									(type === 'user' && reportUserId) ||
										(type === 'post' && postId) ||
										(type === 'comment' && commentId),
								) || errorReportingSocialIssue ? (
									<div>
										{reportingSocialIssueSuccess !== '' ? (
											<CustomAlerts
												success={t('report_success', {
													content:
														type === 'user'
															? t('user')
															: type === 'post'
															? t('post')
															: t('comment'),
												})}
											/>
										) : !errorReportingSocialIssue ? (
											<CustomAlerts success={t('report_received')} />
										) : null}
										<CustomAlerts error={errorReportingSocialIssue} />
										<CustomButton
											variant='contained'
											btnText={t('close')}
											id='customInfoButton'
											onClick={this.handleClose}
											fullWidth
											startIcon={<KeyboardBackspaceIcon />}
										/>
										{type === 'user' && (
											<Grid container className='center mgt2'>
												<Grid item xs={6} sm={6} md={6}>
													<div className='fpass'>
														<Link to='/feed' id='link'>
															<span className='text bold'>{t('home')}</span>
														</Link>
													</div>
												</Grid>
												<Grid item xs={6} sm={6} md={6} id='baseline'>
													<div className='signup'>
														<Link to={`/users/${credentials.handle}`} id='link'>
															<span className='text bold'>{t('profile')}</span>
														</Link>
													</div>
												</Grid>
											</Grid>
										)}
									</div>
								) : (
									<div>
										<form noValidate onSubmit={this.handleSubmit}>
											<CustomAlerts
												error={reportIssueError}
												margin='1rem 0 0 0'
											/>
											<p className='textTitle'>
												{commentId
													? t('comment_author')
													: postId
													? t('post_author')
													: t('account') + ':'}{' '}
												<b>{author}</b>
											</p>
											{/* <p className='textTitle mgtOnly'>{t('report_motive')}</p> */}
											<div className='reportCheckboxes'>
												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport1'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport1}
														/>
													}
													label={t('spam_motive')}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport2'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport2}
														/>
													}
													label={t('not_enjoy_motive')}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport3'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport3}
														/>
													}
													label={t('self_harm_motive')}
													labelPlacement='end'
												/>

												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport4'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport4}
														/>
													}
													label={t('illegal_sale_motive')}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport5'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport5}
														/>
													}
													label={
														type === 'comment'
															? t('sexual_motive')
															: t('nudity_motive')
													}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport6'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport6}
														/>
													}
													label={t('offensive_motive')}
													labelPlacement='end'
												/>

												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport7'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport7}
														/>
													}
													label={t('violence_motive')}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport8'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport8}
														/>
													}
													label={t('bullying_motive')}
													labelPlacement='end'
												/>
												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport9'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport9}
														/>
													}
													label={t('intellectual_motive')}
													labelPlacement='end'
												/>

												<FormControlLabel
													disabled={disabled}
													value='end'
													control={
														<Radio
															onChange={this.handleChecked}
															name='userReport10'
															sx={{
																color: '#2980b9',
															}}
															checked={userReport10}
														/>
													}
													label={t('scam_motive')}
													labelPlacement='end'
												/>
												{type === 'post' && postId && (
													<FormControlLabel
														disabled={disabled}
														value='end'
														control={
															<Radio
																onChange={this.handleChecked}
																name='userReport11'
																sx={{
																	color: '#2980b9',
																}}
																checked={userReport11}
															/>
														}
														label={t('impersonating_motive')}
														labelPlacement='end'
													/>
												)}
											</div>
											<hr />
											<CustomInput
												variant='outlined'
												titleLabel={t('description_optional')}
												placeholder={t('type_here')}
												name='body'
												inputId='body'
												onChange={this.handleChange}
												inputValue={body}
												required
												fullWidth
												helperText={
													body.length >= 500 ? `${body.length}/1500` : ''
												}
												maxLength={1500}
												minRows={3}
												multiline
												disabled={disabled}
											/>
											<CustomButton
												type='submit'
												variant='contained'
												loading={reportingSocialIssue}
												btnText={t('send')}
												id='customInfoButton'
												margin='1.5rem auto 0.2rem auto'
												fullWidth
												startIcon={<SendIcon />}
												disabled={disabled}
											/>
										</form>
									</div>
								)}
							</div>
						</div>
					</div>
				</Dialog>
			</Fragment>
		);
	}
}

SocialReport.propTypes = {
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	clearReportData: PropTypes.func.isRequired,
	reportSocialIssue: PropTypes.func.isRequired,
	setOpenedDialog: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	clearReportData,
	reportSocialIssue,
	setOpenedDialog,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(SocialReport);
