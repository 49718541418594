import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function AlteredContent() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Falsified and Altered Media Policy - Goodbye App</title>
			</Helmet>

			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>
						Falsified and Altered Media Policy
					</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						Sharing synthetic, manipulated, or out-of-context media that could
						deceive or mislead people and potentially cause harm is strictly
						prohibited ("misleading media"). Additionally, we reserve the right
						to label posts containing misleading media to aid users in
						recognizing their authenticity and to offer supplementary context.
					</p>

					<h2>What is in violation of this policy?</h2>
					<p>
						To adhere to our policy concerning misleading media, encompassing
						images, videos, audios, gifs, and URLs hosting relevant content, the
						following conditions are necessary for labeling or removal:
					</p>
					<ol className='text'>
						<li>
							The media should be likely to create widespread confusion on
							public matters, impact public safety, or cause serious harm.
						</li>
						<li>
							The media should exhibit significant and deceptive alteration,
							manipulation, or fabrication.
						</li>
						<li>
							The media must be shared deceptively or with falsified context.
						</li>
					</ol>
					<h2>
						As we assess posts and media for labeling or removal under this
						policy in our continuous efforts to uphold our rules and foster
						healthy and safe conversations, we employ the following criteria:
					</h2>
					<p>
						1. Has the content been significantly and deceptively altered,
						manipulated, or fabricated?
					</p>

					<p>
						For content to be subject to labeling or removal under this policy,
						we must be reasonably convinced that the media have undergone
						significant and deceptive alterations, manipulations, or
						fabrications. Synthetic and manipulated media can take various
						forms, utilizing a wide array of technologies. Key factors we
						consider include:
					</p>

					<ul className='text'>
						<li>
							Whether media depicting real individuals have been fabricated or
							simulated, particularly through the utilization of artificial
							intelligence algorithms.
						</li>
						<li>
							Whether there are any additions, edits, or removals of visual or
							auditory information (like new video frames, overdubbed audio, or
							modified subtitles) that substantially alter the understanding,
							meaning, or context of the media.
						</li>
						<li>
							Whether the media have been extensively edited or post-processed
							in a manner that fundamentally changes their composition,
							sequence, timing, or framing, leading to a distortion of their
							meaning.
						</li>
						<li>
							Whether the media have been enhanced or filtered during creation,
							editing, or post-processing in a way that fundamentally alters the
							understanding, meaning, or context of the content.
						</li>
					</ul>

					<p>
						We do not take action to label or remove media that have undergone
						non-significant edits, such as retouched photos or color-corrected
						videos. To ascertain if media have been significantly and
						deceptively altered or fabricated, we may utilize our own technology
						or rely on reports from partnerships with third parties.
					</p>
					<p>
						In cases where we cannot reliably determine if media have been
						altered or fabricated, we may refrain from taking action to label or
						remove them.
					</p>

					<p>
						2. Is the content presented deceptively or with misleading context?
					</p>

					<p>
						We also evaluate whether the context in which media are shared could
						lead to confusion or implies a deliberate attempt to mislead
						individuals regarding the nature or origin of the content. This
						includes instances where there are false claims about the
						authenticity of the media. We examine the context provided alongside
						media to determine if it offers accurate and factual information.
						Some of the contextual elements we analyze to make this
						determination include:
					</p>

					<ul className='text'>
						<li>
							Whether the context surrounding the identity of the individuals or
							entities depicted in the media is false or misleading.
						</li>
						<li>
							Whether the media are accompanied by false or misleading
							information regarding their source, location, time, or
							authenticity.
						</li>

						<li>
							Whether fictional, inauthentic, or staged media are portrayed as
							real or endorsed as factual events, including reenactments or
							productions presented as genuine occurrences.
						</li>
						<li>
							Whether the media are shared with misrepresentations or false
							quotes, presenting fabricated claims as factual depictions.
						</li>
					</ul>

					<p>
						We do not take action to label or remove media accompanied by
						commentary or opinions that do not mislead or present a false
						context, as outlined above. To determine if media have been shared
						deceptively or with false context, we may utilize our own technology
						or rely on reports from partnerships with third parties. In cases
						where we cannot reliably determine if media have been shared with
						false context, we refrain from labeling or removing the content.
					</p>
					<p>
						2.Content featuring misleading media is subject to removal under
						this policy if it poses a risk of causing serious harm.
					</p>

					<p>We evaluate specific harms such as:</p>

					<ul className='text'>
						<li>
							Threats to privacy or hindrance of free expression and civic
							participation, including stalking, harassment, intimidation, or
							voter suppression
						</li>
						<li>
							Encouragement of abusive behavior towards individuals or groups
						</li>
						<li>Potential for mass violence or widespread civil unrest</li>
						<li>
							Interference with public services, protection efforts, or
							emergency response
						</li>

						<li>Physical threats to individuals or groups</li>
					</ul>

					<p>
						We also assess the timeframe in which the content could impact
						public safety or cause serious harm. Immediate harm is a decisive
						factor for removal under this policy.
					</p>
					<p>
						Content containing misleading media that doesn't pose an immediate
						threat but still carries the potential to impact public safety,
						cause harm, or create confusion on public issues like health,
						environment, safety, human rights, immigration, and social-political
						stability, may be labeled to provide additional context and reduce
						its spread.
					</p>
					<p>
						Although we have other rules addressing similar harms, such as
						policies on violent threats, civic integrity, and hateful conduct,
						we prioritize removal in borderline cases involving misleading media
						that might not otherwise violate existing rules.
					</p>

					<h2>What Doesn't Violate This Policy?</h2>
					<p>
						We aim to safeguard public discourse on diverse topics, often
						accompanied by media that foster ongoing discussions. In the absence
						of other policy breaches, the following generally do not contravene
						this policy:
					</p>

					<ol className='text'>
						<li>
							Commentary, reviews, opinions, or reactions shared alongside media
							edits that solely add commentary, reviews, opinions, or reactions.
							This facilitates further debate and discourse on various topics
							and is not considered a violation of this policy.
						</li>
						<li>
							Animations, illustrations, and cartoons, provided they do not
							significantly confuse viewers about the authenticity of the media.
						</li>
						<li>
							Counterspeech. We permit direct responses to misleading
							information that aim to mitigate its impact by rectifying the
							record, amplifying credible information, and educating the broader
							community about the prevalence and dynamics of misleading
							information.
						</li>
						<li>
							Memes or satire, as long as they do not significantly confuse
							viewers about the authenticity of the media.
						</li>
					</ol>

					<p className='textTitle bold mg0'>
						Consequences of Policy Violations:
					</p>
					<p>
						For severe violations of this policy, particularly involving
						misleading media that pose a significant risk of harm to individuals
						or communities, we mandate the removal of such content.
					</p>
					<p>
						In certain instances, immediate and permanent account suspension may
						be implemented for policy violations. For most breaches, temporary
						account lockouts may precede further actions. Rarely, violative
						content may have its reach restricted on the{' '}
						<span className='notranslate'> Goodbye App </span> to reduce
						visibility.
					</p>
					<p>
						Persistent violations, even after a warning, may result in permanent
						account suspension. We acknowledge that discussions regarding
						individuals credibly accused of severe violence may elicit outrage
						and associated violent speech. In such limited cases, less punitive
						measures may be taken.
					</p>
					<p className='mg0 bold'>
						Ensuring the removal of high-severity violations involving
						misleading media is crucial to maintaining a safe and healthy online
						environment. By taking swift action to eliminate content that poses
						serious risks to individuals or communities, we uphold the integrity
						of our platform and protect users from potential harm. This
						commitment underscores our dedication to fostering responsible
						online interactions and promoting a positive digital experience for
						all.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AlteredContent;
