import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editUserDetails } from '../../redux/actions/userActions';
import { uploadProfileImage } from '../../redux/actions/userActions';
import { resetImage } from '../../redux/actions/userActions';
import { Dialog, DialogContent, Slide } from '@mui/material';
import { Grow, ListItemButton, Toolbar } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import LocationOn from '@mui/icons-material/LocationOn';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfirmationDialog from '../SocialComponents/ConfirmationDialog';
import { ImageEditor } from '../ImageEditor/ImageEditor';
import CustomAlerts from '../SocialComponents/CustomAlerts';
import { HexColorPicker } from 'react-colorful';
import { FileDrop } from 'react-file-drop';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CustomInput from '../SocialComponents/CustomInput';
import CustomButton from '../SocialComponents/CustomButton';
import ImgSrc from '../SocialComponents/ImgSrc';
import heic2any from 'heic2any';
import { noImgUrl, setOpenedDialog } from '../../redux/actions/dataActions';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CreateIcon from '@mui/icons-material/Create';
import Media from 'react-media';
import { toast } from 'react-toastify';
import { withRouter } from '../../redux/withRouter';
import CameraPermission from '../SocialComponents/CameraPermission';
import BackdropUi from '../SocialComponents/BackdropUi';
import { t } from 'i18next';

const TransitionLeft = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='left' ref={ref} {...props} />;
});
const defaultImg = noImgUrl();
class EditDetails extends Component {
	state = {
		name: '',
		biography: '',
		quote: '',
		location: '',
		open: false,
		imgConfirmationDialog: false,
		detailsConfirmationDialog: false,
		errors: '',
		fileName: '',
		stateFileToUpload: null,
		mimetype: null,
		saveImage: false,
		imageShadowColor: '',
		fileDragOver: false,
		loadingHeic: false,
		imageShadowColorError: false,
		userLimiter: false,
		editedFields: [],
	};

	setColor = (event) => {
		if (this.state.imageShadowColorError) {
			this.setState({ imageShadowColorError: false });
		}
		this.setState({ imageShadowColor: event });
	};

	mapUserDetailsToState = (credentials) => {
		this.setState({
			name: credentials.name ? credentials.name : '',
			biography: credentials.biography ? credentials.biography : '',
			quote: credentials.quote ? credentials.quote : '',
			location: credentials.location ? credentials.location : '',
			imageShadowColor: credentials.imageColor,
		});
	};
	handleOpen = () => {
		this.setState({ open: true });
		this.mapUserDetailsToState(this.props.user.credentials);
		this.props.setOpenedDialog(true);
		this.dialogContent.scrollIntoView({
			behavior: 'instant',
			block: 'start',
		});
	};
	clearFileErrors = () => {
		this.setState({
			errors: '',
		});
	};
	closeDialog = () => {
		const userDetails = this.checkChanges();
		if (Object.keys(userDetails).length > 0) {
			this.setState({
				detailsConfirmationDialog: true,
				editedFields: Object.keys(userDetails),
			});
		} else {
			this.handleClose();
		}
	};
	handleClose = () => {
		this.setState({
			open: false,
			errors: '',
			fileName: '',
			stateFileToUpload: null,
			mimetype: null,
			saveImage: false,
			imgConfirmationDialog: false,
			detailsConfirmationDialog: false,
			userLimiter: false,
			editedFields: [],
		});

		if (this.props.data.openedDialog) {
			this.props.setOpenedDialog(false);
		}
	};

	handleConfirmationDialog = (img) => {
		if (img) {
			this.setState((prevState) => ({
				imgConfirmationDialog: !prevState.imgConfirmationDialog,
			}));
		} else {
			this.setState((prevState) => ({
				detailsConfirmationDialog: !prevState.detailsConfirmationDialog,
			}));
		}
	};
	setDragOver = (bool) => {
		if (this.state.fileDragOver !== bool) {
			this.setState({ fileDragOver: bool });
		}
	};
	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	testImageShadowColor = () => {
		var reg = /^#([0-9a-f]{3}){1,2}$/i;
		if (reg.test(this.state.imageShadowColor)) {
			if (this.state.imageShadowColorError) {
				this.setState({ imageShadowColorError: false });
			}
			return true;
		} else {
			this.setState({ imageShadowColorError: true });
			return false;
		}
	};

	checkChanges = () => {
		let state = this.state;
		let trimedName = state.name.trim();
		let trimedBio = state.biography.trim();
		let trimedQuote = state.quote.trim();
		let trimedLoc = state.location.trim();
		let imageShadowColor = state.imageShadowColor;
		const creds = this.props.user.credentials;

		let currentName = creds.name;
		let currentBio = creds.biography;
		let currentQuote = creds.quote;
		let currentLocation = creds.location;
		let currentImgColor = creds.imageColor;

		const userDetails = {};

		if (trimedName !== currentName) {
			userDetails.name = trimedName;
		}

		if (trimedBio !== currentBio) {
			userDetails.biography = trimedBio;
		}
		if (trimedQuote !== currentQuote) {
			userDetails.quote = trimedQuote;
		}

		if (trimedLoc !== currentLocation) {
			userDetails.location = trimedLoc;
		}

		if (currentImgColor !== imageShadowColor && this.testImageShadowColor()) {
			userDetails.imageColor = imageShadowColor;
		}

		return userDetails;
	};
	handleSubmit = (event) => {
		event.preventDefault();
		const userDetails = this.checkChanges();
		if (Object.keys(userDetails).length > 0) {
			this.props.editUserDetails(userDetails);
		}
		if (this.state.stateFileToUpload !== null) {
			this.setState({ saveImage: true });
		} else {
			this.handleClose();
		}
	};
	changeFileType = (fileName, newExtension) => {
		const parts = fileName.split('.');
		parts.pop(); // Remove the current extension
		parts.push(newExtension); // Add the new extension
		return parts.join('.');
	};
	handleImageChange = async (event, fileList) => {
		event.preventDefault();
		let location = fileList !== undefined ? fileList : event.target.files;
		const file = location[0];
		const mimetype = file.type;

		if (file.size > '15728640') {
			this.setState({
				errors: t('less_than_15_MB'),
				fileDragOver: false,
			});
		} else {
			if (file.type === 'image/heic') {
				this.setState({ loadingHeic: true });
				try {
					// Convert HEIC to Blob
					const heicBlob = await heic2any({
						blob: file,
						toType: 'image/jpeg',
						// quality: 1, // number, between 0 and 1
					});

					// console.log('Converted File Type:', heicBlob.type);
					const blobUrl = URL.createObjectURL(heicBlob);
					const originalFileName = file.name;
					const newFileName = this.changeFileType(originalFileName, 'jpeg');

					this.setState({
						loadingHeic: false,
						stateFileToUpload: blobUrl,
						fileName: newFileName,
						mimetype: 'image/jpeg',
						errors: '',
						fileDragOver: false,
					});
					event.target.value = '';
					URL.revokeObjectURL(location[0]);
				} catch (error) {
					// console.error('Error converting HEIC to JPEG:', error);
					this.setState({
						errors: t('error_heic'),
						fileDragOver: false,
						loadingHeic: false,
					});
				}
			} else if (
				mimetype === 'image/jpg' ||
				mimetype === 'image/jpeg' ||
				mimetype === 'image/png' ||
				mimetype === 'image/tiff' ||
				mimetype === 'image/webp' ||
				mimetype === 'image/gif' ||
				mimetype === 'image/avif'
			) {
				const blobUrl = URL.createObjectURL(file);

				const testImage = new Image();
				testImage.src = blobUrl;

				testImage.onload = () => {
					this.setState({
						stateFileToUpload: blobUrl,
						fileName: file.name,
						mimetype: file.type,
						errors: '',
						fileDragOver: false,
					});
				};

				testImage.onerror = () => {
					this.setState({
						errors: t('file_corrupted'),
						fileDragOver: false,
					});
				};
				event.target.value = '';
				URL.revokeObjectURL(location[0]);
			} else {
				this.setState({
					errors: `${t('unsuported_file')} ${t('suported_files')}`,
					fileDragOver: false,
				});
			}
		}
	};

	handleDeleteFile = () => {
		this.setState({
			fileName: '',
			stateFileToUpload: null,
			mimetype: null,
			saveImage: false,
			errors: '',
			fileDragOver: false,
		});
	};
	openFilePicker = (event) => {
		if (this.props.user.credentials.imagesUploadedToday < 30) {
			event.preventDefault();
			const fileInputDetails = document.getElementById('fileInputDetails');
			fileInputDetails.click();
		} else {
			this.setState({
				userLimiter: true,
			});
			toast.info(
				`${t('you_have_exceeded_daily_limit_action')} ${t(
					'security_anti_spam_measures',
				)}`,
				{
					position: 'bottom-left',
					autoClose: 15000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					toastId: 'imagesUploadedToday',
					icon: <ImageIcon className='iconBlue' />,
				},
			);
		}
	};
	deletePicture = () => {
		let userImg = this.props.user.credentials.imageUrl;
		const defaultImg = noImgUrl();
		if (userImg !== defaultImg) {
			this.props.resetImage({ imageUrl: userImg });
		}
		this.handleClose();
	};

	componentWillReceiveProps(nextProps) {
		if (
			(nextProps.UI.uploadProgress !== null &&
				this.state.stateFileToUpload !== null) ||
			nextProps.user.uploadingUserDetails
		) {
			this.handleClose();
		}
	}
	resetImageColor = () => {
		this.setState({
			imageShadowColor: '#6DCCFA',
		});
	};

	scrollToBottom = () => {
		this.dialogContent.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
		});
	};

	render() {
		const {
			user: {
				credentials: { imageUrl, thumbnail, handle },
			},
			profile,
			expanded,
		} = this.props;
		const {
			errors,
			stateFileToUpload,
			fileName,
			mimetype,
			saveImage,
			imageShadowColor,
			fileDragOver,
			imgConfirmationDialog,
			detailsConfirmationDialog,
			open,
			name,
			biography,
			quote,
			location,
			imageShadowColorError,
			loadingHeic,
			userLimiter,
			editedFields,
		} = this.state;

		const editUserDetails = (
			<Media
				queries={{
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) => (
					<div>
						{expanded ? (
							<CustomButton
								iconButton
								onClick={this.handleOpen}
								startIcon={<CreateIcon fontSize='small' />}
							/>
						) : profile ? (
							<CustomButton
								onClick={this.handleOpen}
								variant='text'
								startIcon={
									<CreateIcon className='topTranslate' fontSize='small' />
								}
								btnText={t('edit')}
								margin={matches.isMobile ? '0 auto' : '0.5rem auto'}
								height='36px'
								id='bold'
							/>
						) : (
							<CustomButton
								onClick={this.handleOpen}
								variant='contained'
								btnText={t('edit')}
								fullWidth
								id='customInfoButton'
								startIcon={<CreateIcon />}
								margin='0'
							/>
						)}

						<Dialog
							TransitionComponent={TransitionLeft}
							open={open}
							onClose={this.closeDialog}
							keepMounted
							disableEnforceFocus
							fullWidth
							maxWidth={!matches.isMobile ? 'xl' : false}
							fullScreen={matches.isMobile}
						>
							<Toolbar id='dialogBar'>
								<div className='titleToolbar'>{t('your_profile')}</div>
								<CloseIcon
									id='closeDialogButton'
									onClick={this.closeDialog}
									aria-label='close'
								/>
							</Toolbar>

							<DialogContent sx={{ padding: 0 }}>
								<div
									ref={(node) => {
										this.dialogContent = node;
									}}
								>
									<DialogContent sx={{ padding: 0 }}>
										{open && matches.isMobile && <CameraPermission />}
										<ConfirmationDialog
											open={detailsConfirmationDialog}
											openFunc={this.handleConfirmationDialog}
											closeFunc={this.handleConfirmationDialog}
											actionBtnText={t('exit_without_saving')}
											customButtonId='customYellowButton'
											disableRipple
											variant='none'
											actionBtnMargin='1rem 0'
											cancelBtnText={t('continue_editing')}
											action={this.handleClose}
											phrase={t('exit')}
											contentTitle={t('all_changes_will_be_lost')}
											startIcon={<LogoutIcon />}
											cancelBtnIcon={<EditIcon />}
											list={
												<div className='pdl'>
													<p className='mgbOnly'>{t('edited_fields')}</p>
													<ul>
														{editedFields.map((field, index) => {
															return (
																<li key={index} className='capitalize text'>
																	{t(field)}
																</li>
															);
														})}
													</ul>
												</div>
											}
										/>
										<form onSubmit={this.handleSubmit} className='pdMedia'>
											<p className='textTopic mgbOnly'>
												<ColorLensOutlinedIcon id='edTitleIcons' />{' '}
												{t('image_shadow_color')}
											</p>

											<div className='flex spaceAround'>
												<div className='flexColumn alignSelfCenter'>
													<div className='flexColumn alignItemsCenter'>
														<HexColorPicker
															color={imageShadowColor}
															onChange={this.setColor}
														/>

														<div className='flex hexColorContent'>
															{imageShadowColor !== '#6DCCFA' && (
																<RotateLeftIcon
																	onClick={this.resetImageColor}
																	id='undoHexIcon'
																/>
															)}

															<CustomInput
																type='text'
																titleLabel={t('hex_color')}
																placeholder={t('type_here')}
																name='imageShadowColor'
																onChange={this.handleChange}
																inputValue={imageShadowColor}
																maxLength={9}
																variant='standard'
																margin={0}
																helperText={
																	imageShadowColorError
																		? t('invalid_color')
																		: ''
																}
																inputError={
																	imageShadowColorError ? true : false
																}
																onBlur={this.testImageShadowColor}
															/>
														</div>
													</div>
												</div>
												<div>
													<ImgSrc
														img={imageUrl}
														thumbnail={thumbnail}
														handle={handle}
														imgColor={imageShadowColor}
														css='imgEditDetails'
														fontSize={matches.isMobile ? '7vw' : '60px'}
														stronger
													/>
													<div
														className={
															imageUrl !== defaultImg
																? 'flex spaceBetween alignCenter'
																: ''
														}
													>
														{imageUrl !== defaultImg && (
															<ConfirmationDialog
																open={imgConfirmationDialog}
																openIconOrString={
																	<DeleteOutlineIcon id='iconButton' />
																}
																idOpenIconOrString='ed_deletePictureButton'
																closeFunc={() =>
																	this.handleConfirmationDialog('img')
																}
																openFunc={() =>
																	this.handleConfirmationDialog('img')
																}
																actionBtnText={t('delete')}
																action={this.deletePicture}
																startIcon={
																	<DeleteOutlineIcon id='iconButton' />
																}
																phrase={t('profile_picture')}
																contentTitle={t('del_image_question')}
																tooltip={t('delete')}
																customButtonId='customDangerButton'
															/>
														)}
														{imageUrl !== defaultImg && (
															<hr style={{ width: '100%', minWidth: '10px' }} />
														)}
														<CustomButton
															onClick={this.openFilePicker}
															variant='contained'
															id={userLimiter ? '' : 'customInfoButton'}
															disabled={userLimiter}
															loading={loadingHeic}
															ariaLabel='image'
															tooltip={t('change_picture')}
															onlyIconBtn={<ImageIcon id='iconButton' />}
														/>
													</div>
												</div>
											</div>
											<p className='textTopic mg0 mgt'>{t('name')}</p>
											<CustomInput
												minRows={1}
												multiline
												maxRows={2}
												type='text'
												placeholder={t('type_here')}
												name='name'
												onChange={this.handleChange}
												inputValue={name}
												maxLength={30}
												variant='standard'
												fullWidth
											/>
											<p className='textTopic mgbH mgt'>
												<LocationOn id='edTitleIcons' />
												{t('location')}
											</p>
											<CustomInput
												placeholder={t('type_here')}
												variant='standard'
												useLocation
												name='location'
												startIconType='location'
												multiline
												fullWidth
												maxLength={100}
												inputValue={location}
												onChange={(e) =>
													this.handleChange(e, 0, 'location', 'assets')
												}
											/>
											<p className='textTopic mg0 mgt'>
												<FingerprintIcon id='edTitleIcons' />
												{t('biography')}
											</p>
											<CustomInput
												minRows={1}
												multiline
												maxRows={3}
												type='text'
												name='biography'
												placeholder={t('type_here')}
												onChange={this.handleChange}
												inputValue={biography}
												maxLength={200}
												variant='standard'
												fullWidth
											/>
											<p className='textTopic mg0 mgt'>
												<FormatQuoteIcon id='edTitleIcons' />
												{t('quote')}
											</p>

											<CustomInput
												minRows={1}
												multiline
												maxRows={3}
												type='text'
												name='quote'
												placeholder={t('type_here')}
												onChange={this.handleChange}
												inputValue={quote}
												maxLength={200}
												variant='standard'
												fullWidth
											/>

											<p className='textTopic mgtOnly'>
												<AccountCircleIcon id='edFolderIcon' />{' '}
												{t('profile_picture')}
											</p>
											<CustomAlerts
												error={errors}
												close
												margin='0.5rem 0 0'
												alertId='smallMuiAlert'
												onClose={this.clearFileErrors}
											/>
										</form>
										<input
											type='file'
											id='fileInputDetails'
											hidden='hidden'
											accept='image/*'
											onChange={this.handleImageChange}
										/>
										{fileName && (
											<Grow
												in={fileName === '' ? false : true}
												style={{ transformOrigin: 'top' }}
												{...(fileName ? { timeout: 800 } : {})}
											>
												<div>
													<CustomAlerts
														info={true}
														severity='success'
														icon={<ImageIcon />}
														message={fileName}
														closeIcon={<DeleteOutlineIcon />}
														onClose={this.handleDeleteFile}
														noMargin
														close
														tooltip={t('delete')}
													/>
												</div>
											</Grow>
										)}
										{stateFileToUpload !== null ? (
											<div>
												<ImageEditor
													fileSrc={stateFileToUpload}
													mimetype={mimetype}
													fileName={fileName}
													component='EditDetails'
													profile={true}
													saveImage={saveImage}
													uploadProfileImage={this.props.uploadProfileImage}
													scrollToBottom={this.scrollToBottom}
												/>
												<ListItemButton
													// just using this component for the focus property
													disableRipple
													disableGutters
													autoFocus={stateFileToUpload ? true : false}
													style={{
														backgroundColor: 'transparent',
														cursor: 'default',
														padding: 0,
													}}
												/>
											</div>
										) : (
											<div className='pdX'>
												<BackdropUi open={loadingHeic} circularProgress={true}>
													<FileDrop
														onDragOver={() => this.setDragOver(true)}
														onDragLeave={() => this.setDragOver(false)}
														onDrop={(fileList, event) =>
															this.handleImageChange(event, fileList)
														}
													>
														<div
															onClick={this.openFilePicker}
															className={`
											pointer ${fileDragOver ? 'dropArea_active' : 'dropArea_inactive'}
										`}
														>
															<span className='file-msg'>
																{loadingHeic
																	? ''
																	: matches.isMobile
																	? t('add_image')
																	: fileDragOver
																	? t('release_to_upload')
																	: t('drag_and_drop_image')}
															</span>
														</div>
													</FileDrop>
												</BackdropUi>
											</div>
										)}
										<div></div>
										<div className='flex spaceAround'>
											<CustomButton
												onClick={this.handleSubmit}
												type='submit'
												variant='contained'
												id='customSuccessButton'
												btnText={t('save_details')}
												startIcon={<SaveAltIcon />}
												margin='2rem'
											/>
										</div>
									</DialogContent>
								</div>
							</DialogContent>
						</Dialog>
					</div>
				)}
			</Media>
		);

		return editUserDetails;
	}
}

EditDetails.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	uploadProfileImage: PropTypes.func.isRequired,
	resetImage: PropTypes.func.isRequired,
	setOpenedDialog: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	data: PropTypes.object.isRequired,
};

const mapActionsToProps = {
	editUserDetails,
	uploadProfileImage,
	resetImage,
	setOpenedDialog,
};
const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
	UI: state.UI,
});

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(EditDetails));
