import { Skeleton } from '@mui/material';
import blankImg from '../../util/Images/emailBlankProfile.png';

function QueryUsersSkeleton({ fulline, number, showHr, hasMore }) {
	const content = Array.from({ length: number }).map((item, index) => (
		<li key={index}>
			<div className='flex center pd'>
				<span className='followList'>
					<div
						style={{
							overflow: 'hidden',
							background: '#fff',
						}}
						id='mediaImgSkeleton'
						className='mgr blueBoxShadow'
					>
						<img
							src={blankImg}
							alt={`default img`}
							style={{
								objectFit: 'cover',
								width: '100%',
								height: '100%',
								transform: 'translate(0, -8px)',
							}}
						/>
					</div>
					<div className='listItemText'>
						<Skeleton animation='wave' width={200} height={25} />
						<div className='mgtH'>
							{fulline ? (
								<Skeleton
									animation='pulse'
									variant='rectangular'
									width={120}
									height={15}
									className='mgbH'
								/>
							) : (
								<Skeleton
									animation='pulse'
									variant='rectangular'
									width='26vw'
									height={25}
									className='mgbH'
								/>
							)}
						</div>
					</div>
				</span>
			</div>
			{showHr && (index !== number - 1 || hasMore) && <hr className='mg0' />}
		</li>
	));

	return <ul className='ul_list'>{content}</ul>;
}

export default QueryUsersSkeleton;
