import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function RepeatInfringer() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Repeat Infringer Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Repeat Infringer Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						<span className='notranslate'> Goodbye App </span> takes copyright
						infringement seriously and strives to maintain a safe and legal
						environment for all users. To address cases of repeat infringement,
						we have implemented the following policy:
					</p>

					<p className='bold'>Notice of Copyright Infringement:</p>
					<ul className='text'>
						<li>
							When we receive a valid copyright complaint regarding content
							posted by a user, we will notify the user and remove the
							infringing material as per the{' '}
							<b>Digital Millennium Copyright Act (DMCA) regulations.</b>
						</li>
					</ul>
					<p className='bold'>Repeat Infringement:</p>
					<ul className='text'>
						<li>
							If a user's account is flagged for multiple copyright complaints
							or there is evidence of a pattern of repeat infringement, we will
							take the following actions:
							<ol className='text' type='a'>
								<li>
									<b>Account Suspension</b>: We may suspend the user's account
									in accordance with our <u>Repeat Infringer Policy.</u> This
									suspension is intended to prevent further copyright violations
									and protect intellectual property rights.
								</li>
								<li>
									<b>Review Process</b>: Users who are suspended under this
									policy may have the option to file a suspension appeal. The
									appeal will be reviewed by our team, taking into account any
									valid retractions or counter-notices submitted by the user.
								</li>
							</ol>
						</li>
					</ul>
					<p className='bold'>Appeals:</p>
					<ul className='text'>
						<li>
							Users who believe their account was suspended in error or have
							resolved the copyright issues leading to the suspension may submit
							an appeal. Our team will carefully review the appeal and make a
							decision based on the provided information.
						</li>
					</ul>
					<p className='bold'>Education and Compliance:</p>
					<ul className='text'>
						<li>
							We encourage all users to familiarize themselves with copyright
							laws and our platform's guidelines regarding intellectual
							property. Education and compliance with copyright regulations are
							essential to maintaining a positive and lawful community.
						</li>
					</ul>
					<p className='bold'>Final Decision:</p>
					<ul className='text'>
						<li>
							Our platform reserves the right to make the final decision
							regarding account suspensions based on our{' '}
							<u>Repeat Infringer Policy</u> and relevant legal requirements.
						</li>
					</ul>

					<h2>Who can report violations user violations?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>Select a reason.</li>
						<li>Submit your report.</li>
					</ol>
					<p className='bold mg0'>
						By adhering to these guidelines and respecting intellectual property
						rights, we can create a fair and respectful environment for all
						users on <span className='notranslate'> Goodbye App </span>.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default RepeatInfringer;
