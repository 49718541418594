import { useState, useRef, useEffect, FC, useCallback } from 'react';
import cn from 'classnames';
import {
	Cropper,
	CropperRef,
	CropperState,
	CropperPreview,
	CropperPreviewRef,
	VisibleArea,
	RectangleStencil,
	Priority,
	ImageRestriction,
} from 'react-advanced-cropper';
import { AdjustablePreviewBackground } from './components/AdjustablePreviewBackground';
import { Navigation } from './components/Navigation';
import { Slider } from './components/Slider';
import { AdjustableCropperBackground } from './components/AdjustableCropperBackground';
import { Button } from './components/Button';
import { ResetIcon } from './icons/ResetIcon';
import 'react-advanced-cropper/dist/style.css';
import './ImageEditor.scss';
import 'react-advanced-cropper/dist/themes/bubble.css';
import {
	getAbsoluteZoom,
	getZoomFactor,
} from 'advanced-cropper/extensions/absolute-zoom';
import store from '../../redux/store';
import { EDITED_IMG_FILE } from '../../redux/types';
import { customizedTransformImage, resetBasis } from './components/Algorithms';
import Media from 'react-media';
import { BackgroundWrapperWithNotifications } from './components/BackgroundWrapperWithNotifications';

interface Props {
	profile: boolean;
	saveImage: boolean;
	fileSrc: string;
	fileName: string;
	mimetype: string;
	activeStep: number;
	component: string;
	uploadProfileImage: Function;
	scrollToBottom: Function;
}

export const ImageEditor: FC<Props> = ({
	profile,
	saveImage,
	fileSrc,
	fileName,
	mimetype,
	activeStep,
	component,
	uploadProfileImage,
	scrollToBottom,
}) => {
	useEffect(() => {
		if (typeof window !== 'undefined' && typeof document !== 'undefined') {
			// Use a dynamic import for better compatibility
			import('context-filter-polyfill')
				.then(() => {
					console.log('Context filter polyfill loaded successfully.');
				})
				.catch((error) => {
					console.error('Error loading context filter polyfill:', error);
				});
		}
	}, []);
	const cropperRef = useRef<CropperRef>(null);
	const previewRef = useRef<CropperPreviewRef>(null);
	const [src, setSrc] = useState(
		'https://images.unsplash.com/photo-1599140849279-1014532882fe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80',
	);
	const [mode, setMode] = useState(profile ? 'crop' : 'preview');
	const [absoluteZoom, setabsoluteZoom] = useState(0);
	// const [zoomOut, setZoomOut] = useState(false);
	const [cropperSettings, setCropperSettings] = useState<any>({});
	const [cropperState, setCropperState] = useState<any>({});

	const [adjustments, setAdjustments] = useState({
		brightness: 0,
		hue: 0,
		saturation: 0,
		contrast: 0,
	});
	const changed = Object.values(adjustments).some((el) => Math.floor(el * 100));
	const cropperEnabled = mode === 'crop';

	let numberVal = adjustments[mode as keyof typeof adjustments];

	const onChangeValue = (value: number) => {
		if (mode in adjustments) {
			setAdjustments((previousValue) => ({
				...previousValue,
				[mode]: value,
			}));
		}
	};

	const onReset = () => {
		setMode('crop');
		setAdjustments({
			brightness: 0,
			hue: 0,
			saturation: 0,
			contrast: 0,
		});
	};

	useEffect(() => {
		onReset();
		setMode('crop');
		setSrc(fileSrc);
	}, [fileSrc]);

	useEffect(() => {
		if (saveImage) {
			if (cropperRef.current) {
				const canvas = cropperRef.current?.getCanvas({
					height: profile ? 600 : 1080,
					width: profile ? 600 : 1080,
				});
				if (canvas) {
					canvas.toBlob((blob) => {
						if (blob) {
							let file = new File([blob], fileName, {
								type: mimetype,
							});

							if (component === 'EditDetails') {
								const formData = new FormData();
								formData.append('file', file, file.name);
								uploadProfileImage(formData);
							}
							if (component === 'MakePost') {
								let fileSrc = URL.createObjectURL(blob);
								let fileData = { fileToUpload: file, fileSrc: fileSrc };
								store.dispatch({
									type: EDITED_IMG_FILE,
									payload: fileData,
								});
							}
						}
					}, mimetype);
				}
			}
		}
	}, [
		profile,
		saveImage,
		fileSrc,
		fileName,
		mimetype,
		// activeStep,
		component,
		uploadProfileImage,
	]);
	// const onUpload = (blob: string) => {
	// 	onReset();
	// 	setMode('crop');
	// 	setSrc(blob);
	// };

	// const onDownload = () => {
	// 	if (cropperRef.current) {
	// 		const newTab = window.open();
	// 		if (newTab) {
	// 			newTab.document.body.innerHTML = `<img src="${cropperRef.current
	// 				.getCanvas()
	// 				?.toDataURL()}"/>`;
	// 		}
	// 	}
	// };

	const onReady = () => {
		if (scrollToBottom) {
			scrollToBottom();
		}
	};
	const onUpdate = () => {
		// if (profile) {
		// 	previewRef.current?.refresh();
		// }
		if (cropperRef.current) {
			let state = cropperRef.current.getState();
			let settings = cropperRef.current.getSettings();
			setCropperState(state);
			setCropperSettings(settings);
			setabsoluteZoom(getAbsoluteZoom(state, settings));
		}
	};

	const flip = (horizontal: boolean, vertical: boolean) => {
		setMode('crop');
		if (cropperRef.current) {
			cropperRef.current.flipImage(horizontal, vertical);
		}
	};

	const rotate = (angle: number) => {
		setMode('crop');
		if (cropperRef.current) {
			cropperRef.current.rotateImage(angle);
			// if (!zoomOut) {
			// 	setZoomOut(true);
			// }
		}
	};

	const onZoom = useCallback(
		(value: number, transitions?: boolean): void => {
			if (mode !== 'crop') {
				setMode('crop');
			}
			if (cropperRef.current) {
				cropperRef.current.zoomImage(
					getZoomFactor(cropperState, cropperSettings, value),
					{
						transitions: !!transitions,
					},
				);
			}
		},
		[cropperSettings, cropperState, mode],
	);

	// useEffect(() => {
	// 	if (zoomOut) {
	// 		setZoomOut(false);
	// 		onZoom(Math.max(0, absoluteZoom - 1000000), true);
	// 	}
	// }, [zoomOut, absoluteZoom, onZoom]);

	const onZoomIn = () => {
		if (mode !== 'crop') {
			setMode('crop');
		}
		onZoom(Math.min(1, absoluteZoom + 0.15), true);
	};

	const onZoomOut = () => {
		onZoom(Math.max(0, absoluteZoom - 0.25), true);
	};

	const defaultSize = (state: CropperState) => {
		const visibleArea = state.visibleArea as VisibleArea;
		return {
			width: visibleArea.width * 100,
			height: visibleArea.height * 100,
		};
	};

	return (
		<div
			className='image-editor'
			style={{
				display:
					activeStep <= 1 || component === 'EditDetails'
						? 'inline-block'
						: 'none',
			}}
		>
			<div className='image-editor__cropper'>
				<Cropper
					src={src}
					defaultSize={defaultSize}
					priority={Priority.visibleArea}
					ref={cropperRef}
					stencilComponent={RectangleStencil}
					transformImageAlgorithm={customizedTransformImage}
					postProcess={[resetBasis]}
					stencilProps={{
						aspectRatio: profile ? 4 / 5 : true,
						grid: true,
						// movable: cropperEnabled,
						// resizable: cropperEnabled,
						movable: false,
						resizable: false,
						handlers: {},
						lines: {},
						// lines: cropperEnabled,
						// handlers: cropperEnabled,
						rotatable: cropperEnabled,
						previewClassName: profile ? 'preview' : '',
						overlayClassName: cn(
							'image-editor__cropper-overlay',
							!cropperEnabled && 'image-editor__cropper-overlay--faded',
						),
					}}
					backgroundWrapperProps={{
						scaleImage: cropperEnabled,
						moveImage: cropperEnabled,
					}}
					transformImage={{
						adjustStencil: false,
					}}
					imageRestriction={ImageRestriction.stencil}
					backgroundWrapperComponent={BackgroundWrapperWithNotifications}
					backgroundComponent={AdjustableCropperBackground}
					backgroundProps={adjustments}
					onUpdate={onUpdate}
					onReady={onReady}
					className='cropper'
				/>

				{mode !== 'crop' &&
					mode !== 'preview' &&
					mode !== 'flip' &&
					mode !== 'rotate' && (
						<Slider
							className='image-editor__slider'
							value={numberVal}
							onChange={onChangeValue}
						/>
					)}

				<Media
					query='(min-width: 1064px)'
					render={() =>
						profile ? (
							<CropperPreview
								className={'image-editor__preview'}
								ref={previewRef}
								cropper={cropperRef}
								backgroundComponent={AdjustablePreviewBackground}
								backgroundProps={adjustments}
							/>
						) : (
							<div></div>
						)
					}
				/>

				<Button
					className={cn(
						'image-editor__reset-button',
						!changed && 'image-editor__reset-button--hidden',
					)}
					onClick={onReset}
					tooltip='Reset'
				>
					<ResetIcon />
				</Button>
			</div>
			<Navigation
				mode={mode}
				onChange={setMode}
				onFlip={flip}
				onRotate={rotate}
				zoom={absoluteZoom}
				onZoom={onZoom}
				onZoomIn={onZoomIn}
				onZoomOut={onZoomOut}
			/>
		</div>
	);
};
