import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function CivicIntegrity() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Civic Integrity Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Civic Integrity Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						Civic integrity is paramount on our platform, and we strictly
						prohibit the use of{' '}
						<span className='notranslate'> Goodbye App </span> services for any
						activities aimed at manipulating or interfering in elections or
						other civic processes. This encompasses actions such as posting or
						sharing content that could suppress participation in these processes
						or mislead individuals about crucial details like when, where, or
						how to participate. By upholding these guidelines, we aim to foster
						a fair, transparent, and trustworthy environment where civic
						engagement can flourish without undue influence or manipulation.
					</p>
					<p className='bold'>
						A civic process refers to events or procedures mandated and
						conducted by the governing or electoral body of a country, region,
						or locality, which involve public participation to address matters
						of common concern. These processes are fundamental to democratic
						governance and may include activities such as political elections,
						censuses, major referenda, and ballot initiatives. They are crucial
						for shaping policies, making decisions, and representing the
						collective interests of the community or nation.
					</p>
					<h2>What Constitutes a Violation of This Policy?</h2>

					<p>
						You are not allowed to spread false or misleading information about
						how to participate in an election or other civic process. This
						prohibition includes, but is not limited to, spreading
						misinformation about procedures for participating in a civic process
						(such as false claims that voting can be done via Post, text
						message, email, or phone call in jurisdictions where these methods
						are not allowed), providing misleading information about
						participation requirements like identification or citizenship,
						sharing deceptive claims that create confusion about established
						laws, regulations, procedures, or methods of a civic process, and
						spreading false information about the official date or time of a
						civic process.
					</p>
					<p>
						You are not permitted to promote false or misleading information
						that aims to intimidate or discourage people from participating in
						an election or other civic process. This prohibition encompasses
						spreading deceptive claims such as falsely stating that polling
						places are closed or that polling has ended, disseminating
						misleading information regarding votes not being counted, making
						deceptive statements about police or law enforcement activities
						related to voting or collecting census information, and sharing
						misleading information about long lines, equipment issues, or
						disruptions at voting locations during election periods.
					</p>
					<p>
						You may not engage in or promote behaviors that may coerce others to
						refrain from participating in a civic process. This includes, but is
						not limited to: inciting or promoting violent behaviors
						intentionally near a location where an electoral process is being
						conducted, including polling stations and vote counting locations;
						inciting the disruption or destruction of procedures,
						infrastructure, or election equipment that is necessary for someone
						to participate in a civic process; inciting others to harass voters
						or poll workers; promoting the brandishing of firearms near polling
						locations to intimidate voters and election workers; threats
						regarding voting locations or other key places or events (note that
						our{' '}
						<Link to='/violent-speech' id='link' className='bold'>
							Violent Speech
						</Link>{' '}
						policy may also be relevant for threats not covered by this policy).
					</p>
					<p>
						You are prohibited from creating fake accounts that misrepresent
						their affiliation, as well as sharing content that falsely
						represents its association with a candidate, elected official,
						political party, electoral authority, or government entity.
					</p>

					<h2>What Does Not Constitute a Violation of This Policy?</h2>

					<p className='mg0 bold'>Educational or Critical Commentary:</p>
					<ul className='text'>
						<li>Discussion of public polling information.</li>
						<li>
							Voting and audience participation in entertainment purposes like
							competitions, game shows, or other similar events.
						</li>
						<li>
							Using <span className='notranslate'> Goodbye App </span> platform
							pseudonymously or as a parody, commentary, or fan account to
							discuss elections or politics, as long as it does not involve
							manipulation or interference.
						</li>
						<li>
							Inaccurate statements about elected or appointed officials,
							candidates, or political parties, provided they do not involve
							manipulation or interference.
						</li>
						<li>
							Organic content expressing polarizing, biased, hyperpartisan, or
							controversial viewpoints on elections or politics, as long as it
							does not involve manipulation or interference.
						</li>
					</ul>

					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Scam or fraud".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>
					<p className='bold mg0'>
						When assessing the repercussions for violating this policy, we take
						various factors into account. These may encompass the severity of
						the violation, the user's history of infractions, the impact on
						affected parties, and the intent behind the behavior. Consequently,
						penalties may range from the removal of offending posts and comments
						to the suspension of the user's account. Additionally, in certain
						cases, we may implement temporary restrictions on account privileges
						or provide warnings to encourage compliance with our guidelines.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CivicIntegrity;
