import { Component } from 'react';
import PropTypes from 'prop-types';
import Post from '../../components/Post/Post';
import StaticProfile from '../../components/UserFeedProfile/StaticProfile';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import {
	getUserDataAndPosts,
	clearErrorSetUserAndData,
	getProfilePosts,
	getMoreProfilePostsAdmin,
	fetchUserLikedData,
	resetUserLikedData,
	setQueryOrder,
	clearReportData,
	clearFollowers,
	clearFollowing,
	clearUnreleasedPosts,
} from '../../redux/actions/dataActions';
import { Navigate, Outlet } from 'react-router-dom';
import PostSkeleton from '../../components/Skeletons/PostSkeleton';
import {
	CircularProgress,
	LinearProgress,
	Skeleton,
	Tab,
	Tabs,
} from '@mui/material';
import { withRouter } from '../../redux/withRouter';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import UploadFileControls from '../../components/UploadFileActions/UploadFileControls';
import DottedMenu from '../../components/SocialComponents/DottedMenu';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { Helmet } from 'react-helmet-async';
import CustomButton from '../../components/SocialComponents/CustomButton';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import TodayIcon from '@mui/icons-material/Today';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import NotFound from '../NOTFOUND/NotFound';
import Media from 'react-media';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { t } from 'i18next';

class User extends Component {
	state = {
		profile: null,
		screamIdParam: null,
		likesParam: null,
		lastFetchedKey: '',
		postType: 'livePost',
		getUnreleased: false,
	};

	componentDidMount() {
		const historyPostId = this.props.location.state?.historyPostId;
		if (
			historyPostId &&
			this.props.user.credentials.handle === this.props.params.handle
		) {
			const livePost = this.props.location.state?.livePost;

			this.setState({
				postType: livePost ? 'livePost' : 'unreleasedPost',
			});
			if (
				(livePost && this.props.data.userPosts.length > 0) ||
				(!livePost && this.props.data.unreleasedPosts.length > 0)
			) {
				const element = document.getElementById('firstPost');
				setTimeout(() => {
					element.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
						inline: 'start',
					});
				}, 400);
			}
		}

		this.getUserData();
		this.clearFollowGroup(historyPostId);
	}

	clearFollowGroup = (userId) => {
		if (
			this.props.data.followers.length > 0 &&
			this.props.data.followers[0].followedUserId !== userId
		) {
			this.props.clearFollowers();
		}
		if (
			this.props.data.following.length > 0 &&
			this.props.data.following[0].userHandleId !== userId
		) {
			this.props.clearFollowing();
		}
	};

	getUserData = async (reload, limit) => {
		let credentials = this.props.user.credentials;
		let adminPanelActive = this.props.user.adminPanel.adminPanelActive;
		!adminPanelActive && window.scrollTo(0, 0);
		const userHandle = credentials.handle;
		const paramsHandle = this.props.params.handle;
		let unreleasedCount = credentials.unreleasedPostCount;
		let visitedCreds = this.props.data.visitedUserCredentials;
		let data = this.props.data;
		let postCount = credentials.postCount;
		let userPosts = this.props.data.userPosts.length;
		let unreleasedPosts = this.props.data.unreleasedPosts.length;
		const navigationUserId = this.props.adminUserId
			? this.props.adminUserId
			: this.props.location.state?.data;

		if (reload && paramsHandle === userHandle) {
			if (this.state.postType === 'livePost') {
				await this.props.getProfilePosts({
					postType: 'livePost',
					order: 'desc',
					limit: limit,
				});
			} else if (this.state.postType === 'unreleasedPost') {
				await this.props.getProfilePosts({
					userId: credentials.userId,
					postType: 'unreleasedPost',
					order: 'desc',
					limit: limit,
				});
			} else if (this.state.postType === 'likedPostData') {
				let formData = {
					order: 'desc',
					sortBy: 'post',
				};
				await this.props.fetchUserLikedData(formData);
			}
		} else if (
			(userHandle !== undefined &&
				paramsHandle !== userHandle &&
				!adminPanelActive &&
				visitedCreds.handle !== paramsHandle) ||
			(reload && paramsHandle !== userHandle)
		) {
			this.setState({ postType: 'livePost' });

			await this.props.getUserDataAndPosts({
				credentialsHandle: userHandle,
				userId: navigationUserId,
				paramsHandle,
			});
		} else if (
			paramsHandle === userHandle &&
			unreleasedCount > 0 &&
			this.state.postType === 'unreleasedPost'
		) {
			let unreleasedCountLimit = unreleasedCount > 10 ? 10 : unreleasedCount;
			this.setState({ postType: 'unreleasedPost' });
			if (!data.loadingUnreleasedPosts && unreleasedPosts === 0) {
				await this.props.getProfilePosts({
					userId: credentials.userId,
					postType: 'unreleasedPost',
					order: 'desc',
					limit: unreleasedCountLimit,
				});
			}
		} else if (
			userHandle === paramsHandle &&
			unreleasedCount > 0 &&
			this.state.postType === 'livePost' &&
			unreleasedPosts > 0 &&
			userPosts === 0
		) {
			this.setState({
				postType: 'unreleasedPost',
			});
		} else if (
			!adminPanelActive &&
			(userHandle === paramsHandle ||
				paramsHandle === localStorage.getItem('handle')) &&
			(postCount > 0 || postCount === undefined) &&
			userPosts === 0 &&
			this.state.postType === 'livePost'
		) {
			let postCountLimit = postCount > 10 ? 10 : postCount;
			await this.props.getProfilePosts({
				postType: 'livePost',
				order: 'desc',
				limit: postCountLimit,
			});
		} else if (
			userHandle !== paramsHandle &&
			!this.props.adminUserId &&
			paramsHandle !== localStorage.getItem('handle')
		) {
			await this.props.getUserDataAndPosts({
				credentialsHandle: userHandle,
				userId: navigationUserId,
				paramsHandle,
			});
		}
	};

	componentWillReceiveProps(nextProps) {
		let adminPanelActive = this.props.user.adminPanel.adminPanelActive;
		let unreleasedPostCount = this.props.user.credentials.unreleasedPostCount;
		let unreleasedCountUpdated = nextProps.user.credentials.unreleasedPostCount;

		const userHandle = this.props.user.credentials.handle;
		const paramsHandle = this.props.params.handle;
		const newParamsHandle = nextProps.params.handle;
		const navigationUserId = nextProps.location.state?.data;
		let credentials = this.props.user.credentials;
		let visitedCreds = this.props.data.visitedUserCredentials;

		if (
			userHandle === paramsHandle &&
			((this.state.postType !== 'unreleasedPost' &&
				this.props.data.loadingUnreleasedPosts) ||
				nextProps.data.unreleasedPosts.length >
					this.props.data.unreleasedPosts.length)
		) {
			this.setState({
				postType: 'unreleasedPost',
			});
		}

		let postCount = credentials.postCount;
		let postCountUpdated = nextProps.user.credentials.postCount;
		if (postCountUpdated > postCount) {
			this.setState({ postType: 'livePost' });
		}

		if (newParamsHandle !== paramsHandle) {
			this.clearFollowGroup(navigationUserId);
		}
		//INFINITE LOOP B CAREFULL!
		if (
			newParamsHandle !== paramsHandle &&
			!adminPanelActive &&
			newParamsHandle !== visitedCreds.handle &&
			newParamsHandle !== userHandle &&
			this.props.data.errorSettingUserAndPosts === null
		) {
			this.setState({ postType: 'livePost' });

			this.props.getUserDataAndPosts({
				credentialsHandle: userHandle,
				userId: navigationUserId,
				paramsHandle: newParamsHandle,
				userHandle: userHandle,
			});
		}

		if (
			newParamsHandle !== paramsHandle &&
			newParamsHandle === userHandle &&
			postCount > 0 &&
			this.props.data.userPosts.length === 0 &&
			this.state.postType === 'livePost' &&
			(this.props.data.errorSettingUserAndPosts === null ||
				(this.props.data.errorSettingUserAndPosts &&
					newParamsHandle !== visitedCreds.handle))
		) {
			this.props.getProfilePosts({
				postType: 'livePost',
				order: 'desc',
				limit: postCount > 10 ? 10 : postCount,
			});
		}

		if (
			this.props.data.errorSettingUserAndPosts &&
			newParamsHandle !== paramsHandle &&
			!adminPanelActive &&
			newParamsHandle !== visitedCreds.handle
		) {
			this.props.clearErrorSetUserAndData();
		}

		let userCredentialPath =
			paramsHandle === credentials.handle ? credentials : visitedCreds;
		let postsCollection =
			userHandle === paramsHandle && this.state.postType === 'livePost'
				? nextProps.data.userPosts
				: userHandle === paramsHandle &&
				  this.state.postType === 'unreleasedPost'
				? nextProps.data.unreleasedPosts
				: nextProps.data.visitedUserPosts;

		if (
			!nextProps.data.loadingDeletePost.length > 0 &&
			((postsCollection.length === 10 &&
				userCredentialPath.postCount > 10 &&
				!nextProps.data.loadingProfilePosts &&
				this.state.postType === 'livePost') ||
				(paramsHandle === credentials.handle &&
					nextProps.data.unreleasedPosts.length === 10 &&
					userCredentialPath.unreleasedPostCount > 10 &&
					!nextProps.data.loadingProfilePosts &&
					this.state.postType === 'unreleasedPost'))
		) {
			// console.log('Fetching more profile posts.');

			let key =
				userHandle === paramsHandle && this.state.postType === 'unreleasedPost'
					? postsCollection[postsCollection.length - 1]?.createdAt
					: postsCollection[postsCollection.length - 1]?.releaseDate;

			this.fetchMoreUserPosts(
				paramsHandle,
				userHandle,
				key,
				1,
				this.props.user.adminPanel.adminPanelActive,
			);
		}

		if (
			newParamsHandle === paramsHandle &&
			this.state.postType === 'unreleasedPost' &&
			// unreleasedCountUpdated === 0 &&
			// postCountUpdated > 0
			(postCount + 1 === postCountUpdated ||
				(unreleasedCountUpdated === 0 &&
					unreleasedPostCount - 1 === unreleasedCountUpdated))
		) {
			this.handleChangePanel('', 'livePost');
			if (
				unreleasedCountUpdated === 0 &&
				nextProps.data.unreleasedPosts.length > 0
			) {
				this.props.clearUnreleasedPosts();
			}
		}
		if (
			userHandle !== undefined &&
			paramsHandle !== userHandle &&
			!this.props.data.loadingProfile &&
			Object.keys(visitedCreds).length === 0 &&
			this.props.data.errorSettingUserAndPosts === null
		) {
			this.props.getUserDataAndPosts({
				credentialsHandle: userHandle,
				userId: navigationUserId,
				paramsHandle,
				userHandle: '',
				load: undefined,
			});
		}
	}

	fetchMoreUserPosts = (
		paramsHandle,
		userHandle,
		key,
		limit,
		adminPanelActive,
	) => {
		let userId;
		let props = this.props;
		let credentials = props.user.credentials;
		let visitedCreds = props.data.visitedUserCredentials;
		const ownProfile = paramsHandle === userHandle;
		// let userCredentialPath =
		// 	handle === credentials.handle ? credentials : visitedCreds;

		// let moreUnreleasedCount =
		// 	userCredentialPath.unreleasedCount -
		// 	props.data.unreleasedPosts.length;
		// let moreUnreleasedCountLimit =
		// 	moreUnreleasedCount > 5 ? 5 : moreUnreleasedCount;
		// let postCount = userCredentialPath.postCount - props.data.userPosts.length;
		// let postCountLimit = postCount > 5 ? 5 : postCount;

		let postData = {};
		if (!ownProfile) {
			userId = visitedCreds.userId;
			postData.userImage = visitedCreds.imageUrl;
			postData.userImageColor = visitedCreds.imageColor;
			postData.userHandle = visitedCreds.handle;
		}

		if (this.state.lastFetchedKey !== key) {
			this.setState({ lastFetchedKey: key });
			if (adminPanelActive) {
				if (
					this.props.subCollection === 'likedPostData' ||
					this.props.subCollection === 'likedCommentData' ||
					this.props.subCollection === 'likedReplyData'
				) {
					this.props.fetchUserLikedData({
						key: this.props.data.lastLikedDataPostKey,
						userId: this.props.data.visitedUserCredentials.userId,
						order: this.props.user.adminPanel.order,
						sortBy:
							this.props.subCollection === 'likedPostData'
								? 'post'
								: this.props.subCollection === 'likedCommentData'
								? 'comment'
								: 'reply',
						admin: true,
					});
				} else {
					this.props.getMoreProfilePostsAdmin(
						{
							key: key,
							userId: this.props.data.visitedUserCredentials.userId,
							postCollection: this.props.subCollection
								? this.props.subCollection
								: 'posts',
							order: this.props.user.adminPanel.order,
							limit: 10,
						},
						postData,
					);
				}
			} else if (paramsHandle === userHandle) {
				if (
					this.state.postType === 'unreleasedPost' &&
					credentials.unreleasedPostCount > 0
				) {
					this.props.getProfilePosts({
						userId: credentials.userId,
						postType: 'unreleasedPost',
						order: this.props.data.queryOrder.queryUnreleasedPostsOrder,
						limit: limit,
						key: key,
					});
				} else if (
					this.state.postType === 'likedPostData' &&
					this.props.data.likedPostData.length > 0
				) {
					// console.log('KEY', key);
					// console.log(
					// 	'queryLikesOrder',
					// 	this.props.data.queryOrder.queryLikesOrder,
					// );
					this.props.fetchUserLikedData({
						key: key,
						order: this.props.data.queryOrder.queryLikesOrder,
						sortBy: 'post',
					});
				} else if (
					credentials.postCount > 0 &&
					this.state.postType === 'livePost'
				) {
					this.props.getProfilePosts({
						userId: credentials.userId,
						postType: this.state.postType,
						order: this.props.data.queryOrder.queryPostsOrder,
						limit: limit,
						key: key,
					});
				}
			} else {
				this.props.getProfilePosts({
					userId: userId,
					postType: this.state.postType,
					order: ownProfile
						? this.props.data.queryOrder.queryPostsOrder
						: this.props.data.queryOrder.queryVisitedPostsOrder,
					limit: limit,
					key: key,
					postData: postData,
					visitedProfile: !ownProfile,
				});
			}
		}
	};
	handleChangePanel = (event, newValue) => {
		if (
			this.state.postType !== 'livePost' &&
			newValue === 'livePost' &&
			this.props.data.userPosts.length === 0 &&
			!this.props.data.loadingProfilePosts
		) {
			const creds = this.props.user.credentials;
			const paramsHandle = this.props.params.handle;
			const ownProfile = creds.handle === paramsHandle;
			if ((ownProfile && creds.postCount > 0) || !ownProfile) {
				this.props.getProfilePosts({
					postType: 'livePost',
					order: 'desc',
					limit: 10,
				});
			}
		} else if (
			this.state.postType !== 'likedPostData' &&
			newValue === 'likedPostData' &&
			this.props.data.likedPostData.length === 0 &&
			!this.props.data.loadingLikedPostData
		) {
			let formData = {
				order: 'desc',
				sortBy: 'post',
			};
			this.props.setQueryOrder({ queryLikesOrder: 'desc' });

			this.props.fetchUserLikedData(formData);
		} else if (
			this.state.postType !== 'unreleasedPost' &&
			newValue === 'unreleasedPost' &&
			this.props.data.userPosts.length === 0 &&
			!this.props.data.loadingUnreleasedPosts
		) {
			const creds = this.props.user.credentials;
			const paramsHandle = this.props.params.handle;
			const ownProfile = creds.handle === paramsHandle;
			if (ownProfile && creds.unreleasedCount > 0) {
				this.props.getProfilePosts({
					postType: 'unreleasedPost',
					order: 'desc',
					limit: 10,
				});
			}
		}
		this.setState({ postType: newValue });
	};

	handleChangeQueryOrder = (order) => {
		const creds = this.props.user.credentials;
		const data = this.props.data;
		const visitedCreds = data.visitedUserCredentials;
		const handle = this.props.user.adminPanel.adminPanelActive
			? data.visitedUserCredentials.handle
			: this.props.params.handle;
		const ownProfile = creds.handle === handle;

		if (
			ownProfile &&
			this.state.postType === 'likedPostData' &&
			order !== data.queryOrder.queryLikesOrder
		) {
			let formData = {
				order: order,
				sortBy: 'post',
			};
			this.props.fetchUserLikedData(formData);
			if (data.likedPostData.length > 0) {
				this.props.resetUserLikedData();
			}
			this.props.setQueryOrder({ queryLikesOrder: order });
		} else if (
			this.state.postType === 'livePost' &&
			((ownProfile && order !== data.queryOrder.queryPostsOrder) ||
				(!ownProfile && order !== data.queryOrder.queryVisitedPostsOrder))
		) {
			let userObj = ownProfile ? creds : visitedCreds;
			let postCountLimit = userObj.postCount > 10 ? 10 : userObj.postCount;

			this.props.getProfilePosts({
				userId: ownProfile ? creds.userId : visitedCreds.userId,
				postType: 'livePost',
				action: 'resetState',
				order: order,
				limit: postCountLimit,
				visitedProfile: !ownProfile,
			});

			let newOrder = {};
			if (ownProfile) {
				newOrder.queryPostsOrder = order;
			} else {
				newOrder.queryVisitedPostsOrder = order;
			}
			this.props.setQueryOrder(newOrder);
		} else if (
			this.state.postType === 'unreleasedPost' &&
			order !== data.queryOrder.queryUnreleasedPostsOrder
		) {
			let unreleasedCountLimit =
				creds.unreleasedPostCount > 10 ? 10 : creds.unreleasedPostCount;

			this.props.getProfilePosts({
				userId: creds.userId,
				postType: 'unreleasedPost',
				action: 'resetState',
				order: order,
				limit: unreleasedCountLimit,
			});
			this.props.setQueryOrder({ queryUnreleasedPostsOrder: order });
		}
	};

	componentWillUnmount() {
		if (Object.keys(this.props.data.reportData).length > 0) {
			this.props.clearReportData();
		}
		if (this.props.data.errorSettingUserAndPosts) {
			this.props.clearErrorSetUserAndData();
		}
	}

	render() {
		const {
			user: {
				authenticated,
				credentials,
				loadingUser,
				adminPanel: { adminPanelActive },
			},
			data: {
				userPosts,
				visitedUserPosts,
				unreleasedPosts,
				visitedUserCredentials,
				loadingProfile,
				uploadingPost,
				errorUploadingPost,
				uploadingImageProgress,
				uploadingAudioProgress,
				uploadingVideoProgress,
				errorUploadingImage,
				errorUploadingAudio,
				errorUploadingVideo,
				loadingProfilePosts,
				errorLoadingPosts,
				loadingUnreleasedPosts,
				errorSettingUserAndPosts,
				errorSettingUserPosts,
				errorSettingUnreleasedUserPosts,
				editingPostProgress,
				loadingLikedPostData,
				likedPostData,
				errorLoadingLikedPostData,
				queryOrder: {
					queryLikesOrder,
					queryPostsOrder,
					queryVisitedPostsOrder,
					queryUnreleasedPostsOrder,
				},
			},
			UI: { mode },
			subCollection,
		} = this.props;
		const { postType } = this.state;
		const userHandle = credentials.handle;
		const paramsHandle = this.props.params.handle;
		const ownProfile = paramsHandle === userHandle;

		const postsCollection =
			(subCollection === 'likedPostData' && adminPanelActive) ||
			postType === 'likedPostData'
				? likedPostData
				: ownProfile && postType === 'livePost'
				? userPosts
				: ownProfile && postType === 'unreleasedPost'
				? unreleasedPosts
				: visitedUserPosts;

		const postLoader =
			postType === 'likedPostData'
				? loadingLikedPostData
				: postType === 'unreleasedPost'
				? loadingUnreleasedPosts
				: loadingProfilePosts;

		const key =
			ownProfile && postType === 'unreleasedPost'
				? postsCollection[postsCollection.length - 1]?.createdAt
				: ownProfile && postType === 'likedPostData'
				? postsCollection[postsCollection.length - 1]?.key
				: postsCollection[postsCollection.length - 1]?.releaseDate;

		const livePostOrder = ownProfile ? queryPostsOrder : queryVisitedPostsOrder;
		const userCredentialPath = ownProfile
			? credentials
			: visitedUserCredentials;

		const livePostQueryCount =
			userCredentialPath.postCount -
			(postsCollection ? postsCollection.length : 0);

		const livePostSkeletonLimit =
			livePostQueryCount >= 10 ? 10 : livePostQueryCount;

		const unreleasedPostQueryCount =
			userCredentialPath.unreleasedPostCount -
			(unreleasedPosts ? unreleasedPosts.length : 0);

		const unreleasedPostSkeletonLimit =
			unreleasedPostQueryCount >= 10 ? 10 : unreleasedPostQueryCount;

		const showOrderData =
			(postType === 'livePost' && userCredentialPath.postCount > 1) ||
			(ownProfile &&
				((postType === 'unreleasedPost' &&
					userCredentialPath.unreleasedPostCount > 1) ||
					(postType === 'likedPostData' && likedPostData.length > 1)));

		const limit =
			postType === 'livePost'
				? livePostSkeletonLimit
				: postType === 'unreleasedPost'
				? unreleasedPostSkeletonLimit
				: 1;
		const error =
			postType === 'livePost'
				? errorSettingUserPosts
				: postType === 'unreleasedPost'
				? errorSettingUnreleasedUserPosts
				: errorLoadingLikedPostData;

		const postsMarkup =
			subCollection === 'comments' ||
			subCollection === 'removedComments' ||
			(adminPanelActive &&
				Object.keys(visitedUserCredentials).length ===
					0) ? null : postsCollection.length === 0 &&
			  !loadingProfile &&
			  !loadingUser &&
			  !postLoader &&
			  editingPostProgress === null &&
			  paramsHandle !== userHandle ? (
				<CustomAlerts
					info={true}
					message={t('no_posts_yet', {
						handle: userCredentialPath.handle,
					})}
					margin='2rem 0'
				/>
			) : // (loadingProfile && postsCollection.length === 0) ||
			(loadingUnreleasedPosts &&
					unreleasedPosts.length === 0 &&
					postType === 'unreleasedPost') ||
			  (loadingProfile && userCredentialPath.handle !== paramsHandle) ? (
				<PostSkeleton
					number={1}
					darkMode={mode === 'dark'}
					handle={paramsHandle}
				/>
			) : (
				<div>
					<Media
						queries={{
							isMobile: '(max-width: 1064px)',
							verySmall: '(max-width: 380px)',
						}}
					>
						{(matches) => (
							<div
								className={`${
									showOrderData
										? 'flex pdrOrder spaceBetween'
										: 'flex spaceStart'
								} userTabs`}
							>
								<Tabs
									value={postType}
									onChange={this.handleChangePanel}
									centered
									selectionFollowsFocus
								>
									<Tab
										sx={{ minHeight: '30px' }}
										id='tabs'
										value='livePost'
										iconPosition='start'
										icon={<BlurOnIcon fontSize='small' />}
										label={
											<div>
												<span className='mgr4px'>
													{userCredentialPath.postCount}
												</span>
												{matches.isMobile
													? ''
													: userCredentialPath.postCount === 1
													? t('post')
													: t('posts')}
											</div>
										}
									/>

									{credentials.handle === paramsHandle && (
										<Tab
											sx={{ minHeight: '30px' }}
											id='tabs'
											value='unreleasedPost'
											iconPosition='start'
											icon={<TodayIcon fontSize='small' />}
											label={
												<div>
													<span className='mgr4px'>
														{userCredentialPath.unreleasedPostCount}
													</span>
													{matches.isMobile ? '' : t('scheduled')}
												</div>
											}
										/>
									)}
									{credentials.handle === paramsHandle && (
										<Tab
											sx={{ minHeight: '30px' }}
											id='tabs'
											className='notranslate'
											label={matches.verySmall ? '' : t('liked')}
											iconPosition='start'
											icon={<ThumbUpIcon fontSize='small' />}
											value='likedPostData'
										/>
									)}
								</Tabs>

								{showOrderData && (
									<div className='alignSelfCenter'>
										<DottedMenu
											orderData
											comment={false}
											profileOrder
											queryOrder={
												postType === 'livePost'
													? livePostOrder
													: postType === 'unreleasedPost'
													? queryUnreleasedPostsOrder
													: queryLikesOrder
											}
											handleChangeQueryOrder={this.handleChangeQueryOrder}
										/>
									</div>
								)}
							</div>
						)}
					</Media>
					{(postType === 'livePost' &&
						credentials.handle === paramsHandle &&
						userCredentialPath.postCount) === 0 &&
						userPosts.length === 0 && (
							<CustomAlerts
								info={t('content_appear_here', {
									content: t('posts'),
								})}
								icon={<BlurOnIcon />}
							/>
						)}
					{(postType === 'unreleasedPost' &&
						credentials.handle === paramsHandle &&
						userCredentialPath.unreleasedPostCount) === 0 &&
						unreleasedPosts.length === 0 && (
							<CustomAlerts
								info={t('content_appear_here', {
									content: t('scheduled_posts'),
								})}
								icon={<TodayIcon />}
							/>
						)}

					{postLoader && postsCollection.length === 0 && (
						<PostSkeleton
							number={limit}
							darkMode={mode === 'dark'}
							handle={paramsHandle}
						/>
					)}
					<InfiniteScroll
						dataLength={postsCollection.length} //This is important field to render the next data
						scrollThreshold={0.7} // next will be called when user comes below 70% of the total height.
						next={() =>
							this.fetchMoreUserPosts(
								paramsHandle,
								userHandle,
								key,
								limit,
								adminPanelActive,
							)
						}
						hasMore={
							userCredentialPath.postCount - postsCollection.length > 0 ||
							adminPanelActive ||
							(postType === 'livePost' &&
								!loadingProfilePosts &&
								!errorLoadingPosts &&
								!errorSettingUserPosts) ||
							(postType === 'unreleasedPost' &&
								!loadingProfilePosts &&
								!loadingUnreleasedPosts &&
								!errorSettingUnreleasedUserPosts &&
								userCredentialPath.unreleasedPostCount -
									unreleasedPosts.length >
									0) ||
							(postType === 'likedPostData' &&
								!loadingLikedPostData &&
								!errorLoadingLikedPostData)
						}
						loader={
							postLoader && (
								<PostSkeleton
									number={limit}
									darkMode={mode === 'dark'}
									handle={paramsHandle}
								/>
							)
						}
						scrollableTarget={adminPanelActive ? 'scrollableAdminDiv' : ''}
					>
						<div id='firstPost'></div>
						{postsCollection &&
							postsCollection.map((post) => (
								<Post key={post.postId} post={post} />
							))}
						{postLoader && postsCollection.length > 0 && (
							<PostSkeleton
								number={limit}
								darkMode={mode === 'dark'}
								handle={paramsHandle}
							/>
						)}
						{error &&
							((postType === 'livePost' &&
								postsCollection.length !== userCredentialPath.postCount) ||
								(ownProfile &&
									postType === 'unreleasedPost' &&
									postsCollection.length !==
										userCredentialPath.unreleasedPostCount) ||
								postType === 'likedPostData') && (
								<CustomAlerts
									info={error}
									margin={
										postsCollection.length === 0 ? '1rem 0 0 0' : '0 0 2rem 0'
									}
								/>
							)}
					</InfiniteScroll>
				</div>
			);
		return (
			<div>
				{authenticated ? (
					<div className='pdb3'>
						<Helmet>
							<title>
								{userCredentialPath.handle
									? `${
											userCredentialPath.name ? userCredentialPath.name : ''
									  } (@${userCredentialPath.handle})`
									: 'Goodbye App'}
							</title>
						</Helmet>
						{errorSettingUserAndPosts && !adminPanelActive ? (
							<NotFound user404={userHandle} />
						) : (
							<Grid container>
								<PullToRefresh
									onRefresh={() => this.getUserData(true, limit)}
									isPullable={
										!errorSettingUserAndPosts && !loadingProfile && !postLoader
									}
									pullDownThreshold={50}
									resistance={1}
									refreshingContent={
										<div id='circularProgressQuery'>
											<CircularProgress
												variant='indeterminate'
												id='queryUserAuthProgress'
												size={30}
												thickness={3}
											/>
										</div>
									}
									pullingContent={
										<h3 className='center' id='mgt'>
											{t('pull_to_refresh')}
										</h3>
									}
									className='hidden'
								>
									<Grid item sm={12} xs={12}>
										{userHandle === undefined ? (
											<div>
												<LinearProgress />
												<Skeleton
													variant='rectangular'
													width={'100%'}
													height={'100vh'}
													animation='wave'
												/>
											</div>
										) : (
											<StaticProfile
												profile={
													userHandle !== paramsHandle
														? visitedUserCredentials
														: credentials
												}
											/>
										)}
									</Grid>
									{this.props.location.state?.disabled ||
									(userCredentialPath.disabled && !adminPanelActive) ? (
										<CustomAlerts
											error={
												this.props.location.state?.disabled ||
												userCredentialPath.disabled
											}
											message={t('user_disabled')}
											margin='2rem 0'
										/>
									) : (
										<Grid item sm={12} xs={12}>
											{paramsHandle === userHandle && (
												<div
													className={
														uploadingPost ||
														errorUploadingPost ||
														uploadingImageProgress !== null ||
														uploadingAudioProgress !== null ||
														uploadingVideoProgress !== null ||
														errorUploadingImage !== null ||
														errorUploadingAudio !== null ||
														errorUploadingVideo !== null
															? 'center flexColumn uploadProfilePost'
															: 'flex'
													}
												>
													<UploadFileControls />
													<CustomButton
														btnText={t('letter')}
														startIcon={<HistoryEduIcon />}
														variant='contained'
														id='customCancelButton'
														margin='1rem auto'
														onClick={() => this.props.history('/letter')}
													/>
												</div>
											)}
											{postsMarkup}
										</Grid>
									)}
								</PullToRefresh>
							</Grid>
						)}
					</div>
				) : (
					<Navigate to='/login' />
				)}
				<Outlet />
				<div className='navMobileDiv'></div>
			</div>
		);
	}
}

User.propTypes = {
	getMoreProfilePostsAdmin: PropTypes.func.isRequired,
	getUserDataAndPosts: PropTypes.func.isRequired,
	clearErrorSetUserAndData: PropTypes.func.isRequired,
	getProfilePosts: PropTypes.func.isRequired,
	clearReportData: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	errorSettingUserPosts: PropTypes.string,
	errorSettingUnreleasedUserPosts: PropTypes.string,
	errorSettingUserAndPosts: PropTypes.string,
	loadingProfilePosts: PropTypes.bool,
	loadingProfile: PropTypes.bool,
	visitedUserCredentials: PropTypes.object,
	userPosts: PropTypes.array,
	visitedUserPosts: PropTypes.array,
	fetchUserLikedData: PropTypes.func.isRequired,
	resetUserLikedData: PropTypes.func.isRequired,
	setQueryOrder: PropTypes.func.isRequired,
	clearFollowers: PropTypes.func.isRequired,
	clearFollowing: PropTypes.func.isRequired,
	clearUnreleasedPosts: PropTypes.func.isRequired,
};
const mapActionsToProps = {
	getUserDataAndPosts,
	clearErrorSetUserAndData,
	getProfilePosts,
	clearReportData,
	getMoreProfilePostsAdmin,
	fetchUserLikedData,
	resetUserLikedData,
	setQueryOrder,
	clearFollowers,
	clearFollowing,
	clearUnreleasedPosts,
};
const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(withRouter(User));
