import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSelector } from 'react-redux';

function OffensiveBehaviour() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Offensive Behaviour Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Offensive Behaviour Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						At <span className='notranslate'> Goodbye App,</span> we uphold a
						strict policy against targeting individuals based on their race,
						ethnicity, national origin, caste, sexual orientation, gender,
						gender identity, religious beliefs, age, disability, or health
						condition.
					</p>
					<h2>What Constitutes a Violation of This Policy?</h2>

					<p>
						<b>Hateful Profiles:</b> You are not allowed to use hateful images
						or symbols in your profile picture or header, nor should you use
						your username, display name, or profile biography to engage in
						abusive behavior, such as targeted harassment or expressing hate
						towards individuals, groups, or protected categories.
					</p>
					<p>
						<b> Hateful Imagery:</b> We define hateful imagery as any logos,
						symbols, or images designed to incite hostility or malice towards
						individuals based on their race, religion, disability, sexual
						orientation, gender identity, ethnicity, or nationality. Examples of
						hateful imagery include symbols historically associated with hate
						groups like the Nazi swastika, images that depict individuals as
						subhuman or incorporate hateful symbols, and images manipulated to
						include references to mass murders targeting protected categories,
						such as the Holocaust. Hateful imagery is strictly prohibited in
						videos, account bios, and profile images. Any other instances must
						be marked as sensitive media. Furthermore, sending unsolicited
						hateful imagery to individuals is a violation of our policies.
					</p>
					<p>
						<b>Encouraging Harassment:</b> It is forbidden to urge others to
						engage in harassment against individuals belonging to a protected
						category, whether on or off the platform.
					</p>
					<p>
						<b>Encouraging Fear or Stereotypes:</b> It is forbidden to incite
						fear or spread unfounded stereotypes about any protected category.
						This includes making baseless claims that members of a certain group
						are more prone to engaging in dangerous or unlawful activities, such
						as labeling an entire religious group as terrorists.
					</p>

					<p>
						<b>Slurs and Stereotypes:</b> We strictly prohibit the repeated use
						of derogatory slurs, stereotypes, or any content aimed at degrading
						or perpetuating negative and harmful stereotypes about individuals
						from protected categories. In certain cases, such as instances of
						severe and repeated use of slurs or the promotion of racist/sexist
						tropes with the intent to harass or intimidate, we may require the
						removal of such content. For more moderate and isolated cases of
						usage that still aim to harass or intimidate, we may limit the
						visibility of the content.
					</p>
					<p>
						<b>Dehumanizing Content:</b> It is against our policies to
						dehumanize any group of people based on factors such as religion,
						caste, age, disability, serious illness, nationality, race,
						ethnicity, gender, gender identity, or sexual orientation.
					</p>
					<p>
						<b>Encouraging Discrimination:</b> It is prohibited to prompt others
						to discriminate by withholding support from an individual or group's
						economic activities based on their perceived membership in a
						protected category. For example, statements like "If you shop at a
						store owned by [religious group], you are backing those [derogatory
						term], let's refrain from financially supporting these [derogatory
						term]" are not allowed. Please note that this does not encompass
						content of a political nature, including political discourse or
						discussions related to boycotts or protests.
					</p>
					<h2>What Does Not Constitute a Violation of This Policy?</h2>
					<p>
						While our offensive behavior policy is strict, there are certain
						situations or contexts that may not constitute a violation. These
						exceptions are carefully considered to ensure a fair and balanced
						approach to enforcement. Some examples include:
					</p>
					<p className='mg0 bold'>Contextual Use:</p>
					<ul className='text'>
						<li>
							Content that may appear offensive in isolation but is part of a
							larger conversation or artistic expression where the intent is not
							to harm or offend.
						</li>
						<li>
							Consensual and non-abusive use of certain terms or language
							between friends or within specific communities.
						</li>
					</ul>
					<p className='mg0 bold'>Educational or Critical Commentary:</p>
					<ul className='text'>
						<li>
							Discussions or commentary that are educational, critical, or aimed
							at raising awareness about offensive behavior or societal issues.
						</li>
						<li>
							Academic or journalistic discussions that involve the examination
							or analysis of offensive behavior without promoting or endorsing
							it.
						</li>
					</ul>
					<p className='mg0 bold'>Political or Social Commentary:</p>
					<ul className='text'>
						<li>
							Political discourse or social commentary that is not aimed at
							targeting individuals or groups with malicious intent but rather
							expressing opinions or viewpoints on issues.
						</li>
					</ul>
					<p className='mg0 bold'>Legitimate Debate or Dialogue:</p>
					<ul className='text'>
						<li>
							Civil and respectful debate or dialogue where differing opinions
							are expressed without resorting to personal attacks, harassment,
							or hate speech.
						</li>
					</ul>
					<p className='mg0 bold'>Satire and Humor:</p>
					<ul className='text'>
						<li>
							Satirical content or humor that uses exaggeration, irony, or
							parody to convey a message, provided it is not intended to harm or
							promote offensive behavior.
						</li>
					</ul>
					<p>
						It's important to note that these exceptions are evaluated on a
						case-by-case basis, taking into account the overall context, intent,
						and impact of the content. Additionally, these exceptions do not
						excuse or justify behavior that is clearly intended to harm, harass,
						or discriminate against others.
					</p>
					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Bullying or harassment"</b>, or{' '}
							<b>"Offensive words or symbols"</b>, or{' '}
							<b>"Violence or dangerous organizations".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>
					<p className='bold mg0'>
						When assessing the repercussions for violating this policy, we take
						various factors into account. These may encompass the severity of
						the violation, the user's history of infractions, the impact on
						affected parties, and the intent behind the behavior. Consequently,
						penalties may range from the removal of offending posts to the
						suspension of the user's account. Additionally, in certain cases, we
						may implement temporary restrictions on account privileges or
						provide warnings to encourage compliance with our guidelines.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default OffensiveBehaviour;
