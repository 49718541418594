import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import YoutubeVideos from './YoutubeVideos';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setActiveVideo } from '../../redux/actions/dataActions';
function YTCarousel({ mode }) {
	const [play, setPlay] = useState(true);
	// const dispatch = useDispatch();
	// const activeVideoPostId = useSelector(
	// 	(state) => state.data.activeVideoPostId,
	// );

	// const resetPlayers = (props) => {
	// 	console.log('reset', props);
	// 	if (activeVideoPostId !== '') {
	// 		dispatch(setActiveVideo(''));
	// 		setPlay(true);
	// 	}
	// };
	return (
		<div className='pdt2Media'>
			<Carousel
				fullHeightHover={false}
				autoPlay={play}
				navButtonsAlwaysVisible={true}
				interval={6000}
				duration={1000}
				animation='slide'
				// next={() => resetPlayers('a')}
				// prev={() => resetPlayers('b')}
				// NavButton={() => resetPlayers('c')}
			>
				<div className={`card pdtH ${mode === 'dark' && 'dbc'}`}>
					<YoutubeVideos
						mode={mode}
						esp='https://www.youtube.com/watch?v=q3N6s8c6OKQ'
						eng='https://www.youtube.com/watch?v=Fr_cswok1g0'
						setPlay={() => setPlay(false)}
					/>
				</div>
				<div className={`card pdtH ${mode === 'dark' && 'dbc'}`}>
					<YoutubeVideos
						mode={mode}
						esp='https://www.youtube.com/watch?v=4Itwbn6nrzs'
						eng='https://www.youtube.com/watch?v=d9afg1YBb78&t=53s'
						setPlay={() => setPlay(false)}
					/>
				</div>
				<div className={`card pdtH ${mode === 'dark' && 'dbc'}`}>
					<YoutubeVideos
						mode={mode}
						esp='https://www.youtube.com/watch?v=QtjZCuPYR8I'
						eng='https://www.youtube.com/watch?v=3fTUwY2Sxxw'
						setPlay={() => setPlay(false)}
					/>
				</div>
			</Carousel>
		</div>
	);
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {
	setActiveVideo,
};

export default connect(mapStateToProps, mapActionsToProps)(YTCarousel);
