import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';

function PrivacyPolicy() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Privacy Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Privacy Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<CustomAlerts
						info="It's important to note that we do not sell any
						of your information to anyone."
						margin='1rem 0 0'
					/>

					<h2>
						Is <span className='notranslate'> Goodbye App </span> secure?
					</h2>
					<p>
						Absolutely, ensuring the security and privacy of our users' data is
						our top priority at{' '}
						<span className='notranslate'> Goodbye App </span>. Here’s how we
						achieve that:
					</p>
					<p>
						<b>Trusted Infrastructure:</b> We utilize Firebase and Google Cloud
						for all our data storage needs. As Google products, both Firebase
						and Google Cloud are renowned for their robust security measures and
						adherence to various industry standards. This ensures that our data
						storage solutions are among the most secure available.
					</p>
					<p>
						<b>Encryption:</b> All data transmitted between our app and our
						servers is encrypted using <b>HTTPS</b> which is short Hypertext
						Transfer Protocol Secure. This means that any data you send or
						receive is secure and cannot be intercepted by unauthorized parties.
						Additionally, data at rest in our databases is encrypted, providing
						an extra layer of protection.
					</p>
					<p>
						<b>Compliance:</b> Google Cloud’s infrastructure complies with major
						international security standards and regulations such as GDPR,
						ISO/IEC 27001, and SOC 1/2/3. This means that by using Firebase and
						Google Cloud, we are also compliant with these high standards of
						security and privacy.
					</p>
					<p>
						<b>Authentication and Authorization:</b> We implement strong
						authentication protocols to ensure that only authorized users can
						access their data. Firebase Authentication allows us to use
						industry-standard methods to verify user identity, such as
						email/password, OAuth providers (like Google and Facebook), and
						more.
					</p>
					<p>
						<b>Regular Audits and Updates:</b> We regularly audit our security
						protocols and systems to ensure they are up-to-date with the latest
						security standards and best practices. This helps us to quickly
						identify and fix any potential vulnerabilities.
					</p>

					<p>
						<b>User Control:</b> Users have control over their data within the
						app. They can control who sees their posts, and delete their data
						whenever they wish.
					</p>
					<hr />
					<p>
						At <span className='notranslate'> Goodbye App, </span> we are
						committed to protecting your privacy and ensuring that your personal
						data is handled securely and responsibly. This Privacy Policy
						explains how we collect, use, and share your personal information
						when you use our services.{' '}
						<b>
							For our business customers, we also provide a{' '}
							<Link to='/dpa' id='link' className='bold'>
								Data Processing Addendum (DPA)
							</Link>{' '}
							that details our data processing practices and compliance with
							data protection regulations such as GDPR.
						</b>
					</p>
					<h2>What kinds of information do we collect?</h2>
					<p>
						To provide <span className='notranslate'> Goodbye App </span>{' '}
						service, we must process information about you. The types of
						information we collect depend on how you use our platform. Things
						you and others do and provide. Information and content you provide.
						We collect the content, communications and other information you
						provide when you use our Products, including when you sign up for an
						account, create or share content, submit a support or report form,
						and communicate with others. This can include information in or
						about the content you provide (like metadata), such as the date a
						file was created.
					</p>
					<p>
						<span className='notranslate'> Goodbye App </span> is a product and
						a service that requires an account to access its features and
						functionalities. By creating an account, you agree to provide us
						with the necessary information to deliver and maintain our products
						and services.
					</p>
					<p>
						<b>Personal Accounts</b>
					</p>
					<ul className='text'>
						<li>
							If you create an account, you must provide us with some
							information so that we can provide our products and services to
							you. This includes:
						</li>
						<li>A display name (for example, “Calvin”).</li>
						<li>A username (for example, @calvinjames).</li>
						<li>A password.</li>
						<li>An email address or phone number.</li>
						<li>A date of birth.</li>
						<li>Your display language.</li>
						<li>A date of birth.</li>
						<li>
							Third-party single sign-in information (if you choose this sign-in
							method).
						</li>
					</ul>
					<p>
						You can also choose to share your location in your profile and
						posts, and to upload your address book to{' '}
						<span className='notranslate'> Goodbye App </span> to help find
						people you may know. Your profile information, which includes your
						display name and username, is always public, but you can use either
						your real name or a pseudonym.
					</p>
					<p>
						You can create multiple{' '}
						<span className='notranslate'> Goodbye App </span> accounts, for
						example, to express different parts of your identity, professional
						or otherwise.
					</p>
					<p>
						<b>Payment Information:</b> To purchase ads or other offerings
						within our paid products and services, you'll need to provide
						payment information, including your credit or debit card number,
						card expiration date, CVV code, and billing address.
					</p>
					<p>
						<b>Preferences:</b> We collect information about your preferences
						when you set them using your settings, allowing us to respect and
						adhere to your preferences.
					</p>
					<p>
						<b>Biometric Information:</b> With your consent, we may collect and
						utilize your biometric information for safety, security, and
						identification purposes.
					</p>
					<p>
						You can choose to provide information in your{' '}
						<span className='notranslate'> Goodbye App </span> profile fields,
						posts, and letters about your religious views, political views, who
						you are "interested in," or your health. This and other information
						(such as racial or ethnic origin, philosophical beliefs or trade
						union membership) could be subject to special protections under the
						laws of your country.
					</p>
					<p className='bold'>
						Information We Collect When You Use{' '}
						<span className='notranslate'> Goodbye App </span>
					</p>
					<p>
						When you use our services, we collect information about how you use
						our products and services. We use that information to provide you
						with products and services, to help keep{' '}
						<span className='notranslate'> Goodbye App </span> more secure and
						respectful for everyone, and to make it more relevant to you.
						Including:
					</p>
					<ul className='text'>
						<li>The types of content you view or engage with.</li>
						<li>Your actions on our platform.</li>
						<li>The features you utilize within our products.</li>
						<li>Your interactions with other users or accounts.</li>
						<li>The timing, frequency, and duration of your activities.</li>
					</ul>
					<p>
						Moreover, we also receive and analyze content, communications, and
						information provided by other users when they use our products. This
						includes information related to you, such as when others share or
						comment on content involving you.
					</p>
					<p>
						We collect information from various devices you use, such as
						computers, phones, connected TVs, and other web-connected devices
						that integrate with our Products. This information is combined
						across different devices to enhance your experience. For instance,
						data collected about your Product usage on your phone helps us
						personalize content and features on other devices, such as laptops
						or tablets. We may also measure actions taken in response to ads
						displayed on one device but acted upon on another.
					</p>
					<p>The information obtained from these devices includes:</p>
					<ul className='text'>
						<li>
							<b>Device attributes:</b> Details like the operating system,
							hardware and software versions, battery status, signal strength,
							available storage space, browser type, app and file names/types,
							and plugins.
						</li>
						<li>
							<b>Device operations:</b> Information regarding operations and
							behaviors conducted on the device, such as whether a window is
							active or in the background, or mouse movements (which aids in
							distinguishing between human users and bots).
						</li>
					</ul>
					<p>
						We collect various types of identifiers, signals, and data related
						to devices:
					</p>
					<ul className='text'>
						<li>
							<b>Identifiers:</b> These include unique identifiers, device IDs,
							and other identifiers associated with games, apps, or accounts you
							use, as well as Family Device IDs or other unique identifiers
							linked to GoodbyeApp Corp Products and the same device or account.
						</li>
						<li>
							<b>Device signals:</b> Device signals: This encompasses Bluetooth
							signals, and details about nearby Wi-Fi access points, beacons,
							and cell towers.
						</li>
						<li>
							<b>Data from device settings:</b> We may receive information
							through device settings that you enable, such as access to your
							GPS location, camera, or photos.
						</li>
						<li>
							<b>Network and connections:</b> This includes details like your
							mobile operator or ISP name, language preferences, time zone,
							mobile phone number, IP address, connection speed, and in some
							instances, information about nearby devices or those on your
							network. These details help us provide services like streaming
							content from your phone to your TV.
						</li>
					</ul>
					<p className='bold'>Information We Receive from Third Parties</p>
					<p>
						When you use other online products and services, they may share
						information about that usage with us.
					</p>
					<p>
						<b>Ad Partners, Developers, Publishers:</b> Our ad and business
						partners share information with us such as browser cookie IDs,
						<span className='notranslate'> Goodbye App </span>-generated
						identifiers, mobile device IDs, hashed user information like email
						addresses, demographic or interest data, and content viewed or
						actions taken on a website or app. Some of our ad partners,
						particularly our advertisers, also enable us to collect similar
						information directly from their website or app by integrating our
						advertising technology. Information shared by ad partners and
						affiliates or collected by{' '}
						<span className='notranslate'> Goodbye App </span> from the websites
						and apps of ad partners and affiliates may be combined with the
						other information you share with{' '}
						<span className='notranslate'> Goodbye App </span> and that Goodbye
						App receives, generates, or infers about you as described elsewhere
						in this Privacy Policy.
					</p>
					<p>
						<b>Other Third Parties, Account Connections, and Integrations:</b>{' '}
						We may receive information about you from third parties who are not
						our ad partners, such as other{' '}
						<span className='notranslate'> Goodbye App </span> users,
						developers, and partners who help us evaluate the safety and quality
						of content on our platform, our corporate affiliates, and other
						services you link to your{' '}
						<span className='notranslate'> Goodbye App </span> account. You may
						choose to connect your Goodbye App account to your account on
						another service, and that other service may send us information
						about your account on that service.
					</p>
					<p>
						We also collect cookie data, which includes information from cookies
						stored on your device such as cookie IDs and settings. You can find
						more details about how we use cookies in{' '}
						<span className='notranslate'> Goodbye App </span>{' '}
						<Link to='/cookie-use' id='link' className='bold'>
							Cookie Use
						</Link>
						.
					</p>
					<h2>How We Use Information</h2>
					<p>
						Explaining how we use the information we collect can be complex due
						to the interconnected nature of the systems that deliver our
						products and services. For instance, the same piece of information
						may serve multiple purposes to provide a single service. We believe
						it’s most helpful to outline the five primary ways we use your
						information. If you have any questions that aren't addressed here,
						you can always{' '}
						<Link to='/contact' id='link' className='bold'>
							contact us
						</Link>
						. Here we go:
					</p>
					<p className='bold'>Operate, Improve, and Personalize Our Services</p>
					<p>
						We use the information we collect to provide and operate{' '}
						<span className='notranslate'> Goodbye App </span>
						products and services. Additionally, we use this information to
						improve and personalize our offerings to enhance your experience on
						<span className='notranslate'> Goodbye App </span>. This includes
						showing you more relevant content and ads, suggesting people and
						topics to follow, and helping you discover affiliates, third-party
						apps, and services. We may also use the information we collect,
						along with publicly available information, to train our machine
						learning or artificial intelligence models for the purposes outlined
						in this policy.
					</p>
					<p>
						We may utilize information from accounts of other services that you
						choose to connect to your{' '}
						<span className='notranslate'> Goodbye App </span> account to
						provide features like cross-posting or cross-service authentication,
						and to operate our services effectively.
					</p>
					<p>
						We use your contact information to help others find your account,
						including through third-party services and client applications.
					</p>
					<p>
						We utilize your information to deliver our advertising and sponsored
						content services based on your preferences, enhancing the relevance
						of ads on <span className='notranslate'> Goodbye App </span> to you.
						Additionally, we use this data to gauge the efficacy of ads and to
						identify your devices for ad delivery both within and outside of{' '}
						<span className='notranslate'> Goodbye App </span>. Some of our
						advertising partners also facilitate the collection of similar data
						directly from their platforms through our advertising technology
						integration. Information shared by advertising partners and
						affiliates, or gathered by{' '}
						<span className='notranslate'> Goodbye App </span> from the
						platforms of these partners and affiliates, may be combined with
						other information you provide to{' '}
						<span className='notranslate'> Goodbye App </span> or that{' '}
						<span className='notranslate'> Goodbye App </span> acquires,
						generates, or infers about you, as outlined elsewhere in our Privacy
						Policy.
					</p>
					<p>
						<b>Verification and Security:</b> We use this data to verify
						accounts and activities, counter harmful conduct, identify and
						prevent spam and other negative experiences, uphold the integrity of
						our products, and enhance safety and security both within and
						outside of <span className='notranslate'> Goodbye App </span>. For
						instance, we employ this information to investigate suspicious
						activities or violations of our terms and policies and to identify
						instances where assistance may be required. We collaborate with
						third-party partners to enhance the security and reliability of your
						account. This includes sharing relevant information with them and
						receiving information about your account's reliability. These
						measures help us prevent fraud, abuse, and other harmful activities
						both on and off our Products.
					</p>

					<p>
						<b>Communication:</b> The information is also used to send you
						marketing communications, keep you informed about our Products, and
						update you about our policies and terms. Additionally, we use your
						data to respond to your inquiries when you reach out to us.
					</p>
					<p>
						<b>Research and Innovation:</b> We leverage the information,
						including collaborations with research partners, to conduct and
						support research and innovation focused on societal welfare,
						technological progress, public interest, health, and well-being.
					</p>
					<p className='bold'>Promote Safety and Security</p>
					<p>
						We utilize the information we collect to ensure the safety and
						protection of our users, our products, services, and your account.
						This involves verifying your identity, authenticating your account,
						and safeguarding against fraud, unauthorized access, and illegal
						activities. Additionally, we use the information to assess and
						improve the safety and quality of content on{' '}
						<span className='notranslate'> Goodbye App </span>. This includes
						investigating and enforcing our policies and terms, as well as
						adhering to applicable laws.
					</p>
					<p className='bold'>Enhance, Analyze, and Improve Our Services</p>
					<p>
						We utilize the data we gather to assess and analyze the performance
						of our offerings and to gain insights into how you interact with
						them, with the goal of enhancing their quality.
					</p>
					<p className='bold'>Communicate Regarding Our Services</p>
					<p>
						We use the information we collect to keep you informed about our
						products and services, including updates and revisions to our
						policies and terms. If you're receptive to receiving updates, we may
						also occasionally send you marketing communications.
					</p>
					<p className='bold'>Conducting Research and Improvement</p>
					<p>
						We utilize the information you provide or that we gather to conduct
						research, surveys, product testing, and troubleshooting. These
						efforts assist us in operating and enhancing our products and
						services.
					</p>
					<h2>Information Sharing</h2>
					<p>
						It's important for you to understand how we share your information,
						the reasons behind it, and how you can manage it. We typically share
						your information in five main ways.
					</p>
					<p className='bold'>When you post and share.</p>
					<p>
						<b>With the General Public:</b> By sharing content, you are
						directing us to make that information available as widely as
						possible. <span className='notranslate'> Goodbye App </span>{' '}
						content, including your profile information (e.g., name/pseudonym,
						username, profile pictures), is accessible to the general public.
						Users do not need to be signed in to view some content on{' '}
						<span className='notranslate'> Goodbye App </span>. Additionally,
						they may come across Goodbye App content outside of the platform,
						for example, through search engine results.
					</p>
					<p>
						<b>
							With Other <span className='notranslate'> Goodbye App </span>{' '}
							Users.
						</b>{' '}
						Depending on <span className='notranslate'> Goodbye App </span>
						products and services you use, we share:
					</p>
					<ul className='text'>
						<li>
							Your interactions with content from other{' '}
							<span className='notranslate'> Goodbye App </span> users, such as
							likes and people you follow.
						</li>
					</ul>
					<p>
						<b>With Partners.</b> In addition, we may share specific information
						with third parties to assist in offering or managing our products
						and services.
					</p>
					<p className='bold'>With Third Parties & Third-Party Integrations</p>
					<p>
						<b>With Service Providers:</b> We may share your information with
						service providers who perform various functions and provide services
						on our behalf. This includes payment services providers facilitating
						transactions, hosting services for our blogs and wikis, analytics
						providers helping us understand service usage, applicant tracking
						system providers managing job-related data, and fraud detection
						service providers.
					</p>
					<p>
						<b>With Advertisers: </b> We collaborate with third-party partners
						to enhance and refine our Products, which enables us to run our
						company and offer free services worldwide. It's important to note
						that we do not sell any of your information to anyone, and we have
						stringent controls in place to govern how our partners can utilize
						and disclose the data we supply to them. Advertisers may gather
						information from your interaction with their ads on or off Goodbye
						App. For instance, if you click on an external link or ad within our
						services, the advertiser or website operator may deduce that your
						visit originated from{' '}
						<span className='notranslate'> Goodbye App </span>, along with
						additional information linked to the ad, such as audience
						characteristics it targeted and unique identifiers generated by{' '}
						<span className='notranslate'> Goodbye App </span> for that ad. They
						may also collect other personal information like cookie identifiers
						or your IP address.
					</p>
					<p>
						<b>Third-Party Content & Integrations:</b> We share or disclose your
						information when you provide consent or direct us to do so. This
						includes instances like authorizing a third-party web client or
						application to access your account or instructing us to share your
						feedback with a business. Similarly, to enhance your experience, we
						collaborate with third-party partners to showcase their video
						content on <span className='notranslate'> Goodbye App </span> or
						enable cross-platform sharing. When you engage with content from our
						video or cross-platform sharing partners, they may receive and
						process your personal information as outlined in their respective
						privacy policies.
					</p>
					<p>
						<b>Through Our APIs:</b> We utilize technology such as APIs and
						embeds to provide public{' '}
						<span className='notranslate'> Goodbye App </span> information to
						websites, apps, and other entities for their utilization, such as
						displaying posts on a news website.
					</p>
					<p className='bold'>
						When required by law, to prevent harm, or in the public interest.
					</p>
					<p>
						We may retain, utilize, share, or reveal your information if we deem
						it reasonably necessary to:
					</p>
					<ul className='text'>
						<li>
							Comply with applicable laws, regulations, legal proceedings, or
							governmental requests.
						</li>
						<li>
							Safeguard the well-being of individuals, ensure the safety and
							integrity of our platform, including measures to prevent spam,
							abuse, or malicious activities on our services.
						</li>
						<li>
							Provide explanations for content or account removals from our
							services (e.g., due to a violation of{' '}
							<Link to='/community-guidelines' id='link' className='bold'>
								Our Community Guidelines
							</Link>
							).
						</li>
						<li>
							Address issues related to fraud, security, or technical matters.
						</li>
						<li>
							Protect our rights, properties, or the rights and properties of
							those who use our services.
						</li>
					</ul>
					<p>
						We take various steps to respond to legal requests and prevent harm
						by accessing, preserving, and sharing your information with
						regulators, law enforcement agencies, or other entities:
					</p>
					<p>
						<b>Legal Requests:</b> We respond to legal requests such as search
						warrants, court orders, or subpoenas if we believe in good faith
						that the law requires us to do so. This includes complying with
						legal requests from jurisdictions outside the United States if we
						have a sincere belief that the response is mandated by law in that
						jurisdiction, affects users in that jurisdiction, and aligns with
						internationally recognized standards.
					</p>
					<p>
						<b>Fraud and Unauthorized Use:</b> We may access, preserve, and
						share your information to detect, prevent, and address fraud,
						unauthorized use of our Products, violations of our terms or
						policies, or other harmful or illegal activities.
					</p>
					<p>
						<b>Protection of Rights:</b> Our actions may also include protecting
						ourselves, including our rights, property, or Products, as well as
						you or others. This protection aspect extends to investigations,
						regulatory inquiries, and preventing death or imminent bodily harm.
					</p>
					<p className='italic'>
						Our approach is rooted in maintaining legal compliance, safeguarding
						against harmful activities, and ensuring the safety and security of
						our users and our platform.
					</p>
					<p>
						<b>With our Affiliates.</b> We may share information amongst our
						affiliates to provide our products and services.
					</p>
					<p>
						<b>Due to a change in ownership,</b> we may share, sell, or transfer
						your information in connection with activities such as a merger,
						acquisition, reorganization, sale of assets, or bankruptcy. In such
						cases, this Privacy Policy will continue to apply to your personal
						information, whether it's shared with, transferred to, or remains
						with the new entity (before and after the completion of any
						transaction).
					</p>
					<hr />
					<p>
						When you share and communicate using our Products, you have control
						over the audience for your shared content. For instance, when you
						make a post on <span className='notranslate'> Goodbye App </span>,
						you can choose the audience for the post, whether it's a specific
						group, all your friends, the general public, or a customized list of
						individuals.
					</p>
					<p>
						You, other users of{' '}
						<span className='notranslate'> Goodbye App </span>, and we have the
						ability to grant access to or share public information with anyone,
						whether they are using our Products or not. This information can be
						shared across various{' '}
						<span className='notranslate'> Goodbye App </span> Corporation
						Products, appear in search results, or be accessible through tools
						and APIs. Additionally, public information may be viewed, accessed,
						shared, or downloaded through third-party services such as search
						engines, APIs, and offline media like television. It can also be
						accessed by apps, websites, and other services that integrate with
						our Products.
					</p>
					<p>
						You should be mindful of whom you share your activity with on our
						Products because those individuals can further share it with others
						both within and outside the audience you initially selected. For
						instance, if you share a post or send a message to specific friends
						or accounts, they have the ability to download, take screenshots of,
						or reshare that content with others, whether they are on or off our
						Products. This sharing can occur in real-life interactions or in
						virtual reality environments such as Horizon Worlds.
					</p>
					<p>
						Similarly, when you interact with someone else's content by
						commenting or reacting to it, your interaction is visible to anyone
						who can see that person's content, and the original poster can later
						modify the audience. Users can also use our Products to generate and
						distribute content about you to their chosen audience. For example,
						they may share a photo of you in a post, mention or tag you at a
						location in a post, or include information about you in their posts
						or messages.
					</p>
					<p>
						If you feel uncomfortable about content that others have shared
						about you on our Products, you can find information on how to report
						such content.
					</p>
					<h2>Retention Periods</h2>
					<p>
						The duration for which we retain different types of information
						varies:
					</p>
					<ul className='text'>
						{/* <li>
							Profile information and content are kept for the duration of your
							account's existence.
						</li> */}
						<li>
							Other personally identifiable data collected during your use of
							our products and services is generally retained for up to 18
							months.
						</li>
						<li>
							Personal Data shall be processed and stored for as long as
							required by the purpose they have been collected for and may be
							retained for longer due to applicable legal obligation or based on
							the Users’ consent.
						</li>
					</ul>
					<p>
						Please note that public content may persist elsewhere even after
						removal from <span className='notranslate'> Goodbye App </span>. For
						instance, search engines and other third parties may retain copies
						of your posts longer, according to their respective privacy
						policies, even after deletion or expiration on{' '}
						<span className='notranslate'> Goodbye App </span>.
					</p>
					<p>
						In cases where your account has infringements or is suspended due to
						rule violations, we may retain the identifiers used to create the
						account (e.g., email address or phone number) indefinitely to
						prevent repeat policy violations through new accounts.
					</p>
					<p>
						Certain information may be retained longer than outlined in our
						policies to comply with legal obligations and for safety and
						security purposes.
					</p>
					<h2>Take Control</h2>
					<p className='bold'>Access, Correction, Portability</p>
					<ul className='text'>
						<li>
							You can access, correct, or modify the information you provided to
							us by editing your profile and adjusting your account settings.
						</li>
					</ul>
					<p className='bold'>Privacy and Security Measures</p>
					<ul className='text'>
						<li>
							To protect your privacy and maintain security, we take steps to
							verify your identity before granting you access to your personal
							information or complying with a deletion, portability, or other
							related request.
						</li>
						<li>
							We may, in certain situations, reject your request for access,
							correction, or portability. For example, we may reject access if
							you are unable to verify your identity.
						</li>
					</ul>
					<p className='bold'>Deleting your Information.</p>
					<p>
						We provide you with the ability to access, rectify, port and erase
						your data. Learn more in your{' '}
						<span className='notranslate'> Goodbye App </span> Settings Panel.
					</p>
					<p>
						Your account will be deactivated, and your data will be queued for
						deletion. When deactivated, your{' '}
						<span className='notranslate'> Goodbye App </span> account,
						including your display name, username, and public profile, will no
						longer be viewable on{' '}
						<span className='notranslate'> Goodbye App </span>'s website or
						mobile applications.
					</p>
					<p>
						When you choose to deactivated your account, we remove the content
						you've posted, including photos and status updates. This information
						cannot be recovered once deleted. However, it's important to note
						that content shared by others about you is not part of your account
						and will not be deleted when you deactivate your account.
					</p>
					<p className='bold'>
						Objecting to, Restricting, or Withdrawing your Consent.
					</p>
					<p>
						<b>Adherence to Digital Advertising Alliance (DAA) Principles:</b>{' '}
						<span className='notranslate'> Goodbye App </span> follows the DAA's
						Self-Regulatory Principles for Online Behavioral Advertising. These
						principles include requirements for notice and choice regarding data
						collection for advertising purposes, data security, and limits on
						data usage.
					</p>
					<p>
						<b>Interest-Based Advertising:</b> This refers to the practice of
						collecting information about users’ online activities across
						different websites and apps to deliver more relevant advertising to
						them.
					</p>
					{/* <p>
						<b>Consumer Choice Tool:</b> The DAA provides a tool for consumers
						to opt out of interest-based advertising.{' '}
						<span className='notranslate'> Goodbye App </span> respects this
						tool, meaning users can visit{' '}
						<a
							href='https://optout.aboutads.info/'
							target='_blank'
							rel='noreferrer'
							id='link'
							className='bold'
						>
							https://optout.aboutads.info/
						</a>{' '}
						to opt out of interest-based advertising from participating
						companies.
					</p> */}
					<h2>Data Protection Officer (DPO)</h2>
					<p>
						Our Data Protection Officer (DPO) is responsible for overseeing
						questions in relation to this privacy policy. If you have any
						questions please contact us at dpo@goodbyeapp.com.
					</p>
					<h2>Your Rights and Ours</h2>
					<p>
						We provide <span className='notranslate'> Goodbye App </span> to
						people all over the world and offer many of the same tools and
						controls to all of our users, regardless of where they live.
						However, your experience may differ slightly from users in other
						countries to ensure{' '}
						<span className='notranslate'> Goodbye App </span> complies with
						local requirements.
					</p>
					<p>
						<b>We have specific legal bases to use your information.</b> Goodbye
						App has carefully considered the legal reasons that permit it to
						collect, use, share, and otherwise process your information.
					</p>
					<p>
						Just as you use <span className='notranslate'> Goodbye App </span>{' '}
						to engage in global conversations with people from various
						countries, <span className='notranslate'> Goodbye App </span> must
						transfer information across borders to ensure the safe and reliable
						service you expect. For instance, if you are in Australia and
						chatting with someone in the United States, data needs to flow
						between these countries to facilitate that interaction – it’s a core
						part of our service.
					</p>
					<p>
						We utilize data centers, cloud providers, affiliates, and
						third-party partners across the globe to help deliver our services.
						Before transferring data internationally, we evaluate potential
						risks. When sharing data with third parties, we ensure they uphold
						the same level of data protection that we provide directly. Your
						information may be transferred, transmitted, stored, and processed
						in countries outside of your own, including the United States, to
						fulfill the purposes outlined in this policy. These data transfers
						are essential for providing the services outlined in{' '}
						<span className='notranslate'> Goodbye App </span>
						Terms and for the global operation and delivery of our Products to
						you.
					</p>
					<p>
						To facilitate these data transfers, we adhere to standard contract
						clauses, rely on adequacy decisions by the European Commission for
						certain countries where applicable, and seek your consent for
						transferring data to the United States and other countries as
						necessary. This approach ensures that your data is protected and
						handled appropriately during global operations and service
						provision.
					</p>

					<p>
						We collect and process personal data to provide and improve our
						services. We share data with third-party service providers,
						partners, and affiliates as necessary to support our operations and
						comply with legal obligations. For business customers who use our
						services to process personal data on their behalf, we offer a{' '}
						<Link to='/dpa' id='link' className='bold'>
							Data Processing Addendum (DPA)
						</Link>
						. The DPA outlines the terms and conditions of our data processing
						activities, including our responsibilities as a data processor under
						GDPR. You can view or request a copy of our DPA by contacting us at
						dpo@goodbyeapp.com.
					</p>
					<p>
						Information about our handling of California Consumer Privacy Act
						(CCPA) requests is available{' '}
						<Link to='/ccpa' id='link' className='bold'>
							here
						</Link>
						.
					</p>
					<h2>Changes To This Privacy Policy</h2>
					<p>
						The most recent version of this Privacy Policy will govern our
						processing of your personal data, and we may update this Privacy
						Policy at any time. If we make any significant changes to this
						Privacy Policy, we will notify you via any contact information
						available and give you an opportunity to review the updated Privacy
						Policy before you continue using{' '}
						<span className='notranslate'> Goodbye App </span>.
					</p>
					<h2>General</h2>
					<p>
						The policies of <span className='notranslate'> Goodbye App </span>{' '}
						are initially written in English, with translations provided in
						other languages for convenience. While Goodbye App aims to make
						these translations as accurate as possible, the original English
						version will prevail in the event of any discrepancies or
						inconsistencies. You acknowledge that English is the authoritative
						language for interpreting and enforcing all terms and conditions of{' '}
						<span className='notranslate'> Goodbye App</span>.
					</p>
					<p>
						Information collected by these third-party services is subject to
						their own terms and policies, not this one.
					</p>
					<h2>Use of Local Storage</h2>
					<p>
						We use localStorage to store information about your preferences and
						to improve the functionality of our services. LocalStorage is a
						technology that allows us to store small amounts of data directly in
						your browser, similar to cookies, but with different technical
						properties.
					</p>
					<p>What We Store:</p>
					<ul className='text'>
						<li>
							User preferences, such as theme settings or language selection.
						</li>
						<li>
							Temporary data necessary for the operation of the website, such as
							session information.
						</li>
					</ul>

					<p>Why We Use LocalStorage</p>
					<ul className='text'>
						<li>To remember your preferences and settings between visits.</li>
						<li>To enhance your user experience by personalizing content.</li>
						<li>
							To improve the overall functionality and performance of our
							website.
						</li>
					</ul>
					<p>Your Choices</p>
					<ul className='text'>
						<li>
							You can clear the data stored in localStorage through your browser
							settings at any time.
						</li>
						<li>
							If you disable localStorage in your browser, some features of our
							website may not function correctly.
						</li>
					</ul>
					<hr />

					<CustomAlerts
						success={
							<div>
								If you have any questions that aren't addressed here, you can
								always{' '}
								<Link to='/contact' id='link' className='bold'>
									contact us
								</Link>
								.
							</div>
						}
						icon={<PolicyIcon />}
						margin='0'
					/>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PrivacyPolicy;
