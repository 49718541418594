import { Avatar, Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import { useTranslation } from 'react-i18next';
function EnableNotifications({ popup }) {
	const { t } = useTranslation();
	const mode = useSelector((state) => state.UI.mode);
	const [browser, setBrowser] = useState('');
	useEffect(() => {
		const userAgent = window.navigator.userAgent;
		let browser = '';

		if (userAgent.includes('Chrome')) {
			browser = 'chrome';
		} else if (userAgent.includes('Firefox')) {
			browser = 'firefox';
		} else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
			browser = 'safari';
		}

		setBrowser(browser);
	}, []);

	const radioChange = (browser) => {
		setBrowser(browser);
	};

	return (
		<div>
			<Helmet>
				<title>Enable Notifications - Goodbye App</title>
			</Helmet>
			<div className='skyBackground pd2Media'>
				<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<CircleNotificationsIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mg0'>{t('notifications')}</h1>
					<h3 className='subtitle center mg0'>{t('enable_nots_title')}</h3>
					<p>{t('useful_desc', { setting: t('push_notifications') })}</p>
					<p>
						{t('useful_desc_2', {
							setting: t('notification'),
						})}
					</p>
					<CustomAlerts
						info={true}
						message={t('select_browser')}
						icon={<TravelExploreIcon />}
						margin='0 0 0.5rem'
					/>
					<div className='flexBrowsers spaceAround'>
						<div
							className='flexColumn alignItemsCenter pointer'
							onClick={() => radioChange('chrome')}
						>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2Fchrome_svg.png?alt=media&token=e8bd0586-1ffd-4dab-82b2-dc2ae0cdb931'
								alt='chrome svg'
								className='browserLogo'
							/>
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio checked={browser === 'chrome'} />
								<p className='mg0'>Google Chrome</p>
							</div>
						</div>
						<div
							className='flexColumn alignItemsCenter pointer'
							onClick={() => radioChange('safari')}
						>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2Fsafari_svg.png?alt=media&token=f77cea0c-2e4a-4e7c-bb1f-321af530600f'
								alt='safari svg'
								className='browserLogo'
							/>
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio checked={browser === 'safari'} />
								<p className='mg0'>Safari</p>
							</div>
						</div>
						<div
							className='flexColumn alignItemsCenter  pointer'
							onClick={() => radioChange('firefox')}
						>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FmozillaFirefox_svg.png?alt=media&token=c95ce92a-2848-4f55-932e-d12a5a181a85'
								alt='mozilla firefox svg'
								className='browserLogo'
							/>
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio checked={browser === 'firefox'} />
								<p className='mg0'>Mozilla Firefox</p>
							</div>
						</div>
					</div>
					<hr />

					{browser === 'chrome' ? (
						<div>
							<h2 className='mgbOnly mgtH'>Google Chrome</h2>
							<div className='text'>
								<p>
									1. {t('useful_browser_1', { browser: 'Google Chrome' })}{' '}
									<a
										href='https://goodbyeapp.com/'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold notranslate'
									>
										goodbyeapp.com
									</a>
								</p>
								<p>2. {t('computer_google_2')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationChrome1.jpg?alt=media&token=4022a381-3049-4f12-8f64-dc92f8e4ac21'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
								<p>
									3.{' '}
									{t('computer_google_3', {
										setting: t('notifications'),
									})}
								</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationChrome2.jpg?alt=media&token=1295ecd9-1cdb-4852-ad03-799dd0d6dbf0'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>

								<p>
									4.{' '}
									{t('computer_google_4', {
										setting: t('notifications'),
									})}
								</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationChrome4.jpg?alt=media&token=7a5f81d4-66a6-49a2-9d61-9110a1f494e9'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
								<p>
									5.{' '}
									{t('computer_google_5', {
										setting: t('notifications'),
									})}
								</p>
								<p>
									6.{' '}
									{t('computer_google_6', {
										setting: t('notifications'),
									})}
								</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationChrome3.jpg?alt=media&token=8acaf4bb-c3ea-4378-863b-047704ef5da9'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
							</div>
						</div>
					) : browser === 'safari' ? (
						<div>
							<h2 className='mgbOnly mgtH'>Safari</h2>
							<div className='text'>
								<p>
									1. {t('useful_browser_1', { browser: 'Safari' })}{' '}
									<a
										href='https://goodbyeapp.com/'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold notranslate'
									>
										goodbyeapp.com
									</a>
								</p>
								<p>
									2.{' '}
									{t('computer_safari_2', {
										browser: 'Safari',
									})}
								</p>
								<p>3. {t('computer_safari_3')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2Fsafari1.jpg?alt=media&token=0ebc9ba3-238f-489e-984d-ab2bc11c3842'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
								<p>4. {t('computer_safari_4')}</p>
								<p>
									5.{' '}
									{t('computer_safari_5', {
										setting: t('notifications'),
									})}
								</p>
								<p>6. {t('computer_safari_6')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2Fsafari2.jpg?alt=media&token=0140541e-8e42-488c-85ac-4dac00d0cad4'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
							</div>
						</div>
					) : browser === 'firefox' ? (
						<div>
							<h2 className='mgbOnly mgtH'>Mozilla Firefox</h2>
							<div className='text'>
								<p>
									1. {t('useful_browser_1', { browser: 'Mozilla Firefox' })}{' '}
									<a
										href='https://goodbyeapp.com/'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold notranslate'
									>
										goodbyeapp.com
									</a>
								</p>
								<p>
									2.{' '}
									{t('computer_safari_2', {
										browser: 'Firefox',
									})}
								</p>
								<p>3. {t('computer_firefox_1')}</p>
								<p>4. {t('computer_firefox_2')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationFirefox1.png?alt=media&token=ac17c32d-8448-460e-90b8-38855b7b9363'
									alt='settings icon in the address bar'
									className='imageEnableNotsFirefox'
								/>

								<p>6. {t('computer_firefox_3')}</p>
								<p>
									7. {t('computer_firefox_4', { setting: t('notifications') })}
								</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationFirefox2.png?alt=media&token=5f83120a-19e0-4445-9730-e412407482bc'
									alt='settings icon in the address bar'
									className='imageEnableNotsFirefox'
								/>
								<p>7. {t('computer_firefox_5')}</p>
								<p className='mg0'>8. {t('computer_safari_6')}</p>
							</div>
						</div>
					) : null}
					<CustomAlerts
						success={true}
						variant='outlined'
						message={t('notifications_finish')}
						margin='1.5rem 0 0'
					/>
				</div>
			</div>
			{!popup && <Footer />}
		</div>
	);
}
export default EnableNotifications;
