import WaveSurfer from 'wavesurfer.js';
import store from '../../redux/store';
import { AUDIO_NOT_FOUND } from '../../redux/types';
export const createWaveSurferInstance = async (
	fileUrl,
	postId,
	darkMode,
	isMobile,
	isMessagingSupported,
) => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	// Define the waveform gradient
	const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35);
	gradient.addColorStop(0, '#7e7e7e'); // Top color
	gradient.addColorStop((canvas.height * 0.7) / canvas.height, '#7e7e7e'); // Top color
	// gradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, '#ffffff'); // White line
	// gradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, '#ffffff'); // White line
	gradient.addColorStop((canvas.height * 0.7) / canvas.height, '#65666696'); // Bottom color
	gradient.addColorStop(1, '#65666696'); // Bottom color

	// Define the progress gradient
	const progressGradient = ctx.createLinearGradient(
		0,
		0,
		0,
		canvas.height * 1.35,
	);
	progressGradient.addColorStop(0, '#0a90dd'); // Top color
	progressGradient.addColorStop(
		(canvas.height * 0.7) / canvas.height,
		'#0a90dd',
	); // Top color
	// progressGradient.addColorStop(
	// 	(canvas.height * 0.7 + 1) / canvas.height,
	// 	'#ffffff',
	// ); // White line
	// progressGradient.addColorStop(
	// 	(canvas.height * 0.7 + 2) / canvas.height,
	// 	'#ffffff',
	// ); // White line

	progressGradient.addColorStop(
		(canvas.height * 0.7) / canvas.height,
		'#0a90dd7c',
	); // Bottom color
	progressGradient.addColorStop(1, '#0a90dd7c'); // Bottom color

	let ws = WaveSurfer.create({
		container: `#${postId}`,
		responsive: true,
		url: undefined,
		height: 128,
		width: '100%',
		splitChannels: false,
		normalize: true,

		// waveColor: waveColor,
		// progressColor: '#0a90dd',
		waveColor: isMobile ? '#7e7e7e' : darkMode ? gradient : '#7e7e7e',
		progressColor: isMobile
			? '#0a90dd'
			: darkMode
			? progressGradient
			: '#0a90dd',
		cursorColor: 'lightblue',
		cursorWidth: 3,
		barWidth: 2,
		barGap: 1,
		// backend: isMessagingSupported ? 'MediaElement' : 'WebAudio',
		// backend: isMobile ? 'WebAudio' : 'MediaElement',
		backend: 'MediaElement',
		barRadius: 1,
		barHeight: 1,
		barAlign: '',
		/** Minimum pixels per second of audio (i.e. zoom level) */
		minPxPerSec: 1,
		/** Stretch the waveform to fill the container, true by default */
		fillParent: true,
		/** Whether to show default audio element controls */
		mediaControls: false,
		mediaType: 'audio/mpeg',
		/** Play the audio on load */
		autoplay: false,
		/** Pass false to disable clicks on the waveform */
		interact: true,
		/** Allow to drag the cursor to seek to a new position */
		dragToSeek: isMobile ? false : true,
		/** Hide the scrollbar */
		hideScrollbar: false,
		/** Audio rate */
		audioRate: 1,
		/** Automatically scroll the container to keep the current position in viewport */
		autoScroll: true,
		/** If autoScroll is enabled, keep the cursor in the center of the waveform during playback */
		autoCenter: true,
		/** Decoding sample rate. Doesn't affect the playback. Defaults to 8000 */
		sampleRate: 8000,
		preload: 'metadata',
	});

	ws.load(fileUrl).catch((e) => {
		store.dispatch({
			type: AUDIO_NOT_FOUND,
			payload: fileUrl,
		});
		// console.log('=== Wavesurfer loading failed', e);
	});
	return ws;
};
