import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function IllegalGoodsAndServices() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Illegal Goods and Services - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Illegal Goods and Services</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						You are not allowed to utilize our platform for any unlawful
						purposes or activities. This prohibition extends to engaging in the
						sale, purchase, or facilitation of transactions involving illegal
						goods or services, as well as certain regulated goods or services.
					</p>
					<p>
						We prioritize the safety of our users. To address potential
						real-world risks, we strictly prohibit the use of our platform for
						any illegal activities or to further unlawful behavior. This
						includes transactions related to illegal goods or services, as well
						as certain regulated items. In certain instances, we may request
						collaboration with law enforcement agencies. Furthermore, we
						actively monitor for activities that may violate these guidelines
						and subject them to review.
					</p>

					<h2>What Constitutes a Violation of This Policy?</h2>

					<p className='bold'>Graphic content:</p>
					<ul className='text'>
						<li>Human trafficking.</li>
						<li>
							Weapons, including firearms, ammunition, explosives, and
							instructions on creating weapons (e.g., bombs, 3D printed guns,
							etc.).
						</li>
						<li>Sexual services.</li>
						<li>Counterfeit goods and services.</li>
						<li>Illegally obtained materials.</li>
						<li>Drugs and controlled substances.</li>
						<li>Products derived from endangered or protected species.</li>
					</ul>

					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Sale of illegal or regulated items".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>
					<p className='mg0'>
						Accounts suspected of employing deceptive account information to
						conduct spamming, abusive, or disruptive activities aimed at
						promoting the sale of illegal or regulated goods and services may
						face suspension. If it's found that you have violated this policy,
						your account may also be suspended.{' '}
						<b>This action can be taken upon the initial review.</b>
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default IllegalGoodsAndServices;
