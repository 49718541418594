import { useEffect, useState } from 'react';
import CustomAlerts from './CustomAlerts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import Media from 'react-media';
import { Dialog, DialogContent, Toolbar } from '@mui/material';
import EnableNotifications from '../../pages/USEFUL/EnableNotifications';
import {
	allowNots,
	setFCMData,
	denyBackgroundMessaging,
} from '../../redux/actions/userActions';
import { connect, useDispatch } from 'react-redux';
import CustomButton from './CustomButton';
import { getFCMToken } from '../../firebase/firebaseInit';
import { useTranslation } from 'react-i18next';

const NotificationPermission = ({
	user: {
		allowNotifications,
		denyMessaging,
		credentials: { notificationToken, fcmtCreatedAt },
		fcmData: { disabledPush },
	},
	data: { isMessagingSupported },
	settings,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [notsAllowed, setNotsAllowed] = useState(true);
	const [prompt, setPrompt] = useState(false);
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		isNotificationAllowed();
		return () => {};
	}, []);

	const handleDialog = () => {
		setOpened(!opened);
	};

	const recheckPermission = () => {
		Notification.requestPermission()
			.then((permission) => {
				if (permission === 'granted') {
					dispatch(allowNots(true));
					dispatch(getFCMToken(notificationToken, fcmtCreatedAt));
				} else {
					dispatch(allowNots(false));
					setNotsAllowed(false);
					setPrompt(false);
				}
			})
			.catch((err) => {
				// console.log(err);
				dispatch(setFCMData({ disabledPush: true }));
			});
	};
	const isNotificationAllowed = () => {
		if (typeof Notification === 'undefined') {
			return;
		}
		if (Notification.permission === 'granted') {
			dispatch(allowNots(true));
		} else if (Notification.permission === 'denied') {
			dispatch(allowNots(false));
			setNotsAllowed(false);
			setPrompt(false);
		} else if (Notification.permission === 'default') {
			setNotsAllowed(false);
			const prompt = !allowNotifications;
			setPrompt(prompt);
		}

		if ('permissions' in navigator) {
			navigator.permissions
				.query({ name: 'notifications' })
				.then(function (notificationPerm) {
					notificationPerm.onchange = function () {
						if (notificationPerm.state === 'granted') {
							dispatch(allowNots(true));
							setNotsAllowed(true);
						} else if (notificationPerm.state === 'denied') {
							dispatch(allowNots(false));
							setNotsAllowed(false);
							setPrompt(false);
						} else if (
							notificationPerm.state === 'default' ||
							notificationPerm.state === 'prompt'
						) {
							dispatch(allowNots(false));
							setNotsAllowed(false);
							setPrompt(true);
						}
					};
				});
		}
	};

	const denyNots = () => {
		denyBackgroundMessaging();
	};
	return (
		<div>
			<CustomAlerts
				error={!notsAllowed && !prompt}
				severity='warning'
				margin={settings ? '0 0 1rem 0' : '0'}
				icon={<NotificationsIcon fontSize='small' />}
				message={
					<div className='text alignItemsCenter'>
						{t('notifications_denied')}
						<CustomButton
							onClick={handleDialog}
							className='topTranslate'
							btnText={t('how_to_enable_notifications')}
							id='underlineHover'
							margin='0'
							padding='0 0 0 6px'
							disableRipple
						/>
					</div>
				}
				alertId='smallMuiAlert'
			/>
			{!denyMessaging &&
				isMessagingSupported &&
				allowNotifications === false &&
				disabledPush === undefined && (
					<CustomAlerts
						info={!notsAllowed && prompt}
						margin={settings ? '0 0 1rem 0' : '0'}
						noBorder={!settings}
						icon={<NotificationsIcon fontSize='small' />}
						message={
							<div className='text alignItemsCenter'>
								{t('notifications_allow')}
								<CustomButton
									onClick={recheckPermission}
									className='topTranslate'
									btnText={t('request_permission')}
									id='underlineHover'
									margin='0'
									padding='0 0 0 6px'
									disableRipple
								/>
							</div>
						}
						alertId='smallMuiAlert'
						close
						onClose={denyNots}
					/>
				)}

			<Media
				queries={{
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) => (
					<Dialog
						open={opened}
						onClose={handleDialog}
						disableEnforceFocus
						fullWidth
						maxWidth={!matches.isMobile ? 'xl' : false}
						fullScreen={matches.isMobile}
					>
						<Toolbar id='dialogBar'>
							<div className='titleToolbar'>{t('cam_permissions')}</div>
							<CloseIcon
								id='closeDialogButton'
								onClick={handleDialog}
								aria-label='close'
							/>
						</Toolbar>

						<DialogContent sx={{ padding: 0 }}>
							<EnableNotifications popup />
						</DialogContent>
					</Dialog>
				)}
			</Media>
		</div>
	);
};

const mapActionsToProps = {
	allowNots,
	denyBackgroundMessaging,
};

const mapStateToProps = (state) => ({
	user: state.user,
	data: state.data,
});

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(NotificationPermission);
