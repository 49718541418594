import logo from '../../util/Images/logo.svg';
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useTranslation } from 'react-i18next';

export default function Logo() {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();

	return (
		<div>
			<Helmet>
				<title>Logo - Goodbye App</title>
			</Helmet>
			<div className='skyBackground'>
				<div className='pd2Media'>
					<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'} `}>
						<div className='flexColumn alignItemsCenter'>
							<div className='bigBackgroundLogo mg'>
								<img className='bigLogo' src={logo} alt='Logo' />
							</div>
						</div>
						{/* <div className='center alignItemsCenter'>
							<span className='subtitle  mglH topTranslate'>Our Logo</span>
						</div> */}
						<h2 className='subtitle center mg0'>{t('our_logo')}</h2>
						<p>{t('our_logo_paragraph')}</p>
						<p>{t('our_logo_paragraph_2')}</p>
						<p>{t('our_logo_paragraph_3')}</p>
						<p>{t('our_logo_paragraph_4')}</p>
						<p>{t('our_logo_paragraph_5')}</p>
						<hr />
						<hr className='mgb' />
						<CustomAlerts
							info={t('our_logo_final_message')}
							icon={<AllInclusiveIcon />}
							margin='0'
						/>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
