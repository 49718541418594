import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';

import CopyrightIcon from '@mui/icons-material/Copyright';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import CopyrightProceedings from './CopyrightProceedings';

function Copyright() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Copyright policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<CopyrightIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Copyright policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<div className='flex alignItemsCenter'>
						<a
							href='//www.dmca.com/Protection/Status.aspx?ID=eb86050a-951f-424d-85c9-5da8d99a1122'
							title='DMCA.com Protection Status'
							className='dmca-badge'
						>
							<img
								src='https://images.dmca.com/Badges/dmca-badge-w100-2x1-03.png?ID=eb86050a-951f-424d-85c9-5da8d99a1122'
								alt='DMCA.com Protection Status'
							/>
						</a>
						<script src='https://images.dmca.com/Badges/DMCABadgeHelper.min.js'></script>
						<p className='textTitle bold mglH'>Overview</p>
					</div>
					<p>
						<span className='notranslate'> Goodbye App </span> understands the
						importance of copyright exceptions, which are legal provisions
						allowing individuals to use copyrighted material without seeking
						explicit permission from the copyright holder, under specific
						circumstances. These exceptions play a crucial role in fostering
						creativity, promoting freedom of expression, and balancing the
						rights of creators and users.
					</p>
					<p>
						Note that not all unauthorized uses of copyrighted materials are
						infringements (see our{' '}
						<Link to='/fair-use' id='link' className='bold'>
							fair use
						</Link>{' '}
						article for more information).
					</p>
					<p>
						In the United States, the well-known copyright exception is fair
						use. When determining whether a particular use falls under fair use,
						courts consider four primary factors: the purpose and character of
						the use, the nature of the copyrighted work, the amount and
						substantiality of the portion used, and the effect of the use on the
						potential market for the copyrighted work.
					</p>
					<p>
						Activities like criticism, commentary, and news reporting often
						qualify as fair use and contribute to a vibrant public discourse. In
						contrast, some civil law countries, including many in the European
						Union (EU), recognize more limited exceptions categorized under
						specific headings. For instance, Article 17 of the EU Digital Single
						Market copyright directive outlines categories such as quotation,
						criticism, review, caricature, parody, and pastiche.
					</p>
					<p>
						These categories are interpreted by national courts and the Court of
						Justice of the European Union (CJEU) to ensure a fair balance
						between freedom of expression and copyright protection. Other
						countries like Canada, the UK, and Australia follow a hybrid concept
						known as fair dealing.
					</p>
					<p>
						Similar to fair use, fair dealing involves considering factors such
						as the purpose of the use and the nature of the work. However, the
						use must fall within specific categories like quotation, criticism,
						review, or news reporting. Many countries worldwide have ratified
						the Berne Convention, an international treaty that provides for
						certain copyright exceptions, including those for quotation and news
						reporting. However, despite some commonalities in copyright
						exceptions globally, there remain significant variations in laws
						between countries.
					</p>
					<p>
						At <span className='notranslate'> Goodbye App,</span> we are
						committed to promoting creativity and freedom of expression. We urge
						copyright holders to consider copyright exceptions before submitting
						removal requests, as this approach respects both copyright
						protection and users' rights to express themselves within legal
						boundaries. By embracing copyright exceptions, we can create a
						balanced environment that nurtures innovation and diverse
						perspectives while upholding intellectual property rights.
					</p>
					<h2>Submit Report</h2>
					<CopyrightProceedings />
					<h2>
						Here are the general steps for processing a copyright complaint
						under the Digital Millennium Copyright Act (DMCA):
					</h2>
					<ol className='text'>
						<li>
							Identify the copyrighted material that you believe has been
							infringed upon. This could include providing a link to the
							original work or a clear description of the materials being
							infringed.
						</li>
						<li>
							Identification of the infringing material along with sufficient
							information to allow us to locate it on our website or services.
							If you are reporting the content of a specific post, please
							provide a direct link to that post. It's important to note that a
							link to a profile page alone is insufficient for us to identify
							infringing materials. Please specify if the alleged infringement
							pertains to the header, avatar, or any other specific part of the
							content. Be sure to provide the URL or website/webpage link of the
							content you want removed. If the stolen content is an image or
							video located on our website provide the direct link of the
							content contained in the site.
						</li>
						<li>
							What is the content owner's name and how was the content stolen?
							How is this content yours? How do you own it? Did you create it,
							buy it, copyright it? Who is claiming ownership of the content?
							Who is authorized to file the DMCA Takedown? When did you create
							the content and when was the content stolen?
						</li>
						<li>
							Submit on{' '}
							<a
								href='https://www.dmca.com/Takedowns.aspx?ad=dmcatkdmaxrow&gad_source=1&gclid=CjwKCAjwt-OwBhBnEiwAgwzrUlwokECgkZ_y3iN1NJ7uMvb-vkQLvVx5NrS84NMKEcJxd-9U5G3w0RoCDv0QAvD_BwE'
								target='_blank'
								rel='noopener noreferrer'
								className='link underlineHover'
							>
								DMCA.com
							</a>
						</li>
					</ol>
					<h2>
						Can I still file a DMCA Takedown Notice if I do not live in the USA?
					</h2>
					<p>
						<b>Yes.</b> Most countries accept the standard DMCA Takedown Notice
						form and process. Although the DMCA Takedown is part of US Copyright
						law, a DMCA Takedown Notice is often used and accepted throughout
						the world and not exclusive to the United States. However, many
						countries have their own copyright laws specifically related to the
						removal of content from internet service providers and site owners
						within their borders. What service providers where do and do not
						accept the standard DMCA takedown notice form and process is dynamic
						and constantly changing. DMCA.com's Takedown Professionals are aware
						of these changes and are able to convert the information submitted
						through the standard DMCA Takedown sign up form to suit specific
						countries and/or service providers within the legal process they
						require.
					</p>
					<h2>When to Submit a Counter-Notice</h2>
					<p>
						A counter-notice is a formal request to reinstate the content that
						was removed, marking the beginning of a legal process with
						significant legal implications. By submitting a counter-notice, you
						agree to the jurisdiction of a U.S. Federal court and the disclosure
						of your personal information to the complainant.
					</p>
					<p>
						You may submit a counter-notice if you believe the removed content
						was misidentified or if you have a genuine belief that it should not
						have been taken down. If you're uncertain about whether to file a
						counter-notice, seeking legal advice from an attorney is
						recommended.
					</p>
					<p>
						<b>Note:</b> Reposting content that was removed due to a copyright
						complaint may lead to permanent account suspension. If you believe
						the removal was a mistake, please file a counter-notice instead of
						reposting the content.
					</p>
					<h2>
						What are the consequences if my account receives one or more
						copyright complaints?
					</h2>
					<p>
						If an account receives multiple copyright complaints or if there is
						evidence indicating a pattern of repeated infringement, we reserve
						the right to suspend the account following our{' '}
						<Link to='/repeat-infringer' id='link' className='bold'>
							Repeat Infringer Policy
						</Link>
						. This policy considers valid retractions and counter-notices. Users
						suspended under this policy may have the option to file an appeal
						for suspension.
					</p>
					<a
						href='//www.dmca.com/Protection/Status.aspx?ID=eb86050a-951f-424d-85c9-5da8d99a1122'
						title='DMCA.com Protection Status'
						className='dmca-badge center flex'
					>
						<img
							src='https://images.dmca.com/Badges/dmca-badge-w200-2x1-03.png?ID=eb86050a-951f-424d-85c9-5da8d99a1122'
							alt='DMCA.com Protection Status'
						/>
					</a>
					<hr />
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Copyright;
