import {
	Avatar,
	AvatarGroup,
	CircularProgress,
	Collapse,
	Grow,
	Radio,
	Skeleton,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomButton from './CustomButton';
import { noImgUrl, shortenDate } from '../../redux/actions/dataActions';
import CustomTooltip from './CustomTooltip';
import { TransitionGroup } from 'react-transition-group';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
const Recipients = ({
	admin,
	avatars,
	recipients,
	deleteRecipient,
	loadRecipients,
	message,
	radio,
	radioUserId,
	radioAction,
	radioActionIcon,
	radioSelect,
	documents,
	loading,
	dontShowCount,
	isSmall,
}) => {
	const noImg = noImgUrl();
	const { t } = useTranslation();
	let list;

	if (avatars) {
		list = (
			<AvatarGroup id='mp_avatarGroup' max={20}>
				{loadRecipients
					? loadRecipients.map((rec, index) => (
							<Avatar
								sx={{ flexDirection: 'row-reverse' }}
								style={{ boxShadow: `0px 0px 3px 1px #6DCCFA` }}
								id='mp_loaderRecipientAvatars'
								key={index}
							>
								<CircularProgress
									variant='indeterminate'
									size={20}
									thickness={3}
								/>
							</Avatar>
					  ))
					: recipients &&
					  recipients.map((recipient, index) => {
							const recipientImageUrl =
								recipient.imageUrl === noImg ? null : recipient.imageUrl;
							return (
								<CustomTooltip title={recipient.handle} arrow key={index}>
									<Avatar
										style={{
											backgroundColor: '#000',
											color: '#fff',
											fontFamily: 'Lexend',
											border: '#000',
											boxShadow: `0px 0px 3px 1px ${recipient.imageColor}`,
										}}
										alt={recipient.handle}
										src={recipientImageUrl}
										className='pointer notranslate'
									>
										<span className='topTranslate notranslate'>
											{recipient.handle.toUpperCase().charAt(0)}
										</span>
									</Avatar>
								</CustomTooltip>
							);
					  })}
				{!dontShowCount &&
					((recipients && recipients.length > 6) ||
						(documents && documents.length > 6)) && (
						<CustomTooltip
							title={
								recipients && recipients.length > 1
									? `${recipients.length} ${t('recipients')}`
									: recipients && recipients.length === 1
									? `1 ${t('recipient')}`
									: documents && documents.length > 1
									? `${documents.length} ${t('documents')}`
									: `1 ${t('document')}`
							}
							arrow
						>
							<Avatar
								style={{
									fontWeight: 'bold',
									backgroundColor: '#000',
									color: '#fff',
									border: 'dotted',
									fontFamily: 'serif',
									width: '30px',
									height: '30px',
								}}
							>
								{recipients
									? recipients.length
									: // : documents
									  // ? documents.length
									  recipients.length}
							</Avatar>
						</CustomTooltip>
					)}
			</AvatarGroup>
		);
	} else if (documents && recipients) {
		list = (
			<Grow
				in={true}
				style={{ transformOrigin: 'bottom' }}
				{...(true ? { timeout: 800 } : {})}
			>
				<div className='userSelection_MP'>
					{documents.map((obj, index) => {
						const recipient = recipients.find(
							(recipient) => recipient.userId === obj.userId,
						);
						const recipientImageUrl =
							!recipient || (recipient && recipient.imageUrl === noImg)
								? null
								: recipient.imageUrl;
						return (
							<div
								id='gbl_paperRecipient'
								className={radio ? 'pointer' : ''}
								key={index}
							>
								<div
									className='flex spaceBetween alignCenter'
									onClick={() =>
										radioAction
											? radioAction(obj.letterId)
											: radioSelect
											? radioSelect(obj.userId)
											: ''
									}
								>
									<div className='mp_containerAvatarHandle '>
										{recipient ? (
											<div className='flex alignItemsCenter'>
												<CustomTooltip
													title={
														recipient.name ? recipient.name : recipient.handle
													}
													arrow
												>
													<Avatar
														style={{
															backgroundColor: '#000',
															color: '#fff',
															fontFamily: 'Lexend',
															border: '#000',
															boxShadow: `0px 0px 3px 1px ${recipient.imageColor}`,
														}}
														alt={recipient.handle}
														src={recipientImageUrl}
														className='pointer'
													>
														<span className='topTranslate notranslate'>
															{recipient.handle.toUpperCase().charAt(0)}
														</span>
													</Avatar>
												</CustomTooltip>
												<div className='mp_paperUserRecipientHandle mglH notranslate'>
													<p
														className={`mg0 textStart ${
															recipient.name ? '' : 'bold'
														}`}
													>
														{recipient.name && (
															<span className='bold capitalize mgr3px'>
																{recipient.name}
															</span>
														)}{' '}
														{recipient.handle}
													</p>
												</div>
											</div>
										) : loading ? (
											<div className='flex alignItemsCenter loadingRecipient'>
												<Skeleton
													variant='circular'
													width='40px'
													height='40px'
													className='mgr'
												/>
												<Skeleton
													variant='rectangular'
													width={200}
													animation='wave'
												/>
											</div>
										) : (
											<div className='mp_paperUserRecipientHandle mglH'>
												<span>{t('sender_not_found')}</span>
											</div>
										)}
									</div>
									<div>
										<span className='mgr3px nowrap'>
											{obj.releaseDate && isSmall
												? shortenDate(dayjs(obj.releaseDate).fromNow())
												: dayjs(obj.releaseDate).fromNow()}
										</span>
										{recipient && radioActionIcon && !isSmall && (
											<CustomButton
												iconButton
												startIcon={radioActionIcon}
												margin='0 -6px 0 0'
												color='inherit'
											/>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</Grow>
		);
	} else {
		list = (
			<Grow
				in={true}
				style={{ transformOrigin: 'bottom' }}
				{...(true ? { timeout: 800 } : {})}
			>
				<div className='userSelection_MP'>
					{!admin && <div className='text mgY'>{message}</div>}
					<TransitionGroup>
						{recipients.map((recipient, index) => {
							const recipientImageUrl =
								recipient.imageUrl === noImg ? null : recipient.imageUrl;
							return (
								<Collapse key={index}>
									<div
										id='gbl_paperRecipient'
										className={`${radio ? 'pointer' : ''} max600w `}
										key={index}
									>
										<div
											className='flex spaceBetween alignItemsCenter'
											onClick={() =>
												radioSelect && radioSelect(recipient.userId)
											}
										>
											<div className='mp_containerAvatarHandle'>
												<CustomTooltip title={recipient.handle} arrow>
													<Avatar
														style={{
															backgroundColor: '#000',
															color: '#fff',
															fontFamily: 'Lexend',
															border: '#000',
															boxShadow: `0px 0px 3px 1px ${recipient.imageColor}`,
														}}
														alt={recipient.handle}
														src={recipientImageUrl}
														className='pointer'
													>
														<span className='topTranslate notranslate'>
															{recipient.handle.toUpperCase().charAt(0)}
														</span>
													</Avatar>
												</CustomTooltip>

												<div className='mp_paperUserRecipientHandle mglH'>
													<p className='mg0 notranslate'>
														{recipient.name && (
															<span className='bold notranslate'>
																{recipient.name} -{' '}
															</span>
														)}{' '}
														{recipient.handle}
													</p>
												</div>
											</div>
											{radio ? (
												<Radio checked={radioUserId === recipient.userId} />
											) : deleteRecipient ? (
												<DeleteOutlineIcon
													// id='iconButton'
													onClick={() => deleteRecipient(recipient)}
													id='mp_paperRecipientDelete'
												/>
											) : null}
										</div>

										{radioAction && radioUserId === recipient.userId && (
											<div>
												<hr className='mg0' />
												<Grow in={true}>
													<div className='flex spaceAround'>
														<CustomButton
															startIcon={radioActionIcon}
															iconButton
															onClick={() => radioAction(recipient.letterId)}
														/>
													</div>
												</Grow>
											</div>
										)}
									</div>
								</Collapse>
							);
						})}
					</TransitionGroup>
				</div>
			</Grow>
		);
	}

	return list;
};

export default Recipients;
