import { useState } from 'react';
import { Avatar } from '@mui/material';
import { Tooltip } from '@mui/material';
import { noImgUrl } from '../../redux/actions/dataActions';
import { useSelector } from 'react-redux';

const ImgSrc = ({
	img,
	handle,
	css,
	imgColor,
	height,
	width,
	fontSize,
	stronger,
	light,
	margin,
	thumbnail,
	tooltip,
	noMargin,
	onClick,
}) => {
	const mode = useSelector((state) => state.UI.mode);
	const [loaded, setLoaded] = useState(thumbnail ? false : true);
	const [loadingError, setLoadingError] = useState(false);
	const isDefaultImage = img === noImgUrl();
	const imgSrc =
		isDefaultImage || loadingError || img === undefined ? (
			<Tooltip title={tooltip ? handle : ''} arrow>
				<Avatar
					aria-label='IMG'
					id={css}
					onClick={() => onClick && onClick()}
					className={noMargin ? '' : 'mgr'}
					src={isDefaultImage ? undefined : img}
					style={{
						boxShadow: !imgColor
							? false
							: stronger
							? `0px 0px 9px 3px ${imgColor}`
							: light
							? `0px 0px 4px 0.5px ${imgColor}`
							: `0px 0px 5px 2px ${imgColor}`,
						// height,
						// width,
						margin,
						cursor: 'pointer',
						background: mode === 'dark' ? '#000' : '#fff',
					}}
				>
					<span
						className='postUserFL notranslate'
						style={{ fontSize, color: mode === 'dark' ? '#fff' : '#000' }}
					>
						{handle?.toUpperCase().charAt(0)}
					</span>
				</Avatar>
			</Tooltip>
		) : (
			<img
				id={css}
				src={img}
				alt='IMG'
				className={noMargin ? '' : 'mgr'}
				onClick={() => onClick && onClick()}
				style={{
					background: `url(${thumbnail ? thumbnail : ''}) no-repeat`,
					backgroundSize: 'cover',
					filter: !loaded && 'blur(0.5px)',
					boxShadow: !imgColor
						? false
						: stronger
						? `0px 0px 9px 3px ${imgColor}`
						: `0px 0px 5px 2px ${imgColor}`,
					margin,
					cursor: 'pointer',
				}}
				loading='lazy'
				key={Math.random()}
				onLoad={() => setLoaded(true)}
				onError={(event) => {
					event.onerror = null;
					setLoadingError(true);
					// console.log('Error:', event);
				}}
			/>
		);

	return imgSrc;
};

export default ImgSrc;
