import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function PrivateInformation() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Private Information Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Private Information Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						You are strictly prohibited from sharing or posting private
						information about others without their explicit authorization. This
						includes refraining from threatening to reveal private details or
						encouraging others to do so. Furthermore, sharing private media like
						images or videos of individuals without their consent is also not
						allowed.
					</p>
					<p>
						We understand that certain situations may arise where users share
						such media for newsworthy events or to contribute to public
						discussions on important issues. In such cases, we may make
						exceptions and allow the media to remain accessible on the platform.
					</p>

					<h2>What Constitutes a Violation of This Policy?</h2>

					<p>
						Any content that portrays or advocates for child sexual
						exploitation, including but not limited to:
					</p>
					<ul className='text'>
						<li>
							Identity documents, including government-issued IDs and national
							identity numbers, with limited exceptions based on regional
							privacy standards.
						</li>
						<li>
							Home address or physical location details, including street
							addresses, GPS coordinates, or any identifying information related
							to private locations.
						</li>
						<li>
							Live location data, such as travel routes or real-time physical
							location information, whether shared directly on the platform or
							through third-party URLs.
						</li>
						<li>
							Media portraying prisoners of war, specifically those posted by
							government or state-affiliated media accounts on or after April 5,
							2022.
						</li>
						<li>
							Personal contact information, such as private phone numbers or
							email addresses.
						</li>
						<li>
							Financial account details, including bank account and credit card
							information.
						</li>
						<li>
							Other sensitive private information like biometric data, medical
							records, or media depicting individuals without their consent.
						</li>
					</ul>
					<h2>
						Additionally, the following behaviors are strictly prohibited:
					</h2>

					<ul className='text'>
						<li>
							Sharing information that could facilitate unauthorized access or
							hacking into someone's private data, such as sharing login
							credentials for online banking.
						</li>
						<li>
							Threatening to expose someone's private information publicly.
						</li>
						<li>
							Offering or requesting a bounty or financial reward in exchange
							for posting or refraining from posting someone's private
							information, which is commonly known as blackmail.
						</li>
					</ul>
					<h2>What Does Not Constitute a Violation of This Policy?</h2>
					<p>
						The following actions are not considered violations of this policy:
					</p>
					<ol className='text'>
						<li>
							Individuals sharing their own private information voluntarily.
						</li>
						<li>
							Sharing publicly available location data after a reasonable
							period, ensuring the individual's safety from potential harm.
						</li>
						<li>
							Sharing information that is already publicly available elsewhere
							in a non-abusive manner, excluding live location data.
						</li>
						<li>
							Sharing information deemed non-private, such as:
							<ul className='text'>
								<li>Name</li>
								<li>Birthdate or age</li>
								<li>Physical appearance descriptions</li>
								<li>Educational or employment details</li>
								<li>Gossip, rumors, accusations, or allegations</li>
								<li>
									Location data related to commercial properties or public
									businesses
								</li>
								<li>
									Screenshots of text messages or messages from other platforms
									that do not contain private information like phone numbers.
								</li>
							</ul>
						</li>
					</ol>
					<h2>
						Regarding media, the following actions are not in violation of our
						policy:
					</h2>

					<ol className='text'>
						<li>
							Sharing media that is publicly accessible or covered by mainstream
							media sources.
						</li>
						<li>
							Media and accompanying text that contribute value to public
							discourse or serve public interest.
						</li>
						<li>
							Media containing eyewitness accounts or real-time reports from
							ongoing events.
						</li>
						<li>Media featuring public figures as subjects.</li>
					</ol>

					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Intellectual property infringement".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>

					<p>
						When you violate this policy, the penalty depends on various
						factors, including the severity of the violation and your past
						record of rule infractions.
					</p>
					<p className='bold'>
						For sharing private or live location information:
					</p>
					<ul className='text'>
						<li>
							First Violation: You will receive a warning and be required to
							remove the private information. Your account will be temporarily
							locked until you comply.
						</li>
						<li>
							Subsequent Violations: If you share private information again
							after the initial warning, your account will face permanent
							suspension. Similarly, if your account is primarily dedicated to
							sharing live location information, it will be automatically
							suspended.
						</li>
					</ul>
					<p className='bold'>For sharing private media without consent:</p>
					<ul className='text'>
						<li>
							First Violation: You must remove the private media, and your
							account will be temporarily locked until you comply with the
							policy.
						</li>
					</ul>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default PrivateInformation;
