import { Avatar, Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Footer from '../../components/Footer/Footer';
import { useSelector } from 'react-redux';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ComputerIcon from '@mui/icons-material/Computer';
import DevicesIcon from '@mui/icons-material/Devices';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import { dim } from '../../redux/actions/dataActions';
import { useTranslation } from 'react-i18next';
function EnableCamera({ popup }) {
	const mode = useSelector((state) => state.UI.mode);
	const { t } = useTranslation();
	const [browser, setBrowser] = useState('');
	const [device, setDevice] = useState('');
	const [brand, setBrand] = useState('iphone');
	useEffect(() => {
		const userAgent = window.navigator.userAgent;

		if (userAgent.includes('Chrome')) {
			setBrowser('chrome');
		} else if (userAgent.includes('Firefox')) {
			setBrowser('firefox');
		} else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
			setBrowser('safari');
		}

		if (/Mobi|Android|iPhone/.test(userAgent)) {
			setDevice('phone');
			if (userAgent.includes('Android')) {
				setBrand('android');
			} else if (userAgent.includes('iPhone')) {
				setBrand('iphone');
			}
		} else {
			setDevice('computer');
		}
	}, []);

	const radioChange = ({ browser, device, brand }) => {
		if (device) {
			setDevice(device);
		}

		if (browser) {
			setBrowser(browser);
		}

		if (brand) {
			setBrand(brand);
			if (brand === 'iphone') {
				setBrowser('safari');
			}
			if (brand === 'android' && browser === 'safari') {
				setBrowser('chrome');
			}
		}
	};

	return (
		<div>
			<Helmet>
				<title>Camera - Goodbye App</title>
			</Helmet>
			<div className='skyBackground pd2Media'>
				<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<CameraAltIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mg0'>{t('camera')}</h1>
					<h3 className='subtitle center mg0'>
						{t('useful_subtitle', { setting: t('camera').toLowerCase() })}
					</h3>
					<p>
						{t('useful_desc', {
							setting: `${t('your')} ${t('camera').toLowerCase()}`,
						})}
					</p>
					<p>
						{t('useful_desc_2', {
							setting: t('camera').toLowerCase(),
							action: t('recording').toLowerCase(),
						})}
					</p>
					<hr />
					<CustomAlerts
						success={true}
						message={t('select_device_type')}
						icon={<DevicesIcon />}
						margin='0 0 0.5rem'
					/>
					<div className='flexBrowsers spaceAround mgbH'>
						<div
							className='flexColumn alignItemsCenter pointer'
							onClick={() => radioChange({ device: 'phone', browser, brand })}
						>
							<SmartphoneIcon className='mgYH' />
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio checked={device === 'phone'} />
								<p className='mg0'>{t('smart_phone')}</p>
							</div>
						</div>
						<div
							className='flexColumn alignItemsCenter pointer'
							onClick={() =>
								radioChange({ device: 'computer', browser, brand })
							}
						>
							<ComputerIcon className='mgYH' />
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio checked={device === 'computer'} />
								<p className='mg0'>{t('computer')}</p>
							</div>
						</div>
					</div>
					{device === 'phone' && (
						<div>
							<CustomAlerts
								success={true}
								message={t('brand')}
								severity='warning'
								icon={<PhonelinkSetupIcon />}
								margin='0 0 0.5rem'
							/>
							<div className='flexBrowsers spaceAround mgbH'>
								<div
									className='flexColumn alignItemsCenter pointer'
									onClick={() => radioChange({ brand: 'iphone' })}
								>
									<AppleIcon className='mgYH' />
									<div className='flex alignItemsCenter leftTranslate20'>
										<Radio checked={brand === 'iphone'} />
										<p className='mg0'>Iphone</p>
									</div>
								</div>
								<div
									className='flexColumn alignItemsCenter pointer'
									onClick={() => radioChange({ brand: 'android', browser })}
								>
									<AndroidIcon className='mgYH' />
									<div className='flex alignItemsCenter leftTranslate20'>
										<Radio checked={brand === 'android'} />
										<p className='mg0'>Android</p>
									</div>
								</div>
							</div>
						</div>
					)}
					<CustomAlerts
						info={true}
						message={t('select_browser')}
						icon={<TravelExploreIcon />}
						margin='0 0 0.5rem'
					/>
					<div className='flexBrowsers spaceAround'>
						<div
							className='flexColumn alignItemsCenter pointer'
							onClick={() => radioChange({ browser: 'chrome' })}
						>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2Fchrome_svg.png?alt=media&token=e8bd0586-1ffd-4dab-82b2-dc2ae0cdb931'
								alt='chrome svg'
								className='browserLogo'
							/>
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio checked={browser === 'chrome'} />
								<p className='mg0'>Google Chrome</p>
							</div>
						</div>
						<div
							style={dim(device === 'phone' && brand === 'android')}
							className='flexColumn alignItemsCenter pointer'
							onClick={() => radioChange({ browser: 'safari' })}
						>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2Fsafari_svg.png?alt=media&token=f77cea0c-2e4a-4e7c-bb1f-321af530600f'
								alt='safari svg'
								className='browserLogo'
							/>
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio
									checked={browser === 'safari'}
									disabled={device === 'phone' && brand === 'android'}
								/>
								<p className='mg0'>Safari</p>
							</div>
						</div>
						<div
							className='flexColumn alignItemsCenter  pointer'
							onClick={() => radioChange({ browser: 'firefox' })}
						>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FmozillaFirefox_svg.png?alt=media&token=c95ce92a-2848-4f55-932e-d12a5a181a85'
								alt='mozilla firefox svg'
								className='browserLogo'
							/>
							<div className='flex alignItemsCenter leftTranslate20'>
								<Radio checked={browser === 'firefox'} />
								<p className='mg0'>Mozilla Firefox</p>
							</div>
						</div>
					</div>
					<hr />

					{device === 'phone' && brand === 'android' ? (
						<div>
							<h2 className='mgbOnly mgtH'>
								{browser === 'chrome' ? 'Google Chrome' : 'Firefox'}
							</h2>
							<p>1. {t('android_useful_1')}</p>
							<p>2. {t('android_useful_2')}</p>
							<p>
								3.{' '}
								{t('android_useful_3', {
									browser: browser === 'chrome' ? 'Google Chrome' : 'Firefox',
								})}
							</p>

							<p>4. {t('android_useful_4')}</p>
							<p>
								5.{' '}
								{t('android_useful_5', {
									setting: t('camera'),
								})}
							</p>
							<p className='mgtOnly'>
								6.{' '}
								{t('android_useful_6', {
									setting: t('camera'),
								})}
							</p>
						</div>
					) : device === 'phone' && brand === 'iphone' ? (
						<div>
							<h2 className='mgbOnly mgtH'>
								{browser === 'safari'
									? 'Safari'
									: browser === 'chrome'
									? 'Google Chrome'
									: 'Firefox'}
							</h2>
							<p>1. {t('iphone_google_1')}</p>
							<img
								src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FmobileSettings.png?alt=media&token=b5263f79-377f-44fa-9aa8-9bfa5ab0b131'
								alt='settings icon'
							/>
							<p>
								2. {t('iphone_google_2')}{' '}
								<b>
									{browser === 'safari'
										? 'Safari.'
										: browser === 'chrome'
										? 'Google Chrome.'
										: 'Firefox.'}
								</b>
							</p>
							{browser === 'safari' ? (
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FmobileSafari2.png?alt=media&token=df10a5fc-a3b8-4f85-835c-ba67fd3bf492'
									alt='safari settings'
								/>
							) : browser === 'chrome' ? (
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FchromeMobile1Ios.jpeg?alt=media&token=ae0593a6-4e23-4c22-8c13-f49623c97d54'
									alt='chrome settings'
									width='400'
								/>
							) : (
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FfirefoxMobileIos.jpeg?alt=media&token=266b9036-efe5-4230-bb9e-bbf2b1b8f880'
									alt='firefox settings'
									width='400'
								/>
							)}

							{browser === 'safari' ? (
								<div>
									<p>3. {t('iphone_safari_1', { setting: t('camera') })}</p>
									<img
										src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FmobileSafari3.png?alt=media&token=33248f89-ef9f-4253-aa1b-5415ac211464'
										alt='safari settings'
									/>
									<p>4. {t('iphone_safari_2', { setting: t('camera') })}</p>
									<img
										src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FmobileSafari4.png?alt=media&token=3f7669d0-7277-49b4-a7da-b4926c971fb5'
										alt='safari allow interface'
									/>
								</div>
							) : (
								<div>
									<p>3. {t('iphone_google_3', { setting: t('camera') })}</p>

									<img
										src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FallowBrowser.jpeg?alt=media&token=c94f596f-5a57-4791-b52c-c15ba802216e'
										alt='chrome settings'
										width='400'
									/>
								</div>
							)}
						</div>
					) : device === 'computer' && browser === 'chrome' ? (
						<div>
							<h2 className='mgbOnly mgtH'>Google Chrome</h2>
							<div className='text'>
								<p>
									1. {t('useful_browser_1', { browser: 'Google Chrome' })}{' '}
									<a
										href='https://goodbyeapp.com/'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold notranslate'
									>
										goodbyeapp.com
									</a>
								</p>
								<p>2. {t('computer_google_2')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationChrome1.jpg?alt=media&token=4022a381-3049-4f12-8f64-dc92f8e4ac21'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
								<p>
									3.{' '}
									{t('computer_google_3', {
										setting: t('camera'),
									})}
								</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FcamChrome2.jpg?alt=media&token=1bbf7987-40e9-4d82-822d-80eabc9f060f'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>

								<p>
									4.{' '}
									{t('computer_google_4', {
										setting: t('camera'),
									})}
								</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableMicrophone%2FmicChrome4.jpg?alt=media&token=f0914c3b-2e57-468f-ac78-4eb24bf21c3d'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
								<p>
									5.{' '}
									{t('computer_google_5', {
										setting: t('camera'),
									})}
								</p>
								<p>
									6.{' '}
									{t('computer_google_6', {
										setting: t('camera'),
									})}
								</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FcamChrome4.jpg?alt=media&token=30d877b7-ebe5-45a6-acd0-acf9f850b73f'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
							</div>
						</div>
					) : device === 'computer' && browser === 'safari' ? (
						<div>
							<h2 className='mgbOnly mgtH'>Safari</h2>
							<div className='text'>
								<p>
									1. {t('useful_browser_1', { browser: 'Safari' })}{' '}
									<a
										href='https://goodbyeapp.com/'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold notranslate'
									>
										goodbyeapp.com
									</a>
									.
								</p>
								<p>
									2.{' '}
									{t('computer_safari_2', {
										browser: 'Safari',
									})}
								</p>
								<p>3. {t('computer_safari_3')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2Fsafari1.jpg?alt=media&token=0ebc9ba3-238f-489e-984d-ab2bc11c3842'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
								<p>4. {t('computer_safari_4')}</p>
								<p>
									5.{' '}
									{t('computer_safari_5', {
										setting: t('camera'),
									})}
								</p>
								<p>6. {t('computer_safari_6')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableCamera%2FcamSafari3.jpg?alt=media&token=f530d355-6f26-4952-967c-9c94dbd5cd39'
									alt='settings icon in the address bar'
									className='imageEnableNots'
								/>
							</div>
						</div>
					) : device === 'computer' && browser === 'firefox' ? (
						<div>
							<h2 className='mgbOnly mgtH'>Mozilla Firefox</h2>
							<div className='text'>
								<p>
									1. {t('useful_browser_1', { browser: 'Mozilla Firefox' })}{' '}
									<a
										href='https://goodbyeapp.com/'
										target='_blank'
										rel='noreferrer'
										id='link'
										className='bold notranslate'
									>
										goodbyeapp.com
									</a>
								</p>
								<p>
									2.{' '}
									{t('computer_safari_2', {
										browser: 'Firefox',
									})}
								</p>
								<p>3. {t('computer_firefox_1')}</p>
								<p>4. {t('computer_firefox_2')}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationFirefox1.png?alt=media&token=ac17c32d-8448-460e-90b8-38855b7b9363'
									alt='settings icon in the address bar'
									className='imageEnableNotsFirefox'
								/>

								<p>5. {t('computer_firefox_3')}</p>
								<p>6. {t('computer_firefox_4', { setting: t('camera') })}</p>
								<img
									src='https://firebasestorage.googleapis.com/v0/b/gbapp-b859e.appspot.com/o/enableNotifications%2FnotificationFirefox2.png?alt=media&token=5f83120a-19e0-4445-9730-e412407482bc'
									alt='settings icon in the address bar'
									className='imageEnableNotsFirefox'
								/>
								<p>7. {t('computer_firefox_5')}</p>
								<p className='mg0'>8. {t('computer_safari_6')}</p>
							</div>
						</div>
					) : null}
					<CustomAlerts
						success={true}
						variant='outlined'
						message={t('useful_finish', { setting: t('camera') })}
						margin='1.5rem 0 0'
					/>
				</div>
			</div>

			{!popup && <Footer />}
		</div>
	);
}
export default EnableCamera;
