import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function DeceptiveIdentities() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Misleading and Deceptive Identities Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>
						Misleading and Deceptive Identities Policy
					</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						Users are prohibited from using fake identities or misrepresenting
						themselves as individuals, groups, or organizations that they are
						not associated with. This includes creating profiles with false
						information that could mislead other users about the true identity
						of the account owner. While users have the option to maintain
						privacy by not displaying their real names or images, they are still
						required to uphold authenticity and refrain from using deceptive
						practices that could harm the trust and integrity of the platform.
					</p>
					<p>
						These rules are designed to maintain the integrity and authenticity
						of <span className='notranslate'> Goodbye App </span> community.
					</p>

					<h2>What Constitutes a Violation of This Policy?</h2>

					<p>
						<b>Impersonation and Deceptive Identities</b> are strictly
						prohibited on our platform to maintain authenticity and trust within
						our community. Impersonation involves posing as an existing person,
						group, or organization with the intent to mislead others about your
						identity or affiliations. This includes using elements such as
						names, images, or false claims of association in profiles or posts.
						Similarly, Deceptive Identities involve portraying oneself as a
						nonexistent individual to mislead others about who you are or
						represent. This can include using someone else's image or making
						false statements of affiliation, as well as using computer-generated
						images to pose as nonexistent individuals. These policies are in
						place to uphold transparency, honesty, and trustworthiness in all
						interactions on our platform.
					</p>

					<h2>What Does Not Constitute a Violation of This Policy?</h2>

					<p>
						At <span className='notranslate'> Goodbye App,</span> we value the
						freedom for individuals to express themselves authentically while
						maintaining their privacy. This is why we allow the use of
						pseudonymous accounts, where users are not obligated to use their
						real names or images in their profiles. We understand that many
						users may prefer to use pseudonyms or avatars to represent
						themselves online for various reasons, and we respect their choice.
					</p>
					<p>
						It's important to note that accounts on{' '}
						<span className='notranslate'> Goodbye App </span> are permitted to
						have similar names or appearances as long as their intention is not
						to deceive or manipulate others. For example, if two accounts share
						the same name but have no other commonalities and their purpose is
						not to mislead or impersonate, they are not in violation of our
						policy. Our aim is to create a platform where users can freely
						express themselves while maintaining a safe and transparent
						environment for everyone.
					</p>
					<p>
						We encourage users to use pseudonyms responsibly and to avoid
						misrepresenting themselves or others in any way that could lead to
						confusion or harm within our community.
					</p>

					<ul className='text'>
						<li>
							Account name: The account name should be distinct and clearly
							indicate that the account is not associated with the individual,
							group, or organization portrayed in the profile. Users can do this
							by incorporating terms such as "parody," "fake," "fan," or
							"commentary" in the account name. These terms should be easily
							understandable to any audience and should not be contradicted by
							using terms like "official" or similar affiliation terminology.
						</li>
						<li>
							Biography: The biography of the account should explicitly state
							that it is not affiliated with the subject depicted in the
							profile. This can be achieved by including phrases such as "not
							affiliated with," "parody," "fake," "fan," or "commentary" in the
							biography. The language used should be clear and understandable to
							all users, ensuring that there is no confusion regarding the
							account's true affiliation or purpose.
						</li>
					</ul>
					<p>
						These behaviors are considered disruptive and are not allowed on the{' '}
						<span className='notranslate'> Goodbye App </span> platform.
					</p>

					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Impersonation".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>

					<p>
						When you violate this policy, the penalty depends on various
						factors, including the severity of the violation and your past
						record of rule infractions.
					</p>
					<p className='bold'>Profile modifications:</p>
					<ul className='text'>
						<li>
							If there are concerns that your account's affiliation or
							representation could lead to confusion among users or violate our
							guidelines regarding parody, commentary, or fan accounts, we may
							request that you make necessary adjustments to your profile
							content for clarity. This is essential to ensure transparency and
							prevent misleading information or misinterpretation among our
							community members. Persistent violations of this policy following
							the initial warning may result in the temporary or permanent
							suspension of your account to uphold the integrity of our platform
							and user experience.
						</li>
					</ul>

					<p className='bold'>Permanent suspension:</p>
					<ul className='text'>
						<li>
							If we detect that you are engaging in impersonation or using a
							deceptive identity, your account may be subject to immediate
							suspension. In such cases, we may also request that you provide
							valid government-issued identification, such as a driver’s license
							or passport, as part of our verification process before
							reinstating your account. This is done to ensure the authenticity
							and legitimacy of user identities on our platform and to maintain
							a safe and trustworthy environment for all users.
						</li>
					</ul>

					<p className='bold mg0'>
						Our spam policy is designed to prevent the misuse of our services
						for deceptive, manipulative, or disruptive purposes. By adhering to
						these guidelines, users contribute to a positive and genuine
						experience on <span className='notranslate'> Goodbye App </span>,
						ensuring that our community remains vibrant, engaging, and free from
						harmful practices. We appreciate the cooperation of our users in
						upholding these standards and creating a respectful and enjoyable
						environment for everyone.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default DeceptiveIdentities;
