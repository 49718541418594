import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';

function SubmittingAppeals() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Appeals - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Appeals</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						Depending on the type of infringement, users might be able to appeal
						the decision and possibly get reinstated the removed content or
						account. Our goal is to ensure fair treatment and transparency in
						content moderation. Here’s how you can appeal a content removal:
					</p>

					<h2>
						Appealing Removed Content on{' '}
						<span className='notranslate'> Goodbye App</span>
					</h2>
					<p>
						<b>Notification of Removed Content:</b> When your content is
						removed, you will receive an email and an in-app notification
						informing you of the removal. The reason for the removal might be
						due to a copyright or trademark violation, impersonation, unlawful
						conduct, harassment, etc.
					</p>
					<p>
						<b>Accessing the Appeal Process</b>
						Click on the specific infringement notification in your user
						notifications panel. This will redirect you to the the removed
						content, where you can delete the infringing data (comment, post or
						account) or appeal the decision if granted.
					</p>
					<p>You can view or find removed content by:</p>

					<p className='bold'>Email Notification</p>
					<ol className='text'>
						<li>Sign in to your email account.</li>
						<li>Click on the infringement email.</li>
						<li>Click appeal button if present.</li>
					</ol>
					<p className='bold'>Filtering Notifications</p>
					<ol className='text'>
						<li>
							Open <span className='notranslate'> Goodbye App,</span>{' '}
							authenticate and go to the notification panel.
						</li>
						<li>
							Use the filter options to select "Infringement" to see only the
							notifications related to content removals.
						</li>
						<li>
							Click on any infringement notification to start the appeal process
							(if granted).
						</li>
					</ol>

					<p>
						By following these steps, users can effectively manage and appeal
						content removals, ensuring their voices are heard and their content
						is handled fairly.
					</p>

					<p>
						<b>Submitting an Appeal:</b> On the appeal page, you will find a
						form to provide additional information or context that supports your
						appeal. You can explain why you believe the content should not have
						been removed and provide any relevant evidence or documentation.
						Then, submit the appeal form for further review.
					</p>
					<p>
						<b>Appeal Review Process:</b> Once your appeal is submitted, our
						team will review it and determine whether the content can be
						restored. You will receive updates on the status of your appeal via
						email and in-app notifications.
					</p>
					<p>
						<b>Outcome of Appeal:</b> If your appeal is successful, the removed
						content will be restored, and you will be notified via email and
						in-app notification.
					</p>
					<p>
						If your appeal is denied, you will receive an explanation for the
						decision.
					</p>

					<hr />
					<CustomAlerts
						info="While we strive to provide a fair appeals process, it's important to
						note that not every content removal decision can be appealed. Here's
						why:"
						margin='0'
					/>
					<ul className='text'>
						<li className='mgb'>
							<b>Violations of Clear Policies:</b> Content that clearly violates
							our policies, such as hate speech, violence, or explicit material,
							may not be eligible for appeal. These policies are designed to
							maintain a safe and respectful environment for all users.
						</li>
						<li className='mgb'>
							<b>Legal Compliance:</b> Content that infringes upon legal
							regulations, such as copyright violations or unlawful activities,
							may not be subject to appeal. Compliance with legal requirements
							is paramount, and we must uphold these standards.
						</li>
						<li className='mgb'>
							<b>Immediate Safety Concerns:</b> If content poses an immediate
							risk to user safety or security, such as phishing links or harmful
							malware, it may be removed without the option for appeal. Our
							priority is to protect our users from potential harm.
						</li>
						<li className='mgb'>
							<b>Repeated Violations:</b> Content from accounts with a history
							of repeated violations or policy breaches may not be eligible for
							appeal. Consistent adherence to our policies is essential for
							maintaining a positive user experience.
						</li>
					</ul>
					<p>
						While we understand the importance of appealing content removals,
						these limitations ensure that our platform remains safe, compliant,
						and conducive to meaningful interactions. Users are encouraged to
						review our policies and guidelines to understand what content is
						permissible on the platform.
					</p>
					<hr />

					<p>
						Our team carefully evaluates each appeal, considering the context,
						policies, and user safety implications.
					</p>
					<p>
						We encourage users to familiarize themselves with our policies and
						guidelines to ensure compliance and minimize the risk of content
						removals. Transparency, communication, and user feedback are
						integral parts of our appeal process, and we are committed to
						continuously improving and refining our procedures to best serve our
						community.
					</p>
					<p>
						<b>Support Contact:</b> If you need further assistance or have
						questions about the appeal process, you can contact our support team
						directly through the app or by visiting our{' '}
						<Link to='/contact' id='link' className='bold'>
							support page
						</Link>
						.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default SubmittingAppeals;
