import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Spam() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Platform Manipulation and Spam Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>
						Platform Manipulation and Spam Policy
					</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						Engaging in any form of artificial manipulation or suppression of
						information on the{' '}
						<span className='notranslate'>Goodbye App's</span> services, as well
						as behavior that disrupts the platform's defenses against
						manipulation, is strictly prohibited.{' '}
						<span className='notranslate'> Goodbye App </span> aims to foster
						genuine human connections, provide reliable information, and ensure
						a safe and open environment for expression and goodbye declaration.
						Therefore, spamming and platform manipulation in any form are not
						tolerated. Platform manipulation encompasses various prohibited
						activities, including spamming for commercial purposes, creating
						inauthentic engagements to inflate popularity, coordinating
						activities to influence conversations, and promoting harmful
						behavior.
					</p>
					<p>
						These rules are designed to maintain the integrity and authenticity
						of <span className='notranslate'> Goodbye App </span> community.
					</p>

					<h2>What Constitutes a Violation of This Policy?</h2>

					<p>
						Operating multiple accounts with the intention of artificially
						boosting or manipulating the visibility of content or{' '}
						<span className='notranslate'> Goodbye App </span> accounts is
						prohibited. This includes actions such as:
					</p>
					<ul className='text'>
						<li>Creating multiple accounts to boost trending hashtags.</li>
						<li>Engaging with the same posts and accounts.</li>
						<li>
							Misusing the mention or reply feature across multiple accounts.
						</li>
						<li>
							Improperly boosting one's own accounts through misuse of
							engagement features like likes and comments.
						</li>
					</ul>
					<p>
						Such behavior is aimed at manipulating the prominence of content and
						accounts and goes against the principles of fair and authentic
						interaction on the{' '}
						<span className='notranslate'> Goodbye App </span>.
					</p>
					<p>
						Boosting engagement or metrics artificially is not allowed on the{' '}
						<span className='notranslate'> Goodbye App </span>. This includes
						activities such as buying or selling followers, likes, comments,
						mentions, using or promoting third-party apps that promise to
						increase followers or engagement, engaging in reciprocal exchanges
						to boost follows or Post engagements (e.g., participating in "follow
						trains," "decks," or "Like for Like" schemes), and engaging in the
						sale, purchase, trade, or offering of{' '}
						<span className='notranslate'> Goodbye App </span> accounts,
						usernames, or temporary access to{' '}
						<span className='notranslate'> Goodbye App </span> accounts. These
						practices are considered manipulative and go against the principles
						of authentic interaction and community building on the{' '}
						<span className='notranslate'> Goodbye App </span>.
					</p>

					<h2>
						Additionally, the following behaviors are strictly prohibited:
					</h2>

					<p>
						You are prohibited from misusing{' '}
						<span className='notranslate'> Goodbye App </span> product features
						in a way that disrupts the experience of others. This includes
						activities such as:
					</p>

					<ul className='text'>
						<li>
							Sending bulk, aggressive, or high-volume unsolicited replies, or
							mentions.
						</li>
						<li>Repeatedly posting and deleting the same content.</li>
						<li>Excessively posting content</li>
						<li>Posting identical or nearly identical Posts multiple times.</li>
						<li>
							Duplicating existing phrases or content either individually or in
							coordination with other accounts.
						</li>
						<li>
							Leveraging the existing engagement on a Post to promote
							significantly different content.
						</li>
						<li>
							Repeatedly editing a Post to the extent that its meaning becomes
							substantially or completely different from its previous versions
							is not allowed. This includes making frequent changes to a Post
							that significantly alter its content, message, or purpose, leading
							to confusion or misinterpretation among users. It's important to
							ensure that edits to Posts maintain consistency and clarity,
							avoiding substantial changes that may mislead or deceive others.
						</li>
						<li>
							Following behaviors that are not allowed include "follow churn,"
							which involves following and then unfollowing numerous accounts to
							artificially boost one's own follower count, as well as
							indiscriminate following and unfollowing of unrelated accounts in
							a short period, especially through automated methods.
						</li>
						<li>
							Duplicating another account's followers, particularly using
							automation.
						</li>
						<li>
							Aggressive or automated actions aimed at driving traffic or
							attention to accounts, websites, products, services, or
							initiatives are not allowed.
						</li>
						<li>
							Posting with excessive, unrelated hashtags in a single post or
							across multiple posts is prohibited.
						</li>
						<li>
							Misusing hashtags includes using a trending or popular hashtag
							with the intent to subvert or manipulate a conversation or to
							drive traffic or attention to accounts, websites, products,
							services, or initiatives.
						</li>
					</ul>
					<p>
						These behaviors are considered disruptive and are not allowed on the{' '}
						<span className='notranslate'> Goodbye App </span> platform.
					</p>

					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Scam or fraud".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>

					<p>
						When you violate this policy, the penalty depends on various
						factors, including the severity of the violation and your past
						record of rule infractions.
					</p>
					<p className='bold'>To address suspicious activity:</p>
					<ul className='text'>
						<li>
							Accounts may undergo anti-spam challenges such as being locked and
							required to provide additional information like a phone number, or
							solve a reCAPTCHA. These measures help maintain the integrity of
							the platform and prevent spam or malicious behavior.
						</li>
					</ul>
					<p className='bold'>
						Limiting the visibility of Posts and temporary account locks:
					</p>
					<ul className='text'>
						<li>
							Posts that violate our policies may have limited visibility within
							certain areas of the{' '}
							<span className='notranslate'> Goodbye App </span> platform and
							may not be recommended or promoted by{' '}
							<span className='notranslate'> Goodbye App </span> algorithms.
							Further actions may include post deletion and temporary account
							locks for first-time offenders of platform manipulation or spam.
							Subsequent offenses will result in permanent suspension of the
							account(s).
						</li>
					</ul>
					<p className='bold'>Temporary limitations or restrictions:</p>
					<ul className='text'>
						<li>
							You may experience temporary limitations or restrictions on
							accessing various{' '}
							<span className='notranslate'> Goodbye App </span> features or
							products, such as Post, Edit Post, Letter, as part of our
							enforcement measures.
						</li>
					</ul>
					<p className='bold'>Permanent suspension:</p>
					<ul className='text'>
						<li>
							Accounts found to have engaged in severe violations will be
							permanently suspended upon first detection. Severe violations
							include operating accounts primarily engaged in behavior that
							breaches the policies outlined above, attempting to undermine
							election integrity using the tactics described, buying or selling
							accounts, creating accounts to replace suspended ones, and
							operating accounts linked to entities with a history of violating{' '}
							<Link to='/community-guidelines' id='link' className='bold'>
								Our Community Guidelines
							</Link>
							.
						</li>
					</ul>

					<p className='bold mg0'>
						In conclusion, our spam policy is designed to maintain the
						integrity, authenticity, and user experience of the platform. We do
						not allow spamming, platform manipulation, or any behavior that
						disrupts the community's trust and safety. By prohibiting activities
						such as artificially inflating metrics, engaging in follow churn,
						misusing hashtags, and manipulating conversations, we ensure that
						<span className='notranslate'> Goodbye App </span>
						remains a fair, transparent, and engaging platform for all users.
						These guidelines help us create a positive environment where people
						can connect, share meaningful content, and interact without
						interference from spam or manipulative tactics. Thank you for
						helping us uphold these standards and contribute to a vibrant and
						respectful community on the{' '}
						<span className='notranslate'> Goodbye App </span>.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Spam;
