import { Link } from 'react-router-dom';

export default function LinkFormatter({
	str,
	adminPanelActive,
	previewPost,
	mentions,
	hashtags,
	commentFormatter,
}) {
	const findMentionIndex = (mention) => {
		return mentions.findIndex((user) => `@${user.handle}` === mention);
	};

	const hasHashtag = (hashtag) => {
		return hashtags.includes(hashtag);
	};

	// Preprocess the string to replace three or more consecutive \n with two \n
	const preprocessedStr = commentFormatter
		? str.replace(/\n{2,}/g, '\n')
		: str.replace(/\n{3,}/g, '\n\n');

	// Split by new lines while preserving new line characters
	const parts = preprocessedStr.split(/(\n+)/);

	// Process each part to format mentions and hashtags
	const content = parts.map((part, i) => {
		// If the part is a newline, return a <br> element
		if (part.match(/\n+/)) {
			return part.split('').map((char, j) => <br key={`${i}-${j}`} />);
		}

		// Otherwise, split the part into words
		const words = part.split(/(\s+)/).map((word, j) => {
			if (word.startsWith('@')) {
				const index = findMentionIndex(word);
				if (index !== -1) {
					return (
						<Link
							key={`${i}-${j}`}
							to={
								adminPanelActive || previewPost
									? '#'
									: `/users/${mentions[index].handle}`
							}
							state={{
								data:
									adminPanelActive || previewPost ? '' : mentions[index].userId,
							}}
							className='notranslate'
							id='brightLink'
						>
							{word}
						</Link>
					);
				}
			} else if (word.startsWith('#')) {
				if (hasHashtag(word.substring(1).toLowerCase())) {
					return (
						<Link
							key={`${i}-${j}`}
							to={adminPanelActive || previewPost ? '#' : '/feed'}
							state={{
								hashtag:
									adminPanelActive || previewPost
										? ''
										: word.substring(1).toLowerCase(),
							}}
							id='brightLink'
							className='notranslate lowercase'
						>
							{word}
						</Link>
					);
				}
			}
			return word;
		});

		return <span key={i}>{words}</span>;
	});

	return content;
}
