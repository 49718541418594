import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setActiveVideo, addViewed } from '../../redux/actions/dataActions';
import { useParams } from 'react-router-dom';

const VideoPlayer = ({
	fileUrl,
	postId,
	dialogPost,
	makePostFunctionality,
}) => {
	const playerRef = useRef(null);
	const dispatch = useDispatch();
	const [ref, inView] = useInView({ threshold: 0.9 });
	const [viewedCount, setViewedCount] = useState(0);
	const paramsPostId = useParams().postId;
	const activeVideoPostId = useSelector(
		(state) => state.data.activeVideoPostId,
	);
	const [duration, setDuration] = useState('');
	// const [aspectRatio, setAspectRatio] = useState('4 / 5'); // Default aspect ratio
	const [addedViewed, setAddedViewed] = useState(false);
	const filePlayed = localStorage.getItem('filePlayed');
	const openedDialog = useSelector((state) => state.data.openedDialog);
	const viewedPostIds = useSelector((state) => state.data.viewedPostIds);

	// const videoAspectRatio = () => {
	// 	if (playerRef.current && playerRef.current.getInternalPlayer()) {
	// 		const player = playerRef.current.getInternalPlayer();
	// 		const videoWidth = player.videoWidth || player.videoWidth();
	// 		const videoHeight = player.videoHeight || player.videoHeight();
	// 		if (videoWidth && videoHeight) {
	// 			const calculatedAspectRatio = (videoWidth / videoHeight).toFixed(2);
	// 			setAspectRatio(calculatedAspectRatio > 1 ? '16 / 9' : '4 / 5');
	// 		}
	// 	}
	// };

	useEffect(() => {
		if (
			inView &&
			playerRef &&
			filePlayed &&
			activeVideoPostId !== '' &&
			activeVideoPostId !== postId &&
			!dialogPost &&
			!makePostFunctionality
		) {
			localStorage.setItem('filePlayed', 0);
			playerRef.current.seekTo(0);
		} else if (
			inView &&
			playerRef &&
			filePlayed &&
			activeVideoPostId === postId
		) {
			playerRef.current.seekTo(filePlayed);
		}
		if (!dialogPost && !openedDialog) {
			if (inView && activeVideoPostId !== postId) {
				dispatch(setActiveVideo(postId));
			}
			if (!inView && activeVideoPostId === postId) {
				dispatch(setActiveVideo(''));
			}
		}
		return () => {};
	}, [inView, postId, dispatch, dialogPost, openedDialog, paramsPostId]);

	const onEnded = () => {
		if (viewedCount < 3 && !makePostFunctionality) {
			playerRef.current.seekTo(0);
		}
	};

	const updateViewed = (playedSeconds) => {
		localStorage.setItem('filePlayed', playedSeconds);

		if (
			!addedViewed &&
			(playedSeconds >= 30 || duration / 2 <= playedSeconds)
		) {
			setAddedViewed(true);
			const hasViewed = viewedPostIds.includes(postId);
			setViewedCount(viewedCount + 1);
			if (postId !== 'preview' && !hasViewed && !makePostFunctionality) {
				dispatch(addViewed(postId));
			}
		}
	};

	return (
		<div ref={ref} className='react-wrapper'>
			<ReactPlayer
				ref={playerRef}
				url={fileUrl}
				controls={true}
				playing={
					(!openedDialog && activeVideoPostId === postId && inView) ||
					dialogPost ||
					makePostFunctionality
				}
				loop={false}
				autoPlay={true}
				stopOnUnmount
				volume={0.5}
				onEnded={onEnded}
				onDuration={(duration) => setDuration(duration)}
				// onReady={videoAspectRatio}
				// width='100%'
				// height='100%'
				config={{
					file: {
						attributes: {
							// controlsList: 'nofullscreen nodownload',
							controlsList: 'nodownload',
							playsInline: true,
							disablePictureInPicture: true,
						},
					},
				}}
				className='react-player'
				onProgress={(progress) => {
					updateViewed(progress.playedSeconds);
				}}
			/>
		</div>
	);
};

const mapActionsToProps = {
	setActiveVideo,
	addViewed,
};
const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapActionsToProps)(VideoPlayer);
