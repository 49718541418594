import { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Dialog,
	Toolbar,
	DialogContent,
	ToggleButtonGroup,
	ToggleButton,
	CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import {
	fetchOnlyUserComments,
	fetchUserLikedData,
	resetUserLikedData,
	clearLoadingCommentsErrors,
	clearLoadingPostCommentsErrors,
} from '../../redux/actions/dataActions';
import { withRouter } from '../../redux/withRouter';
import PostComments from './PostComments';
import SmsIcon from '@mui/icons-material/Sms';
import ForumIcon from '@mui/icons-material/Forum';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CustomAlerts from './CustomAlerts';
import Media from 'react-media';
import { t } from 'i18next';

class OnlyComments extends Component {
	state = {
		showComments: false,
		oldPath: '',
		newPath: '',
		commentKey: '',
		sortBy: 'comment',
	};
	componentDidMount() {
		this.setState({
			showComments: true,
		});
		let sortBy = 'comment';
		if (this.props.location.state?.repliedId) {
			this.setState({
				sortBy: 'reply',
			});
			sortBy = 'reply';
		}

		if (
			this.props.onlyLiked &&
			this.props.data.likedCommentData.length === 0 &&
			sortBy === 'comment'
		) {
			this.props.fetchUserLikedData({ order: 'desc', sortBy: 'comment' });
		} else if (
			this.props.onlyLiked &&
			this.props.data.likedReplyData.length === 0 &&
			sortBy === 'reply'
		) {
			this.props.fetchUserLikedData({ order: 'desc', sortBy: 'reply' });
		} else if (
			this.props.onlyComments &&
			this.props.data.onlyUserComments.length === 0
		) {
			this.props.fetchOnlyUserComments({ order: 'desc' });
		}
	}

	hideComments = () => {
		const { pathname } = this.props.location;
		let split = pathname.split('/');
		let splicedStr = split.slice(0, split.length - 1).join('/');
		this.props.history(splicedStr);
		this.setState({ showComments: false, commentKey: '' });
	};

	componentWillUnmount() {
		if (
			this.props.data.likedCommentData.length !== 0 ||
			this.props.data.onlyUserComments.length !== 0
		) {
			this.props.resetUserLikedData();
		}
		if (this.props.data.errorLoadingMoreComments) {
			this.props.clearLoadingCommentsErrors();
		}
		if (
			this.props.data.errorLoadingPostComments ||
			this.props.data.errorLoadingOnlyComments ||
			this.props.data.errorLoadingLikedReplyData
		) {
			this.props.clearLoadingPostCommentsErrors();
		}
		this.setState({ showComments: false, commentKey: '' });
	}

	handleChange = (e) => {
		e.preventDefault();
		let value = e.target.value;
		if (value !== this.state.sortBy) {
			this.setState({ sortBy: value });
			if (
				value === 'comment' &&
				this.props.data.likedCommentData.length === 0 &&
				!this.props.data.loadingLikedCommentData &&
				!this.props.data.errorLoadingLikedCommentData
			) {
				this.props.fetchUserLikedData({
					order: 'desc',
					sortBy: 'comment',
				});
			}
			if (
				value === 'reply' &&
				this.props.data.likedReplyData.length === 0 &&
				!this.props.data.loadingLikedReplyData &&
				!this.props.data.errorLoadingLikedReplyData
			) {
				this.props.fetchUserLikedData({
					order: 'desc',
					sortBy: 'reply',
				});
			}
		}
	};

	refresh = async () => {
		if (this.props.onlyComments) {
			await this.props.fetchUserLikedData({ order: 'desc', sortBy: 'comment' });
		}
		if (this.props.onlyLiked && this.state.sortBy === 'comment') {
			await this.props.fetchUserLikedData({ order: 'desc', sortBy: 'comment' });
		}
		if (this.props.onlyLiked && this.state.sortBy === 'reply') {
			await this.props.fetchUserLikedData({ order: 'desc', sortBy: 'reply' });
		}
	};
	render() {
		const { showComments, sortBy } = this.state;
		const {
			data: {
				loadingOnlyComments,
				onlyUserComments,
				likedCommentData,
				likedReplyData,
				loadingLikedCommentData,
				loadingLikedReplyData,
				errorLoadingOnlyUserComments,
				errorLoadingLikedCommentData,
				errorLoadingLikedReplyData,
			},
			onlyLiked,
			onlyComments,
		} = this.props;

		return (
			<Media
				queries={{
					isMobile: '(max-width: 1064px)',
				}}
			>
				{(matches) =>
					showComments && (
						<Dialog
							disableEnforceFocus
							open={showComments}
							onClose={this.hideComments}
							style={{ zIndex: '4' }}
							fullWidth
							maxWidth={!matches.isMobile ? 'xl' : false}
							fullScreen={matches.isMobile}
						>
							<Toolbar id='dialogBar' sx={{ minWidth: '90vw' }}>
								<div className='longTitleToolbar'>
									{onlyComments
										? t('your_comments_and_replies')
										: onlyLiked && sortBy === 'comment'
										? t('liked_comments')
										: t('liked_replies')}
								</div>
								<CloseIcon
									id='closeDialogButton'
									onClick={this.hideComments}
									aria-label='close'
								/>
							</Toolbar>
							<DialogContent sx={{ padding: 0 }} id='scrollableOnlyComments'>
								<div>
									<PullToRefresh
										onRefresh={this.refresh}
										isPullable={
											(onlyComments === 'posts' && !loadingOnlyComments) ||
											(onlyLiked &&
												sortBy === 'comment' &&
												!loadingLikedCommentData) ||
											(onlyLiked &&
												sortBy === 'reply' &&
												!loadingLikedReplyData)
										}
										refreshingContent={
											<div id='circularProgressQuery'>
												<CircularProgress
													variant='indeterminate'
													id='queryUserAuthProgress'
													size={30}
													thickness={3}
												/>
											</div>
										}
										pullDownThreshold={50}
										resistance={1}
										pullingContent={
											<h3 className='center' id='mgt'>
												{t('pull_to_refresh')}
											</h3>
										}
									>
										{onlyLiked && (
											<ToggleButtonGroup
												name='Comment List'
												color='info'
												value={sortBy}
												exclusive
												onChange={this.handleChange}
												aria-label='Platform'
												fullWidth
											>
												<ToggleButton
													sx={{ borderRadius: '0' }}
													value='comment'
													id={sortBy === 'comment' ? 'noBorder' : ''}
												>
													<SmsIcon className='mgr' />
													{t('comments')}
												</ToggleButton>
												<ToggleButton
													sx={{ borderRadius: '0' }}
													value='reply'
													id={sortBy === 'reply' ? 'noBorder' : ''}
												>
													<ForumIcon className='mgr' />
													{t('replies')}
												</ToggleButton>
											</ToggleButtonGroup>
										)}
										<PostComments
											sortBy={sortBy}
											onlyComments={onlyComments}
											onlyLiked={onlyLiked}
											comments={
												onlyComments
													? onlyUserComments
													: onlyLiked && sortBy === 'comment'
													? likedCommentData
													: likedReplyData
											}
										/>
										{onlyComments && (
											<CustomAlerts
												info={errorLoadingOnlyUserComments}
												noMargin
											/>
										)}
										{onlyLiked && (
											<div>
												{sortBy === 'comment' && (
													<CustomAlerts
														info={errorLoadingLikedCommentData}
														noMargin
													/>
												)}
												{sortBy === 'reply' && (
													<CustomAlerts
														info={errorLoadingLikedReplyData}
														noMargin
													/>
												)}
											</div>
										)}
									</PullToRefresh>
								</div>
							</DialogContent>
						</Dialog>
					)
				}
			</Media>
		);
	}
}

OnlyComments.propTypes = {
	fetchOnlyUserComments: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
	fetchOnlyUserComments: PropTypes.func.isRequired,
	fetchUserLikedData: PropTypes.func.isRequired,
	resetUserLikedData: PropTypes.func.isRequired,
	clearLoadingCommentsErrors: PropTypes.func.isRequired,
	clearLoadingPostCommentsErrors: PropTypes.func.isRequired,
});

const mapActionsToProps = {
	fetchOnlyUserComments,
	fetchUserLikedData,
	resetUserLikedData,

	clearLoadingCommentsErrors,
	clearLoadingPostCommentsErrors,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(OnlyComments));
