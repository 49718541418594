import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CopyrightIcon from '@mui/icons-material/Copyright';
export default function CopyrightProceedings() {
	return (
		<div>
			<p className='bold'>In-app and Desktop</p>
			<p>You can report content for review in-app and desktop as follows:</p>
			<ol className='text'>
				<li>
					Select Report Account from the icon:
					<IconButton aria-haspopup='true'>
						<MoreHorizIcon aria-haspopup='true' />
					</IconButton>
				</li>
				<li>
					Select <b>"Intellectual property infringement.".</b>
				</li>
				<li>Submit your report.</li>
			</ol>
			{/* <p className='bold'>Email: copyright@goodbyeapp.com</p>
					<ContactOption
						icon={<CopyrightIcon id='cardMainIconSize' />}
						text='Copyright'
						contact='copyright@goodbyeapp.com'
					/> */}
			<h2>Legal Proceedings</h2>
			<p>
				<span className='notranslate'> Goodbye App </span> handles copyright
				complaints that are submitted under the{' '}
				<b>Digital Millennium Copyright Act ("DMCA").</b> Section 512 of the
				DMCA sets forth the requirements for formally reporting copyright
				infringement and provides guidance on how affected parties can challenge
				a removal by submitting a compliant counter-notice.
			</p>
			<p>
				If you have concerns regarding the use of your brand or entity's name,
				we recommend reviewing our{' '}
				<Link to='/trademark' id='link' className='bold'>
					trademark policy
				</Link>{' '}
				for guidance on protecting your intellectual property rights.
			</p>
			<p>
				Before submitting a copyright complaint to us, please consider whether
				or not the use could be considered{' '}
				<Link to='/fair-use' id='link' className='bold'>
					fair use
				</Link>
				.
			</p>
			<p>
				We address reports of alleged copyright infringement, including
				instances of unauthorized use of copyrighted images as profile or header
				photos, unauthorized use of copyrighted videos or images uploaded
				through our media hosting services.
			</p>
			<p>
				If you have thoroughly evaluated fair use and still intend to proceed
				with a copyright complaint on{' '}
				<span className='notranslate'> Goodbye App,</span> please note that:{' '}
			</p>
			<CustomAlerts
				info={true}
				severity='warning'
				margin='0'
				message="Under 17 U.S.C. § 512(f), you may be held accountable for any
							damages, including costs and attorneys' fees, incurred by us or
							our users if you knowingly materially misrepresent that material
							or activity is infringing."
			/>
			<p>
				It is crucial to ensure the accuracy of your claim before filing a
				notification with us. If you are uncertain about whether the material
				you are reporting constitutes infringement, we recommend seeking legal
				advice from an attorney to avoid potential legal repercussions.
			</p>
			<h2>Notifications of Infringement</h2>
			<p>
				If you are a copyright owner or an agent thereof, and you believe that
				any material available on our Services infringes your copyrights, then
				you may submit a written copyright infringement notification
				("Notification) using the contact details below pursuant to the DMCA.
				All such Notifications must comply with the DMCA requirements. You may
				refer to a{' '}
				<a
					href='https://www.dmca.com/Takedowns.aspx?ad=dmcatkdmaxrow&gad_source=1&gclid=CjwKCAjwt-OwBhBnEiwAgwzrUlwokECgkZ_y3iN1NJ7uMvb-vkQLvVx5NrS84NMKEcJxd-9U5G3w0RoCDv0QAvD_BwE'
					target='_blank'
					rel='noopener noreferrer'
					className='link underlineHover'
				>
					DMCA takedown notice generator
				</a>{' '}
				or other similar services to avoid making mistake and ensure compliance
				of your Notification.
			</p>
			<CustomAlerts
				message='Filing a DMCA complaint is the start of a pre defined legal process.
						Your complaint will be reviewed for accuracy, validity, and
						completeness. If your complaint has satisfied these requirements,
						our response may include the removal or restriction of access to
						allegedly infringing material.'
				info={true}
				variant='outlined'
				icon={<CopyrightIcon />}
			/>
			<p>
				If we remove or restrict access to materials or terminate an account in
				response to a Notification of alleged infringement, we will make a good
				faith effort to contact the affected user with information concerning
				the removal or restriction of access. Notwithstanding anything to the
				contrary contained in any portion of this Policy, the Operator reserves
				the right to take no action upon receipt of a DMCA copyright
				infringement notification if it fails to comply with all the
				requirements of the DMCA for such notifications. The process described
				in this Policy does not limit our ability to pursue any other remedies
				we may have to address suspected infringement.
			</p>
			<p>
				Our copyright agents that will receive notifications of claimed
				copyright infringement required by the Digital Millennium Copyright Act
				(“DMCA”):
			</p>
			<p>Active Service Provider: Goodbye App Corporation</p>
			<p>Registration Number: DMCA-1059455</p>
			<p className='notranslate'>
				GOODBYE APP CORPORATION 928 21ST ST APT 1 SANTA MONICA, CA 90403, United
				States dmca@goodbyeapp.com
			</p>
		</div>
	);
}
