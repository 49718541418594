import React, { useState, useEffect, useMemo } from 'react';
import {
	CircularProgress,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HttpsIcon from '@mui/icons-material/Https';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import KeyIcon from '@mui/icons-material/Key';
import FolderIcon from '@mui/icons-material/Folder';
import ExploreIcon from '@mui/icons-material/Explore';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_API_KEY;
function loadScript(src, position, id) {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null };

const CustomInput = ({
	type,
	titleLabel,
	smallLabel,
	label,
	inputFunction,
	placeholder,
	name,
	inputId,
	onChange,
	helperText,
	inputError,
	inputValue,
	inputAdornmentPosition,
	required,
	autoComplete,
	maxLength,
	autoFocus,
	fullWidth,
	deletable,
	isEmail,
	variant,
	margin,
	minRows,
	maxRows,
	multiline,
	disabled,
	focus,
	title,
	loadingLabel,
	startIconType,
	defaultValue,
	onFocus,
	onBlur,
	useLocation,
	noLabelMg,
}) => {
	const [capsLockOn, setCapsLockOn] = useState(false);
	const [visibility, setVisibility] = useState(true);
	const [focused, setFocused] = useState(false);
	const [shrink, setShrink] = useState(false);
	const [value, setValue] = React.useState(null);
	const [textfieldValue, setTextfieldValue] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const loaded = React.useRef(false);
	const mode = useSelector((state) => state.UI.mode);
	const darkMode = mode === 'dark';
	const checkCapsLock = (event) => {
		if (type === 'password' || type === 'confirmPassword') {
			setCapsLockOn(event.getModifierState('CapsLock'));
		}
	};

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=Function.prototype&libraries=places`,
				document.querySelector('head'),
				'google-maps',
			);
		}

		loaded.current = true;
	}

	const fetch = useMemo(
		() =>
			debounce((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 400),
		[],
	);

	useEffect(() => {
		if (useLocation) {
			let active = true;

			if (!autocompleteService.current && window.google) {
				autocompleteService.current =
					new window.google.maps.places.AutocompleteService();
			}
			if (!autocompleteService.current) {
				return undefined;
			}

			if (textfieldValue === '') {
				setOptions(value ? [value] : []);
				return undefined;
			}

			fetch({ input: textfieldValue }, (results) => {
				if (active) {
					let newOptions = [];

					if (value) {
						newOptions = [value];
					}

					if (results) {
						newOptions = [...newOptions, ...results];
					}

					setOptions(newOptions);
				}
			});

			return () => {
				active = false;
			};
		}
	}, [useLocation, value, textfieldValue, fetch]);

	const iconFunction = (event) => {
		event.preventDefault();
		if (deletable && focused && inputValue !== '') {
			deletable();
		} else if (inputValue) {
			setVisibility(!visibility);
		}
		if (inputId) {
			document.getElementById(inputId).focus();
		}
	};

	const onFocusTextField = (e) => {
		setShrink(true);
		if (deletable || type === 'password' || type === 'confirmPassword') {
			setFocused(true);
		}
		if (onFocus) {
			onFocus();
		}

		if (type !== 'email') {
			e.currentTarget.setSelectionRange(
				e.currentTarget.value.length,
				e.currentTarget.value.length,
			);
		}
	};

	const onBlurTextField = (e) => {
		if (!e.target.value || !inputValue) {
			setShrink(false);
		}
		if (deletable || type === 'password' || type === 'confirmPassword') {
			setFocused(false);
		}
		if (onBlur) {
			onBlur(e);
		}
	};

	return (
		<div className='fullWidth'>
			{titleLabel && variant === 'outlined' && (
				<label
					className={`${noLabelMg ? '' : 'mgt'} inputText mgb3px capitalize`}
				>
					{titleLabel}{' '}
					{capsLockOn && focused ? (
						<small className='mgl4px textInherit'> - Caps lock.</small>
					) : smallLabel ? (
						<small className='mgl4px textInherit'>{smallLabel}</small>
					) : (
						''
					)}
					{loadingLabel && (
						<CircularProgress
							id='queryUserAuthProgress'
							size={15}
							style={{ margin: '0 0 0 8px' }}
							thickness={3}
						/>
					)}
				</label>
			)}
			<div className='customInputContainer'>
				{useLocation ? (
					<Autocomplete
						id='google-map-demo'
						sx={{ margin: margin ? margin : 0 }}
						fullWidth
						getOptionLabel={(option) =>
							typeof option === 'string' ? option : option.description
						}
						filterOptions={(x) => x}
						options={options}
						autoComplete
						includeInputInList
						filterSelectedOptions
						defaultValue={inputValue}
						value={inputValue}
						freeSolo
						onChange={(e, newValue) => {
							setOptions(newValue ? [newValue, ...options] : options);
							if (newValue) {
								setValue(newValue);
							}
							onChange({
								target: {
									name: name,
									value: newValue ? newValue.description : '',
								},
							});
						}}
						onInputChange={(e, newInputValue) => {
							setTextfieldValue(newInputValue);
							onChange({
								target: { name: name, value: newInputValue },
							});
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={label}
								placeholder={placeholder}
								fullWidth
								multiline={useLocation} // this adds a console warning.
								sx={() => ({
									'& .MuiOutlinedInput-notchedOutline': {
										px: 1,
									},
								})}
								inputProps={{
									...params.inputProps,
									maxLength: maxLength,
								}}
								helperText={helperText}
								InputProps={{
									...params.InputProps,
									startAdornment: (
										<InputAdornment position='start'>
											<TravelExploreIcon
												className={`mgr6px- ${
													darkMode ? '' : 'textfieldIconBlue'
												}`}
											/>
										</InputAdornment>
									),
								}}
							/>
						)}
						renderOption={(props, option) => {
							const matches =
								option.structured_formatting.main_text_matched_substrings || [];

							const parts = parse(
								option.structured_formatting.main_text,
								matches.map((match) => [
									match.offset,
									match.offset + match.length,
								]),
							);

							return (
								<li {...props}>
									<Grid container alignItems='center'>
										<Grid item sx={{ display: 'flex', width: 44 }}>
											<LocationOnIcon sx={{ color: 'text.secondary' }} />
										</Grid>
										<Grid
											item
											sx={{
												width: 'calc(100% - 44px)',
												wordWrap: 'break-word',
											}}
										>
											{parts.map((part, index) => (
												<Box
													key={index}
													component='span'
													sx={{
														fontWeight: part.highlight ? 'bold' : 'regular',
													}}
												>
													{part.text}
												</Box>
											))}
											<Typography variant='body2' color='text.secondary'>
												{option.structured_formatting.secondary_text}
											</Typography>
										</Grid>
									</Grid>
								</li>
							);
						}}
					/>
				) : (
					<TextField
						label={label ? label : variant !== 'outlined' ? titleLabel : ''}
						variant={variant}
						onKeyUp={(e) => checkCapsLock(e)}
						onKeyDown={(e) => checkCapsLock(e)}
						minRows={minRows}
						defaultValue={defaultValue}
						value={inputValue}
						multiline={multiline}
						maxRows={maxRows}
						onClick={inputFunction}
						margin='normal'
						disabled={disabled}
						fullWidth={fullWidth}
						autoFocus={autoFocus}
						placeholder={placeholder}
						name={name}
						type={
							(type === 'password' || type === 'confirmPassword') && visibility
								? 'password'
								: type === 'email'
								? 'email'
								: type === 'number'
								? 'number'
								: type === 'tel'
								? 'tel'
								: 'username'
						}
						id={inputId}
						autoComplete={autoComplete}
						required={required}
						onChange={onChange}
						helperText={helperText}
						error={inputError}
						onFocus={(e) => onFocusTextField(e)}
						onBlur={(e) => onBlurTextField(e)}
						sx={() => ({
							margin: margin ? margin : 'auto',
							'& .MuiOutlinedInput-notchedOutline': {
								px: startIconType ? 4 : 'auto',
							},
						})}
						inputRef={(input) => input && focus && input.focus()}
						InputLabelProps={{
							shrink: inputValue ? true : shrink,
							style: {
								marginLeft: startIconType ? 26 : 0,
								textAlign: title ? 'center' : '',
							},
						}}
						inputProps={{
							style: {
								fontSize: title ? '1.4rem' : '1.2rem',
								textAlign: title ? 'center' : '',
							},
							maxLength: maxLength,
						}}
						InputProps={{
							startAdornment: startIconType && (
								<InputAdornment position='start' sx={{ pointerEvents: 'none' }}>
									<IconButton
										aria-label='toggle'
										edge='start'
										disabled={disabled}
										disableRipple={true}
									>
										{startIconType === 'search' ? (
											<SearchIcon
												className={`mgr10px- ${
													darkMode ? '' : 'textfieldIconBlue'
												}`}
											/>
										) : startIconType === 'location' ? (
											<ExploreIcon
												className={`mgr10px- ${
													darkMode ? '' : 'textfieldIconBlue'
												}`}
											/>
										) : startIconType === 'phoneNumber' ? (
											<LocalPhoneSharpIcon
												className={`mgr10px- ${
													darkMode ? '' : 'textfieldIconBlue'
												}`}
											/>
										) : startIconType === 'money' ? (
											<PaidSharpIcon
												className={`mgr10px- ${
													darkMode ? '' : 'textfieldIconBlue'
												}`}
											/>
										) : startIconType === 'keyLocation' ? (
											<div>
												<KeyIcon
													className={`mgr10px- ${
														darkMode ? '' : 'textfieldIconGray'
													}`}
												/>
											</div>
										) : startIconType === 'docLocation' ? (
											<div>
												<FolderIcon
													className={`mgr10px- ${
														darkMode ? '' : 'textfieldIconGray'
													}`}
												/>
											</div>
										) : (
											''
										)}
									</IconButton>
								</InputAdornment>
							),
							endAdornment:
								inputAdornmentPosition === 'end' ? (
									<InputAdornment position={inputAdornmentPosition}>
										<IconButton
											onMouseDown={iconFunction}
											disabled={disabled}
											aria-label='toggle'
											edge='end'
											disableRipple={
												(type !== 'email' &&
													type !== 'password' &&
													type !== 'confirmPassword' &&
													!focused &&
													!deletable) ||
												(type === 'email' && !isEmail && !focused) ||
												(type === 'password' && !inputValue) ||
												(type === 'confirmPassword' && !inputValue) ||
												(!inputValue &&
													deletable &&
													type !== 'password' &&
													type !== 'confirmPassword') ||
												(!deletable &&
													type !== 'password' &&
													type !== 'confirmPassword')
											}
											className='leftTranslate3'
											style={{
												cursor:
													inputValue &&
													(type === 'password' ||
														type === 'confirmPassword' ||
														deletable)
														? 'pointer'
														: 'default',
											}}
										>
											{deletable && focused && inputValue !== '' ? (
												<CloseIcon
													className={darkMode ? '' : 'textfieldIconBlue'}
												/>
											) : type === 'email' ? (
												isEmail ? (
													<MarkEmailReadIcon
														className={darkMode ? '' : 'textfieldIconBlue'}
													/>
												) : !isEmail && inputValue === '' ? (
													<MailOutlineIcon
														className={darkMode ? '' : 'textfieldIconBlue'}
													/>
												) : titleLabel === 'Email or Username' ? (
													<AccountCircleIcon
														className={darkMode ? '' : 'textfieldIconBlue'}
													/>
												) : !isEmail && inputValue !== '' ? (
													<UnsubscribeIcon id='textfieldIconError' />
												) : null
											) : (type === 'password' || type === 'confirmPassword') &&
											  inputValue === '' ? (
												<HttpsIcon
													className={darkMode ? '' : 'textfieldIconBlue'}
												/>
											) : (type === 'password' || type === 'confirmPassword') &&
											  visibility ? (
												<VisibilityIcon
													className={darkMode ? '' : 'textfieldIconBlue'}
												/>
											) : (type === 'password' || type === 'confirmPassword') &&
											  !visibility ? (
												<VisibilityOffIcon
													className={darkMode ? '' : 'textfieldIconBlue'}
												/>
											) : type === 'given-name' ? (
												<AccountCircleIcon
													className={darkMode ? '' : 'textfieldIconBlue'}
												/>
											) : null}
										</IconButton>
									</InputAdornment>
								) : (
									false
								),
						}}
					/>
				)}
			</div>
		</div>
	);
};
export default CustomInput;
