import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Trademark() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Trademark Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Trademark Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>
					<p>
						It is prohibited to infringe upon the intellectual property rights
						of others, including copyright and trademark violations.
					</p>
					<p className='bold'>
						A trademark refers to a word, logo, phrase, or symbol that
						distinguishes the goods or services of its owner in the marketplace.
						Trademark laws are in place to prevent unauthorized or misleading
						use of trademarks by others.
					</p>
					<h2>What Constitutes a Violation of This Policy?</h2>

					<p>
						Utilizing someone else's trademark in a manner that could mislead or
						confuse individuals regarding your association may constitute a
						violation of our trademark policy.
					</p>

					<h2>What Does Not Constitute a Violation of This Policy?</h2>
					<p>
						Referencing another's trademark does not necessarily violate Goodbye
						App's trademark policy. Examples of situations that do not
						constitute violations include:
					</p>
					<p>
						Using a trademark in a manner that falls outside the scope of the
						trademark registration, such as in a different geographic region or
						for different types of goods or services than those specified in the
						registration. Using a trademark in a nominative or other{' '}
						<Link to='/fair-use' id='link' className='bold'>
							fair use
						</Link>{' '}
						manner. For further details, please refer to our{' '}
						<Link
							to='/misleading-and-deceptive-identities'
							id='link'
							className='bold'
						>
							Misleading and deceptive identities
						</Link>
					</p>

					<h2>Who can report violations of this policy?</h2>

					<p>
						<span className='notranslate'> Goodbye App </span> only processes
						requests that are submitted by the trademark holder or their
						authorized representative, such as a legal representative or another
						representative acting on behalf of a brand.
					</p>
					<p>
						We encourage trademark holders to report any potential violations
						promptly.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Intellectual property infringement.".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>

					<p>
						If we find that you have breached our trademark policy, we reserve
						the right to suspend your account. Depending on the severity of the
						violation, we may offer you a chance to rectify the situation by
						adhering to our policies. In certain cases, an account may be
						permanently suspended upon initial review.
					</p>
					<p>
						Users who repeatedly violate our trademark policy may face escalated
						consequences, including permanent account suspension or legal
						action.
					</p>
					<p className='bold mg0'>
						Jurisdiction: Our trademark policy applies globally, and users are
						expected to comply with trademark laws and regulations relevant to
						their geographic location.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Trademark;
