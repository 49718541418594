import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSelector } from 'react-redux';

function AbuseHarrasment() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Abuse/Harassment Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Abuse/Harassment Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						At <span className='notranslate'> Goodbye App,</span> we uphold a
						strict policy against child sexual exploitation. Our platform
						maintains a firm stance against any content that features or
						endorses child sexual exploitation, as it represents one of the
						gravest violations of Our Rules. This encompasses various forms of
						media, including text, imagery, illustrations, and
						computer-generated depictions.
					</p>
					<p>
						Regardless of the intent behind it, the act of viewing, sharing, or
						linking to materials involving child sexual exploitation perpetuates
						the victimization of the depicted children. This policy also extends
						to any content that may further contribute to the victimization of
						minors by promoting or glorifying such exploitation. For the
						purposes of this policy, a minor is defined as any individual under
						the age of 18.
					</p>
					<h2>What Constitutes a Violation of This Policy?</h2>
					<p>
						We strictly forbid behaviors and content that involve harassing,
						shaming, or demeaning others, as outlined below. It's important to
						note that, to better understand the context, our teams may need to
						hear directly from the targeted individual in order to gather
						necessary information before taking appropriate and proportional
						enforcement measures.
					</p>
					<p>
						<b>Targeted Harassment:</b> We expressly prohibit the deliberate and
						unprovoked targeting (such as mentioning or tagging) of
						individual(s), especially when done with the intent to humiliate or
						degrade someone.
					</p>
					<p>
						<b>This may include:</b> Sharing numerous posts in a brief period or
						repeatedly posting replies containing malicious content to target an
						individual. This encompasses accounts solely dedicated to harassing
						one or more individuals. Mentioning or tagging users with malicious
						content.
					</p>
					<p>
						<b>Encouragement of Harassment:</b> We strictly prohibit behavior
						that seeks to motivate others to harass or single out specific
						individuals or groups with abusive behavior. This encompasses, but
						is not limited to, the following: Direct calls to target individuals
						or groups with online abuse or harassment. Actions that advocate for
						offline activities, such as physical harassment, against individuals
						or groups.
					</p>
					<p>
						<b>Derogatory Language:</b> We enforce measures against the
						utilization of insults or profanity aimed at others. Nevertheless,
						while certain terms may be offensive to some individuals, we
						exercise discretion and do not act against every instance where such
						language is employed.
					</p>
					<h2>What Does Not Constitute a Violation of This Policy?</h2>
					<p>
						We acknowledge that certain posts might seem harmful when examined
						individually, yet may not carry the same connotation when considered
						within a broader dialogue. For instance, friends might mutually
						employ specific terms or expressions as part of their interaction,
						which may appear offensive without this context. Additionally, our
						platform serves as a means to spotlight, denounce, or shed light on
						others' detrimental behaviors. In such instances, we refrain from
						intervention when the context is evidently non-abusive and aims to
						address such rhetoric.
					</p>
					<p>
						Furthermore, we uphold the principle that critique of institutions,
						practices, and concepts constitutes an essential aspect of freedom
						of expression. Consequently, we refrain from taking action on such
						critical assessments.
					</p>
					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Bullying or harassment"</b>, or{' '}
							<b>"Offensive words or symbols"</b>, or{' '}
							<b>"Violence or dangerous organizations".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>
					<p className='bold mg0'>
						When assessing the repercussions for violating this policy, we take
						various factors into account. These may encompass the severity of
						the violation, the user's history of infractions, the impact on
						affected parties, and the intent behind the behavior. Consequently,
						penalties may range from the removal of offending posts to the
						suspension of the user's account. Additionally, in certain cases, we
						may implement temporary restrictions on account privileges or
						provide warnings to encourage compliance with our guidelines.
					</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default AbuseHarrasment;
