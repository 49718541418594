import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const BackdropUi = ({ open, circularProgress, children }) => {
	return (
		<div style={{ position: 'relative' }}>
			<Backdrop
				sx={{
					color: '#FFFFFF',
					zIndex: (theme) => theme.zIndex.drawer + 1,
					position: 'absolute',
				}}
				open={open}
			>
				{circularProgress && <CircularProgress color='inherit' />}
			</Backdrop>
			{children}
		</div>
	);
};

export default BackdropUi;
