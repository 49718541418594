import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Alert, Avatar } from '@mui/material';
import CustomButton from '../../components/SocialComponents/CustomButton';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ErrorBoundary500 = ({ error, resetErrorBoundary }) => {
	const [boundaryId, setBoundaryId] = useState(null);
	const { t } = useTranslation();
	const reload = () => {
		window.location.replace('/login');
		setTimeout(() => {
			window.location.reload();
		}, 500);
	};

	useEffect(() => {
		const checkStorage = () => {
			const id = localStorage.getItem('boundaryId');
			if (id) {
				setBoundaryId(id);
			}
		};

		const intervalId = setInterval(checkStorage, 500);

		return () => {
			clearInterval(intervalId);
			localStorage.removeItem('boundaryId');
		};
	}, []);

	return (
		<div className='skyBackgroundFill pdMedia'>
			<div className='cardReport pd'>
				<Avatar id='cardMainIcon' className='flexAuto'>
					<PriorityHighIcon id='cardMainIconSize' />
				</Avatar>
				<p className='title mgH mgb center'>{t('oops')}</p>

				<Alert
					severity='success'
					id='muiAlert'
					sx={{
						borderRadius: '4px',
					}}
				>
					{t('err_b_logged')}{' '}
					{boundaryId ? `${t('identification')} ${boundaryId}` : ''}
				</Alert>
				<Alert
					severity='warning'
					id='muiAlert'
					sx={{
						borderRadius: '4px',
						margin: '1rem 0',
					}}
				>
					{t('err_b_details')}
				</Alert>
				<Alert
					icon={<AllInclusiveIcon />}
					color='primary'
					id='muiAlert'
					sx={{
						borderRadius: '4px',
					}}
				>
					{t('sorry_error_b')}
				</Alert>
				<hr />
				<div className='flex spaceAround'>
					<CustomButton
						id='customCancelButton'
						startIcon={<RotateLeftIcon />}
						btnText={t('reload')}
						onClick={reload}
						margin='0.5rem 0 0 0'
					/>
					<a
						href={
							boundaryId
								? `https://goodbyeapp.com/report/${boundaryId}`
								: 'https://goodbyeapp.com/report'
						}
						target='_blank'
						rel='noopener noreferrer'
					>
						<CustomButton
							id='customInfoButton'
							startIcon={<AnnouncementIcon />}
							btnText={t('report')}
							margin='0.5rem 0 0 0'
						/>
					</a>
				</div>
			</div>
		</div>
	);
};

export default ErrorBoundary500;
