import { Component } from 'react';
import { connect } from 'react-redux';
import {
	createMessage,
	clearMessageAction,
	unsentEmails,
} from '../../redux/actions/userActions';
import PropTypes from 'prop-types';
import CustomInput from '../../components/SocialComponents/CustomInput';
import CustomButton from '../../components/SocialComponents/CustomButton';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
	Divider,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Radio,
	Select,
} from '@mui/material';
import Searchbar from '../../components/SocialComponents/Searchbar';
import Switch from '../../components/SocialComponents/Switch';
import Recipients from '../../components/SocialComponents/Recipients';
import { toast } from 'react-toastify';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ThreePSharpIcon from '@mui/icons-material/ThreePSharp';
import ContactMailSharpIcon from '@mui/icons-material/ContactMailSharp';
import EmailIcon from '@mui/icons-material/Email';
import dayjs from 'dayjs';
import { t } from 'i18next';

const initialState = {
	subject: '',
	title: '',
	body: '',
	errors: {},
	emailRecipient: '',
	group: 'users',
	querySearchbar: true,
	recipients: [],
	topic: 'globalTopic',
	type: 'app',
	url: '',
	messageType: 'email',
	notificationType: 'email',
	template: '',
	btnText: '',
	resend: false,
};
class CreateMessage extends Component {
	state = {
		subject: '',
		title: '',
		body: '',
		errors: {},
		emailRecipient: '',
		group: 'users',
		querySearchbar: true,
		recipients: [],
		topic: 'globalTopic',
		type: 'app',
		url: '',
		messageType: 'email',
		notificationType: 'email',
		template: '',
		btnText: '',
		resend: false,
	};

	componentDidMount() {
		if (!this.props.custom) {
			this.setState({
				// subject: `Goodbye App ${this.props.reportType.slice(0, -1)}`,
				emailRecipient: this.props.reported
					? this.props.reported
					: this.props.userId,
			});
		}

		// if (this.props.component === 'report') {
		this.setState({
			messageType: 'email',
			notificationType: 'email',
		});
		// }

		if (this.props.reportType) {
			this.setState({
				subject: this.props.reportType,
			});
		}
	}

	retrieveUnsentEmails = () => {
		let formData = {
			resend: this.state.resend,
		};
		this.props.unsentEmails(formData);
		if (!this.state.resend) {
			this.setState({
				resend: true,
			});
		}
	};
	componentWillUnmount() {
		if (
			this.props.user.sendingMessageSuccess !== '' ||
			this.props.user.errorSendingMessage !== null
		) {
			this.props.clearMessageAction();
		}
	}

	submit = () => {
		let state = this.state;
		let formData = {
			notificationType: state.notificationType,
		};

		if (state.url && state.btnText) {
			formData.url = state.url;
			formData.btnText = state.btnText;
		}

		if (state.messageType === 'email') {
			formData.subject = state.subject;
			formData.emailTemplate = state.template;
		}

		if (state.title.trim() !== '') {
			formData.title = state.title;
		}
		if (state.body.trim() !== '') {
			formData.body = state.body;
		}

		if (this.props.custom) {
			formData.allUsers = state.group === 'allUsers';
			if (state.group === 'users') {
				let recipients = state.recipients.map((obj) => obj['userId']);
				formData.recipients = recipients;
			}

			if (state.messageType === 'notification') {
				formData.topic = state.topic;
				formData.type = state.type;
				formData.url = state.url;
			}
		}
		// if (this.props.component === 'report')
		else {
			formData.recipients = [state.emailRecipient];
			formData.reportId = this.props.reportId;
			formData.reportType = this.props.reportType;
		}
		if (state.messageType === 'email' && state.template === '') {
			this.setState({ errors: { template: 'Please enter a template.' } });
		} else if (state.messageType === 'email' && state.subject === '') {
			this.setState({ errors: { subject: 'Please enter a subject.' } });
		} else if (state.title === '' && state.template !== 'updatedTerms') {
			this.setState({ errors: { title: 'Please enter a title.' } });
		} else if (state.body === '' && state.template !== 'updatedTerms') {
			this.setState({ errors: { body: 'Please enter a body.' } });
		} else if (state.messageType === 'notification' && state.url === '') {
			this.setState({ errors: { url: 'Please enter a url.' } });
		} else if (
			state.messageType === 'email' &&
			state.url !== '' &&
			state.btnText === ''
		) {
			this.setState({ errors: { btnText: 'Please enter the button text.' } });
		} else if (
			(state.btnText !== '' || state.url !== '') &&
			!this.isUrl(state.url)
		) {
			this.setState({ errors: { url: 'Please enter a VALID url.' } });
		} else if (
			this.props.custom &&
			state.group === 'users' &&
			state.recipients.length === 0
		) {
			this.setState({
				errors: {
					recipientError: 'Please add a recipient.',
				},
				querySearchbar: true,
			});
		} else {
			this.setState(initialState);
			this.props.createMessage(formData);
		}
	};

	handleChange = (e) => {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value,
		});
	};
	handleChangeSelect = (e) => {
		e.preventDefault();
		if (
			this.props.user.sendingMessageSuccess !== '' ||
			this.props.user.errorSendingMessage !== null
		) {
			this.props.clearMessageAction();
		}
		if (e.target.name === 'messageType') {
			if (e.target.value === 'notification') {
				this.setState({
					notificationType: 'pushNotification',
				});
			}
			if (e.target.value === 'email') {
				this.setState({
					notificationType: 'email',
				});
			}
		}
		let subject = '';
		let title = '';
		let body = '';
		let url = '';
		let type = 'app';
		if (e.target.value === 'message') {
			subject = '';
		} else if (e.target.value === 'updatedTerms') {
			subject = 'We have updated our terms and conditions';
			if (this.state.messageType === 'notification') {
				title = 'We have updated our legal terms';
				body = `Click here to review our Privacy Policy, Community Guidelines, and Terms of Service. Effective: ${dayjs().format(
					'll',
				)}.`;
				url = 'https://goodbyeapp.com/terms-of-service';
				type = 'legal';
			}
		}

		this.setState({
			[e.target.name]: e.target.value,
			subject,
			title,
			body,
			url,
			type,
		});
	};

	isUrl = (url) => {
		const regEx =
			/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/; // eslint-disable-line
		// eslint disable comment on line 85 to remove no-useless-escape console warning...
		if (url.match(regEx)) return true;
		else return false;
	};

	setRecipient = (recipient) => {
		let state = this.state;
		let recipientIndex = state.recipients.findIndex(
			(rec) => rec.handle === recipient.handle,
		);
		let arr = [...state.recipients];
		if (recipientIndex === -1) {
			arr = [...state.recipients, recipient];
			this.setState({
				recipients: arr,
				errors: {},
			});
		} else {
			toast.warn(`${recipient.handle} already added.`, {
				position: 'bottom-left',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				containerId: 'E',
			});
		}
	};
	deleteRecipient = ({ userId }) => {
		let arr = this.state.recipients.filter(
			(recipient) => recipient.userId !== userId,
		);
		this.setState({
			recipients: arr,
		});
	};
	resetInput = (stateKey) => {
		this.setState({
			[stateKey]: '',
		});
		document.getElementById(stateKey).focus();
	};

	handleCheckedPanel = (e, val) => {
		if (
			this.props.user.sendingMessageSuccess !== '' ||
			this.props.user.errorSendingMessage !== null
		) {
			this.props.clearMessageAction();
		}

		if (val === 'query') {
			this.setState((prevState) => ({
				querySearchbar: !prevState.querySearchbar,
			}));
		} else {
			if (this.props.custom) {
				if (
					this.state.messageType === 'email' ||
					e.target.name === 'users' ||
					e.target.name === 'allUsers'
				) {
					e.preventDefault();
					if (e.target.name === 'users' && !this.state.querySearchbar) {
						this.setState({ group: e.target.value, querySearchbar: true });
					} else {
						this.setState({ group: e.target.value });
					}
				}
				if (
					this.state.messageType === 'notification' &&
					e.target.name === 'notificationType'
				) {
					this.setState({
						notificationType: e.target.value,
					});
				}
			} else if (this.props.userId) {
				e.preventDefault();
				this.setState({ emailRecipient: e.target.value });
			}
		}
	};

	render() {
		const {
			user: {
				sendingMessage,
				sendingMessageSuccess,
				errorSendingMessage,

				loadingUnsentEmails,
				errorLoadingUnsentEmails,

				unsentEmailsSuccess,
			},
			UI: { mode },
			userId,
			reported,
			custom,
			// component,
		} = this.props;
		const {
			subject,
			title,
			body,
			errors,
			emailRecipient,
			group,
			querySearchbar,
			recipients,
			topic,
			type,
			url,
			messageType,
			notificationType,
			template,
			btnText,
			resend,
		} = this.state;

		return (
			<div className={`pd ${mode === 'dark' && 'dbc'}`}>
				<FormControl fullWidth id='mgY'>
					<InputLabel>Message Type</InputLabel>
					<Select
						value={messageType}
						label='Message Type'
						name='messageType'
						onChange={this.handleChangeSelect}
					>
						{custom && <MenuItem value={'notification'}>Notification</MenuItem>}
						<MenuItem value={'email'}>Email</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth id='mgY'>
					<InputLabel>Notification Type</InputLabel>
					<Select
						value={notificationType}
						label='Notification Type'
						name='notificationType'
						onChange={this.handleChangeSelect}
					>
						{messageType === 'notification' && (
							<MenuItem value={'pushNotification'}>Only Push</MenuItem>
						)}
						{messageType === 'notification' && (
							<MenuItem value={'notification'}>Push + Create</MenuItem>
						)}
						{messageType === 'email' && (
							<MenuItem value={'email'}>Create Email</MenuItem>
						)}
					</Select>
				</FormControl>
				<FormControl fullWidth id='mgY' error={errors.template ? true : false}>
					<InputLabel>Template</InputLabel>
					<Select
						value={template}
						label='Template'
						name='template'
						onChange={this.handleChangeSelect}
					>
						<MenuItem value={''}>None</MenuItem>
						{messageType === 'email' && this.props.reportId && (
							<MenuItem value={'reportMail'}>Report</MenuItem>
						)}
						{messageType === 'email' && (
							<MenuItem value={'message'}>Message</MenuItem>
						)}

						<MenuItem value={'updatedTerms'}>Updated Legal Terms</MenuItem>
					</Select>
					{errors.template && (
						<FormHelperText>{errors.template}</FormHelperText>
					)}
				</FormControl>

				<div className='reportPanelOptions'>
					{custom ? (
						<div>
							<FormControlLabel
								value='users'
								control={
									<Radio
										onChange={this.handleCheckedPanel}
										name='users'
										sx={{
											color: '#2980b9',
										}}
										checked={group === 'users'}
									/>
								}
								label='Users'
								labelPlacement='bottom'
							/>
							<FormControlLabel
								value='allUsers'
								control={
									<Radio
										onChange={this.handleCheckedPanel}
										name='allUsers'
										sx={{
											color: '#2980b9',
										}}
										checked={group === 'allUsers'}
									/>
								}
								label='All users'
								labelPlacement='bottom'
							/>
						</div>
					) : (
						<div>
							{reported && (
								<FormControlLabel
									value={reported}
									control={
										<Radio
											onChange={this.handleCheckedPanel}
											name='reported'
											sx={{
												color: '#2980b9',
											}}
											checked={emailRecipient === reported}
										/>
									}
									label='Reported'
									labelPlacement='bottom'
								/>
							)}
							<FormControlLabel
								value={userId}
								control={
									<Radio
										onChange={this.handleCheckedPanel}
										name='userId'
										sx={{
											color: '#2980b9',
										}}
										checked={emailRecipient === userId}
									/>
								}
								label='User Id / Reporter'
								labelPlacement='bottom'
							/>
						</div>
					)}
				</div>

				{custom && (
					<div>
						<CustomAlerts error={errors.recipientError} close />

						{group === 'users' && (
							<FormControlLabel
								control={
									<Switch
										value='querySearchbar'
										handleSwitchChange={(e) =>
											this.handleCheckedPanel(e, 'query')
										}
										checked={querySearchbar}
										name='querySearchbar'
									/>
								}
								label='Query User Id'
								labelPlacement='bottom'
							/>
						)}

						{group === 'users' && (
							<div>
								{querySearchbar && (
									<div
										id='scrollableAdminDiv'
										style={{
											overflow: 'auto',
											height: '21vh',
											minHeight: '100px',
										}}
										className='mgY2'
									>
										<Searchbar
											adminFunctionality={true}
											setRecipient={this.setRecipient}
										/>
									</div>
								)}

								{recipients.length > 0 && (
									<Recipients
										recipients={recipients}
										deleteRecipient={this.deleteRecipient}
										admin={true}
									/>
								)}
							</div>
						)}
					</div>
				)}

				{(messageType === 'report' || messageType === 'email') && (
					<CustomInput
						variant='outlined'
						type='subject'
						titleLabel='Email Subject'
						placeholder='Subject'
						name='subject'
						inputId='subject'
						autoComplete='subject'
						onChange={this.handleChange}
						helperText={errors.subject}
						inputError={errors.subject ? true : false}
						inputValue={subject}
						maxLength={5000}
						fullWidth={true}
						deletable={() => this.resetInput('subject')}
					/>
				)}

				{messageType === 'notification' && (
					<div>
						<br />
						<FormControl fullWidth id='mgY'>
							<InputLabel>Topic</InputLabel>
							<Select
								value={topic}
								label='Topic'
								name='topic'
								onChange={this.handleChangeSelect}
							>
								<MenuItem value={'globalTopic'}>Global</MenuItem>
							</Select>
						</FormControl>
						<br />
						<FormControl fullWidth id='mgY'>
							<InputLabel>Type</InputLabel>
							<Select
								value={type}
								label='Type'
								name='type'
								onChange={this.handleChangeSelect}
							>
								<MenuItem value={'app'}>App</MenuItem>
								<MenuItem value={'legal'}>Legal</MenuItem>
							</Select>
						</FormControl>
					</div>
				)}
				{(notificationType !== 'email' ||
					(notificationType === 'email' && template !== 'updatedTerms')) && (
					<CustomInput
						variant='outlined'
						type='title'
						titleLabel='Title'
						placeholder='Please enter a title'
						name='title'
						inputId='title'
						autoComplete='title'
						onChange={this.handleChange}
						helperText={errors.title}
						inputError={errors.title ? true : false}
						inputValue={title}
						maxLength={5000}
						fullWidth={true}
						deletable={() => this.resetInput('title')}
					/>
				)}
				{(notificationType !== 'email' ||
					(notificationType === 'email' && template !== 'updatedTerms')) && (
					<CustomInput
						variant='outlined'
						type='body'
						titleLabel='Content'
						placeholder='Your email content'
						name='body'
						inputId='body'
						autoComplete='body'
						onChange={this.handleChange}
						helperText={errors.body}
						inputError={errors.body ? true : false}
						inputValue={body}
						maxLength={20000}
						fullWidth={true}
						minRows={5}
						multiline
						deletable={() => this.resetInput('body')}
					/>
				)}

				{messageType === 'email' && template !== 'updatedTerms' && (
					<div>
						{messageType === 'email' && <Divider id='mgt2'>Optional</Divider>}
						<CustomInput
							variant='outlined'
							type='btnText'
							titleLabel='Button Text'
							placeholder='Click, Review, See'
							name='btnText'
							inputId='btnText'
							autoComplete='btnText'
							onChange={this.handleChange}
							helperText={errors.btnText}
							inputError={errors.btnText ? true : false}
							inputValue={btnText}
							maxLength={5000}
							fullWidth={true}
							deletable={() => this.resetInput('btnText')}
						/>
					</div>
				)}
				{((messageType === 'email' && template !== 'updatedTerms') ||
					messageType === 'notification') && (
					<CustomInput
						variant='outlined'
						type='url'
						titleLabel='Url'
						placeholder='Button Url'
						name='url'
						inputId='url'
						autoComplete='url'
						onChange={this.handleChange}
						helperText={errors.url}
						inputError={errors.url ? true : false}
						inputValue={url}
						maxLength={5000}
						fullWidth={true}
						deletable={() => this.resetInput('url')}
					/>
				)}

				{template === 'reportMail' && emailRecipient && (
					<p id='mgt'>
						<u>Recipient:</u> {emailRecipient}
					</p>
				)}
				<CustomButton
					onClick={this.submit}
					variant='contained'
					btnText={t('send')}
					margin='2rem auto 1rem auto'
					id='customCancelButton'
					loading={sendingMessage}
					endIcon={<MailOutlineIcon />}
					startIcon={<CircleNotificationsIcon />}
				/>

				<CustomAlerts error={errorSendingMessage} margin='0' close />
				<CustomAlerts success={sendingMessageSuccess} margin='0' close />

				{custom && (
					<div>
						<hr />
						{typeof unsentEmailsSuccess === 'string' &&
						unsentEmailsSuccess !== '' ? (
							<CustomAlerts
								info={unsentEmailsSuccess}
								noMargin
								icon={<EmailIcon />}
							/>
						) : (
							<CustomButton
								onClick={this.retrieveUnsentEmails}
								loading={loadingUnsentEmails}
								btnText={
									!resend || errorLoadingUnsentEmails
										? 'Retrieve undelivered mails'
										: `Resend ${unsentEmailsSuccess} email/s`
								}
								startIcon={
									!resend || errorLoadingUnsentEmails ? (
										<ThreePSharpIcon />
									) : (
										<ContactMailSharpIcon />
									)
								}
								id='customGrayButton'
								margin='1rem 0 0 0'
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

CreateMessage.propTypes = {
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	// userId: PropTypes.string.isRequired,
	createMessage: PropTypes.func.isRequired,
	clearMessageAction: PropTypes.func.isRequired,
	unsentEmails: PropTypes.func.isRequired,
};

const mapActionsToProps = {
	createMessage,
	clearMessageAction,
	unsentEmails,
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(CreateMessage);
