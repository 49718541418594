import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { follow, unfollow } from '../../redux/actions/userActions';
import {
	clearFollowers,
	clearFollowing,
} from '../../redux/actions/dataActions';
import { setFeedData } from '../../redux/actions/dataActions';
import { withRouter } from '../../redux/withRouter';
import { toast } from 'react-toastify';
import CustomButton from './CustomButton';
import { t } from 'i18next';

class FollowButton extends Component {
	state = {
		userLimiter: false,
	};
	checkFeedCollection = () => {
		if (this.props.data.feedCollection === 'following') {
			this.props.setFeedData({
				feedCollection: 'posts',
			});
		}
	};
	follow = (userId) => {
		if (this.props.user.credentials.usersFollowedToday < 150) {
			let location = this.props.location.pathname.split('/')[1];
			this.props.follow(userId, location);
			this.checkFeedCollection();
		} else {
			this.setState({
				userLimiter: true,
			});
			toast.info(
				`${t('you_have_exceeded_daily_limit_action')} ${t(
					'security_anti_spam_measures',
				)}`,
				{
					position: 'bottom-left',
					autoClose: 15000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					containerId: 'app',
					toastId: 'usersFollowedToday',
				},
			);
		}
	};
	unfollow = (userId) => {
		let location = this.props.location.pathname.split('/')[1];
		this.props.unfollow(userId, location);

		this.checkFeedCollection();
	};
	travelTo = (userHandle) => {
		this.props.clearFollowers();
		this.props.clearFollowing();
		this.props.history(`/users/${userHandle}`);
		if (this.props.onClick) {
			this.props.onClick();
		}
	};
	render() {
		const {
			user: {
				credentials: { followingUserIds, handle },
				loadingFollowActionIds,
				errorLoadingFollowActionIds,
			},
			navbar,
			isFollowingBool,
			isFollowingBackBool,
			userHandle,
			profile,
			userId,
			disabled,
		} = this.props;
		const { userLimiter } = this.state;

		let loadButton = loadingFollowActionIds.includes(userId);

		return (
			<div className={profile ? '' : 'width30'}>
				{userId === undefined ||
				(isFollowingBool === null && isFollowingBackBool === null) ||
				loadButton ? (
					<div>
						<div className={profile ? 'pdtFollowBtn' : ''}>
							<CustomButton
								variant='contained'
								disabled={true}
								loading={true}
								id='customDisabledButton'
								circular
								borderRadius='4px'
								padding='0px 10px'
								margin='0 auto'
							/>
						</div>
					</div>
				) : followingUserIds === undefined ||
				  (!profile && followingUserIds.includes(userId)) ||
				  handle === userHandle ||
				  navbar ? (
					<p
						onClick={() => this.travelTo(userHandle)}
						className='mg0 nowrap pointer actionDialogText'
					>
						{t('view')}
					</p>
				) : profile && followingUserIds.includes(userId) ? (
					<div className={profile ? 'pdtFollowBtn' : ''}>
						<CustomButton
							onClick={() => this.unfollow(userId)}
							variant='contained'
							disabled={
								loadButton ||
								userLimiter ||
								errorLoadingFollowActionIds.includes(userId)
							}
							id={
								userLimiter || errorLoadingFollowActionIds.includes(userId)
									? 'customDisabledButton'
									: 'customCancelButton'
							}
							btnText={t('unfollow')}
							textTransform='capitalize'
							borderRadius='4px'
							padding='0px 10px'
							margin='0 auto'
						/>
					</div>
				) : !followingUserIds.includes(userId) &&
				  isFollowingBackBool === true &&
				  !disabled ? (
					<div className={profile ? 'pdtFollowBtn' : 'flexEnd'}>
						<CustomButton
							onClick={() => this.follow(userId)}
							variant='contained'
							disabled={
								loadButton ||
								userLimiter ||
								errorLoadingFollowActionIds.includes(userId)
							}
							id={
								userLimiter || errorLoadingFollowActionIds.includes(userId)
									? 'customDisabledButton'
									: 'customSuccessButton'
							}
							btnText={t('follow_back')}
							borderRadius='4px'
							className='alignLastCenter'
							padding='0px 10px'
							textTransform='capitalize'
							margin={profile ? '0 auto' : '0'}
						/>
					</div>
				) : !followingUserIds.includes(userId) &&
				  !disabled &&
				  (isFollowingBackBool === false ||
						isFollowingBackBool === undefined) ? (
					<div className={profile ? 'pdtFollowBtn' : ''}>
						<CustomButton
							onClick={() => this.follow(userId)}
							variant='contained'
							disabled={
								loadButton ||
								userLimiter ||
								errorLoadingFollowActionIds.includes(userId)
							}
							id={
								userLimiter || errorLoadingFollowActionIds.includes(userId)
									? 'customDisabledButton'
									: 'customSuccessButton'
							}
							btnText={t('follow')}
							textTransform='capitalize'
							borderRadius='4px'
							padding='0px 10px'
							margin='0 auto'
						/>
					</div>
				) : null}
			</div>
		);
	}
}

FollowButton.propTypes = {
	userId: PropTypes.string,
	userHandle: PropTypes.string,
	user: PropTypes.object.isRequired,
	follow: PropTypes.func.isRequired,
	unfollow: PropTypes.func.isRequired,
	setFeedData: PropTypes.func.isRequired,
	clearFollowers: PropTypes.func.isRequired,
	clearFollowing: PropTypes.func.isRequired,
	isFollowingBool: PropTypes.bool,
	isFollowingBackBool: PropTypes.bool,
	followBack: PropTypes.string,
};
const mapStateToProps = (state) => ({
	data: state.data,
	user: state.user,
});
const mapActionsToProps = {
	follow,
	unfollow,
	clearFollowers,
	clearFollowing,
	setFeedData,
};
export default connect(
	mapStateToProps,
	mapActionsToProps,
)(withRouter(FollowButton));
