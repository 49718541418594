import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar } from '@mui/material';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useSelector } from 'react-redux';
import CustomAlerts from '../../components/SocialComponents/CustomAlerts';
import PolicyIcon from '@mui/icons-material/Policy';
import { Link } from 'react-router-dom';

function CommunityGuidelines() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Community Guidelines - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2b1Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<SettingsAccessibilityIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Community Guidelines</h1>
					<h2>Our Rules</h2>
					<p>
						Welcome to <span className='notranslate'> Goodbye App,</span> where
						connections matter! We're dedicated to creating a warm and inclusive
						space for all users. To foster a vibrant community, we've
						established these guidelines:
					</p>
					<p>
						<b>Respect Others:</b> Treat all users with kindness, empathy, and
						respect. Harassment, bullying, hate speech, and discrimination of
						any kind will not be tolerated.
					</p>
					<p>
						<b>Spread Positivity:</b> Share uplifting and inspiring content.
						Spread kindness, encouragement, and positivity throughout the
						community. A small gesture can make a big difference in someone's
						day.
					</p>
					<p>
						<b>Continuous Improvement:</b> We welcome feedback and suggestions
						for improving the <span className='notranslate'> Goodbye App </span>{' '}
						community. Share your ideas with us to help make our platform better
						for everyone.{' '}
						<Link to='/feature' id='link' className='bold'>
							Feature Request.
						</Link>
					</p>
					<p>
						<b>Copyright and Trademark:</b> You are not allowed to infringe upon
						the intellectual property rights of others, including copyright and
						trademark violations. Please familiarize yourself with our{' '}
						<Link to='/trademark-policy' id='link' className='bold'>
							trademark policy
						</Link>{' '}
						and{' '}
						<Link to='/copyright-policy' id='link' className='bold'>
							copyright policy
						</Link>{' '}
						to understand the guidelines and rules regarding these matters.
					</p>
					<p>
						<b>Embrace Diversity:</b> Celebrate the diversity of our community.
						Embrace different perspectives, backgrounds, and cultures. Let's
						learn from each other and grow together.
					</p>
					<p>
						<b>Creativity Encouraged:</b> Unleash your creativity! Whether it's
						through art, music, writing, or other forms of expression, feel free
						to showcase your talents and inspire others.
					</p>
					<p>
						<b>Mindful Communication:</b> Practice mindful communication. Listen
						actively, express yourself thoughtfully, and seek to understand
						before being understood. Effective communication is the key to
						meaningful connections.
					</p>
					<p>
						<b>No Spam or Self-Promotion:</b> Avoid spamming the platform with
						irrelevant content or excessive self-promotion. Respect the
						community by sharing meaningful and relevant content.
					</p>
					<p>
						<b>Protect Privacy:</b> Respect the privacy of others. Do not share
						personal information or sensitive data without consent.
					</p>
					<p>
						<b>Stay Safe:</b> Be cautious when interacting with others online.
						Do not share personal information or engage in risky behavior.
					</p>
					<p>
						<b>Obey the Law:</b> Follow all local, national, and international
						laws and regulations. Do not engage in illegal activities or promote
						harmful behavior.
					</p>
					<p>
						<b>Support Each Other:</b> Be a supportive friend to fellow users.
						Offer a listening ear, provide advice when needed, and lend a
						helping hand to those going through tough times. Together, we can
						overcome any challenges.
					</p>
					<p>
						<b>Be Responsible:</b> Take responsibility for your actions and
						their impact on the community. Help maintain a positive and
						welcoming environment for all users.
					</p>
					<p>
						<b>Sensitive Content:</b> You are prohibited from sharing media that
						contains excessive gore, violent scenes, or adult content in video,
						profile images, or images. Additionally, media depicting sexual
						violence or assault is strictly prohibited.{' '}
						<Link to='/sensitive-content' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Child Sexual Exploitation:</b> We have zero tolerance for child
						sexual exploitation on{' '}
						<span className='notranslate'> Goodbye App </span>.{' '}
						<Link to='/sexual-exploitation' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Report Violations:</b> If you encounter any violations of these
						guidelines or witness inappropriate behavior, report it to
						<span className='notranslate'> Goodbye App </span> moderation team
						immediately.
					</p>
					<p>
						<b>Illegal Goods or Services:</b>{' '}
						<span className='notranslate'> Goodbye App </span> users are
						prohibited from using our platform for any illegal activities or
						purposes. This encompasses engaging in the sale, purchase, or
						facilitation of transactions involving illegal goods or services.
						Additionally, certain regulated goods or services are also
						restricted from being traded or promoted through our service.{' '}
						<Link to='/illegal-goods-or-services' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Violent Speech:</b> It is prohibited to engage in speech that
						includes threats, incitement, glorification, or expressions of
						desire for violence or harm. You may not threaten terrorism and/or
						violent extremism, nor promote violent and hateful entities.{' '}
						<Link to='/violent-speech' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Private Information:</b> You are not allowed to publish or share
						private information belonging to others, such as home phone numbers
						addresses, physical location information, identity documents,
						financial account information, biometric data or medical records,
						without their explicit consent. This includes threatening to
						disclose private information or encouraging others to do so.{' '}
						<Link to='/private-information' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Abuse/Harassment:</b> Sharing abusive content, participating in
						targeted harassment against individuals, or encouraging others to do
						so is prohibited.{' '}
						<Link to='/abuse-harassment' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Civic integrity:</b> Civic integrity is paramount on our
						platform, and we strictly prohibit the use of{' '}
						<span className='notranslate'>Goodbye App</span> services for any
						activities aimed at manipulating or interfering in elections or
						other civic processes. This encompasses actions such as posting or
						sharing content that could suppress participation in these processes
						or mislead individuals about crucial details like when, where, or
						how to participate. By upholding these guidelines, we aim to foster
						a fair, transparent, and trustworthy environment where civic
						engagement can flourish without undue influence or manipulation.{' '}
						<Link to='/civic-integrity' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Abuse/Harassment:</b> Sharing abusive content, participating in
						targeted harassment against individuals, or encouraging others to do
						so is prohibited.{' '}
						<Link to='/abuse-harassment' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Misleading and Deceptive Identities:</b> The Misleading and
						Deceptive Identities policy is designed to maintain the authenticity
						and trustworthiness of interactions on the{' '}
						<span className='notranslate'> Goodbye App </span>. Users are
						prohibited from impersonating individuals, groups, or organizations
						in a manner that is intended to mislead, confuse, or deceive others.
						This includes creating fake accounts or using false identities to
						manipulate or disrupt the experience of other users on the platform.
						By upholding this policy, the{' '}
						<span className='notranslate'> Goodbye App </span> aims to foster a
						safe and genuine environment where users can engage without fear of
						encountering misleading or deceptive content.{' '}
						<Link
							to='/misleading-and-deceptive-identities'
							id='link'
							className='bold'
						>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Follow Terms of Service:</b> Adhere to Goodbye App Terms of
						Service at all times. Violation of the Terms of Service may result
						in account suspension or termination.
					</p>
					<p>
						<b>Offensive Behavior:</b> It is prohibited to target individuals
						based on their race, ethnicity, national origin, caste, sexual
						orientation, gender, gender identity, religious beliefs, age,
						disability, or health condition.{' '}
						<Link to='/offensive-behaviour' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Perpetrators of Violent Attacks:</b> To maintain a safe and
						responsible community, we enforce strict measures against
						individuals associated with violent attacks or extremism.{' '}
						<Link
							to='/perpetrators-of-violent-attacks'
							id='link'
							className='bold'
						>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Suicide and Self-Harm:</b> Suicide and Self-Harm Policy We
						strictly prohibit any promotion or encouragement of suicide or
						self-harm within our community. This includes:{' '}
						<Link to='/suicide-and-self-harm' id='link' className='bold'>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Account Compromise:</b> You are prohibited from using or trying
						to use credentials, passwords, tokens, keys, cookies, or any other
						data to access, manipulate, or modify the private information or
						account features of any{' '}
						<span className='notranslate'> Goodbye App </span> account that does
						not belong to you, unless you have explicit authorization through{' '}
						<span className='notranslate'> Goodbye App </span> Teams
						authorization, OAuth authorization (open-standard authorization
						protocol or framework that provides applications the ability for
						“secure designated access.”), or a similar official mechanism.
					</p>
					<p>
						<b>Platform Manipulation and Spam:</b> It is strictly forbidden to
						utilize <span className='notranslate'>Goodbye App's</span> services
						with the intention of artificially boosting or diminishing the
						visibility of information. Moreover, any form of behavior aimed at
						manipulating or disturbing the experience of others on the{' '}
						<span className='notranslate'> Goodbye App </span> is also
						prohibited. This includes tactics such as creating multiple accounts
						to promote or suppress content, engaging in automated activities to
						manipulate engagement metrics, or employing deceptive methods to
						mislead or exploit users' interactions on the platform. Such actions
						undermine the integrity of the{' '}
						<span className='notranslate'> Goodbye App </span> community and are
						subject to immediate enforcement actions.{' '}
						<Link
							to='/platform-manipulation-and-spam'
							id='link'
							className='bold'
						>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Respect Boundaries:</b> Respect personal boundaries and consent.
						Always ask for permission before sharing someone else's content or
						engaging in private conversations. Consent is essential in building
						trust and mutual respect.
					</p>
					<p>
						<b>Falsified and Altered Content:</b> Users are prohibited from
						sharing misleading or altered media that could potentially result in
						harm. Furthermore, we reserve the right to tag posts containing
						falsified or altered media to assist users in discerning their
						authenticity and to offer further context.{' '}
						<Link
							to='/falsified-and-altered-content'
							id='link'
							className='bold'
						>
							Learn more.
						</Link>
					</p>
					<p>
						<b>Environmental Awareness:</b> Let's be mindful of our
						environmental impact. Reduce, reuse, and recycle whenever possible.
						Consider the planet in your actions and strive to leave a positive
						footprint on the world.
					</p>
					<hr />
					<p>
						<b>Appealing Removed Content or Account:</b> Depending on the type
						of infringement, users might be able to appeal the decision and
						possibly get reinstated the removed content or account. Our goal is
						to ensure fair treatment and transparency in content moderation.{' '}
						<Link to='/submitting-appeals' id='link' className='bold'>
							Learn more.
						</Link>
					</p>

					<hr />
					<p>
						We want <span className='notranslate'> Goodbye App </span> to
						continue to be an authentic and safe place for inspiration and
						expression. By using{' '}
						<span className='notranslate'> Goodbye App </span>, you agree to
						these guidelines and our Terms of Service. We’re committed to these
						guidelines and we hope you are too. Overstepping these boundaries
						may result in deleted content, disabled accounts, or other
						restrictions. In some cases, we allow content for public awareness
						which would otherwise go against our Community Guidelines – if it is
						newsworthy and in the public interest. We do this only after
						weighing the public interest value against the risk of harm and we
						look to international human rights standards to make these
						judgments.
					</p>
					<p>
						Share only photos and videos that you’ve taken or have the right to
						share. Remember to post authentic content, and don’t post anything
						you’ve copied or collected from the Internet that you don’t have the
						right to post. Learn more about intellectual property rights. Post
						photos and videos that are appropriate for a diverse audience. We
						know that there are times when people might want to share nude
						images that are artistic or creative in nature, but for a variety of
						reasons, we don’t allow nudity on{' '}
						<span className='notranslate'> Goodbye App </span>. This includes
						photos, videos, and some digitally-created content that show sexual
						intercourse, genitals, and close-ups of fully-nude buttocks. It also
						includes some photos of female nipples, but photos in the context of
						breastfeeding, birth giving and after-birth moments, health-related
						situations (for example, post-mastectomy, breast cancer awareness or
						gender confirmation surgery) or an act of protest are allowed.
						Nudity in photos of paintings and sculptures is OK, too.
					</p>
					<p>
						People like to share photos or videos of their children. For safety
						reasons, there are times when we may remove images that show nude or
						partially-nude children. Even when this content is shared with good
						intentions, it could be used by others in unanticipated ways. Foster
						meaningful and genuine interactions.
					</p>
					<p>
						Help us stay spam-free by not artificially collecting likes,
						followers, posting repetitive comments or content, or repeatedly
						contacting people for commercial purposes without their consent.
						Don’t offer money or giveaways of money in exchange for likes,
						followers, comments or other engagement. Don’t post content that
						engages in, encourages, facilitates, or admits to the offering,
						solicitation or trade of fake and misleading user reviews or
						ratings.
					</p>
					<p>
						You don’t have to use your real name on{' '}
						<span className='notranslate'> Goodbye App </span>, but we do
						require <span className='notranslate'> Goodbye App </span> users to
						provide us with accurate and up to date information. Don't
						impersonate others and don't create accounts for the purpose of
						violating our guidelines or misleading others. Follow the law.
					</p>
					<p>
						<span className='notranslate'> Goodbye App </span> is not a place to
						support or praise terrorism, organized crime, or hate groups.
						Offering sexual services, buying or selling firearms, alcohol, and
						tobacco products between private individuals, and buying or selling
						non-medical or pharmaceutical drugs are also not allowed. We also
						remove content that attempts to trade, co-ordinate the trade of,
						donate, gift, or ask for non-medical drugs, as well as content that
						either admits to personal use (unless in the recovery context) or
						coordinates or promotes the use of non-medical drugs.
					</p>
					<p>
						<span className='notranslate'> Goodbye App </span> prohibits the
						sale of live animals between private individuals, though
						brick-and-mortar stores may offer these sales. No one may coordinate
						poaching or selling of endangered species or their parts. Remember
						to always follow the law when offering to sell or buy other
						regulated goods.
					</p>
					<CustomAlerts
						info={true}
						message='We have zero tolerance when it comes to sharing sexual content
						involving minors or threatening to post intimate images of others.
						Respect other members of the community. We want to
						foster a positive, diverse community.'
						icon={<PolicyIcon />}
						margin='0'
					/>
					<p>
						We remove content that contains credible threats or hate speech,
						content that targets private individuals to degrade or shame them,
						personal information meant to blackmail or harass someone, and
						repeated unwanted messages. We do generally allow stronger
						conversation around people who are featured in the news or have a
						large public audience due to their profession or chosen activities.{' '}
						<b>
							It's never OK to encourage violence or attack anyone based on
							their race, ethnicity, national origin, sex, gender, gender
							identity, sexual orientation, religious affiliation, disabilities,
							or diseases.
						</b>{' '}
						When hate speech is being shared to challenge it or to raise
						awareness, we may allow it. In those instances, we ask that you
						express your intent clearly. Serious threats of harm to public and
						personal safety aren't allowed. This includes specific threats of
						physical harm as well as threats of theft, vandalism, and other
						financial harm. We carefully review reports of threats and consider
						many things when determining whether a threat is credible.
					</p>
					<p>
						Maintain our supportive environment by not glorifying self-injury.
						The <span className='notranslate'> Goodbye App </span> community
						cares for each other, and is often a place where people facing
						difficult issues such as eating disorders, cutting, or other kinds
						of self-injury come together to create awareness or find support. We
						try our best to do our part. Encouraging or urging people to embrace
						self-injury is counter to this environment of support, and we’ll
						remove it or disable accounts if it’s reported to us. We may also
						remove content identifying victims or survivors of self-injury if
						the content targets them for attack or humor.
					</p>
					<p>
						Be thoughtful when posting newsworthy events. We understand that
						many people use <span className='notranslate'> Goodbye App </span>{' '}
						to share important and newsworthy events. Some of these issues can
						involve graphic images. Because so many different people and age
						groups use <span className='notranslate'> Goodbye App </span>, we
						may remove videos of intense, graphic violence to make sure{' '}
						<span className='notranslate'> Goodbye App </span> stays appropriate
						for everyone. We understand that people often share this kind of
						content to condemn, raise awareness or educate. If you do share
						content for these reasons, we encourage you to caption your photo
						with a warning about graphic violence. Sharing graphic images for
						sadistic pleasure or to glorify violence is never allowed.
					</p>

					<p>
						Help us keep the community strong: Each of us is an important part
						of the <span className='notranslate'> Goodbye App </span> community.
						If you see something that you think may violate our guidelines,
						please help us by using our built-in reporting option. We have a
						team that reviews these reports and works as quickly as possible to
						remove content that doesn’t meet our guidelines. When you complete
						the report, try to provide as much information as possible, such as
						links, usernames, and descriptions of the content, so we can find
						and review it quickly. We may remove entire posts if either the
						imagery or associated captions violate our guidelines.
					</p>
					<p>
						You must obtain our prior consent before submitting, posting, or
						displaying any video content on or through our services that
						contains third-party advertising, including pre-roll video ads or
						sponsorship graphics.
					</p>
					<p>
						You may find content you don’t like, but doesn’t violate the
						Community Guidelines. If that happens, you can unfollow the person
						who posted it. If there's something you don't like in a comment on
						one of your posts, you can delete that comment. Many disputes and
						misunderstandings can be resolved directly between members of the
						community. If one of your photos or videos was posted by someone
						else, you could try commenting on the post and asking the person to
						take it down. If that doesn’t work, you can file a copyright report.
					</p>
					<p>
						If you believe someone is violating your trademark, you can file a
						trademark report. Don't target the person who posted it by posting
						screenshots and drawing attention to the situation because that may
						be classified as harassment. We may work with law enforcement,
						including when we believe that there’s risk of physical harm or
						threat to public safety.
					</p>
					<CustomAlerts
						info={true}
						message={
							<b>
								Thank you for helping us create one of the best communities in
								the world, the <span className='notranslate'>Goodbye App</span>{' '}
								Team!
							</b>
						}
						icon={<AllInclusiveIcon />}
						margin='0'
					/>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default CommunityGuidelines;
