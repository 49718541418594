import { FC } from 'react';
import cn from 'classnames';
import { CropIcon } from '../icons/CropIcon';
import { HueIcon } from '../icons/HueIcon';
import { SaturationIcon } from '../icons/SaturationIcon';
import { ContrastIcon } from '../icons/ContrastIcon';
import { BrightnessIcon } from '../icons/BrightnessIcon';
// import { UploadIcon } from '../icons/UploadIcon';
// import { DownloadIcon } from '../icons/DownloadIcon';
import { Button } from './Button';
import './Navigation.scss';
import { FlipHorizontalIcon } from '../icons/FlipHorizontalIcon';
import { RotateRightIcon } from '../icons/RotateRightIcon';
import { SliderZoom } from './SliderZoom';
import { ZoomInIcon } from '../icons/ZoomInIcon';
import { ZoomOutIcon } from '../icons/ZoomOutIcon';
import { useTranslation } from 'react-i18next';

interface Props {
	className?: string;
	mode?: string;
	onChange?: (mode: string) => void;
	// onDownload?: () => void;
	// onUpload?: (blob: string) => void;
	onFlip: (horizontal: boolean, vertical: boolean) => void;
	onRotate: (angle: number) => void;
	zoom?: number;
	onZoom?: (value: number, transitions?: boolean) => void;
	onZoomIn?: () => void;
	onZoomOut?: () => void;
}

export const Navigation: FC<Props> = ({
	className,
	onChange,
	// onUpload,
	// onDownload,
	mode,
	onFlip,
	onRotate,
	onZoom,
	zoom,
	onZoomIn,
	onZoomOut,
}) => {
	const setMode = (mode: string) => () => {
		onChange?.(mode);
	};
	const { t } = useTranslation();
	// const inputRef = useRef<HTMLInputElement>(null);

	const rotate = () => {
		onRotate(90);
	};

	return (
		<div>
			<div className={cn('image-editor-navigation', className)}>
				{/* <Button
					className={'image-editor-navigation__button'}
					onClick={onUploadButtonClick}
				>
					<UploadIcon />
					<input
						ref={inputRef}
						type='file'
						accept='image/*'
						onChange={onLoadImage}
						className='image-editor-navigation__upload-input'
					/>
				</Button> */}
				<div className='image-editor-navigation__buttons'>
					<Button
						className={'image-editor-navigation__button'}
						active={mode === 'crop'}
						onClick={setMode('crop')}
						tooltip={t('crop')}
					>
						<CropIcon />
					</Button>

					<Button
						className={'image-editor-navigation__button'}
						active={mode === 'saturation'}
						onClick={setMode('saturation')}
						tooltip={t('saturation')}
					>
						<SaturationIcon />
					</Button>
					<Button
						className={'image-editor-navigation__button'}
						active={mode === 'brightness'}
						onClick={setMode('brightness')}
						tooltip={t('brightness')}
					>
						<BrightnessIcon />
					</Button>
					<Button
						className={'image-editor-navigation__button'}
						active={mode === 'contrast'}
						onClick={setMode('contrast')}
						tooltip={t('contrast')}
					>
						<ContrastIcon />
					</Button>
					<Button
						className={'image-editor-navigation__button'}
						active={mode === 'hue'}
						onClick={setMode('hue')}
						tooltip={t('hue')}
					>
						<HueIcon />
					</Button>

					<Button
						className={'image-editor-navigation__button'}
						onClick={() => onFlip(true, false)}
						tooltip={t('flip')}
					>
						<FlipHorizontalIcon />
					</Button>
					<Button
						className={'image-editor-navigation__button'}
						onClick={rotate}
						tooltip={t('rotate')}
					>
						<RotateRightIcon className='white' />
					</Button>
				</div>
				{/* <Button
					className={'image-editor-navigation__button'}
					onClick={onDownload}
				>
					<DownloadIcon />
				</Button> */}
			</div>
			<div className={cn('image-editor-navigation', className)}>
				<Button
					className={'absolute-zoom-cropper-navigation__button'}
					onClick={onZoomOut}
					tooltip={t('zoom_out')}
				>
					<ZoomOutIcon />
				</Button>
				<SliderZoom
					value={zoom}
					onChange={onZoom}
					className='absolute-zoom-cropper-navigation__slider'
				/>
				<Button
					className={'absolute-zoom-cropper-navigation__button'}
					onClick={onZoomIn}
					tooltip={t('zoom_in')}
				>
					<ZoomInIcon />
				</Button>
			</div>
		</div>
	);
};
