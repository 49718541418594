import {
	Backdrop,
	Box,
	Divider,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import TimerCountdown from '../PostTimer/TimerCountdown';
import { setDateTimer } from '../../redux/actions/dataActions';
import { connect } from 'react-redux';
import TodayIcon from '@mui/icons-material/Today';
import CustomAlerts from './CustomAlerts';
import { t } from 'i18next';

class DateAndTimePicker extends Component {
	constructor(props) {
		super(props);
		this.containerRef = React.createRef(null);
	}
	state = {
		openDatePicker: false,
		noAmAvailable: false,
		noPmAvailable: false,
		disabledHours: [],
		datePickerErrors: false,
	};

	componentDidMount() {
		if (Object.keys(this.props.data.dateTimer).length > 0) {
			let dateTimer = this.props.data.dateTimer;
			let datedPickedFormatted = dayjs(dateTimer.dateValue)
				.format('DD/MM/YYYY')
				.valueOf();
			let userDateTimeFormatted = dayjs().format('DD/MM/YYYY').valueOf();
			this.isDisabled(
				datedPickedFormatted,
				userDateTimeFormatted,
				dateTimer.meridiem,
			);
		}
	}
	componentWillUnmount() {
		if (this.state.openDatePicker) {
			this.setState({ openDatePicker: false });
		}
	}

	setOpenDatePicker = (bool) => {
		this.setState({ openDatePicker: bool });
		document.getElementById('dateInputClicker').blur();
	};

	formatHour = (i, meridiem) => {
		let formattedHour;
		if (meridiem === 'AM' && i !== 12) {
			formattedHour = i;
		} else if (meridiem === 'AM' && i === 12) {
			formattedHour = 0;
		} else if (meridiem === 'PM' && i !== 12) {
			formattedHour = i + 12;
		} else {
			formattedHour = i;
		}
		return formattedHour;
	};

	setTime = (value, time) => {
		let state = this.state;
		let dateTimer = this.props.data.dateTimer;
		let newDateValue = '';
		if (time) {
			// change date value in dateTimer with new hour
			let time = new Date(dateTimer.dateValue);
			newDateValue = time.setHours(
				this.formatHour(value.target.value, dateTimer.meridiem),
				0,
				0,
				0,
			);

			this.props.setDateTimer({
				dateValue: newDateValue,
				meridiem: dayjs(newDateValue).format('A'),
				hourValue: value.target.value,
			});
		} else {
			let userDateTime = dayjs().valueOf();
			let datedPicked = dayjs(value).valueOf();
			let meridiem = dayjs().format('A');
			//cheking if user already typed an hour and comparing dates
			if (dateTimer.hourValue !== '' && datedPicked > userDateTime) {
				let formattedHour = this.formatHour(
					dateTimer.hourValue,
					dateTimer.meridiem,
				);
				newDateValue = new Date(value).setHours(formattedHour, 0, 0, 0);

				this.props.setDateTimer({
					dateValue: newDateValue,
				});

				if (state.noAmAvailable) {
					this.setState({ noAmAvailable: false });
				}
				if (state.noPmAvailable) {
					this.setState({ noPmAvailable: false });
				}
				if (state.disabledHours.length > 0) {
					this.setState({ disabledHours: [] });
				}
			} else {
				// user selected date of Today
				let hourNow = parseInt(dayjs().format('H'));
				let result = (hourNow % 12) + 1 || 12 + 1;
				newDateValue = new Date(value).setHours(
					new Date().getHours() + 1,
					0,
					0,
					0,
				);
				if (this.props.onlyPicker) {
					const dateVal = this.props.signup
						? value
						: dayjs(value).format('DD/MM/YYYY');
					this.props.setDate(dateVal);
				}
				this.props.setDateTimer({
					dateValue: newDateValue,
					hourValue: result,
					meridiem: meridiem,
				});
			}
			let datedPickedFormatted = dayjs(value).format('DD/MM/YYYY').valueOf();
			let userDateTimeFormatted = dayjs().format('DD/MM/YYYY').valueOf();
			this.isDisabled(datedPickedFormatted, userDateTimeFormatted, meridiem);
		}
		if (state.datePickerErrors) {
			this.setState({ datePickerErrors: false });
		}
	};

	meridiemChange = (event) => {
		let dateTimer = this.props.data.dateTimer;

		let time = new Date(dateTimer.dateValue);
		let datedPickedFormatted = dayjs(time).format('DD/MM/YYYY').valueOf();
		let userDateTimeFormatted = dayjs().format('DD/MM/YYYY').valueOf();

		// changing meridiem and dateValue
		let hour = parseInt(dayjs(time).format('H'));
		let meridiem = event.target.value;
		let formatted = meridiem === 'AM' ? hour - 12 : hour + 12;
		let newDateValue = time.setHours(formatted);

		this.props.setDateTimer({
			dateValue: newDateValue,
			meridiem: meridiem,
		});

		if (datedPickedFormatted === userDateTimeFormatted) {
			this.isDisabled(
				datedPickedFormatted,
				userDateTimeFormatted,
				event.target.value,
			);
		}
	};

	isDisabled = (datedPicked, userDateTime, meridiem) => {
		// Check if user selected date is today
		if (datedPicked === userDateTime) {
			const newDisabledAMHours = [];
			const newDisabledPMHours = [];

			// Calculate disabled hours for both AM and PM
			for (let i = 1; i <= 12; i++) {
				const hourAM = this.formatHour(i, 'AM');
				const boolAM = dayjs().isAfter(new Date().setHours(hourAM, 0, 0, 0));
				newDisabledAMHours.push(boolAM);

				const hourPM = this.formatHour(i, 'PM');
				const boolPM = dayjs().isAfter(new Date().setHours(hourPM, 0, 0, 0));
				newDisabledPMHours.push(boolPM);
			}

			// console.log('newDisabledPMHours', newDisabledPMHours);
			// Get the current selected hour value
			let dateTimer = this.props.data.dateTimer;

			const hourValue = dateTimer.hourValue;

			// Check if the selected hour is disabled
			if (meridiem === 'AM' && newDisabledAMHours[hourValue - 1]) {
				this.handleDisabledHourSelection('AM');
			} else if (meridiem === 'PM' && newDisabledPMHours[hourValue - 1]) {
				this.handleDisabledHourSelection('PM');
			}

			// Check if all hours are disabled for both AM and PM
			const allPmDisabled = newDisabledPMHours.every((v) => v === true);
			const allAmDisabled = newDisabledAMHours.every((v) => v === true);

			// Set state based on disabled hours
			if (allPmDisabled && allAmDisabled) {
				this.handleReleaseTomorrow();
			} else if (allPmDisabled) {
				this.setState({ noPmAvailable: true });
				this.props.setDateTimer({
					meridiem: 'AM',
				});
			} else if (allAmDisabled) {
				this.setState({
					noAmAvailable: true,
					disabledHours: newDisabledPMHours,
				});
				this.props.setDateTimer({
					meridiem: 'PM',
				});
			} else {
				this.setState({
					disabledHours:
						meridiem === 'AM' ? newDisabledAMHours : newDisabledPMHours,
				});
			}
		} else {
			// Handle other conditions if the user selected date is not today
			return;
		}
	};

	handleDisabledHourSelection = (meridiem) => {
		// Handle the case where the selected hour is disabled
		const hourNow = parseInt(dayjs().format('H'));
		const result = (hourNow % 12) + 1 || 12 + 1;
		const newDateHour = new Date().setHours(new Date().getHours() + 1, 0, 0, 0);

		// this.setState({
		// 	dateValue: newDateHour,
		// 	hourValue: result,
		// 	meridiem: meridiem,
		// });
		this.props.setDateTimer({
			dateValue: newDateHour,
			hourValue: result,
			meridiem: meridiem,
		});
	};

	handleReleaseTomorrow = () => {
		// Release tomorrow's first hour
		let newDateValue = new Date().setHours(new Date().getHours() + 1, 0, 0, 0);

		this.props.setDateTimer({
			dateValue: newDateValue,
			hourValue: 12,
			meridiem: 'AM',
		});
	};
	render() {
		const {
			datePickerErrors,
			openDatePicker,
			disabledHours,
			noAmAvailable,
			noPmAvailable,
		} = this.state;
		const {
			data: {
				dateTimer: { dateValue, meridiem, hourValue },
			},
			makePostFunctionality,
			onlyPicker,
			signup,
			error,
			disabled,
		} = this.props;
		const yearsFromNow = new Date();
		yearsFromNow.setFullYear(yearsFromNow.getFullYear() + 30);
		yearsFromNow.getTime();

		return (
			<div>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<Backdrop sx={{ zIndex: 10 }} open={openDatePicker} />
					{onlyPicker ? (
						<DesktopDatePicker
							disabled={disabled}
							openTo='year'
							views={['year', 'month', 'day']}
							value={dateValue}
							onChange={(newValue) => {
								this.setTime(newValue);
							}}
							maxDate={new Date()}
							error={datePickerErrors}
							disableFuture
							open={openDatePicker}
							label={t('calendar_label')}
							onOpen={() => this.setOpenDatePicker(true)}
							onClose={() => this.setOpenDatePicker(false)}
							inputFormat='DD/MM/YYYY'
							renderInput={(params) => (
								<TextField
									{...params}
									id='dateInputClicker'
									helperText={
										signup ? <small>{t('birth_not_publicly')}</small> : ''
									}
									variant='outlined'
									onClick={(e) => this.setOpenDatePicker(true)}
									error={datePickerErrors || error}
									inputProps={{
										...params.inputProps,
										readOnly: true,
									}}
									fullWidth
								/>
							)}
						/>
					) : (
						<div>
							<Box>
								<div className='formDateContainer_mp'>
									<DesktopDatePicker
										openTo='year'
										views={['year', 'month', 'day']}
										value={dateValue}
										onChange={(newValue) => {
											this.setTime(newValue);
										}}
										error={datePickerErrors}
										disablePast
										minDate={Date.now()}
										maxDate={yearsFromNow}
										open={openDatePicker}
										onOpen={() => this.setOpenDatePicker(true)}
										onClose={() => this.setOpenDatePicker(false)}
										label={t('calendar_label')}
										inputFormat='DD/MM/YYYY'
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder=''
												id='dateInputClicker'
												variant='outlined'
												onClick={(e) => this.setOpenDatePicker(true)}
												error={datePickerErrors}
												// focused
												inputProps={{
													...params.inputProps,
													readOnly: true,
												}}
											/>
										)}
									/>
								</div>
								{dateValue !== null && (
									<div>
										<Divider>{t('select_time')}</Divider>
										<div className='formHourContainer_mp'>
											<FormControl id='formHourSelector_mp'>
												<InputLabel>{t('hour')}</InputLabel>
												<Select
													value={hourValue}
													label='Hour'
													onChange={(newValue) => this.setTime(newValue, true)}
													sx={{
														'.MuiOutlinedInput-notchedOutline': {
															border: '2px solid #1976D2',
														},
														'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
															border: '2px solid #0587F0',
														},
														'&:hover .MuiOutlinedInput-notchedOutline': {
															borderColor: '#028dff',
														},
														'.MuiSvgIcon-root ': {
															fill: '#1976D2 !important',
														},
													}}
												>
													<MenuItem disabled={disabledHours[0]} value={1}>
														1
													</MenuItem>
													<MenuItem disabled={disabledHours[1]} value={2}>
														2
													</MenuItem>
													<MenuItem disabled={disabledHours[2]} value={3}>
														3
													</MenuItem>
													<MenuItem disabled={disabledHours[3]} value={4}>
														4
													</MenuItem>
													<MenuItem disabled={disabledHours[4]} value={5}>
														5
													</MenuItem>
													<MenuItem disabled={disabledHours[5]} value={6}>
														6
													</MenuItem>
													<MenuItem disabled={disabledHours[6]} value={7}>
														7
													</MenuItem>
													<MenuItem disabled={disabledHours[7]} value={8}>
														8
													</MenuItem>
													<MenuItem disabled={disabledHours[8]} value={9}>
														9
													</MenuItem>
													<MenuItem disabled={disabledHours[9]} value={10}>
														10
													</MenuItem>
													<MenuItem disabled={disabledHours[10]} value={11}>
														11
													</MenuItem>
													<MenuItem disabled={disabledHours[11]} value={12}>
														12
													</MenuItem>
												</Select>
											</FormControl>
											<RadioGroup
												name='radio-buttons-group'
												id='meridiem_mp'
												value={meridiem}
												onChange={this.meridiemChange}
												className='notranslate'
											>
												{!noAmAvailable && (
													<FormControlLabel
														value='AM'
														control={<Radio />}
														label={t('am')}
													/>
												)}
												{!noPmAvailable && (
													<FormControlLabel
														value='PM'
														control={<Radio />}
														label={t('pm')}
													/>
												)}
											</RadioGroup>
										</div>
									</div>
								)}
							</Box>
							<CustomAlerts
								icon={<TodayIcon />}
								success={dateValue}
								message={
									<TimerCountdown
										releaseDate={dateValue}
										post={true}
										selectorId='releasesIn'
										text={
											makePostFunctionality ? t('release_in') : t('sends_in')
										}
									/>
								}
								alertId='smallMuiAlert'
								noMargin
							/>
						</div>
					)}
				</LocalizationProvider>
			</div>
		);
	}
}
const mapActionsToProps = {
	setDateTimer,
};
const mapStateToProps = (state) => ({
	data: state.data,
});

export default connect(mapStateToProps, mapActionsToProps)(DateAndTimePicker);
