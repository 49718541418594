import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer/Footer';
import { Avatar, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PolicyIcon from '@mui/icons-material/Policy';
import { useSelector } from 'react-redux';

function SexualExplotation() {
	const mode = useSelector((state) => state.UI.mode);
	return (
		<div>
			<Helmet>
				<title>Sexual Explotation Policy - Goodbye App</title>
			</Helmet>
			<div className='terms__Container pd2Media'>
				<div className={`card pd2Media ${mode === 'dark' && 'dbc'}`}>
					<Avatar id='cardMainIcon' className='flexAuto'>
						<PolicyIcon id='cardMainIconSize' />
					</Avatar>
					<h1 className='title center mgbOnly'>Sexual Explotation Policy</h1>
					<h2>Overview</h2>
					<b>Effective April 18, 2021</b>

					<p>
						At <span className='notranslate'> Goodbye App,</span> we uphold a
						strict policy against child sexual exploitation. Our platform
						maintains a firm stance against any content that features or
						endorses child sexual exploitation, as it represents one of the
						gravest violations of Our Rules. This encompasses various forms of
						media, including text, imagery, illustrations, and
						computer-generated depictions.
					</p>
					<p>
						Regardless of the intent behind it, the act of viewing, sharing, or
						linking to materials involving child sexual exploitation perpetuates
						the victimization of the depicted children. This policy also extends
						to any content that may further contribute to the victimization of
						minors by promoting or glorifying such exploitation. For the
						purposes of this policy, a minor is defined as any individual under
						the age of 18.
					</p>
					<h2>What Constitutes a Violation of This Policy?</h2>
					<p>
						Any content that portrays or advocates for child sexual
						exploitation, including but not limited to:
					</p>
					<ul className='text'>
						<li>
							Visual representations of a child involved in sexually explicit or
							suggestive activities.
						</li>
						<li>
							Illustrated, computer-generated, or other realistic depictions of
							a human child in sexually explicit situations or engaging in such
							acts.
						</li>
						<li>
							Sexualized remarks directed towards known or unknown minors.
						</li>
						<li>
							Links to external websites hosting material related to child
							sexual exploitation.
						</li>
					</ul>
					<h2>
						Additionally, the following behaviors are strictly prohibited:
					</h2>

					<ul className='text'>
						<li>
							Sharing or promoting fantasies involving child sexual
							exploitation.
						</li>
						<li>
							Expressing a desire to obtain materials depicting child sexual
							exploitation.
						</li>
						<li>
							Soliciting, advertising, or expressing interest in commercial sex
							acts involving minors, or activities related to harboring or
							transporting children for sexual purposes.
						</li>
						<li>
							Initiating or attempting to initiate sexually explicit
							conversations with minors.
						</li>
						<li>
							Coercing or attempting to coerce minors into providing sexually
							explicit media or engaging in sexual activities through blackmail
							or other means of manipulation.
						</li>
						<li>
							Identifying alleged victims of childhood sexual exploitation by
							name or image.
						</li>
						<li>
							Normalizing or promoting sexual attraction to minors as a form of
							identity or sexual orientation.
						</li>
					</ul>

					<h2>What Does Not Constitute a Violation of This Policy?</h2>
					<p>
						Discussions concerning child sexual exploitation are permissible, as
						long as they do not normalize, promote, or glorify such exploitation
						in any manner.
					</p>
					<p>
						Conversations addressing the help-seeking efforts of individuals
						grappling with attractions towards minors.
					</p>
					<p>
						Advocacy against illegal or harmful activities involving minors is
						allowed, provided there is no sharing or linking to material
						featuring child sexual exploitation.
					</p>
					<p>
						Depictions of nude minors within a non-sexualized context or setting
						may be acceptable in certain limited scenarios, such as artworks by
						globally recognized artists featuring minors, news media coverage,
						or media crafted and distributed for scientific or educational
						purposes.
					</p>
					<h2>Who can report violations of this policy?</h2>
					<p>
						Any user with a <span className='notranslate'> Goodbye App </span>{' '}
						account has the ability to report potential violations of this
						policy.
					</p>
					<p className='bold'>In-app and Desktop</p>
					<p>
						You can report content for review in-app and desktop as follows:
					</p>
					<ol className='text'>
						<li>
							Select Report Account from the icon:
							<IconButton aria-haspopup='true'>
								<MoreHorizIcon aria-haspopup='true' />
							</IconButton>
						</li>
						<li>
							Select <b>"Offensive words or symbols"</b>, or{' '}
							<b>"Nudity or sexual behavior."</b>, or{' '}
							<b>"Violence or dangerous organizations".</b>
						</li>
						<li>Submit your report.</li>
					</ol>
					<h2>Consequences of Policy Violations:</h2>
					<p className='bold mg0'>Immediate and permanent suspension.</p>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default SexualExplotation;
