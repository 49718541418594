import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
	Backdrop,
	CircularProgress,
	Dialog,
	LinearProgress,
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar/Navbar';
import Offline from '../pages/OFFLINE/Offline';
import blueLogo from '../util/Images/blueLogo.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import CustomAlerts from '../components/SocialComponents/CustomAlerts';
import LogoutIcon from '@mui/icons-material/Logout';
import CustomButton from '../components/SocialComponents/CustomButton';
import {
	logoutAllTabs,
	refreshAllTabsListener,
	logoutAllTabsListener,
	changeColorModeAllTabsListener,
	logoutUser,
	refreshAllTabs,
} from '../firebase/firebaseInit';
import ScrollToTop from '../components/ScrollToTop/ScollToTop';
import AuthRequired from '../pages/AUTH_REQUIRED/AuthRequired';
import gbaLogo from './Images/gbaLogo.png';
import { useTranslation } from 'react-i18next';

const AuthLoadingComponent = ({
	Component,
	componentName,
	credentials,
	authenticated,
	emailVerified,
	disabled,
	useAuth,
	onlineStatus,
	fullAuthComponent,
	navigate,
	refreshAllTabsListener,
	logoutAllTabsListener,
	changeColorModeAllTabsListener,
	loadingLogin,
	loadingSignup,
	loadingLogout,
	loadingUser,
	logginOutAllUserSessions,
	mode,
	fullAccess,
}) => {
	const { t } = useTranslation();
	const [timedOut, setTimedOut] = useState(false);
	const dispatch = useDispatch();
	const maintanance = false;

	useEffect(() => {
		refreshAllTabsListener();
		logoutAllTabsListener();
		changeColorModeAllTabsListener();
	}, []);

	const logout = () => {
		localStorage.removeItem('FBIdToken');
		localStorage.removeItem('userId');
		localStorage.removeItem('viewedPostIds');
		localStorage.removeItem('boundaryId');
		dispatch(logoutUser());
		dispatch(logoutAllTabs());
		dispatch(refreshAllTabs(false));
	};
	useEffect(() => {
		if (
			authenticated &&
			!useAuth &&
			onlineStatus &&
			!emailVerified &&
			(loadingLogin || loadingUser)
		) {
			const timerHandle = setTimeout(
				() => {
					setTimedOut(true);
				},
				!useAuth && authenticated && !emailVerified ? 30000 : 20000,
			);

			return () => {
				setTimedOut(false);
				clearTimeout(timerHandle);
			};
		}
	}, [
		onlineStatus,
		useAuth,
		authenticated,
		emailVerified,
		refreshAllTabsListener,
		logoutAllTabsListener,
		changeColorModeAllTabsListener,
		loadingLogin,
		loadingUser,
	]);

	return (
		<div>
			{(loadingLogout || logginOutAllUserSessions) && (
				<Backdrop sx={{ color: '#fff', zIndex: 10 }} open={true}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
			<ScrollToTop />
			{maintanance && authenticated && <Navigate to='/logout' />}
			{!onlineStatus && <Offline />}
			{!fullAuthComponent && <Navbar />}
			{(!disabled || (componentName === 'appeal' && disabled)) &&
			(navigate ||
				(fullAuthComponent && emailVerified && credentials.handle) ||
				(!useAuth && !fullAuthComponent && authenticated && emailVerified) ||
				useAuth ||
				fullAccess) ? (
				<Component />
			) : authenticated && !emailVerified && loadingUser ? (
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={true}
				>
					<CircularProgress color='inherit' />
				</Backdrop>
			) : (
				<AuthRequired />
			)}

			{disabled && window.location.pathname !== '/appeal' && (
				<Navigate to='/appeal' />
			)}

			{(loadingLogin || loadingUser || loadingSignup) &&
				authenticated &&
				!useAuth &&
				!fullAccess &&
				onlineStatus && (
					<Dialog open={true} fullScreen>
						<Helmet>
							<title>Loading - Goodbye App</title>
						</Helmet>
						{!timedOut && <LinearProgress />}
						<div className='skyBackgroundFill'>
							<div className='pd2Media'>
								<div
									className={`authLoadingCard pdt ${
										timedOut && mode === 'dark' ? 'dbc' : ''
									}`}
								>
									{timedOut ? (
										<div>
											<h1 className='title mg0 center'>Timed out</h1>
											<CustomAlerts info={t('unable_to_verify_token')} />
											<CustomAlerts
												success={true}
												severity='warning'
												message={t('verify_account_first', {
													email: credentials.email,
												})}
												margin='0.5rem 0'
												icon={<MailIcon />}
											/>

											<CustomButton
												fullWidth
												id='customCancelButton'
												onClick={() => logout()}
												startIcon={<LogoutIcon />}
												btnText={t('return_to_log_in')}
											/>
										</div>
									) : (
										<div>
											<div className='center'>
												<img
													src={blueLogo}
													className='offlineLogo'
													alt='Goodbye App Logo'
												/>
												<div className='flex alignItemsCenter center'>
													<img
														className='gbHomeLogo invert'
														// className={`gbHomeLogo ${
														// 	mode === 'light' ? 'invert' : ''
														// }`}
														src={gbaLogo}
														alt='Goodbye App'
													/>{' '}
													<span className='registeredLoading bold dark'>®</span>
												</div>
											</div>
											<CustomAlerts
												info={t('authenticating')}
												icon={<AccountCircleIcon />}
												close
												closeIcon={
													<CircularProgress
														style={{ marginLeft: '1rem' }}
														size={16}
													/>
												}
												margin='0'
											/>
										</div>
									)}
								</div>
							</div>

							<div className='offlineContainerGB mgX'>
								<small className='website-rights animation'>
									© Copyright 2024 Goodbye App®, Corporation
								</small>
							</div>
						</div>
					</Dialog>
				)}
		</div>
	);
};

AuthLoadingComponent.propTypes = {
	authenticated: PropTypes.bool.isRequired,
	emailVerified: PropTypes.bool.isRequired,
	loadingLogin: PropTypes.bool,
	loadingSignUp: PropTypes.bool,
	loadingUser: PropTypes.bool,
	useAuth: PropTypes.bool,
	onlineStatus: PropTypes.bool.isRequired,
	fullAuthComponent: PropTypes.bool,
	navigate: PropTypes.bool,
	logoutAllTabs: PropTypes.func.isRequired,
	refreshAllTabsListener: PropTypes.func.isRequired,
	logoutAllTabsListener: PropTypes.func.isRequired,
	changeColorModeAllTabsListener: PropTypes.func.isRequired,
	logoutUser: PropTypes.func.isRequired,
	refreshAllTabs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	credentials: state.user.credentials,
	authenticated: state.user.authenticated,
	emailVerified: state.user.emailVerified,
	disabled: state.user.credentials.disabled,
	loadingLogin: state.user.loadingLogin,
	loadingSignup: state.user.loadingSignup,
	loadingLogout: state.user.loadingLogout,
	loadingUser: state.user.loadingUser,
	logginOutAllUserSessions: state.user.logginOutAllUserSessions,
	onlineStatus: state.UI.onlineStatus,
	mode: state.UI.mode,
});

const mapActionsToProps = {
	refreshAllTabsListener,
	logoutAllTabsListener,
	changeColorModeAllTabsListener,
	logoutUser,
	logoutAllTabs,
	refreshAllTabs,
};

export default connect(
	mapStateToProps,
	mapActionsToProps,
)(AuthLoadingComponent);
