import { Fragment, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LanguageIcon from '@mui/icons-material/Language';
import CustomAlerts from './CustomAlerts';
import { InputLabel, LinearProgress, MenuItem } from '@mui/material';
import i18next from 'i18next';
import { t } from 'i18next';
import CustomButton from './CustomButton';
import { useSelector } from 'react-redux';
import { setLanguage } from '../../redux/actions/userActions';
import store from '../../redux/store';

export default function SetLang({ mobile, closeDialog }) {
	const localLang = localStorage.getItem('i18nextLng');
	const [open, setOpen] = useState(false);
	const langSetToday = useSelector(
		(state) => state.user.credentials.langSetToday,
	);
	const settingLang = useSelector((state) => state.user.settingLang);

	useEffect(() => {
		if (localLang) {
			if (localLang === 'ar') {
				document.documentElement.setAttribute('dir', 'rtl');
			}
		}
		if (mobile) {
			setOpen(true);
		}
	}, []);

	const handleChange = (event) => {
		const language = event.target.value;

		i18next.changeLanguage(language);
		if (langSetToday < 40) {
			store.dispatch(setLanguage(language));
		}
		if (language === 'ar') {
			document.documentElement.setAttribute('dir', 'rtl');
		} else {
			document.documentElement.setAttribute('dir', 'ltr');
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		if (closeDialog) {
			closeDialog();
		}
	};

	return (
		<Fragment>
			{!mobile && (
				<div className='flexColumn nav-links-user mgX'>
					<div
						className='flexColumn alignItemsCenter'
						onClick={handleClickOpen}
					>
						<LanguageIcon className='white' />
						<span className='capitalize'>{t('language')}</span>
					</div>
				</div>
			)}

			<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
				<CustomAlerts
					info={t('language')}
					icon={<LanguageIcon />}
					noMargin
					close
					onClose={handleClose}
				/>
				{settingLang && <LinearProgress />}
				<DialogContent sx={{ padding: '1.5rem 1rem' }}>
					<FormControl sx={{ minWidth: 120 }} fullWidth>
						<InputLabel>{t('language')}</InputLabel>
						<Select
							value={localLang}
							onChange={handleChange}
							input={<OutlinedInput label={t('language')} />}
							id='centerSelect'
						>
							<MenuItem value='en'>
								<img
									src='https://flagpedia.net/data/flags/w1160/us.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>
								{t('english')}
							</MenuItem>
							<MenuItem value='es'>
								<img
									src='https://flagpedia.net/data/flags/h160/es.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>
								{t('spanish')}
							</MenuItem>
							<MenuItem value='zh'>
								<img
									src='https://flagpedia.net/data/flags/h160/cn.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>

								{t('chinese')}
							</MenuItem>
							<MenuItem value='ar'>
								<img
									src='https://flagpedia.net/data/flags/h160/sa.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>
								{t('arabic')}
							</MenuItem>
							<MenuItem value='fr'>
								<img
									src='https://flagpedia.net/data/flags/h160/fr.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>
								{t('french')}
							</MenuItem>
							<MenuItem value='pt'>
								<img
									src='https://flagpedia.net/data/flags/h160/pt.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>
								{t('portuguese')}
							</MenuItem>
							<MenuItem value='ru'>
								<img
									src='https://flagpedia.net/data/flags/h160/ru.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>
								{t('russian')}
							</MenuItem>
							<MenuItem value='ja'>
								<img
									src='https://flagpedia.net/data/flags/h160/jp.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>

								{t('japanese')}
							</MenuItem>
							<MenuItem value='de'>
								<img
									src='https://flagpedia.net/data/flags/h160/de.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>
								{t('german')}
							</MenuItem>
							<MenuItem value='uk'>
								<img
									src='https://flagpedia.net/data/flags/h160/ua.webp'
									alt={t('english')}
									width='20px'
									height='15px'
									className='mgrH'
								/>

								{t('ukrainian')}
							</MenuItem>
						</Select>
					</FormControl>

					<CustomButton
						btnText={t('close')}
						id='customCancelButton'
						margin='1.5rem auto 0'
						onClick={handleClose}
					/>
				</DialogContent>
			</Dialog>
		</Fragment>
	);
}
