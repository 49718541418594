import { Tooltip } from '@mui/material';
import { useState } from 'react';
import CustomTooltip from './CustomTooltip';
import { useTranslation } from 'react-i18next';

export default function CopyText({
	text,
	btnText,
	frase,
	info,
	placement,
	icon,
	component,
	noMargin,
	noTooltip,
	small,
}) {
	const [copied, setCopied] = useState(false);
	const { t } = useTranslation();
	const copyText = (text) => {
		navigator.clipboard.writeText(text);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 1000);
	};
	return (
		<CustomTooltip
			arrow
			placement={placement ? placement : 'right'}
			title={copied ? t('copied') : noTooltip ? '' : t('copy')}
		>
			<div onClick={() => copyText(text)}>
				{icon ? (
					icon
				) : component ? (
					<div className='flex alignItemsCenter '>
						{component}
						<p
							className={`pointer mg0 ${noMargin ? '' : 'mglH'} ${
								small ? 'smallText' : ''
							}`}
						>
							{' '}
							{btnText ? btnText : text}
						</p>
					</div>
				) : (
					<p
						className={`pointer mg0  ${noMargin ? '' : 'mglH'} ${
							small ? 'smallText' : ''
						}`}
					>
						{frase && frase} {btnText ? btnText : text}
					</p>
				)}
			</div>
		</CustomTooltip>
	);
}
